<script>
    import {HorizontalBar} from 'vue-chartjs'
    export default {
        props:["data","option"],
        name: "HorizontalBarChart",
        extends: HorizontalBar,
        mounted() {
            this.renderChart(this.data, this.option)
        }
    }
</script>

<style scoped>

</style>