import BaseResponse from "@/model/response/BaseResponse";

class InvestmentResponse extends BaseResponse {
    readSingleResponse() {
        return {

            investmentCreatedAt: null,
            investmentUpdatedAt: null,
            responseCode: null,
            responseMessage: null,
        };
    }
    readResponse() {
        return {
            responseCode: null,
            responseMessage: null,
            data: [],
        };
    }
}

export default InvestmentResponse;
