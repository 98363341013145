import BaseResponse from "@/model/response/BaseResponse";

class CampaignResponse extends BaseResponse {
  readSingleResponse() {
    return {
      campaignId: null,
      customerOrganisationCustomerId: null,
      campaignName: null,
      campaignType: null,
      campaignDescription: null,
      campaignBudgetExhausted: null,
      campaignClickCount: null,
      campaignStartDate: null,
      campaignBanners: null,
      campaignView: null,
      campaignChannel: null,
      campaignEndDate: null,
      campaignCategory: null,
      campaignBudget: null,
      campaignAudience: null,
      campaignCreatedAt: null,
      campaignUpdatedAt: null,
      campaignRegion: null,
      relatedCampaigns: [],
      responseCode: null,
      responseMessage: null,
    };
  }
  form() {
    return {
      campaignId: null,
      campaignCustomerId: null,
      campaignName: null,
      campaignDescription: null,
      campaignOrganisationId: null,
      organisationCategory: null,
      campaignBanners: null,
      campaignType: null,
      campaignStartDate: null,
      campaignLink: null,
      campaignLink2: null,
      campaignChannel: null,
      campaignEndDate: null,
      campaignCategory: null,
      campaignBudget: "",
      campaignAudience: null,
      campaignRegion: null,
    };
  }
  readResponse() {
    return {
      responseCode: null,
      responseMessage: null,
      data: [],
    };
  }
  readCampaignCategory() {
    return {
      responseCode: null,
      responseMessage: null,
      data: [
        { label: "All", value: "ALL" },
        { label: "Banking", value: "BANKING" },
        { label: "Education", value: "EDUCATION" },
        { label: "Technology", value: "TECHNOLOGY" },
        { label: "Fintech", value: "FINTECH" },
        { label: "SME", value: "SME" },
      ],
    };
  }
}

export default CampaignResponse;
