class ReviewResponse {
  reviewCreateResponse() {
    return {
      responseCode: null,
      responseMessage: null,
    };
  }
  reviewReadResponse() {
    return {
      responseCode: null,
      responseMessage: null,
      data: [
        {
          reviewId: null,
          reviewOrderId: null,
          reviewRating: null,
          reviewTopic: null,
          reviewBody: null,
          reviewProductId: null,
          reviewStatus: null,
          reviewCreatedAt: null,
        },
      ],
    };
  }
  static  reviewReadByIdResponse = {
    responseMessage: null,
    responseCode: null,
    reviewId: null,
    reviewOrderId: null,
    reviewProductId: null,
    reviewRating: null,
    reviewTopic: null,
    reviewBody: null,
    reviewCreatedAt: null,
    reviewStatus: null
  }
  static reviewReadByProductIdResponse = {
    responseCode: null,
    responseMessage: null,
    data: [
      {
        reviewId: null,
        reviewOrderId: null,
        reviewRating: null,
        reviewTopic: null,
        reviewBody: null,
        reviewStatus: null,
        reviewCreatedAt: null,
      },
    ],
  };
  reviewUpdateResponse() {
    return {
      responseCode: null,
      responseMessage: null,
    };
  }
  reviewDeleteResponse() {
    return {
      responseCode: null,
      responseMessage: null,
    };
  }
}

export default ReviewResponse;
