<template>
  <div class="main-dashboard-section wf-section">
    <div class="main-dashboard-body mall-copy">
      <div class="container-v2">
        <div class="div-block-28-copy">
          <div class="breadcrumb">
            <div class="text-block-145">
              <router-link to="/mall-customer-view" class="breadcrumblink"
                >Back to mall</router-link
              >
            </div>
          </div>
          <div  class="product-header customer">
            <div   class="div-block-269">
              <div  class="div-block-37">
                <a href="#" class="lightbox-link w-inline-block w-lightbox">
                  <img
                    :src="product.productImage.split(',')[0]"
                    loading="lazy"
                    width="46"
                    alt=""
                    class="image-63"
                  />
                </a>
                <a href="#" class="lightbox-link w-inline-block w-lightbox">
                  <img
                    :src="product.productImage.split(',')[1]"
                    loading="lazy"
                    width="46"
                    alt=""
                    class="image-63"
                  />
                </a>
                <a href="#" class="lightbox-link w-inline-block w-lightbox">
                  <img
                    :src="product.productImage.split(',')[2]"
                    loading="lazy"
                    width="46"
                    alt=""
                    class="image-63"
                  />
                </a>
              </div>
              <div  class="product-image"
                   style="height: 10px; border-radius: 1px">
                <div
                  style="width: 342px; height: 10px"
                  data-delay="4000"
                  data-animation="slide"
                  class="slider w-slider"
                  data-autoplay="false"
                  data-easing="ease"
                  data-hide-arrows="false"
                  data-disable-swipe="false"
                  data-autoplay-limit="0"
                  data-nav-spacing="3"
                  data-duration="500"
                  data-infinite="true"
                >
                  <b-carousel
                    id="carousel-1"
                    v-model="slide"
                    :interval="4000"
                    controls
                    indicators
                    label-next=""
                    label-prev=""
                    background="#ffffff"
                    style="text-shadow: 1px 1px 2px #333; width: 105%; "
                    @sliding-start="onSlideStart"
                    @sliding-end="onSlideEnd"
                  >
                    <b-carousel-slide
                      style="border-radius: 3px 3px 3px 3px "
                      v-for="image in product.productImage.split(',')"
                      v-bind:key="image"
                    >
                      <template #img>
                        <img
                          style=" border-radius: 5px 5px 5px 5px; width: 100%; height: 270px"
                          class="d-block img-fluid"
                          width="606"
                          height="387"
                          :src="image"
                          alt="image slot"
                        />
                      </template>
                    </b-carousel-slide>
                  </b-carousel>
                </div>
              </div>
              <div class="div-block-38">
                <div class="text-block-142">{{ product.productName }}</div>
                <div class="div-block266">
                  <div class="text-block-143">₦ {{product.productLocalCurrencyPrice | formatAmount}}   <span class="dollartext">(${{ product.productUsdPrice }})</span></div>

                  <!--                  <div class="text-block-143 usdamounttext">-->
<!--                    (${{ product.productUsdPrice }})-->
<!--                  </div>-->
                </div>
                <div class="div-block-267">
                  <!--                  <img :src="require('@/assets/images/Frame-65.png')" loading="lazy" width="103" alt="">-->
                  <!--                  <div class="text-block-158">5.0</div>-->
                  <!--                  <div class="text-block-159">(104)</div>-->
                  <!--                  <star-rating-->
                  <!--                    :star-size="20"-->
                  <!--                  ></star-rating>-->
                </div>
                <!--                <div class="quantity">-->
                <!--                  <div class="text-block-160">Quantity</div>-->
                <!--                  <div class="div-block-268">-->
                <!--                    <button @click="reduceQty()" href="#" :disabled="checkQty"  class="button-29 w-button"></button>-->
                <!--                    <div>{{count}}</div>-->
                <!--                    <a @click="addQty()" style="color: black; padding: 0.5rem; " href="#" class="button-30 w-button">+</a>-->
                <!--                  </div>-->
                <!--                </div>-->
                <a data-w-id="cda450f4-fef0-c5a1-8394-f27025b48e11"
                  :href="buy.redirectURL"
                  class="btn edit-profile-btn-copy w-button"
                  >Buy product</a>
              </div>
            </div>
            <div class="div-block-284">
              <div style="padding-top: 3rem"
                class="text-block-161">
                Product Description
              </div>
              <p style="padding-top: 1rem" class="paragraph-19">
                {{ product.productDescription }}
              </p>
            </div>
          </div>
          <!--          <div  class="div-block-270">-->
          <!--            <div class="" style=" display: flex;justify-content: space-between">-->
          <!--              <div class="div-block-276">-->
          <!--                <div  class="text-block-162">Reviews</div>-->
          <!--                <div class="div-block-267">-->
          <!--&lt;!&ndash;                  <img :src="require('@/assets/images/Frame-65.png')" loading="lazy" width="103" alt="">&ndash;&gt;-->
          <!--&lt;!&ndash;                  <div class="text-block-158">5.0</div>&ndash;&gt;-->
          <!--                  <star-rating-->
          <!--                    :rating="5.0"-->
          <!--                    :read-only="true"-->
          <!--                    :star-size="20"-->
          <!--                  ></star-rating>-->
          <!--                  <div class="text-block-159">(104 Reviews)</div>-->
          <!--                </div>-->
          <!--                <div class="text-block-163">-->
          <!--                  100% of customers recommend this product-->
          <!--                </div>-->
          <!--                <div class="ratings">-->
          <!--                  <div class="div-block-272">-->
          <!--                    <div class="text-block-164">5 stars</div>-->
          <!--                    <div class="div-block-273">-->
          <!--                      <div class="div-block-274 _5stars"></div>-->
          <!--                    </div>-->
          <!--                    <div class="text-block-165">(50)</div>-->
          <!--                  </div>-->
          <!--                  <div class="div-block-272">-->
          <!--                    <div class="text-block-164">4 stars</div>-->
          <!--                    <div class="div-block-273">-->
          <!--                      <div class="div-block-274 _4stars"></div>-->
          <!--                    </div>-->
          <!--                    <div class="text-block-165">(37)</div>-->
          <!--                  </div>-->
          <!--                  <div class="div-block-272">-->
          <!--                    <div class="text-block-164">3 stars</div>-->
          <!--                    <div class="div-block-273">-->
          <!--                      <div class="div-block-274 _3stars"></div>-->
          <!--                    </div>-->
          <!--                    <div class="text-block-165">(37)</div>-->
          <!--                  </div>-->
          <!--                  <div class="div-block-272">-->
          <!--                    <div class="text-block-164">2 stars</div>-->
          <!--                    <div class="div-block-273">-->
          <!--                      <div class="div-block-274 _2stars"></div>-->
          <!--                    </div>-->
          <!--                    <div class="text-block-165">(22)</div>-->
          <!--                  </div>-->
          <!--                  <div class="div-block-272">-->
          <!--                    <div class="text-block-164">1 stars</div>-->
          <!--                    <div class="div-block-273">-->
          <!--                      <div class="div-block-274 _1star"></div>-->
          <!--                    </div>-->
          <!--                    <div class="text-block-165">(10)</div>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--              <div class="div-block-277">-->
          <!--                <div class="text-block-166">Most Recent Review</div>-->
          <!--                <div class="div-block-267">-->
          <!--&lt;!&ndash;                  <img :src="require('@/assets/images/Frame-65.png')" loading="lazy" width="103" alt="">&ndash;&gt;-->
          <!--&lt;!&ndash;                  <div class="text-block-158">5.0</div>&ndash;&gt;-->
          <!--                  <star-rating-->
          <!--                    :rating="5.0"-->
          <!--                    :read-only="true"-->
          <!--                    :star-size="20"-->
          <!--                  ></star-rating>-->

          <!--                </div>-->
          <!--                <div class="text-block-167">Verified Purchase</div>-->
          <!--                <div class="div-block-275">-->
          <!--                  <div class="text-block-168">Nice Shoes</div>-->
          <!--                  <div class="text-block-169"> The fabric is good quality but what i loved most was that the pearls arent sown by thread, they are attached with a metal kind of like earrings</div>-->
          <!--                </div>-->
          <!--                <a data-w-id="ade4fe4b-055e-35b3-8368-ebf2dd73e3fe" href="#" class="apvertise-button review w-button">View all Reviews</a>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->

          <!--          <div class="div-block-270 emptystate">-->
          <!--            <img :src="require('@/assets/images/untit4led-1.png')" loading="lazy" width="214" alt="">-->
          <!--            <div class="text-block-170">No Reviews Yet<br>When a customer leaves a review it’ll appear here</div>-->
          <!--          </div>-->
          <p class="paragraph-20">More items from this merchant</p>
          <!--          <div class="w-layout-grid grid-6-copy">-->
          <!--            <div id="w-node-_21287286-73b8-4af6-c926-58e364c9d777-c9adb406" class="product-card">-->
          <!--              <img id="w-node-_21287286-73b8-4af6-c926-58e364c9d778-c9adb406" loading="lazy" :src="require('@/assets/images/Rectangle-458.png')" alt="">-->
          <!--              <div class="product-details">-->
          <!--                <div class="product-name">Apple MacBook Pro 13&quot; I7, 4GB RAM 1TB HDD, Silver...</div>-->
          <!--                <div class="price">₦ 469,000</div>-->
          <!--                <a href="#" class="btn view-product-btn w-button">View Product</a>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
          <div class="w-layout-grid grid-6" style="width: 100%">
            <div
              id="w-node-_919e8174-62fc-3f62-43aa-05e8085252c5-26baae02"
              class="product-card card"
              v-for="i in products"
              :key="i.productId">
              <!--              <div class="card-header bbb" style="height: 125px;" :style="{'backgroundImage':`url(${i.productImage.split(',')[0]})`}">-->
              <img
                id="w-node-_919e8174-62fc-3f62-43aa-05e8085252c6-26baae02"
                loading="lazy"
                :src="i.productImage.split(',')[0]"
                alt=""
                class="img-fluid img"
              />
              <!--              </div>-->
              <div class="product-details card-body">
                <div class="product-name">
                  {{ i.productName }}
                </div>
                <div class="price">
                  ₦ {{ i.productLocalCurrencyPrice | formatAmount }}</div>
              <div class="">
                <a
                  href="#upMove"
                  @click="viewProduct(i.productId)"
                  class="btn view-product-btn w-button"
                  style="vertical-align: bottom; alignment: bottom"
                  >View Product</a>
              </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    <customer-details-modal :show="showModal"/>-->
  </div>
</template>

<script>
// import StarRating from "vue-star-rating";
import OrganisationRequest from "@/model/request/OrganisationRequest";
import { mapState } from "vuex";
import ProductRequest from "@/model/request/ProductRequest";
import OrderRequest from "@/model/request/OrderRequest";
// import CustomerDetailsModal from "@/components/modal/CustomerDetailsModal";
export default {
  name: "ProductCustomerView",
  data() {
    return {
      model: OrganisationRequest.prototype.organisationReadSingleRequest(),
      allProducts: ProductRequest.prototype.productReadByOrgRequest(),
      currentProduct: ProductRequest.productReadByProductIdRequest,
      obtainProduct: ProductRequest.buyProductRequest,
      showModal: true,
      orderModel: OrderRequest.prototype.orderCreateRequest(),
      slide: 0,
      count: 0,
      sliding: null,
      image:
        "https://i.insider.com/61fc2855b0ec040018d0baa4?width=1136&format=jpeg",
    };
  },
  computed: {
    ...mapState(["product", "organisation", "auth"]),
    ...mapState({
      organisation: (state) => state.organisation.organisation,
      organisations: (state) =>
        state.organisation.organisations[0].organisationId,
      products: (state) => state.product.product.data,
      productObj: (state) => state.product.productDetails,
      product: (state) => state.product.singleProduct,
      buy: (state) => state.product.purchaseProduct,
      // productImages: function(){
      //   if (this.product.productDetails.productImage == null) return []
      //   return this.product.productDetails.productImage.split(",")
      // },
    }),
    checkQty() {
      return this.count === 0;
    },
  },
  components: {
    // StarRating
  },
  methods: {
    addQty() {
      this.count++;
    },
    reduceQty() {
      this.count--;
    },
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
    viewProduct(i) {
      localStorage.productId = i;
      location.reload();
      this.currentProduct.productId = localStorage.productId;
      this.$store.dispatch("updateSingleProduct", this.singleProduct);
      this.$router.push("/product-merchant-view");
      // this.$store.commit("product/updateProductDetails", product);
      // // this.$router.push("/product-merchant-view")
    },

    // custOrMerch(){
    //
    //   localStorage.productID = prop
    //   this.$store.dispatch("property/updateProduct", prop)
    //   this.proId = localStorage.propertyId
    //   console.log(this.proId)
    //   if(this.auth.customerId = organisation.customerOrganisationCustomerId ){
    //     this.$router.push("/product-merchantView")
    //
    //   } else{
    //     this.$router.push("/product-customer-view")
    //
    //   }
    //
    // z
  },
  mounted() {
    this.currentProduct.productId = localStorage.productId;
    this.$store.dispatch("product/updateSingleProduct", this.currentProduct);
    // this.allProducts.productOrganisationId = localStorage.organisationId;
    (this.allProducts.productOrganisationId = localStorage.organisationId),
      this.$store.dispatch("product/updateProduct", this.allProducts);
    this.obtainProduct.productId = localStorage.productId;
    this.$store.dispatch("product/updatePurchaseProduct", this.obtainProduct);
    console.log(localStorage.productId);
  },

  created() {
    console.log("use");
    const script = document.createElement("script");
    script.src = "https://apvertise.com/js/webflow.js";
  },
};
</script>

<style scoped>
.btn{
  color: #5DC9A0 !important;
  border: 1px solid #5DC9A0 !important;
}
.card{
  border-radius: 7px;
  width: 207px;
  height: 280px;
}
.price{
  margin: 0;
}
.card-body{
  margin: 0;
}

.img{
  border-top-left-radius: 7px;
  border-top-right-radius:7px ;
}
.view-product-btn{
  margin: 0;
}


.div-block-38 {
  width: 398px;
  padding-left: 3rem;
}
.text-block-161 {
  margin-bottom: 5px;
  color: #15253d;
  font-size: 16px;
  font-weight: 500;

}
.div-block-269 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.div-block-284 {
  margin-top: 10.6rem;
}
.product-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 64px;
  padding-bottom: 60px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  border-bottom: 1.5px solid rgba(15, 79, 170, 0.1);
}
.product-header.customer {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}


.dollartext{
  color: #63b8a0;
  width: 25%;
  padding-left: 0.5rem;
}
.div-block-37{
  flex-direction: column;
}
.div-block-38 {
  width: 400px;
}

@media screen and (max-width: 767px){

  .div-block-38 {
    width: auto;
  }
  .product-header {
    margin-bottom: 40px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .product-header.customer {
    padding-bottom: 40px;
  }
  .div-block-38 {
    width: auto;
  }
  .div-block-269{
    flex-direction: column;
  }
  .div-block-37 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .product-header.customer {
    padding-bottom: 40px;
  }

}

@media screen and (max-width: 479px){

  .div-block-38 {
    width: 100%;
    text-align: left;
  }
  .text-block-161 {
    text-align: left;
  }
  .product-header.customer {
    margin-top: 40px;
  }
  .div-block-269{
    flex-direction: column;
  }
  .div-block-37 {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    grid-column-gap: 30px;
  }

}


@media screen and (max-width: 991px){
  .div-block-37 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 10px;
    margin-right: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    grid-column-gap: 15px;
  }
}





.div-block-268 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 120px;
  height: 36px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(21, 37, 61, 0.1);
  border-radius: 2px;
}


.btn.edit-profile-btn-copy {
  margin-top: 20px;
  border-color: #e8e9eb;
  margin-right: 0px;
  background-image: none;
  font-weight: 500;
  font-size: 14px;
}


</style>
