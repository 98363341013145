<template>
  <api-documentation/>
</template>

<script>
import ApiDocumentation from "@/components/documentation/ApiDocumentation.vue";

export default {
  name: "ApiDocumentationView",
  components: { ApiDocumentation }
};
</script>

<style scoped>

</style>