// import router from "@/router";
import MaintenanceService from "@/service/MaintenanceService";
// import MaintenanceRequest from "@/model/request/MaintenanceRequest";
import MaintenanceResponse from "@/model/response/MaintenanceResponse";
import swal from "sweetalert";

export const state = {
  maintenance: MaintenanceResponse.prototype.readSingleResponse(),
  maintenances: MaintenanceResponse.prototype.readResponse(),
  bankListFlw: { responseCode: "", responseMessage: "", data: [] },
  maintenanceLoading: false,
};
export const getters = {};
export const mutations = {
  updateMaintenance: (state, payload) => {
    state.maintenance = payload;
  },
  updateMaintenances: (state, payload) => {
    state.maintenances = payload;
  },
  updateBankListFlw: (state, payload) => {
    state.bankListFlw = payload;
  },
  updateMaintenanceLoading: (state, payload) => {
    state.maintenanceLoading = payload;
  },
};
export const actions = {
  processSms: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateMaintenanceLoading", true);
    return MaintenanceService.callProcessSmsApi(payload)
      .then((response) => {
        commit("updateMaintenanceLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          commit("updateMaintenance", responseData);
        } else {
          swal("Error", responseData.responseMessage, "error");
        }
      })
      .catch((err) => {
        commit("updateMaintenanceLoading", false);
        console.log(err);
      });
  },

  processEmail: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateMaintenanceLoading", true);
    return MaintenanceService.callProcessEmailApi(payload)
      .then((response) => {
        commit("updateMaintenanceLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          commit("updateMaintenances", responseData);
        }
      })
      .catch((err) => {
        commit("updateMaintenanceLoading", false);
        console.log(err);
      });
  },

  bankList: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateMaintenanceLoading", true);
    return MaintenanceService.callBankListApi(payload)
      .then((response) => {
        commit("updateMaintenanceLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          swal("Success", responseData.responseMessage, "success");
        } else {
          swal("Error", responseData.responseMessage, "error");
        }
      })
      .catch((err) => {
        commit("updateMaintenanceLoading", false);
        console.log(err);
      });
  },

  bankListFlwApi: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateMaintenanceLoading", true);
    return MaintenanceService.callBankListFlwApi(payload)
      .then((response) => {
        commit("updateMaintenanceLoading", false);
        let responseData = response.data;
        commit("updateBankListFlw", responseData);
      })
      .catch((err) => {
        commit("updateMaintenanceLoading", false);
        console.log(err);
      });
  },

  readBeneficiary: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateMaintenanceLoading", true);
    return MaintenanceService.callReadBeneficiaryApi(payload)
      .then((response) => {
        commit("updateMaintenanceLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          swal("Success", responseData.responseMessage, "success");
        } else {
          swal("Error", responseData.responseMessage, "error");
        }
      })
      .catch((err) => {
        commit("updateMaintenanceLoading", false);
        console.log(err);
      });
  },

  removeBeneficiary: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateMaintenanceLoading", true);
    return MaintenanceService.callRemoveBeneficiaryApi(payload)
      .then((response) => {
        commit("updateMaintenanceLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          swal("Success", responseData.responseMessage, "success");
        } else {
          swal("Error", responseData.responseMessage, "error");
        }
      })
      .catch((err) => {
        commit("updateMaintenanceLoading", false);
        console.log(err);
      });
  },

  bankListFromAccount: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateMaintenanceLoading", true);
    return MaintenanceService.callBankListFromAccountApi(payload)
      .then((response) => {
        commit("updateMaintenanceLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          swal("Success", responseData.responseMessage, "success");
        } else {
          swal("Error", responseData.responseMessage, "error");
        }
      })
      .catch((err) => {
        commit("updateMaintenanceLoading", false);
        console.log(err);
      });
  },
};
