import MallGetStarted from "../../views/mall/MallGetStarted";
import AddProducts from "../../views/mall/AddProducts";
import AddProductsSuccess from "../../views/mall/AddProductsSuccess";
import BusinessDetails from "../../views/mall/BusinessDetails";
import MallNewUser from "../../views/mall/MallNewUser";
import MallRegistered from "../../views/mall/MallRegistered";
import MallCreationSuccess from "../../views/mall/MallCreationSuccess";
import AddProductsTwo from "../../views/mall/AddProductsTwo";
import ProductMerchantView from "@/views/mall/ProductMerchantView";
import MallAll from "@/views/mall/MallAll";
import MallCustomerView from "@/views/mall/MallCustomerView";
import ProductCustomerView from "@/views/mall/ProductCustomerView";
import EditProducts from "@/views/mall/EditProducts";
import EditMall from "@/views/mall/EditMall";

export default [
  {
    path: "/mall-get-started",
    name: "MallGetStarted",
    meta: { layout: "dashboard", authRequired: true },
    component: MallGetStarted,
  },
  {
    path: "/add-products",
    name: "AddProducts",
    meta: { layout: "dashboard", authRequired: true },
    component: AddProducts,
  },
  {
    path: "/add-products-two",
    name: "AddProductsTwo",
    meta: { layout: "dashboard", authRequired: true },
    component: AddProductsTwo,
  },
  {
    path: "/add-products-success",
    name: "AddProductsSuccess",
    meta: { layout: "dashboard", authRequired: true },
    component: AddProductsSuccess,
  },
  {
    path: "/business-details",
    name: "BusinessDetails",
    meta: { layout: "dashboard", authRequired: true },
    component: BusinessDetails,
  },
  {
    path: "/mall-new-user",
    name: "MallNewUser",
    meta: { layout: "dashboard", authRequired: true },
    component: MallNewUser,
  },
  {
    path: "/mall-registered",
    name: "MallRegistered",
    meta: { layout: "dashboard", authRequired: true },
    component: MallRegistered,
  },
  {
    path: "/mall-customer-view",
    name: "MallCustomerView",
    meta: { layout: "dashboard", authRequired: true },
    component: MallCustomerView,
  },
  {
    path: "/mall-creation-success",
    name: "MallCreationSuccess",
    meta: { layout: "dashboard", authRequired: true },
    component: MallCreationSuccess,
  },
  {
    path: "/product-merchant-view",
    name: "ProductMerchantView",
    meta: { layout: "dashboard", authRequired: true },
    component: ProductMerchantView,
  },
  {
    path: "/product-customer-view",
    name: "ProductCustomerView",
    meta: { layout: "dashboard", authRequired: true },
    component: ProductCustomerView,
  },
  {
    path: "/mall-all",
    name: "MallAll",
    meta: { layout: "dashboard", authRequired: true },
    component: MallAll,
  },
  {
    path: "/edit-products",
    name: "EditProducts",
    meta: { layout: "dashboard", authRequired: true },
    component: EditProducts,
  },
  {
    path: "/edit-mall",
    name: "EditMall",
    meta: { layout: "dashboard", authRequired: true },
    component: EditMall,
  },
];
