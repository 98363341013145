// import router from "@/router";
import ContractService from "@/service/ContractService";
// import ContractRequest from "@/model/request/ContractRequest";
import ContractResponse from "@/model/response/ContractResponse";
import swal from "sweetalert";

export const state = {
  contract: ContractResponse.prototype.readSingleResponse(),
  contracts: ContractResponse.prototype.readResponse(),
  contractLoading: false,
};

export const getters = {};

export const mutations = {
  updateContract: (state, payload) => {
    state.contract = payload;
  },
  updateContracts: (state, payload) => {
    state.contracts = payload;
  },
  updateContractLoading: (state, payload) => {
    state.contractLoading = payload;
  },
};

export const actions = {
  createContract: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateContractLoading", true);
    return ContractService.callCreateContractApi(payload)
      .then((response) => {
        commit("updateContractLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          commit("updateContract", responseData);
        } else {
          swal("Error", responseData.responseMessage, "error");
        }
      })
      .catch((err) => {
        commit("updateContractLoading", false);
        console.log(err);
      });
  },

  readContract: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateContractLoading", true);
    return ContractService.callReadContractApi(payload)
      .then((response) => {
        commit("updateContractLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          commit("updateContracts", responseData);
        }
      })
      .catch((err) => {
        commit("updateContractLoading", false);
        console.log(err);
      });
  },

  updateContract: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateContractLoading", true);
    return ContractService.callUpdateContractApi(payload)
      .then((response) => {
        commit("updateContractLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          swal("Success", responseData.responseMessage, "success");
        } else {
          swal("Error", responseData.responseMessage, "error");
        }
      })
      .catch((err) => {
        commit("updateContractLoading", false);
        console.log(err);
      });
  },

  updateContractStatus: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateContractLoading", true);
    return ContractService.callUpdateContractStatusApi(payload)
      .then((response) => {
        commit("updateContractLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          swal("Success", responseData.responseMessage, "success");
        } else {
          swal("Error", responseData.responseMessage, "error");
        }
      })
      .catch((err) => {
        commit("updateContractLoading", false);
        console.log(err);
      });
  },
};
