
import client from "./BaseService";

export default {
    callCardDetailsApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("apvCardDetails", payload);
    },
    callCardSendPinApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("apvCardSendPin", payload);
    },
    callCardSendOtpApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("apvCardSendOtp", payload);
    },
    callReadSavedCardApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("apvReadSavedCard", payload);
    },
    callValidateChargeFromSavedCardApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("apvValidateChargeFromSavedCard", payload);
    },

    callUpdateSavedCardStatusApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("apvUpdateSavedCardStatus", payload);
    },
    callRequestDebitCardApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("apvRequestDebitCard", payload);
    },
    callReadCustomerCardApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("apvReadCustomerCard", payload);
    },
    callRubiesCardFundingApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("rubiesCardFunding", payload);
    },
    callRubiesCardFundingOtpApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("rubiesCardFundingOtp", payload);
    },
};
