
import client from "./BaseService";

export default {
    callCreateContractApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("apvCreateContract", payload);
    },
    callReadContractApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("apvReadContract", payload);
    },
    callUpdateContractApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("apvUpdateContract", payload);
    },
    callUpdateContractStatusApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("apvUpdateContractStatus", payload);
    },

};
