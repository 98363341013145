// import router from "@/router";
import BillService from "@/service/BillService";
// import BillRequest from "@/model/request/BillRequest";
import BillResponse from "@/model/response/BillResponse";
import swal from "sweetalert";

export const state = {
  bill: BillResponse.prototype.readSingleResponse(),
  bills: BillResponse.prototype.readResponse(),
  billLoading: false,
};

export const getters = {
  getTok: (state) => {
    // console.log("getter getTok")
    return state.bill;
  },
};

export const mutations = {
  updateBill: (state, payload) => {
    state.bill = payload;
  },
  updateBills: (state, payload) => {
    state.bills = payload;
  },
  updateBillLoading: (state, payload) => {
    state.billLoading = payload;
  },
};

export const actions = {
  categoryList: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateBillLoading", true);
    return BillService.callCategoryListApi(payload)
      .then((response) => {
        commit("updateBillLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          commit("updateBill", responseData);
        } else {
          swal("Error", responseData.responseMessage, "error");
        }
      })
      .catch((err) => {
        commit("updateBillLoading", false);
        console.log(err);
      });
  },

  billerCategoryList: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateBillLoading", true);
    return BillService.callBillerCategoryListApi(payload)
      .then((response) => {
        commit("updateBillLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          commit("updateBills", responseData);
        }
      })
      .catch((err) => {
        commit("updateBillLoading", false);
        console.log(err);
      });
  },

  billerProductList: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateBillLoading", true);
    return BillService.callBillerProductListApi(payload)
      .then((response) => {
        commit("updateBillLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          swal("Success", responseData.responseMessage, "success");
        } else {
          swal("Error", responseData.responseMessage, "error");
        }
      })
      .catch((err) => {
        commit("updateBillLoading", false);
        console.log(err);
      });
  },

  billerValidation: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateBillLoading", true);
    return BillService.callBillerValidationApi(payload)
      .then((response) => {
        commit("updateBillLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          swal("Success", responseData.responseMessage, "success");
        } else {
          swal("Error", responseData.responseMessage, "error");
        }
      })
      .catch((err) => {
        commit("updateBillLoading", false);
        console.log(err);
      });
  },

  billerPayment: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateBillLoading", true);
    return BillService.callBillerPaymentApi(payload)
      .then((response) => {
        commit("updateBillLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          swal("Success", responseData.responseMessage, "success");
        } else {
          swal("Error", responseData.responseMessage, "error");
        }
      })
      .catch((err) => {
        commit("updateBillLoading", false);
        console.log(err);
      });
  },

  mobileDataProductList: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateBillLoading", true);
    return BillService.callMobileDataProductListApi(payload)
      .then((response) => {
        commit("updateBillLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          swal("Success", responseData.responseMessage, "success");
        } else {
          swal("Error", responseData.responseMessage, "error");
        }
      })
      .catch((err) => {
        commit("updateBillLoading", false);
        console.log(err);
      });
  },

  mobileDataPurchase: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateBillLoading", true);
    return BillService.callMobileDataPurchaseApi(payload)
      .then((response) => {
        commit("updateBillLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          swal("Success", responseData.responseMessage, "success");
        } else {
          swal("Error", responseData.responseMessage, "error");
        }
      })
      .catch((err) => {
        commit("updateBillLoading", false);
        console.log(err);
      });
  },

  airtimePurchase: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateBillLoading", true);
    return BillService.callAirtimePurchaseApi(payload)
      .then((response) => {
        commit("updateBillLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          swal("Success", responseData.responseMessage, "success");
        } else {
          swal("Error", responseData.responseMessage, "error");
        }
      })
      .catch((err) => {
        commit("updateBillLoading", false);
        console.log(err);
      });
  },
};
