<template>
  <div class="dashboard-nav wf-section">
  <div class="main-dashboard-section wf-section">
    <div class="main-dashboard-body mall">
      <div class="content-6">
        <div class="lotti-animation-container">

          <lottie-player
              autoplay
              loop
            mode="normal"
            src="documents/64787-success.json"
            class="lottie-animation"
            style="width: 150px;"
          ></lottie-player>
        </div>
        <h3 class="heading get-started business-verified">Successful</h3>
        <div class="sub verfiy-business details">Your Online mall has been created successfully</div>
        <div class="button-container-3">
          <router-link to="/mall-registered" class="button-4-copy-copy mall w-button">Skip</router-link>
          <router-link to="/add-products" class="apvertise-button w-button">+Add new product</router-link>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  name: "MallCreationSuccess"
};
</script>

<style scoped>
.button-container-3{
  display: flex;
  gap: 20px;
}

.button-container-3 a{
  width: 50%;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>