// import router from "@/router";
import FundTransferService from "@/service/FundTransferService";
// import FundTransferRequest from "@/model/request/FundTransferRequest";
import FundTransferResponse from "@/model/response/FundTransferResponse";
import swal from "sweetalert";

export const state = {
  fundTransfer: FundTransferResponse.prototype.readSingleResponse(),
  fundTransfers: FundTransferResponse.prototype.readResponse(),
  fundTransferLoading: false,
};

export const getters = {};

export const mutations = {
  updateFundTransfer: (state, payload) => {
    state.fundTransfer = payload;
  },
  updateFundTransfers: (state, payload) => {
    state.fundTransfers = payload;
  },
  updateFundTransferLoading: (state, payload) => {
    state.fundTransferLoading = payload;
  },
};

export const actions = {
  createFundTransfer: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateFundTransferLoading", true);
    return FundTransferService.callCreateFundTransferApi(payload)
      .then((response) => {
        commit("updateFundTransferLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          commit("updateFundTransfer", responseData);
        } else {
          swal("Error", responseData.responseMessage, "error");
        }
      })
      .catch((err) => {
        commit("updateFundTransferLoading", false);
        console.log(err);
      });
  },

  readFundTransfer: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateFundTransferLoading", true);
    return FundTransferService.callReadFundTransferApi(payload)
      .then((response) => {
        commit("updateFundTransferLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          commit("updateFundTransfers", responseData);
        }
      })
      .catch((err) => {
        commit("updateFundTransferLoading", false);
        console.log(err);
      });
  },

  updateFundTransfer: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateFundTransferLoading", true);
    return FundTransferService.callUpdateFundTransferApi(payload)
      .then((response) => {
        commit("updateFundTransferLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          swal("Success", responseData.responseMessage, "success");
        } else {
          swal("Error", responseData.responseMessage, "error");
        }
      })
      .catch((err) => {
        commit("updateFundTransferLoading", false);
        console.log(err);
      });
  },

  updateFundTransferStatus: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateFundTransferLoading", true);
    return FundTransferService.callUpdateFundTransferStatusApi(payload)
      .then((response) => {
        commit("updateFundTransferLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          swal("Success", responseData.responseMessage, "success");
        } else {
          swal("Error", responseData.responseMessage, "error");
        }
      })
      .catch((err) => {
        commit("updateFundTransferLoading", false);
        console.log(err);
      });
  },
};
