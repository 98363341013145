<template>
  <div class="div-block-96">
    <div class="div-block-97">
      <div class="text-block-45">Totals</div>
    </div>
    <div>
      <div class="money-spent">
        <div class="div-block-100"><img src="@/assets/images/Debit.svg" loading="lazy" alt="" class="image-34">
          <div>Spent</div>
        </div>
        <div>
          <div class="text-block-46">{{ currency }} {{ debits | formatAmount }}</div>
        </div>
      </div>
      <div class="money-received">
        <div class="div-block-100"><img src="@/assets/images/credit.svg" loading="lazy" alt="" class="image-34">
          <div>Received</div>
        </div>
        <div>
          <div class="text-block-46">{{ currency }} {{ credits | formatAmount }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "WalletStatCard",
  computed:{
    ...mapState(["auth","transaction"]),
    currency: function(){
      if (this.transaction.transactions.accounts.length < 1) return "---"
      else return this.transaction.transactions.accounts[0].accountCurrency
    },
    balance: function(){
      if (this.transaction.transactions.accounts.length < 1) return "0.00"
      else return this.transaction.transactions.accounts[0].accountBalance
    },
    debits: function(){
      if (this.transaction.transactions.transactions.length < 1) return "0.00"
      else return this.transaction.transactions.transactions.filter(it => it.drCr === 'DR').reduce((init, curr) => (
        init+=parseFloat(curr.amount==null?'0.00':curr.amount.toString().replace(/,/g,''))
      ),0).toFixed(2)
    },
    credits: function(){
      if (this.transaction.transactions.transactions.length < 1) return "0.00"
      else return this.transaction.transactions.transactions.filter(it => it.drCr === 'CR').reduce((init, curr) => (
        init+=parseFloat(curr.amount==null?'0.00':curr.amount.toString().replace(/,/g,''))
      ),0).toFixed(2)
    }
  },
  mounted() {
    this.$store.dispatch("transaction/queryTransaction", {customerId: this.auth.userInfo.customerId})
  }
};
</script>

<style scoped>

</style>