<template>
  <span>
    <apvertise-earn-banner/>
    <dashboard-header v-if="auth.userInfo.responseCode === '00' && this.$route.path !== '/api-documentation'" />
    <home-header v-if="auth.userInfo.responseCode !== '00' && this.$route.path !== '/api-documentation'" />
    <div class="main-dashboard-section wf-section">
      <div class="main-dashboard-body">
        <router-view></router-view>
      </div>
    </div>
  </span>
  <!--  <router-view></router-view>-->
</template>

<script>
import DashboardHeader from "@/components/menu/DashboardHeader";
import { mapState } from "vuex";
import HomeHeader from "@/components/menu/HomeHeader";
import ApvertiseEarnBanner from "@/components/banner/ApvertiseEarnBanner.vue";
export default {
  name: "DashboardLayout",
  components: {
    ApvertiseEarnBanner,
    HomeHeader,
    DashboardHeader,
  },
  created() {
    console.log(this.$route.path)
    const plugin = document.createElement("script");
    plugin.setAttribute("src", "https://apvertise.com/js/webflow.js");
    plugin.async = true;
    document.head.appendChild(plugin);
  },
  computed: {
    ...mapState(["auth"])
  }
};
</script>

<style scoped></style>
