<template>
  <div class="create-campaign-form-container">
    <div class="back-button-container">
      <a href="#" @click="goBack" class="link-block-11-copy w-inline-block">
        <div class="text-block-69">Back</div>
      </a>
    </div>
    <div class="create-campaign-header">
      <div>
        <h1 class="heading-23">Edit Campaign</h1>
      </div>
      <div class="div-block-90">
        <div class="text-block-41">
          Connect with brands, advertisers, and publishers for sponsorship
          opportunities to create and share content.
        </div>
      </div>
      <div class="div-block-87">
        <div class="campaigndetailcomplete">
          <img
            src="@/assets/images/checkmark.svg"
            loading="lazy"
            alt=""
            class="image-28"
          />
          <div class="text-block-70">Campaign <br />Details</div>
        </div>
        <div class="mediauploadcomplete">
          <img
            src="@/assets/images/checkmark.svg"
            loading="lazy"
            alt=""
            class="image-29"
          />
          <div class="text-block-71">Campaign <br />Banner</div>
        </div>
        <div class="filterinfluencers">
          <img
            src="@/assets/images/3.svg"
            loading="lazy"
            alt=""
            class="image-29"
          />
          <div class="text-block-77">Campaign <br />Exhibits</div>
        </div>
      </div>
    </div>
    <div class="new-campaign-block2">
      <div class="w-form">
        <form
          id="email-form"
          @submit.prevent="navigate"
          name="email-form"
          data-name="Email Form"
        >
          <div class="location">
            <b-row>
              <b-col md="8"
              ><label for="Location" class="field-label-9"
              >Location
                <b-icon-info-circle
                  style="cursor: pointer"
                  @click="displayMessage('Location', locationMsg)" /></label
              ></b-col>
              <b-col md="4"
              ><input
                type="checkbox"
                @click="value = []"
                v-model="selectAllCountry"
                style="color: #2e7d32"
              /><span> Select All Countries</span></b-col
              >
            </b-row>
            <multiselect
              style="font-size: 14px"
              id="Location"
              v-model="value"
              :disabled="selectAllCountry"
              tag-placeholder="Add list countries to view campaigns"
              placeholder="Search or add a country"
              label="name"
              track-by="code"
              :options="countryOption"
              :multiple="true"
              :taggable="true"
              @tag="addTag"
              :allow-empty="false"
            ></multiselect>
          </div>

          <div class="region">
            <b-row>
              <b-col md="8"
              ><label for="Location" class="field-label-9"
              >Region
                <b-icon-info-circle
                  style="cursor: pointer"
                  @click="displayMessage('Region/State', regionMsg)" /></label
              ></b-col>
              <b-col md="4"
              ><input
                type="checkbox"
                @click="regionValue = []"
                v-model="selectAllRegion"
                style="color: #2e7d32"
              /><span> Select All Regions</span></b-col
              >
            </b-row>
            <multiselect
              style="font-size: 14px"
              id="Region"
              v-model="regionValue"
              :disabled="selectAllRegion"
              tag-placeholder="Add list region to view campaigns"
              placeholder="Search or add a region"
              label="name"
              track-by="code"
              :options="states"
              :multiple="true"
              :taggable="true"
              @tag="addTag"
              :allow-empty="false"
            ></multiselect>
          </div>
          <br/>
          <div class="audience">
            <label for="Audience" class="field-label-9"
              >Exhibit Type
              <b-icon-info-circle
                style="cursor: pointer"
                @click="displayMessage('Exhibit Type', exhibitTypeMsg)"
            /></label>
            <multiselect
              v-model="exhibitValue"
              style="font-size: 14px"
              :options="campaign.exhibitionTypes.data"
              :multiple="false"
              group-values="list"
              group-label="group"
              :group-select="false"
              placeholder="Type to search exhibit type"
              track-by="label"
              id="Audience"
              label="label"
              :allow-empty="false"
              ><span slot="noResult"
                >Oops! No elements found. Consider changing the search
                query.</span
              ></multiselect
            >
            <p style="text-align: left; color: #63b8a0">
              price per impression: USD
              {{ exhibitValue.price ? exhibitValue.price : "0.00" }}
            </p>
            <!--            <select-->
            <!--              id="Audience"-->
            <!--              v-model="campaignType"-->
            <!--              name="Audience"-->
            <!--              data-name="Audience"-->
            <!--              required=""-->
            <!--              class="select-field-2 w-select"-->
            <!--            >-->
            <!--              <option value="">Select Exhibit</option>-->
            <!--              <option-->
            <!--                v-for="exhibition in campaign.exhibitionTypes.data"-->
            <!--                v-bind:key="exhibition.value"-->
            <!--                :value="exhibition.value"-->
            <!--              >-->
            <!--                {{ exhibition.label }}-->
            <!--              </option>-->
            <!--            </select>-->
          </div>
          <div style="margin-top: -10px" class="campaign-name">
            <label for="Campaign-Budget" class="field-label-9"
              >Budget (in USD)
              <b-icon-info-circle
                style="cursor: pointer"
                @click="displayMessage('Budget', budgetMsg)"
            /></label>
            <input
              type="number"
              @keypress="this.isNumber"
              v-model="campaignBudget"
              step="0.01"
              class="text-field-10 w-input"
              min="10"
              name="Campaign-Name"
              data-name="Campaign Name"
              placeholder="Enter Campaign budget"
              id="Campaign-Budget"
              required=""
            />
            <p style="text-align: left; color: #63b8a0; margin-top: -10px">
              {{
                parseInt(
                  (campaignBudget ? campaignBudget : 0.0) / (exhibitValue.price ? exhibitValue.price : 1.0)
                ) | formatAmount
              }}
              unique impression(s)
            </p>
          </div>
          <span
            v-if="
              (exhibitValue.value ? exhibitValue.value : '').includes('APP')
            "
          >
            <div class="audience">
              <label for="campaignLink" class="field-label-9"
              >Firebase dynamic link (the long link)
                <b-icon-info-circle
                  style="cursor: pointer"
                  @click="displayMessage('Firebase dynamic link (the long link)', firebaseMsg)"/></label>
              <input
                v-if="isDynamicLink(campaignLink)"
                type="text"
                v-model="campaignLink"
                class="text-field-10 w-input"
                maxlength="256"
                name="Audience"
                data-name="Budget"
                placeholder="https://samplelink.com"
                id="campaignLinkk"
                required=""
              />
              <input
                v-else
                type="text"
                v-model="campaignLink"
                style="border-color: red;"
                class="text-field-10 w-input"
                maxlength="256"
                name="Audience"
                data-name="Budget"
                placeholder="https://samplelink.com"
                id="campaignLink"
                required=""
              />
              <b-alert  v-if="!isDynamicLink(campaignLink) && (exhibitValue.value?exhibitValue.value:'').includes('APP')" show variant="danger">Invalid firebase link please use the <code>long link</code> generated on your firebase account</b-alert>
            </div>
          </span>
          <span v-else>
            <div class="audience">
              <label for="Budget" class="field-label-9"
                >Web/Social Media Page Link:
                <b-icon-info-circle
                  style="cursor: pointer"
                  @click="displayMessage('Web/Social Media Page Link', exhibitLinkMsg)"
              /></label>
              <input
                type="text"
                v-model="campaignLink"
                class="text-field-10 w-input"
                maxlength="256"
                name="Audience"
                data-name="Budget"
                placeholder="https://samplelink.com"
                id="Budget"
                required=""
              />
            </div>
          </span>
          <base-button :disabled="!isDynamicLink(this.model.campaignLink) && (exhibitValue.value?exhibitValue.value:'').includes('APP')" title="Submit" :loading="campaign.campaignLoading" />
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import CampaignResponse from "@/model/response/CampaignResponse";
import { mapState } from "vuex";
import BaseButton from "@/components/button/BaseButton";
import countriesJson from "@/assets/countries/data/en/countries.json";
import store from "@/store/store";
import CountriesStates from "@/utilities/CountriesStatesUtil";

export default {
  name: "CreateCampaignFormThree",
  components: { BaseButton },
  data() {
    return {
      locationMsg: `<p>List all the countries where you wish to promote your brand, campaign or creation. You may tick the box to select all countries. Apvertise only allows promotion in selected countries.</p>`,
      regionMsg: `<p>List all the regions/states where you wish to promote your brand, campaign or creation. You may tick the box to select all countries. Apvertise only allows promotion in selected region/states.</p>`,
      exhibitTypeMsg: `<p>There are two categories of promotions that Apvertise can drive;</p>
        <br>
        <p><b>Awareness:</b></p>
        <p>These are simple impressions. It counts when people open your website or social media pages, depending on what you are promoting. You want people to know about your brand/pages/websites.</p>
        <br>
        <p><b>Signup:</b></p>
        <p>This is the right category if you are promoting actually buying, downloads, signups. This requires that your developer make API calls to Apvertise.com. Please visit apvertise.com\\developers or email info@apvertise.com for details</p>
      `,
      budgetMsg: `<p>This is your marketing budget to promote your campaign. If you want 1,000 impressions, then your budget is $100 if the cost per impression is $0.1</p>`,
      playstoreMsg: `<p>This is the Google Playstore link for your Android app. Be sure to activate deep link in Playstore. Leave blank if you do not have an Android app. You are not simply promoting awareness; you are more interested in users taking specific actions.</p>`,
      firebaseMsg: `
        <p>Generate the firebase dynamic link from <code><a href="https://firebase.google.com/docs/dynamic-links/create-links" target="_blank">firebase</a></code> and get the <code>long link</code>.<p><br>
        <p><b>A typical Firebase dynamic link looks like this:</b></p>
        <p style="font-size: 12px;"><i>https://<code>your_subdomain</code>.page.link/?link=<code>your_deep_link</code>&apn=<code>package_name</code>[&amv=<code>minimum_version</code>][&afl=<code>fallback_link</code>].</i></p>`,
      appstoreMsg: `<p>This is the Appstore link for your iOS app. Be sure to activate deep link in Appstore. Leave blank if you do not have an iOS app. You are not simply promoting awareness; you are more interested in users taking specific actions.</p>`,
      exhibitLinkMsg: `<p>This is the URL of the website or social media page that you wish to promote, whether you are simply creating awareness or driving signup, actual buying or adoption.</p>`,

      model: new CampaignResponse().form(),
      selectAllCountry: false,
      selectAllRegion: false,
      value: [],
      regionValue: [],
      exhibitValue: {},
    };
  },
  methods: {
    async navigate() {
      console.log(this.exhibitValue)
      if (this.exhibitValue.value == null ){
        store.commit("updateMessage", {
          type: "error",
          title: "Error",
          body: "Exhibit type must not be empty",
        });
        return
      }
      this.model.campaignType = this.exhibitValue.value;
      this.model.campaignCategory = this.model.campaignType;
      this.model.campaignChannel = this.model.campaignType;
      if (!this.isDynamicLink(this.model.campaignLink) && this.model.campaignType.includes("APP")){
        store.commit("updateMessage", {
          type: "error",
          title: "Error",
          body: "Invalid firebase link please use the <code>long link</code> generated on your firebase account",
        });
        return;
      }
      if (
        (this.model.campaignLink === "" || this.model.campaignLink == null) &&
        (this.model.campaignLink2 === "" || this.model.campaignLink2 == null)
      ) {
        let payload = {
          type: "error",
          title: "Error",
          body: "Website or Social media link must not be empty",
        };
        if (this.model.campaignType.includes("APP"))
          payload.body =
            "Either Playstore or Appstore link must be provided";
        store.commit("updateMessage", payload);
        return;
      }
      this.model.campaignType = this.exhibitValue.value;
      this.model.campaignCategory = this.model.campaignType;
      this.model.campaignChannel = this.model.campaignType;
      this.model.campaignCustomerId = this.model.campaignType;
      if (this.selectAllCountry) {
        this.model.campaignAudience = "ALL";
      } else {
        this.model.campaignAudience = this.value
          .map(function (val) {
            return val.code;
          })
          .join(",");
      }
      if (this.selectAllRegion) {
        this.model.campaignRegion = "ALL";
      } else {
        this.model.campaignRegion = this.regionValue
          .map(function (val) {
            return val.code;
          })
          .join(",");
      }
      // this.model.campaignEndDate = "2030-01-01"
      // this.model.campaignStartDate = "2021-01-01"
      //this.$store.commit("campaign/updateCampaignForm", this.model)
      console.log(this.campaign.campaignEditForm);
      await this.$store.dispatch(
        "campaign/editCampaign",
        this.campaign.campaignEditForm
      );
      if (this.campaign.screen === "one")
        this.$store.commit("sbucket/updateUrls", []);
    },
    goBack() {
      this.$store.commit("campaign/updateScreen", "two");
    },
    isDynamicLink(link) {
      return link ? link.includes("?link=") : link;
    },
    isNumber(event) {
      if (!/\d/.test(event.key) && event.key !== ".")
        return event.preventDefault();
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.options.push(tag);
      this.value.push(tag);
    },
    displayMessage(_title, _message) {
      store.commit("updateMessage", {
        type: "n/a",
        title: _title,
        body: _message,
        action: "NO_BUTTON",
      });
    },
  },
  computed: {
    ...mapState(["campaign"]),
    countries() {
      return countriesJson;
    },
    states() {
      let result = CountriesStates.filter(it =>this.value.map((val)=>{return val.code}).join().toLowerCase().includes(it.code2.toLowerCase()));
      let rtn = [];
      result.length > 0 ? result.map((country)=>country.states).forEach((c)=>c.forEach((s)=>rtn.push(s))) : [];
      return rtn;
    },
    countryOption() {
      return this.countries.map(function (val) {
        return { code: val.alpha2.toString().toUpperCase(), name: val.name };
      });
    },
    campaignAudience: {
      set(val) {
        this.model.campaignAudience = val;
        this.$store.commit("campaign/updateCampaignEditForm", this.model);
      },
      get() {
        return this.campaign.campaignEditForm.campaignAudience;
      },
    },
    campaignType: {
      set(val) {
        this.model.campaignType = val;
        this.$store.commit("campaign/updateCampaignEditForm", this.model);
      },
      get() {
        return this.campaign.campaignEditForm.campaignType;
      },
    },
    campaignLink: {
      set(val) {
        this.model.campaignLink = val;
        this.$store.commit("campaign/updateCampaignEditForm", this.model);
      },
      get() {
        return this.campaign.campaignEditForm.campaignLink;
      },
    },
    campaignLink2: {
      set(val) {
        this.model.campaignLink2 = val;
        this.$store.commit("campaign/updateCampaignForm", this.model);
      },
      get() {
        return this.campaign.campaignEditForm.campaignLink2;
      },
    },
    campaignBudget: {
      set(val) {
        this.model.campaignBudget = val;
        this.$store.commit("campaign/updateCampaignForm", this.model);
      },
      get() {
        return this.campaign.campaignEditForm.campaignBudget;
      },
    },
  },
  mounted() {
    console.log("Mount Campaign Form three...");
    this.model = this.campaign.campaignEditForm;
    let self = this;
    if (this.model.campaignAudience.toUpperCase() === "ALL") {
      this.selectAllCountry = true;
    }else {
      this.value = this.model.campaignAudience.split(",").map(function(val) {
        let country = self.countryOption.filter((it) => it.code === val)[0];
        return { name: country.name, code: country.code };
      });
    }
    if (this.model.campaignRegion.toUpperCase() === "ALL") {
      this.selectAllRegion = true;
    } else {
      this.regionValue = this.states.filter((it) => this.model.campaignRegion.includes(it.code) );
    }
    let exhibitValueArr1 = self.campaign.exhibitionTypes.data.filter(it => it.list.filter(y => y.value === this.model.campaignType).length > 0)
    let exhibitValueArr2 = [];
    if (exhibitValueArr1.length > 0)
      exhibitValueArr2 = exhibitValueArr1[0].list.filter(it => it.value === this.model.campaignType )
    console.warn(exhibitValueArr2);
    if (exhibitValueArr2.length > 0) this.exhibitValue = exhibitValueArr2[0]
    console.warn(JSON.stringify(this.value));
  },
  watch: {
    exhibitValue: function(newValue, oldValue) {
      console.log(oldValue, newValue);
      if (newValue == null) this.exhibitValue = oldValue;
      if (
        this.exhibitValue.value.includes("SIGNUP") ||
        this.exhibitValue.value.includes("DOWNLOAD")
      ) {
        store.commit("updateMessage", {
          type: "n/a",
          title: "TODO",
          body: `<P>For your Acquisition campaign to be active,
                  Please integrate the callback URL for every successful download/signup.<br>See <a href="https://documenter.getpostman.com/view/4543866/UVJZoJRD" target="_blank"><code>API documentation</code></a></P>
                  `,
        });
      }
    }
  }
};
</script>

<style scoped></style>
