<template>
  <div class="create-campaign-form-container">
    <div class="back-button-container">
      <a href="#" @click="goBack" class="back-button w-inline-block">
        <div>Back</div>
      </a>
    </div>
    <div class="create-campaign-header">
      <div>
        <h1 class="heading-23">Edit Campaign</h1>
      </div>
      <div class="div-block-90">
        <div class="text-block-41">
          Connect with brands, advertisers, and publishers for sponsorship
          opportunities to create and share content.
        </div>
      </div>
      <div class="div-block-87">
        <div class="campaigndetaildone"><img src="@/assets/images/checkmark.svg" loading="lazy" alt="" class="image-28">
          <div class="text-block-42">Campaign <br>Details</div>
        </div>
        <div class="mediaupload"><img src="@/assets/images/2.svg" loading="lazy" alt="" class="image-29">
          <div class="text-block-76">Media <br>Uploads</div>
        </div>
        <div class="filterinfluencers"><img src="@/assets/images/3.svg" loading="lazy" alt="" class="image-29">
          <div class="text-block-75">Filter<br>Influencers</div>
        </div>
      </div>
    </div>
    <form @submit.prevent="navigate">
<!--      <upload-multiple-image-field name="banner pictures" :max="urlMax"/><br><br>-->
<!--      <upload-image-with-cropper :edit="true" name="banner"/>-->
      <upload-image-with-cropper-v2 :edit="true" name="banner"/>
      <br>
      <br>

      <base-button v-if="this.sbucket.urls.length > 0" title="Proceed" :loading="campaign.campaignLoading"/>
    </form>
  </div>
</template>

<script>
import CampaignResponse from "@/model/response/CampaignResponse";
import UploadMultipleImageField from "@/components/form/input/UploadMultipleImageField";
import swal from "sweetalert";
import { mapState } from "vuex";
import BaseButton from "@/components/button/BaseButton";
import UploadImageWithCropperV2 from "@/components/form/input/UploadImageWithCropperV2";

export default {
  name: "CreateCampaignFormTwo",
  // eslint-disable-next-line vue/no-unused-components
  components:{ UploadImageWithCropperV2, BaseButton, UploadMultipleImageField},
  data(){
    return{
      urlMax: 1,
      model: CampaignResponse.prototype.form()
    }
  },
  methods:{
    navigate(){
      if (this.sbucket.urls.length < this.urlMax){
        swal("Error", `Please select at least  ${this.urlMax} image(s)`);
        return
      }
      //this.model.campaignBanners = this.sbucket.urls.join()
      this.$store.commit("campaign/updateCampaignEditForm", { campaignBanners:  this.sbucket.urls.join()})
      console.log(this.campaign.campaignEditForm);
      this.$store.commit("campaign/updateScreen", "three")
    },
    goBack(){
      this.$store.commit("campaign/updateScreen", "one")
    }
  },
  computed:{
    ...mapState(["campaign","organisation","auth","sbucket"]),
    campaignStartDate: {
      set(val) {
        this.model.campaignStartDate = val;
        this.$store.commit("campaign/updateCampaignEditForm", this.model);
      },
      get() {
        return this.campaign.campaignEditForm.campaignStartDate;
      },
    },
  },
  mounted() {
    console.log("Mount Campaign Form two...");
    this.model = this.model = this.campaign.campaignEditForm;
  }
};
</script>

<style scoped>

</style>