<template>
  <div>
    <div class="qoo-modal privacymodal">
      <div
        class="qoo-modal-body"
      >
        <div class="modal-content-1" v-if="state === 1">
          <div class="image-container">
            <img src="../../assets/images/Banknote-pana-1.svg" alt="Banknote pana"/>
          </div>
          <h3>A new way to earn 🥳</h3>
          <p>Earn a 5% commission from the company budget for every campaign created through your referral link.</p>
          <div class="link-container">
            <button></button>
            <button class="inactive"></button>
          </div>
        </div>
        <div class="modal-content-1" v-if="state === 2">
          <div class="image-container">
            <img src="../../assets/images/Dashboard-mockup.svg" alt="Dashboard mockup"/>
          </div>
          <h3>Copy your referral link</h3>
          <p>Go to your profile page, copy your referral link and share across all social media platforms </p>
          <div class="link-container">
            <button class="inactive"></button>
            <button></button>
          </div>
        </div>

        <div class="button-block-5">
          <button class="button-101" @click="goToAccount">Skip</button>
          <button class="button-102" @click="state++" v-if="state === 1">Get started</button>
          <button class="button-102" @click="goToAccount" v-if="state === 2">Next</button>
        </div>
        <div class="loader" style="display: none"></div>
      </div>
    </div>
    <div class="banner-container" v-if="open && auth.userInfo.customerInviteId === null">
      <div class="banner-main">
        <img src="../../assets/images/layers-1.svg" alt="Banner">
        <p>There’s a new way to earn on Apvertise 🥳. <a @click="uploadProfile('.privacymodal')"> Click here</a> to find out</p>
        <a><img src="../../assets/images/close.svg" alt="Close" @click="close"></a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ApvertiseEarnBanner",
  data(){
    return{
      state: 1,
      open: true,
      falseValue: false
    }
  },
  computed:{
    ...mapState(["auth"]),
  },
  methods:{
    close(){
      document.querySelector(".banner-container").style.display = "none"
    },
    close2(){
      document.querySelector(".qoo-modal").style.display = "none"
    },
    goToAccount(){
      this.close2()
      localStorage.getItem("wallet")
      this.$router.push("/profile-settings")
    },
    uploadProfile(className) {
      this.state = 1
      this.open = false;
      localStorage.setItem("open", this.falseValue)
      document.querySelector(className).style.display = "flex";
      setTimeout(()=>{
        document.querySelector('.qoo-modal-body').style.left = 0;
      }, 300)
    },
  },
  mounted() {
    this.open = localStorage.getItem("open")
    window.onclick = function(event) {
      if (event.target !== document.querySelector('.qoo-modal')) {
        document.querySelector('.qoo-modal-body').style.left = '1100px';
        setTimeout(()=>{
          document.querySelector('.qoo-modal').style.display = "none";
        }, 300)
      }
    }
  }
};
</script>

<style scoped>
.btn{
  color: #63b8a0 !important;
  border: 1px solid #63b8a0;
}

a:hover{
  color: white !important;
}
.image-container{
  margin: 0;
}
.banner-container{
  position: relative;
  width: 100%;
  left: 0px;
  top: 0px;
  background: #63B8A0;
  height: 60px;
  z-index: 9999;
}

.banner-main{
  width: 100%;
  height: 60px;
  padding: 0 30px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
}

.banner-main p{
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  /* identical to box height, or 175% */
  color: #FFFFFF;
  margin: 0 0 0 27px !important;
}

.banner-main a{
  margin-left: auto;
  padding-left: 5px;
  text-decoration: underline !important;
}

.qoo-modal {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 999999;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: none;
  width: 100vw;
  height: 100vh;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.45);
  opacity: 1;
  transition: opacity .3s ease-in-out;
}

.transition {
  opacity: 1;
}

.qoo-modal-body{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  gap: 32px;

  position: relative;
  width: 400px;
  //height: 447px;
  left: 1100px;
  /* White */

  background: #FFFFFF;
  /* Shadow/xl */

  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  border-radius: 12px;
  transition: all 0.3s ease-in;
}

.modal-content-1{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 20px;
  width: 100%;
}

.modal-content-1 h3{
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  /* identical to box height, or 156% */

  text-align: center;

  color: rgba(31, 36, 49, 0.9);
  margin: 0;
}

.modal-content-1 p{
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */
  width: 80%;
  text-align: center;

  /* Gray/500 */

  color: #667085;

}

.link-container button{
  width: 8px;
  height: 12px;
  background: #63B8A0;
  border-radius: 50%;
  margin-right: 5px;

}

.link-container button.inactive{
  background: rgba(99, 184, 160, 0.1);
}

.button-101{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;

  //width: 170px;
  //height: 44px;

  /* White */
  width: 100%;
  background: #FFFFFF;
  /* Gray/300 */

  border: 1px solid #D0D5DD;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */


  /* Gray/700 */

  color: #344054;

}

.button-102{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;
  width: 100%;
  //width: 170px;
  //height: 44px;

  background: #63B8A0;
  border: 1px solid #63B8A0;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */


  /* White */

  color: #FFFFFF;
}

.button-block-5{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;

}

@media screen and (max-width: 395px){
  .banner-main p{
    font-size: 11px;
  }
}
</style>