<template>
  <div data-duration-in="300" data-duration-out="100" class="tabs w-tabs">
    <div class="tabs-menu-5 w-tab-menu">
      <a data-w-tab="Tab 1" class="accounts-tab w-inline-block w-tab-link" :class="{'w--current' : wallet === 'yes' || active === 'payout'}" @click="active = 'payout'">
        <div>Recent Payouts</div>
      </a>
      <a data-w-tab="Tab 2" class="accounts-tab w-inline-block w-tab-link" :class="{'w--current': active === 'account'}" @click="active = 'account'">
        <div>Payout Account</div>
      </a>
      <a data-w-tab="Tab 3" class="accounts-tab w-inline-block w-tab-link" :class="{'w--current' : wallet === 'no' || wallet === null && active === 'information'}" @click="active = 'information'">
        <div>Basic Information</div>
      </a>
      <a data-w-tab="Tab 4" class="accounts-tab w-inline-block w-tab-link" :class="{'w--current' : active === 'password'}" @click="active = 'password'">
        <div>Change Password</div>
      </a>
      <a data-w-tab="Tab 5" class="accounts-tab w-inline-block w-tab-link" :class="{'w--current' : active === 'notification'}" @click="active = 'notification'">
        <div>Notification</div>
      </a>
    </div>
    <div class="tabs-content-3 w-tab-content">
      <div data-w-tab="Tab 1" class="basic-informations-tab w-tab-pane" :class="{' w--tab-active' : wallet === 'yes' || active === 'payout'}">
        <payout-card/>
      </div>
      <div data-w-tab="Tab 2" class="payout-account-tab w-tab-pane" :class="{' w--tab-active' : active === 'account'}">
        <payout-account-form/>
      </div>
      <div data-w-tab="Tab 3" class="change-password-tab w-tab-pane" :class="{' w--tab-active' : wallet === 'no' || wallet === null && active === 'information'}">
        <basic-information-form/>
      </div>
      <div data-w-tab="Tab 4" class="payout-tab w-tab-pane" :class="{' w--tab-active' : active === 'password'}">
        <change-password-form/>
      </div>
      <div data-w-tab="Tab 5" class="payout-tab w-tab-pane" :class="{' w--tab-active' : active === 'notification'}">
        <notification-form/>
      </div>
    </div>
  </div>
</template>

<script>
import BasicInformationForm from "@/components/form/BasicInformationForm";
import ChangePasswordForm from "@/components/form/ChangePasswordForm";
import PayoutAccountForm from "@/components/form/PayoutAccountForm";
import PayoutCard from "@/components/card/PayoutCard";
import NotificationForm from "@/components/form/NotificationForm";
export default {
  name: "Profile",
  data(){
    return{
      prevRoute: null,
      active: "information",
    }
  },
  components: { PayoutCard, PayoutAccountForm, ChangePasswordForm, BasicInformationForm, NotificationForm },
  created() {
    const plugin = document.createElement("script");
    plugin.setAttribute("src", "https://play-apvertise.com/js/webflow.js");
    plugin.async = true;
    document.head.appendChild(plugin);
  },
  computed:{
    wallet(){
      if (this.active === 'information') {return localStorage.getItem("wallet")}
      else {return "not really"}
    }
  },
  methods:{

  },

};
</script>

<style scoped>

</style>