<template>
  <div id="app">
    <!--    <transition name="fade" mode="out-in"> this.system.overlayLoading-->
    <message-modal />
    <preloader v-if="this.system.overlayLoading"></preloader>
    <newloader v-if="this.product.productLoading"></newloader>
    <newloader v-if="this.organisation.organisationLoading"></newloader>
    <dashboard-layout v-if="layout === 'dashboard-layout'"></dashboard-layout>
    <auth-layout v-if="layout === 'auth-layout'"></auth-layout>
    <home-layout v-if="layout === 'home-layout'"></home-layout>
    <home-layout v-if="layout === 'default-layout'"></home-layout>
    <loader-layout v-if="layout === 'preloader-layout'"></loader-layout>
    <not-found-layout v-if="layout === '404-layout'"></not-found-layout>

    <!--    </transition>-->
  </div>
</template>

<script>
import AuthLayout from "@/template/layout/AuthLayout";
import NotFoundLayout from "@/template/layout/NotFoundLayout";
import HomeLayout from "@/template/layout/HomeLayout";
import DashboardLayout from "@/template/layout/DashboardLayout";
import LoaderLayout from "@/template/layout/LoaderLayout";
import Preloader from "@/views/authentication/Preloader";
import { mapState } from "vuex";
import MessageModal from "@/components/modal/MessageModal";
import SecurityUtil from "@/utilities/SecurityUtil";
import Newloader from "@/components/loader/Newloader";

const default_layout = "default";

export default {
  components: {
    Newloader,
    MessageModal,
    DashboardLayout,
    HomeLayout,
    AuthLayout,
    LoaderLayout,
    Preloader,
    NotFoundLayout,
  },
  data() {
    return {
      isLoading: false,
      config: "",
      ran: false,
    };
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || default_layout) + "-layout";
    },
    ...mapState(["system","product","organisation"]),
    // model:{
    //   get(){
    //       // console.log(this.officerFilteredByUserId(this.system.userInfo.id)[0])
    //       // return this.officerFilteredByUserId(this.system.userInfo.id)[0]
    //   },
    //   set(){
    //
    //   }
    // }
  },

  methods: {
    getLocation() {
      this.$fingerprint.get((components) => {
        this.finger = this.$fingerprint.x64hash128(
          components
            .map((pair) => {
              return pair.value;
            })
            .join(),
          31
        );
        console.log(this.finger);
        this.$store.commit("auth/updateDeviceId", this.finger);
        localStorage._xhr = this.finger;
      });
      fetch("http://ip-api.com/json")
        .then((x) => x.json())
        .then(
          ({ countryCode, country, region, regionName, query, lat, lon }) => {
            localStorage._ipAddress = query;
            localStorage._countryCode = countryCode;
            localStorage._country = country;
            localStorage._region = region;
            localStorage._regionName = regionName;
            localStorage._lat = lat;
            localStorage._lon = lon;
            if (this.$route.params.reference != null && !this.ran) {
              console.log(this.$route.params.reference);
              let campaignRequest = JSON.parse(
                SecurityUtil.prototype.decrypt(this.$route.params.reference)
              );
              console.log(campaignRequest);
              this.$store.dispatch("campaign/campaignSettlement", {
                customerId: campaignRequest.customerId,
                campaignId: campaignRequest.campaignId,
                region: localStorage._region,
                deviceId: localStorage._xhr, //this.auth.deviceId,
                ipAddress: localStorage._ipAddress,
                longitude: lon,
                latitude: lat,
                impressionType: "CLICK",
              });
            }
          }
        );
    },
    async readTextFile(file) {
      function reqListener() {
        console.log(this.responseText);
      }
      const oReq = new XMLHttpRequest();
      oReq.addEventListener("load", reqListener);
      oReq.open("GET", file);
      oReq.send();
    },
  },
  // watch: {
  //   '$route.params.inviteId': function (){
  //     console.log("this.$route.params>>>>",this.$route.params);
  //     localStorage.inviteId = this.$route.params.inviteId;
  //   },
  //   '$route.params.shareId': function (){
  //     console.log("this.$route.params>>>>",this.$route.params);
  //     localStorage.shareId = this.$route.params.shareId;
  //   },
  //   '$route.params.referralCode': function (){
  //     console.log("this.$route.params>>>>",this.$route.params);
  //     localStorage.referralCode = this.$route.params.referralCode;
  //   }
  // },
  created() {
    this.getLocation();
    function isURL(str) {
      let strArr = str.split(" ");
      for (let i = 0; i < strArr.length; i++){
        if (
          /^(?:\w+:)?\/\/([^\s.]+\.\S{2}|localhost[:?\d]*)\S*$/.test(strArr[i]) ||
          strArr[i].includes("www.")
        ) {
          return true;
        }
      }
      return false;
    }
    console.log("Is URL", isURL("bbcehc hebfcehr foo.in hjvecbe"));
    //localStorage.inviteId = this.$route.params.inviteId;

    const script = document.createElement("script");
    script.src = "https://apvertise.com/js/webflow.js";
  },
  mounted(){
    this.$store.dispatch("campaign/updateExhibitionTypes", {readAll:"YES"})
    this.$store.dispatch("organisation/updateOrganisationTypes", {readAll:"YES"})
    let test = "00000001"
    let encode = test.hexEncode()
    console.log(encode);
    console.log(encode.hexDecode());
    console.log(this.$route.path);
    console.log("new build");
    console.log("this.$route.params>>>>>>>>:::<<<<<<<",this.$route.params);
    console.log("this.$route.query>>>>>>>>:::<<<<<<<",this.$route.query);
    if (this.$route.params.inviteId != null)
      localStorage.inviteId = this.$route.params.inviteId;
    if (this.$route.query.shareId != null)
      localStorage.shareId = this.$route.query.shareId;
    if (this.$route.query.referralCode != null)
      localStorage.referralCode = this.$route.query.referralCode;
    this.$store.dispatch("auth/validate", {}, { root: true }).then();

    const plugin = document.createElement("script");
    plugin.setAttribute("src", "https://apvertise.com/js/webflow.js");
    plugin.async = true;
    document.head.appendChild(plugin);
  },
};
</script>

<style>
#app{
  font-family: "DM Sans", sans-serif !important;
}

.modal-content {
  background: white;
}

a:hover{
  color: #63b8a0 !important;
  cursor: pointer;
}

.w-button{
  color: white !important;
}
/*.close-topup-modal {*/
/*  position: absolute;*/
/*  left: auto;*/
/*  top: 0%;*/
/*  right: 0%;*/
/*  bottom: auto;*/
/*  display: block;*/
/*}*/
</style>
