<template>
<!--  <div class="div-block-122">-->
<!--    <div>-->
<!--      <h1 class="heading-27">Payout Account</h1>-->
<!--    </div>-->
<!--    <div>-->
<!--      <div>-->
<!--        <div class="w-form">-->
<!--          <form-->
<!--            id="email-form"-->
<!--            @submit.prevent="createPayoutAccount"-->
<!--            name="email-form"-->
<!--            data-name="Email Form"-->
<!--          >-->
<!--            <div class="div-block-125">-->
<!--              <div class="div-block-126">-->
<!--                <label for="name" class="field-label-10">Account Name</label>-->
<!--                <input-->
<!--                  type="text"-->
<!--                  v-model="model.accountName"-->
<!--                  class="text-field-12 w-input"-->
<!--                  maxlength="256"-->
<!--                  name="name"-->
<!--                  data-name="Name"-->
<!--                  placeholder="Account Name"-->
<!--                  id="name"-->
<!--                />-->
<!--              </div>-->
<!--              <div class="div-block-127">-->
<!--                <label for="email" class="field-label-11">Account Number</label>-->
<!--                <input-->
<!--                  @keypress="this.isNumber"-->
<!--                  type="text"-->
<!--                  v-model="model.accountNumber"-->
<!--                  class="text-field-13 w-input"-->
<!--                  maxlength="256"-->
<!--                  name="email"-->
<!--                  data-name="Email"-->
<!--                  placeholder="Account Number"-->
<!--                  id="email"-->
<!--                  required=""-->
<!--                />-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="div-block-125">-->
<!--              <div class="div-block-126">-->
<!--                <label for="name-2" class="field-label-12">Country</label>-->
<!--                <select-->
<!--                  id="name-2"-->
<!--                  @change="fetchBank"-->
<!--                  class="text-field-15 w-input"-->
<!--                  v-model="model.accountCountry"-->
<!--                >-->
<!--                  <option value="">Select Country</option>-->
<!--                  <option value="NG">Nigeria</option>-->
<!--                </select>-->
<!--              </div>-->
<!--              <div class="div-block-126">-->
<!--                <label for="name-3" class="field-label-12"-->
<!--                  >Bank-->
<!--                  <b-spinner-->
<!--                    v-if="maintenance.maintenanceLoading"-->
<!--                    small-->
<!--                    style="color: #63b8a0"-->
<!--                /></label>-->
<!--                <select-->
<!--                  id="name-3"-->
<!--                  class="text-field-15 w-input"-->
<!--                  v-model="model.accountBankCode"-->
<!--                >-->
<!--                  <option value="">Select Bank</option>-->
<!--                  <option-->
<!--                    v-for="bank in maintenance.bankListFlw.data"-->
<!--                    v-bind:key="bank.code"-->
<!--                    :value="bank.code"-->
<!--                  >-->
<!--                    {{ bank.name }}-->
<!--                  </option>-->
<!--                </select>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="div-block-125">-->
<!--              <div class="div-block-126">-->
<!--                <label for="name-4" style="width: 100%" class="field-label-12"-->
<!--                  >OTP</label-->
<!--                >-->
<!--                <span style="display: flex; flex-direction: row">-->
<!--                  <input-->
<!--                    style="width: 60%"-->
<!--                    type="text"-->
<!--                    v-model="model.otp"-->
<!--                    class="text-field-13 w-input"-->
<!--                    maxlength="256"-->
<!--                    name="email"-->
<!--                    data-name="Email"-->
<!--                    placeholder="OTP"-->
<!--                    id="name-4"-->
<!--                    required=""-->
<!--                  />-->
<!--                  <button-->
<!--                    v-if="timerCount < 1"-->
<!--                    @click="sendOtp"-->
<!--                    type="button"-->
<!--                    style="width: 40%; height: 50px; padding: 0; color: #63b8a0"-->
<!--                  >-->
<!--                    <b-spinner-->
<!--                      v-if="auth.authLoading"-->
<!--                      small-->
<!--                      style="color: #63b8a0"-->
<!--                    /><span v-else>request otp</span>-->
<!--                  </button>-->
<!--                  <button-->
<!--                    v-else-->
<!--                    type="button"-->
<!--                    style="width: 40%; height: 50px; padding: 0; color: #63b8a0"-->
<!--                  >-->
<!--                    Resend in {{ timerCount }}s-->
<!--                  </button>-->
<!--                </span>-->
<!--              </div>-->
<!--            </div>-->
<!--            <input-->
<!--              v-if="!accountPayout.accountPayoutLoading"-->
<!--              type="submit"-->
<!--              value="Save Changes"-->
<!--              class="submit-button-8 w-button"-->
<!--            />-->
<!--            <b-spinner small v-else style="color: #63b8a0" />-->
<!--          </form>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->

  <div class="tabs-content-3 w-tab-content">
    <div data-w-tab="Tab 2" class="social-profile-tab w-tab-pane w--tab-active">
      <div class="div-block-122">
        <div class="div-block-237">
          <h1 class="basic-info-header-text">Payout account</h1>
          <a href="#" @click="showModal = !showModal" class="link-block-26 w-inline-block">
            <div class="text-block-138">Change account</div>
          </a>
        </div>
        <div v-if="accountPayout.accountPayout.responseCode !== '00'" class="empty-state">
          <div class="div-block-222"><img loading="lazy" src="@/assets/images/Frame.svg" alt="" class="image-59">
            <div class="text-block-129">You haven’t added a payout <br>account yet</div>
            <a data-w-id="38944904-705f-7d46-73ba-4c9ca100133a" @click="showModal = !showModal" href="#" class="button-24 w-button">Add payout account</a>
          </div>
        </div>
        <div v-else class="div-block-221-copy account" style="margin-top: 30px;">
          <div class="div-block-232">
            <div class="w-form">
              <form id="email-form-4" @submit.prevent="createPayoutAccount" name="email-form-4" data-name="Email Form 4">
                <div class="div-block-233">
                  <div class="div-block-234"><label for="name-3" class="field-label-19">Account Name</label><input v-model="model.accountName" type="text" class="text-field-26 w-input" maxlength="256" name="name-3" data-name="Name 3" placeholder="OROFIN OLUWASEUN GODWIN" id="name-3"></div>
                  <div class="div-block-235"><label for="name-3" class="field-label-20">Account Number</label><input v-model="model.accountNumber" type="text" class="text-field-26 w-input" maxlength="256" name="name-3" data-name="Name 3" placeholder="0121925568" id="name-3"></div>
                </div>
                <div class="div-block-233">
                  <div class="div-block-234"><label for="field-4" class="field-label-21">Bank</label>
                    <select id="field-4" v-model="model.accountBankCode" name="field-4" data-name="Field 4" class="select-field-8 w-select">
                      <option value="">Select Bank</option>
                      <option v-for="bank in maintenance.bankListFlw.data" v-bind:key="bank.code" :value="bank.code">
                        {{ bank.name }}
                      </option>
                    </select>
                  </div>
                  <div class="div-block-235"><label class="field-label-22">OTP</label>
                    <div class="div-block-236"> <input v-model="model.otp" required="" type="text" class="text-field-25 w-input" maxlength="256" name="name-3" data-name="Name 3" placeholder="" id="name-32">
                        <a  v-if="auth.authLoading" href="#" class="button-25 w-button"><b-spinner small style="color: #ffffff" /></a>
                        <a v-else-if="timerCount > 0" href="#" class="button-25 w-button">Request in {{timerCount}}</a>
                        <a v-else href="#" @click="sendOtp" class="button-25 w-button">Request OTP</a>
                    </div>
                  </div>
                </div>
                <input v-if="!accountPayout.accountPayoutLoading" type="submit" value="Save changes" data-wait="Please wait..." class="button-24 w-button">
                <button v-else class="button-24 w-button"><b-spinner small style="color: #ffffff" /> please wait...</button>
              </form>
              <div class="w-form-done">
                <div>Thank you! Your submission has been received!</div>
              </div>
              <div class="w-form-fail">
                <div>Oops! Something went wrong while submitting the form.</div>
              </div>
            </div>
          </div>
          <div class="old">
            <div class="div-block-227">
              <div class="div-block-230">
                <div class="text-block-131">ALIU MUIBI-HAMMED</div><img width="28" loading="lazy" src="@/assets/images/🇳🇬.png" alt="">
              </div>
              <div class="div-block-228">
                <div class="text-block-132">Access Bank</div>
                <div class="text-block-133">0123456789</div>
              </div>
            </div>
            <a data-w-id="6278d09c-2e04-4d1e-493e-d4220dfaa59c" href="#" class="link-block-23 w-inline-block">
              <div>
                <div>
                  <div class="text-block-134">+</div>
                </div>
                <div>
                  <div class="text-block-135">Add new account</div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <add-payout-account-modal :show="showModal"/>
  </div>
</template>

<script>
import { mapState } from "vuex";
import AccountPayoutRequest from "@/model/request/AccountPayoutRequest";
import AddPayoutAccountModal from "@/components/modal/AddPayoutAccountModal";
export default {
  name: "PayoutAccountForm",
  components:{
    AddPayoutAccountModal
  },
  data() {
    return {
      showModal: true,
      model: new AccountPayoutRequest().create(),
      timer: "2:00",
      timerCount: 0,
    };
  },
  computed: {
    ...mapState(["auth", "maintenance", "accountPayout"]),
  },
  methods: {
    fetchBank() {
      this.$store.dispatch("maintenance/bankListFlwApi", {
        country: this.model.accountCountry,
      });
    },
    startTimer(duration) {
      let timer = duration,
        minutes,
        seconds;
      setInterval(function () {
        minutes = parseInt((timer / 60).toString(), 10);
        seconds = parseInt((timer % 60).toString(), 10);
        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;
        this.timer = minutes + ":" + seconds;
        if (--timer < 0) {
          timer = duration;
        }
      }, 1000);
    },
    sendOtp() {
      this.$store
        .dispatch("auth/resendOtpInApp", {
          phoneNumber: this.auth.userInfo.customerEmail,
          countryCode: "234",
        })
        .then(() => {
          console.log("Timer...");
          this.timerCount = 120;
        });
    },
    createPayoutAccount() {
      this.model.accountCustomerId = this.auth.userInfo.customerId;
      this.model.username = this.auth.userInfo.customerEmail;
      this.model.accountBankName = this.maintenance.bankListFlw.data.filter(
        (it) => it.code === this.model.accountBankCode
      )[0].name;
      this.$store
        .dispatch("accountPayout/createAccountPayout", this.model)
        .then(() => {
          this.$store.dispatch("accountPayout/updateAccountPayout", {
            customerId: this.auth.userInfo.customerId,
          });
          this.model.otp = "";
          this.timerCount = 0;
          // .then(() => {
          //   this.model.accountCountry =
          //     this.accountPayout.accountPayout.accountCountry;
          //   this.model.accountNumber =
          //     this.accountPayout.accountPayout.accountNumber;
          //   this.model.accountName =
          //     this.accountPayout.accountPayout.accountName;
          //   this.model.accountBankCode =
          //     this.accountPayout.accountPayout.accountBankCode;
          //   this.model.accountBankName =
          //     this.accountPayout.accountPayout.accountBankName;
          //   this.model.accountCustomerId =
          //     this.accountPayout.accountPayout.accountCustomerId;
          // });
        });
    },
    isNumber(event) {
      if (!/\d/.test(event.key) && event.key !== ".")
        return event.preventDefault();
    },
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        }
      },
      //immediate: true, // This ensures the watcher is triggered upon creation
    },
    'auth.userInfo.customerId':{
      handler(){
        this.$store.dispatch("accountPayout/updateAccountPayout", {
          customerId: this.auth.userInfo.customerId,
        })
          .then(() => {
            this.model.accountCountry =
              this.accountPayout.accountPayout.accountCountry;
            this.model.accountNumber =
              this.accountPayout.accountPayout.accountNumber;
            this.model.accountName = this.accountPayout.accountPayout.accountName;
            this.model.accountBankCode =
              this.accountPayout.accountPayout.accountBankCode;
            this.model.accountBankName =
              this.accountPayout.accountPayout.accountBankName;
            this.model.accountCustomerId =
              this.accountPayout.accountPayout.accountCustomerId;
          });
      },
    },
    'accountPayout.accountPayoutLoading':{
      handler(){
        this.model.accountCountry =
          this.accountPayout.accountPayout.accountCountry;
        this.model.accountNumber =
          this.accountPayout.accountPayout.accountNumber;
        this.model.accountName = this.accountPayout.accountPayout.accountName;
        this.model.accountBankCode =
          this.accountPayout.accountPayout.accountBankCode;
        this.model.accountBankName =
          this.accountPayout.accountPayout.accountBankName;
        this.model.accountCustomerId =
          this.accountPayout.accountPayout.accountCustomerId;
      }
    }
  },
  mounted() {
    this.fetchBank()
    this.model.accountCountry =
      this.accountPayout.accountPayout.accountCountry;
    this.model.accountNumber =
      this.accountPayout.accountPayout.accountNumber;
    this.model.accountName = this.accountPayout.accountPayout.accountName;
    this.model.accountBankCode =
      this.accountPayout.accountPayout.accountBankCode;
    this.model.accountBankName =
      this.accountPayout.accountPayout.accountBankName;
    this.model.accountCustomerId =
      this.accountPayout.accountPayout.accountCustomerId;
  },
};
</script>

<style scoped></style>
