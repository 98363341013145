<template>
  <div style="height: 345px;" class="campaign-cards-dashboard">
    <div class="div-block-15">
      <div class="div-block-16">
        <div class="text-block-57">{{ category }}
        </div>
      </div>
      <div v-if="campaignBudgetExhausted === 'NO'" class="budget-status">
        <img src="@/assets/images/Active.svg" loading="lazy" width="10" alt="" class="image-56">
        <div class="text-block-121">Active</div>
      </div>
      <div v-else class="budget-status">
        <img src="@/assets/images/Inactive.svg" loading="lazy" width="10" alt="" class="image-56">
        <div class="inactive">Inactive</div>
      </div>
    </div>
    <div class="div-block-17">
      <div class="div-block-18">
        <b-avatar style="width: 50px; height: 50px; border-style: solid; border-width: 1px; border-color: #e7e7e7;" :src="logo" loading="lazy" alt=""></b-avatar>
      </div>
      <div>
        <h1 class="heading-13">{{ companyName | formatCompanyNameWithEllipsis }}</h1>
      </div>
    </div>
    <div class="div-block-19">
      <div>
        <h1 class="heading-14">{{ title }}</h1>
      </div>
      <div>
        <div class="text-block-7">{{ description | formatCampaignDescriptionWithEllipsis }}</div>
      </div>
    </div>
    <div class="div-block-20">
      <div>
<!--        <a v-if="auth.userInfo.responseCode === '00'" href="#"  @click="selectCampaign(campaignId)" class="button-4-copy-copy w-button">Share & Earn</a>-->
        <a href="#" @click="selectCampaign(campaignId, campaignBudgetExhausted)" class="button-4-copy-copy w-button">Share & Earn</a>
<!--        <router-link v-else to="/logon" class="button-4-copy-copy w-button">Share & Earn</router-link>-->
      </div>
      <div>
        <share-campaign-button type="icon" :customer-id="customerId" :campaign-id="campaignId" :title="title" :description="description" :campaign-budget-exhausted="campaignBudgetExhausted"/>
<!--        <a data-w-id="5b5cdcc9-8396-ba05-767d-aa83d2f72874" href="#" class="w-inline-block"><img src="@/assets/images/Share-icon.svg" loading="lazy" alt=""></a>-->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ShareCampaignButton from "@/components/button/ShareCampaignButton";

export default {
  name: "CampaignAllCard",
  components: { ShareCampaignButton },
  props:["logo","category", "companyName", "title", "description", "campaignId", "customerId", "campaignBudgetExhausted"],
  computed:{
    ...mapState(["campaign","auth"])
  },
  methods:{
    selectCampaign(_id, _campaignBudgetExhausted) {
      console.log(_campaignBudgetExhausted);
        localStorage.campaignId = _id;
        console.log(this.$route.path);
        if (!this.$route.path.includes("campaign-details"))
          this.$router.push(`/campaign-details/${_id.hexEncode()}`);
        this.$store.dispatch("campaign/updateCampaign", {
          campaignId: localStorage.campaignId,
        });
      // } else {
      //   store.commit("updateMessage", {
      //     type: "error",
      //     title: "Error",
      //     body: "Campaign budget has been exhausted!",
      //   });
      // }
    },
  },
  mounted() {
    //this.$store.dispatch("campaign/updateMyCampaigns", { customerId: this.auth.userInfo.customerId});
  }
};
</script>

<style scoped>
.w-button{
  color: #63b8a0 !important;
}
.w-button:hover{
  color: white !important;
}
</style>