<template>
  <div  :class="transactionClass">
    <div class="div-block-75">
      <div class="text-block-31">Recent Transactions</div>
      <router-link to="/view-wallet" class="link-block-7 w-inline-block">
        <div v-if="$route.path !== '/view-wallet'" class="text-block-35">View all</div>
      </router-link>
    </div>
    <div style="height: 500px;" class="overflow-auto" v-if="transaction.transactions.transactions.length > 0">
      <div v-for="trxn in transaction.transactions.transactions" v-bind:key="trxn.id" class="transaction-box">
        <div class="div-block-70">
          <div class="div-block-71">
            <img v-if="trxn.drCr === 'CR'" src="@/assets/images/credit.svg" loading="lazy" alt="">
            <img v-else src="@/assets/images/Debit.svg" loading="lazy" alt="">
          </div>
          <div class="div-block-76">
            <div class="div-block-78">
              <div class="text-block-32">{{ trxn.narration }}</div>
              <div v-if="trxn.drCr === 'CR'" class="text-block-33">
                + {{ trxn.amount | formatAmount }} {{ currency }}
              </div>
              <div v-else class="text-block-33-copy">
                - {{ trxn.amount | formatAmount }} {{ currency }}
              </div>
            </div>
            <div class="div-block-77">
              <div class="text-block-34">{{ trxn.eventDate | moment("calendar") }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <span v-else>
      <div class="div-block-105-copy"><img src="@/assets/images/transaction.svg" loading="lazy" width="73" alt="" class="image-41-copy">
        <div class="text-block-56">No recent transaction</div>
        <p class="paragraph-11">You have no recent transactions</p>
      </div>
    </span>
  </div>
</template>

<script>
import {mapState} from "vuex"

export default {
  name: "TransactionCard",
  computed:{
    ...mapState(["auth","transaction"]),
    currency: function(){
      if (this.transaction.transactions.accounts.length < 1) return "---"
      else return this.transaction.transactions.accounts[0].accountCurrency
    },
    balance: function(){
      if (this.transaction.transactions.accounts.length < 1) return "0.00"
      else return this.transaction.transactions.accounts[0].accountBalance
    },
    transactionClass: function(){
      if (this.$route.path === '/view-wallet') return 'div-block-95'
      else return 'transaction-block'
    },
    debits: function(){
      if (this.transaction.transactions.transactions.length < 1) return "0.00"
      else return this.transaction.transactions.transactions.filter(it => it.drCr === 'DR').reduce((init, curr) => (
        init+=parseFloat(curr.amount==null?'0.00':curr.amount.toString().replace(/,/g,''))
      ),0).toFixed(2)
    },
    credits: function(){
      if (this.transaction.transactions.transactions.length < 1) return "0.00"
      else return this.transaction.transactions.transactions.filter(it => it.drCr === 'CR').reduce((init, curr) => (
        init+=parseFloat(curr.amount==null?'0.00':curr.amount.toString().replace(/,/g,''))
      ),0).toFixed(2)
    }
  },
  mounted() {
    this.$store.dispatch("transaction/queryTransaction", {customerId: this.auth.userInfo.customerId})
  }
};
</script>

<style scoped>

</style>