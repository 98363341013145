import BaseResponse from "@/model/response/BaseResponse";

class ManualFundingResponse extends BaseResponse {

  read(){
    return {
      data: [],
      responseCode: null,
      responseMessage: null
    }
  }

  readSingle(){
    return {
     userId: null,
     userStatus: null,
      responseCode: null,
      responseMessage: null,
    }
  }

}

export default ManualFundingResponse;