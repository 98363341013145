<template>
  <div class="dashboard-nav wf-section">
    <div class="main-dashboard-section wf-section">
      <div class="main-dashboard-body mall-copy">
        <div class="get-started-wrapper"></div>
        <div class="mall-profile-details">
          <div class="container-v2">
            <div class="profile-picture">
              <img
                :src="require('@/assets/images/Male.png')"
                loading="lazy"
                width="120"
                alt=""
                class="image-61"
              />
            </div>
            <div class="div-block-193">
              <div class="div-block-3">
                <h1 class="business-name">Salfun Global</h1>
                <div class="div-block-238">
                  <a href="#" class="btn edit-profile-btn w-button"
                    >Edit Profile</a>
                  <a href="#" class="btn share-btn w-button"></a>
                </div>
              </div>
              <div class="merchant-bio">
                Lorem ipsum dolor sit amhjdjsj. Neque turpis lorem a ipsum dolor
                sit amhjdjsj. dolor sit amhjdjsj dolor sit amhjdjsj.
              </div>
              <div class="div-block-239">
                <div class="merchant-email">
                  <img
                    :src="require('@/assets/images/sms.svg')"
                    loading="lazy"
                    alt=""
                    class="contact-icon"
                  />
                  <div class="text-block-139">salfunlimited@gmail.com</div>
                </div>
                <div class="merchant-phone">
                  <img
                    :src="require('@/assets/images/call.svg')"
                    loading="lazy"
                    alt=""
                    class="contact-icon"
                  />
                  <div class="text-block-140">+2347654789954</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container-v2">
          <div
            data-current="Tab 4"
            data-easing="ease"
            data-duration-in="300"
            data-duration-out="100"
            class="tabs-2 w-tabs"
          >
            <div class="tabs-menu w-tab-menu">
              <a
                data-w-tab="Tab 4"
                class="tab products w-inline-block w-tab-link w--current"
                ><img
                  loading="lazy"
                  :src="require('@/assets/images/wallet.svg')"
                  alt=""
                  class="tab-icons"
                />
                <div class="tab-text">PRODUCTS</div>
              </a>
              <a data-w-tab="Tab 2" class="tab orders w-inline-block w-tab-link"
                ><img
                  loading="lazy"
                  :src="require('@/assets/images/receipt.svg')"
                  alt=""
                  class="tab-icons"
                />
                <div class="tab-text">ORDERS</div>
              </a>
              <a data-w-tab="Tab 3" class="tab w-inline-block w-tab-link"
                ><img
                  loading="lazy"
                  :src="require('@/assets/images/bag-2.svg')"
                  alt=""
                  class="tab-icons"
                />
                <div class="tab-text">SALES</div>
              </a>
            </div>
            <div class="w-tab-content">
              <div
                data-w-tab="Tab 4"
                class="tab-content w-tab-pane w--tab-active"
              >
                <div class="empty-state">
                  <div class="div-block-240">
                    <img
                      loading="lazy"
                      :src="require('@/assets/images/shopping.svg')"
                      alt=""
                      class="image-62"
                    />
                    <div class="text-block-141">
                      You have no product at the moment
                    </div>
                    <router-link
                      to="/add-products"
                      class="btn edit-profile-btn-copy w-button"
                      >+ Add new product</router-link
                    >
                  </div>
                </div>
              </div>
              <div data-w-tab="Tab 2" class="tab-content w-tab-pane">
                <div class="empty-state">
                  <div class="div-block-240">
                    <img
                      loading="lazy"
                      :src="require('@/assets/images/shopping.svg')"
                      alt=""
                      class="image-62"
                    />
                    <div class="text-block-141">
                      You have no orders at the moment
                    </div>
                    <a href="#" class="btn edit-profile-btn-copy w-button"
                      >+ Add new product</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MallNewUser",
  mounted() {
    const plugin = document.createElement("script");
    plugin.setAttribute("src", "https://apvertise.com/js/webflow.js");
    plugin.async = true;
    document.head.appendChild(plugin);
  },

  created() {
    const script = document.createElement("script");
    script.src = "https://apvertise.com/js/webflow.js";
  },
};
</script>

<style scoped></style>
