import client from "./BaseService";

export default {
  callCreateCampaignApi(payload) {
    // console.log("Service Payload ==>>", payload)
    return client.apiClient.post("campaignCreate", payload);
  },
  callReadCampaignApi(payload) {
    // console.log("Service Payload ==>>", payload)
    return client.apiClient.post("campaignRead", payload);
  },
  callReadCampaignByUserIdApi(payload) {
    // console.log("Service Payload ==>>", payload)
    return client.apiClient.post("campaignReadByUserId", payload);
  },
  callReadSingleCampaignApi(payload) {
    // console.log("Service Payload ==>>", payload)
    return client.apiClient.post("campaignReadSingle", payload);
  },
  callReadExhibitionTypesApi(payload) {
    // console.log("Service Payload ==>>", payload)
    return client.apiClient.post("campaignExhibitionTypes", payload);
  },
  callReadSharedCampaignApi(payload) {
    // console.log("Service Payload ==>>", payload)
    return client.apiClient.post("campaignSharedRead", payload);
  },
  callUpdateCampaignApi(payload) {
    // console.log("Service Payload ==>>", payload)
    return client.apiClient.post("campaignUpdate", payload);
  },
  callUpdateCampaignStatusApi(payload) {
    // console.log("Service Payload ==>>", payload)
    return client.apiClient.post("campaignUpdateStatus", payload);
  },
  callCampaignSettlementApi(payload) {
    // console.log("Service Payload ==>>", payload)
    return client.apiClient.post(`share/${payload}`, { reference: payload });
  },
  callGetShareLinkApi(payload) {
    // console.log("Service Payload ==>>", payload)
    return client.apiClient.post("getShareLink", payload);
  },
};
