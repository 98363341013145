import BaseResponse from "@/model/response/BaseResponse";

class DocumentResponse extends BaseResponse {
    readSingleResponse() {
        return {
            documentId: null,
            documentType: null,
            documentUrl: null,
            documentGroup: null,
            documentOwner: null,
            documentFormat: null,
            documentBankCode: null,
            documentCreatedAt: null,
            documentUpdatedAt: null,
            responseCode: null,
            responseMessage: null,
        };
    }
    readResponse() {
        return {
            responseCode: null,
            responseMessage: null,
            data: [],
        };
    }
}

export default DocumentResponse;