import client from "./BaseService";

export default {
    callBalanceEnquiryApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("balanceEnquiry", payload);
    },
    callQueryTransactionApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("queryTransaction", payload);
    },
    callReversalApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("reversal", payload);
    },
    callFundTransferOutwardApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("fundTransferOutward", payload);
    },
    callFundTransferInternalApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("fundTransferInternal", payload);
    },

    callRequestStatementApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("requestStatement", payload);
    },

    callPayoutReadByCustomerIdApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("payoutReadByCustomerId", payload);
    },

    callPayoutCreateApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("payoutCreate", payload);
    },
    callFlutterwaveApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("flutterwave-funding", payload);
    },
    callFlutterwaveVerifyApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("flutterwave-verify", payload);
    },
};

