
import client from "./BaseService";

export default {

  callCreateFundTransferApi(payload) {
    // console.log("Service Payload ==>>", payload)
    return client.apiClient.post("apvCreateFundTransfer", payload);
  },
  callReadFundTransferApi(payload) {
    // console.log("Service Payload ==>>", payload)
    return client.apiClient.post("apvReadFundTransfer", payload);
  },
  callUpdateFundTransferApi(payload) {
    // console.log("Service Payload ==>>", payload)
    return client.apiClient.post("apvUpdateFundTransfer", payload);
  },
  callUpdateFundTransferStatusApi(payload) {
    // console.log("Service Payload ==>>", payload)
    return client.apiClient.post("apvUpdateFundTransferStatus", payload);
  },
};