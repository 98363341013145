import client from "./BaseService";

export default {
    callValidateBvnApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("apvValidateBvn", payload);
    },

    callCompleteUpgradeAccountApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("apvCompleteUpgradeAccount", payload);
    },

    callGetUpgradeStatusApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("apvGetUpgradeStatus", payload);
    },

    callCompleteUpgradeDetailsApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("apvCompleteUpgradeDetails", payload);
    },

    callCreateSubAccountApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("apvCreateSubAccount", payload);
    },

    callValidatePhoneNumberApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("apvValidatePhoneNumber", payload);
    },

    callQueryCustomerApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("apvQueryCustomer", payload);
    },

    callReferalUpdateApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("apvReferalUpdate", payload);
    },

    callReferalReadApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("apvReferalRead", payload);
    },
};
