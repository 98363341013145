class AuthRequest {
  logon() {
    return {
      password: null,
      appVersion: "1.0.0",
      customerPushId: null,
      countryCode: "000",
      latitude: null,
      ipAddress: null,
      source: "WEB",
      devicePlatform: null,
      deviceId: null,
      deviceName: null,
      username: null,
      longitude: null,
      idToken: null,
    };
  }

  logout() {
    return {};
  }

  initiateEnrollment() {
    return {
      accountClass: "AC001",
      accountCurrency: "USD",
      customerAddress: null,
      customerCountryCode: null,
      customerDob: null,
      customerEmail: null,
      customerFirstName: null,
      customerGender: null,
      customerLastName: null,
      customerMiddleName: null,
      customerPhoneNumber: null,
      customerState: null,
      source: "WEB",
    };
  }

  completeEnrollment() {
    return {
      countryCode: null,
      customerPhoneNumber: null,
      otp: null,
      password: null,
      hostId: null,
      passwordConfirmation: null,
      source: "WEB",
    };
  }

  initiateResetPassword() {
    return {
      username: null,
      countryCode: "234",
    };
  }

  completeResetPassword() {
    return {
      username: null,
      password: null,
      passwordConfirmation: null,
      otp: null,
      countryCode: null,
    };
  }

  changePassword() {
    return {
      username: null,
      oldPassword: null,
      password: null,
      passwordConfirmation: null,
      countryCode: "234",
    };
  }

  checkPhoneExistence() {
    return {
      phoneNumber: null,
      countryCode: null,
    };
  }

  resendOtp() {
    return {
      phoneNumber: null,
      countryCode: null,
    };
  }
}

export default AuthRequest;
