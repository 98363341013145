<template>
  <div class="div-block-96">
    <div class="div-block-97">
      <div class="div-block-98">
        <img src="@/assets/images/Cardholder_1.svg" loading="lazy" alt="" />
      </div>
      <div>
        <div class="text-block-45">Total Balance</div>
        <div class="text-block-44">
          {{ currency }} {{ balance | formatAmount }}
        </div>
      </div>
    </div>
    <div>
      <a
        data-w-id="1f32ba0e-d1bd-71a8-191d-bd10970f7603"
        href="#"
        class="top-up-button w-button"
        @click="showModalAction"
        >Top Up</a
      >
      <button
        @click="goToWallet"
        class="cashout-button w-button"
        style="color: #63b8a0 !important;"
        >Cash Out</button
      >
    </div>
    <flutterwave-modal :show="showModal"/>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FlutterwaveModal from "@/components/modal/FlutterwaveModal";


export default {
  name: "BalanceCard",
  components: { FlutterwaveModal },
  data(){
    return{
      showModal: false
    }
  },
  methods:{
    showModalAction() {
      this.showModal = !this.showModal
    },
    goToWallet(){
      localStorage.setItem("wallet", "yes");
      this.$router.push("/profile-settings")
    }
  },
  computed: {
    ...mapState(["auth", "transaction"]),
    currency: function () {
      if (this.transaction.transactions.accounts.length < 1) return "---";
      else return this.transaction.transactions.accounts[0].accountCurrency;
    },
    balance: function () {
      if (this.transaction.transactions.accounts.length < 1) return "0.00";
      else return this.transaction.transactions.accounts[0].accountBalance;
    },
  },
  mounted() {
    this.$store.dispatch("transaction/queryTransaction", {
      customerId: this.auth.userInfo.customerId,
    });
  },
};
</script>

<style scoped></style>
