import client from "./BaseService";

export default {
  callCreateReviewApi(payload) {
    // console.log("Service Payload ==>>", payload)
    return client.apiClient.post("review/create", payload);
  },
  callReadReviewApi(payload) {
    // console.log("Service Payload ==>>", payload)
    return client.apiClient.post("review/read", payload);
  },
  callReadSingleReviewApi(payload) {
    // console.log("Service Payload ==>>", payload)
    return client.apiClient.post("review/read-by-id", payload);
  },
  callReadReviewByProductIdApi(payload) {
    // console.log("Service Payload ==>>", payload)
    return client.apiClient.post("review/read-by-product-id", payload);
  },
  // callUpdateReviewApi(payload) {
  //   // console.log("Service Payload ==>>", payload)
  //   return client.apiClient.post("product/update", payload);
  // // },
  // callDeleteReviewApi(payload) {
  //   // console.log("Service Payload ==>>", payload)
  //   return client.apiClient.post("product/delete", payload);
  // },
};