<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "HomeLayout",
  created() {
    const plugin = document.createElement("script");
    plugin.setAttribute("src", "https://apvertise.com/js/webflow.js");
    plugin.async = true;
    document.head.appendChild(plugin);
  }
};
</script>

<style scoped>

</style>