import BaseResponse from "@/model/response/BaseResponse";

class AccountResponse extends BaseResponse {
  readSingleResponse() {
    return {
      accountId: null,
      accountNumber: null,
      accountUserId: null,
      accountOpenDate: null,
      accountCloseDate: null,
      accountStatus: null,
      accountBalance: null,
      accountCreatedAt: null,
      accountUpdatedAt: null,
      responseCode: null,
      responseMessage: null,
    };
  }
  readResponse() {
    return {
      responseCode: null,
      responseMessage: null,
      data: [],
    };
  }
}

export default AccountResponse;
