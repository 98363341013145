import router from "@/router";
import OrganisationService from "@/service/OrganisationService";
// import OrganisationRequest from "@/model/request/OrganisationRequest";
import OrganisationResponse from "@/model/response/OrganisationResponse";
import swal from "sweetalert";
import store from "@/store/store";
import organisationResponse from "@/model/response/OrganisationResponse";

export const state = {
  organisation: OrganisationResponse.prototype.readSingleResponse(),
  organisations: OrganisationResponse.prototype.readResponse(),
  organisationTypes: OrganisationResponse.prototype.readOrganisationCategoryResponse(),
  malls: OrganisationResponse.mallReadByCustomerIdResponse,
  filteredMalls: [],
  allMalls: OrganisationResponse.mallsReadResponse,
  editMall: organisationResponse.organisationUpdateResponse,
  organisationLoading: false,
};
export const getters = {
  getCurrentOrganization: state => {
    if (state.organisations == null) return {}
    return state.organisations.filter(it => it.organisationId.toString() === localStorage.organisationId).length < 1
      ? {}
      : state.organisations.filter(it => it.organisationId.toString() === localStorage.organisationId)[0]
  },
  getRecentOrganization: state => {
    if (state.organisations == null) return {}
    return state.organisations.filter(it => it.organisationId.toString() === localStorage.organisationId).length < 1
      ? {}
      : state.organisations.filter(it => it.organisationId.toString() === localStorage.organisationId)[0]
  },
};
export const mutations = {
  updateOrganisation: (state, payload) => {
    state.organisation = payload;
  },
  updateOrganisations: (state, payload) => {
    state.organisations = payload;
  },
  updateMalls: (state, payload) => {
    state.malls = payload;
  },
  updateEditMall: (state, payload) => {
    state.editMall = payload;
  },
  updateAllMalls: (state, payload) => {
    state.allMalls = payload;
  },
  updateFilteredMalls: (state, payload) => {
    state.filteredMalls = payload;
  },
  updateOrganisationLoading: (state, payload) => {
    state.organisationLoading = payload;
  },
  updateOrganisationTypes: (state, payload) => {
    state.organisationTypes = payload;
  },
};
export const actions = {
  updateOrganisation: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateOrganisationLoading", true);
    return OrganisationService.callReadSingleOrganisationApi(payload)
      .then((response) => {
        commit("updateOrganisationLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          commit("updateOrganisation", responseData);
        } else {
          store.commit("updateMessage", {
            type: "error",
            title: "Error",
            body: responseData.responseMessage,
          });
        }
      })
      .catch((err) => {
        commit("updateOrganisationLoading", false);
        console.log(err);
      });
  },
  updateMalls: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateOrganisationLoading", true);
    return OrganisationService.callReadMallByCustomerIdApi(payload)
      .then((response) => {
        commit("updateOrganisationLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          commit("updateMalls", responseData);
        } else {
          store.commit("updateMessage", {
            type: "error",
            title: "Error",
            body: responseData.responseMessage,
          });
        }
      })
      .catch((err) => {
        commit("updateOrganisationLoading", false);
        console.log(err);
      });
  },
  allMalls: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateOrganisationLoading", true);
    return OrganisationService.callReadAllMallsApi(payload)
      .then((response) => {
        commit("updateOrganisationLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          commit("updateAllMalls", responseData);
        } else {
          store.commit("updateMessage", {
            type: "error",
            title: "Error",
            body: responseData.responseMessage,
          });
        }
      })
      .catch((err) => {
        commit("updateOrganisationLoading", false);
        console.log(err);
      });
  },

  updateOrganisationTypes: ({ commit }, payload) => {
    return OrganisationService.callReadOrganisationTypesApi(payload)
      .then((response) => {
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          commit("updateOrganisationTypes", responseData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },

  updateOrganisations: ({ commit}, payload) => {
    //TODO USER LOADING
    commit("updateOrganisationLoading", true);
    return OrganisationService.callReadOrganisationByUserIdApi(payload)
      .then((response) => {
        commit("updateOrganisationLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          commit("updateOrganisations", responseData.data);
          console.log(responseData)
          // if (!localStorage.organisationId) localStorage.organisationId = responseData.data[0].organisationId
          // else {
          //   if (responseData.data.filter(it => it.organisationId === localStorage.organisationId).length < 1)
          //     localStorage.organisationId = responseData.data[0].organisationId
          // }
        }
      })
      .catch((err) => {
        commit("updateOrganisationLoading", false);
        console.log(err);
      });
  },

  createOrganisation: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateOrganisationLoading", true);
    return OrganisationService.callCreateOrganisationApi(payload)
      .then((response) => {
        commit("updateOrganisationLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          store.commit("updateMessage", {
            type: "success",
            title: "Success",
            body: responseData.responseMessage,
          });
        } else {
          store.commit("updateMessage", {
            type: "error",
            title: "Error",
            body: responseData.responseMessage,
          });
        }
      })
      .catch((err) => {
        commit("updateOrganisationLoading", false);
        console.log(err);
      });
  },

  editMall: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateOrganisationLoading", true);
    return OrganisationService.callUpdateOrganisationApi(payload)
      .then((response) => {
        commit("updateOrganisationLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          router.push({ name: "MallCreationSuccess" });
        }
        else {
          store.commit("updateMessage", {
            type: "error",
            title: "Error",
            body: responseData.responseMessage,
          });
        }
      })
      .catch((err) => {
        commit("updateOrganisationLoading", false);
        console.log(err);
      });
  },


  editOrganisation: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateOrganisationLoading", true);
    return OrganisationService.callUpdateOrganisationApi(payload)
      .then((response) => {
        commit("updateOrganisationLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          swal("Success", responseData.responseMessage, "success");
        } else {
          swal("Error", responseData.responseMessage, "error");
        }
      })
      .catch((err) => {
        commit("updateOrganisationLoading", false);
        console.log(err);
      });
  },

  editOrganisationStatus: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateOrganisationLoading", true);
    return OrganisationService.callUpdateOrganisationStatusApi(payload)
      .then((response) => {
        commit("updateOrganisationLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          swal("Success", responseData.responseMessage, "success");
        } else {
          swal("Error", responseData.responseMessage, "error");
        }
      })
      .catch((err) => {
        commit("updateOrganisationLoading", false);
        console.log(err);
      });
  },
};
