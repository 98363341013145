// import router from "@/router";
import MoneyService from "@/service/MoneyService";
// import MoneyRequest from "@/model/request/MoneyRequest";
import MoneyResponse from "@/model/response/MoneyResponse";
import swal from 'sweetalert'

export const state = {
    money: MoneyResponse.prototype.readSingleResponse(),
    moneys: MoneyResponse.prototype.readResponse(),
    moneyLoading: false,
};
export const getters = {};
export const mutations = {
    updateMoney: (state, payload) => {
        state.money = payload;
    },
    updateMoneys: (state, payload) => {
        state.moneys = payload;
    },
    updateMoneyLoading: (state, payload) => {
        state.moneyLoading = payload;
    },
};
export const actions = {
    sendMoneyRequest: ({ commit }, payload) => {
        //TODO USER LOADING
        commit("updateMoneyLoading", true);
        return MoneyService.callSendMoneyRequestApi(payload)
            .then((response) => {
                commit("updateMoneyLoading", false);
                let responseData = response.data;
                if (responseData.responseCode === "00") {
                    commit("updateMoney", responseData);
                }else{
                    swal("Error", responseData.responseMessage, "error");
                }
            })
            .catch((err) => {
                commit("updateMoneyLoading", false);
                console.log(err);
            });
    },

    readMoneyRequestList: ({ commit }, payload) => {
        //TODO USER LOADING
        commit("updateMoneyLoading", true);
        return MoneyService.callReadMoneyRequestListApi(payload)
            .then((response) => {
                commit("updateMoneyLoading", false);
                let responseData = response.data;
                if (responseData.responseCode === "00") {
                    commit("updateMoneys", responseData);
                }
            })
            .catch((err) => {
                commit("updateMoneyLoading", false);
                console.log(err);
            });
    },

    moneyRequestResponse: ({ commit }, payload) => {
        //TODO USER LOADING
        commit("updateMoneyLoading", true);
        return MoneyService.callMoneyRequestResponseApi(payload)
            .then((response) => {
                commit("updateMoneyLoading", false);
                let responseData = response.data;
                if (responseData.responseCode === "00") {
                    swal("Success", responseData.responseMessage, "success");
                }else{
                    swal("Error", responseData.responseMessage, "error");
                }
            })
            .catch((err) => {
                commit("updateMoneyLoading", false);
                console.log(err);
            });
    },

    moneyRequestReminder: ({ commit }, payload) => {
        //TODO USER LOADING
        commit("updateMoneyLoading", true);
        return MoneyService.callMoneyRequestReminderApi(payload)
            .then((response) => {
                commit("updateMoneyLoading", false);
                let responseData = response.data;
                if (responseData.responseCode === "00") {
                    swal("Success", responseData.responseMessage, "success");
                }else{
                    swal("Error", responseData.responseMessage, "error");
                }
            })
            .catch((err) => {
                commit("updateMoneyLoading", false);
                console.log(err);
            });
    },

};


