<template>
  <div class="login-form w-form">
    <form id="email-form" autocomplete="off" @submit.prevent="login" name="email-form" data-name="Email Form" class="form-2">
      <div class="div-block-64">
        <label for="name" class="embolden field-label-3">Email</label>
        <input type="text" autocomplete="false" v-model="model.username" class="text-field-4 w-input" maxlength="256" name="name" data-name="Name" placeholder="Enter your email address" id="name"></div>
      <div>
        <label for="Password" class="embolden field-label-4">Passcode</label>
        <input type="password" autocomplete="false" v-model="model.password" @keypress="isNumber" class="text-field-4 w-input" minlength="6" maxlength="6" name="Password" data-name="Password" placeholder="Enter your Password" id="Password" required=""></div>
      <div class="forgot-password-block">
        <router-link to="/forget-password" class="link-block-6 w-inline-block">
          <div class="text-block-22">Forgot your password?</div>
        </router-link>
      </div>
      <base-button :loading="auth.authLoading" title="Continue"/>
    </form>
    <div class="w-form-done">
      <div>Thank you! Your submission has been received!</div>
    </div>
<!--    <div class="w-form-fail">-->
<!--      <div>Oops! Something went wrong while submitting the form.</div>-->
<!--    </div>-->
  </div>
</template>

<script>
import BaseButton from "@/components/button/BaseButton";
import AuthRequest from "@/model/request/AuthRequest";
import {mapState} from "vuex"
export default {
  name: "LoginForm",
  components:{
    BaseButton
  },
  data(){
    return{
      model: AuthRequest.prototype.logon()//new AuthRequest().logon(),
    }
  },
  methods:{
    login(){
      this.$store.dispatch("auth/login", this.model)
    },
    isNumber(event) {
      if (!/\d/.test(event.key) && event.key !== ".")
        return event.preventDefault();
    },
  },
  computed:{
    ...mapState(["auth"])
  }
};
</script>

<style scoped>
.embolden{
  font-weight: bold;
}

</style>