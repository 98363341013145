<template>
  <div class="dashboard-nav wf-section">
    <div class="main-dashboard-section wf-section">
      <div class="main-dashboard-body mall-copy">
        <div class="container-v2">
          <div class="create-campaign-form-container">
            <div class="campain-success">
              <lottie-player
                autoplay
                loop
                mode="normal"
                src="documents/64787-success.json"
                class="lottie-animation"
                style="width: 150px"
              ></lottie-player>

              <h1 class="heading-24">Product added 🥳</h1>
              <div class="text-block-43">
                You have successfully added a new product
              </div>
              <router-link to="/mall-registered" class="button-11 w-button"
                >Continue</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as animationData from "@/assets/documents/64787-success.json";
//import BaseLottie from "@/components/lottie/BaseLottie";
export default {
  name: "AddProductsSuccess",
  components: {
    //  BaseLottie,
  },
  data() {
    return {
      defaultOptions: { animationData: animationData },
      animationSpeed: 1,
    };
  },
  methods: {
    handleAnimation: function (anim) {
      this.anim = anim;
    },

    stop: function () {
      this.anim.stop();
    },

    play: function () {
      this.anim.play();
    },

    pause: function () {
      this.anim.pause();
    },

    onSpeedChange: function () {
      this.anim.setSpeed(this.animationSpeed);
    },
  },
};
</script>

<style scoped></style>
