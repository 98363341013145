// import router from "@/router";
import AccountService from "@/service/AccountService";
// import AccountRequest from "@/model/request/AccountRequest";
import AccountResponse from "@/model/response/AccountResponse";
import swal from "sweetalert";
import store from "@/store/store";

export const state = {
  account: AccountResponse.prototype.readSingleResponse(),
  accounts: AccountResponse.prototype.readResponse(),
  accountLoading: false,
};
export const getters = {};
export const mutations = {
  updateAccount: (state, payload) => {
    state.account = payload;
  },
  updateAccounts: (state, payload) => {
    state.accounts = payload;
  },
  updateAccountLoading: (state, payload) => {
    state.accountLoading = payload;
  },
};
export const actions = {
  validateBvn: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateAccountLoading", true);
    return AccountService.callValidateBvnApi(payload)
      .then((response) => {
        commit("updateAccountLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          commit("updateAccount", responseData);
        } else {
          swal("Error", responseData.responseMessage, "error");
        }
      })
      .catch((err) => {
        commit("updateAccountLoading", false);
        console.log(err);
      });
  },

  completeUpgradeAccount: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateAccountLoading", true);
    return AccountService.callCompleteUpgradeAccountApi(payload)
      .then((response) => {
        commit("updateAccountLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          commit("updateAccounts", responseData);
        }
      })
      .catch((err) => {
        commit("updateAccountLoading", false);
        console.log(err);
      });
  },

  getUpgradeStatus: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateAccountLoading", true);
    return AccountService.callGetUpgradeStatusApi(payload)
      .then((response) => {
        commit("updateAccountLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          store.commit("updateMessage",{
            type:"success",
            title:"Successful",
            body:responseData.responseMessage
          });
        } else {
          store.commit("updateMessage",{
            type:"error",
            title:"Error",
            body:responseData.responseMessage
          })
        }
      })
      .catch((err) => {
        commit("updateAccountLoading", false);
        console.log(err);
      });
  },

  completeUpgradeDetails: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateAccountLoading", true);
    return AccountService.callCompleteUpgradeDetailsApi(payload)
      .then((response) => {
        commit("updateAccountLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          swal("Success", responseData.responseMessage, "success");
        } else {
          swal("Error", responseData.responseMessage, "error");
        }
      })
      .catch((err) => {
        commit("updateAccountLoading", false);
        console.log(err);
      });
  },

  createSubAccount: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateAccountLoading", true);
    return AccountService.callCreateSubAccountApi(payload)
      .then((response) => {
        commit("updateAccountLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          swal("Success", responseData.responseMessage, "success");
        } else {
          swal("Error", responseData.responseMessage, "error");
        }
      })
      .catch((err) => {
        commit("updateAccountLoading", false);
        console.log(err);
      });
  },

  validatePhoneNumber: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateAccountLoading", true);
    return AccountService.callValidatePhoneNumberApi(payload)
      .then((response) => {
        commit("updateAccountLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          commit("updateAccounts", responseData);
        }
      })
      .catch((err) => {
        commit("updateAccountLoading", false);
        console.log(err);
      });
  },

  queryCustomer: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateAccountLoading", true);
    return AccountService.callQueryCustomerApi(payload)
      .then((response) => {
        commit("updateAccountLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          swal("Success", responseData.responseMessage, "success");
        } else {
          swal("Error", responseData.responseMessage, "error");
        }
      })
      .catch((err) => {
        commit("updateAccountLoading", false);
        console.log(err);
      });
  },

  referalUpdate: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateAccountLoading", true);
    return AccountService.callReferalUpdateApi(payload)
      .then((response) => {
        commit("updateAccountLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          swal("Success", responseData.responseMessage, "success");
        } else {
          swal("Error", responseData.responseMessage, "error");
        }
      })
      .catch((err) => {
        commit("updateAccountLoading", false);
        console.log(err);
      });
  },

  referalRead: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateAccountLoading", true);
    return AccountService.callReferalReadApi(payload)
      .then((response) => {
        commit("updateAccountLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          swal("Success", responseData.responseMessage, "success");
        } else {
          swal("Error", responseData.responseMessage, "error");
        }
      })
      .catch((err) => {
        commit("updateAccountLoading", false);
        console.log(err);
      });
  },
};
