import BaseResponse from "@/model/response/BaseResponse";

class FundTransferResponse extends BaseResponse {
  readSingleResponse() {
    return {
      userid: null,
      creditAccountNumber: null,
      debitAccountNumber: null,
      creditAccountName: null,
      ftProductCode: null,
      reference: null,
      source: null,
      username: null,
      password: null,
      countryCode: null,
      trnAmount: null,
      trnNarration: null,
      responseCode: null,
      responseMessage: null,
    };
  }
  readResponse() {
    return {
      responseCode: null,
      responseMessage: null,
      data: [],
    };
  }
}

export default FundTransferResponse;