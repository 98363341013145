import Vue from "vue";
import VueRouter from "vue-router";
import VueMeta from "vue-meta";

import NProgress from "nprogress";

import store from "../store/store";
import authRoutes from "./routes/auth-routes";
import dashboardRoutes from "./routes/dashboard-routes";
import campaignRoutes from "./routes/campaign-routes";
import walletRoutes from "./routes/wallet-routes";
import mallRoutes from "@/router/routes/mall-routes";
import ApiDocumentationView from "@/views/documentation/ApiDocumentationView.vue";

Vue.use(VueRouter);
Vue.use(VueMeta, {
  // The component option name that vue-meta looks for meta info on.
  keyName: "page",
});

VueRouter.prototype.absUrl = function (url, newTab = true) {
  const link = document.createElement("a");
  link.href = url;
  link.target = newTab ? "_blank" : "";
  if (newTab) link.rel = "noopener noreferrer"; // IMPORTANT to add this
  link.click();
};

const baseRoutes = [
  {
    path: "/api-documentation",
    name: "ApiDocumentationView",
    meta: { authRequired: false },
    component: ApiDocumentationView,
  },
];

const routes = baseRoutes.concat(
  dashboardRoutes,
  campaignRoutes,
  walletRoutes,
  mallRoutes,
  authRoutes,
);

const router = new VueRouter({
  routes,
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  mode: "history",
  base: process.env.BASE_URL,
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});
console.log(routes);
// Before each route evaluates...
router.beforeEach(async (routeTo, routeFrom, next) => {
  // Check if auth is required on this route
  NProgress.start();
  console.log("routeFrom >>>>>>>>>>>> ", routeFrom);
  if (routeFrom.meta.authRequired && !routeTo.meta.authRequired){
    console.log(`routeTo >>>>>>>>>>>> ${routeFrom.fullPath}`, routeTo);
    if (routeTo.path.includes("campaign-details") || routeTo.path.includes("campaign-all"))
      return next();
    else return next(routeFrom.fullPath);
  }

  // Check if auth is required on this route
  // (including nested routes).
  const authRequired = routeTo.matched.some((route) => route.meta.authRequired);

  // console.log("Token b4: ",store.getters["getToken"])

  // If auth isn't required for the route, just continue.
  if (!authRequired) return next();
  console.log("Token: ",store.getters["getToken"])
  // If auth is required and the user is logged in...
  // console.log("user info: "+JSON.stringify(store.getters["auth/getUser"]))
  if (store.getters["auth/getUser"].responseCode === "00") {
    // Validate the local user token...
    // console.log("found user info: "+store.getters["auth/getUser"])
    // return next()
    return next();
  } else if (store.getters["getToken"] != null) {
    store.commit("system/updateOverlayLoading", true)
    return next();
    // return next({ name: "Preloader" });
    // console.log("Token found: ",store.getters["getToken"])
  }

  // If auth is required and the user is NOT currently logged in,
  // redirect to login.
  console.log("redirect to login");
  redirectToLogin();

  // eslint-disable-next-line no-unused-vars
  function redirectToLogin() {
    // Pass the original route to the login component
    next({ name: "Logon", query: { redirectFrom: routeTo.fullPath } });
  }
});

router.beforeResolve(async (routeTo, routeFrom, next) => {
  // Create a `beforeResolve` hook, which fires whenever
  // `beforeRouteEnter` and `beforeRouteUpdate` would. This
  // allows us to ensure data is fetched even when params change,
  // but the resolved route does not. We put it in `meta` to
  // indicate that it's a hook we created, rather than part of
  // Vue Router (yet?).
  try {
    // For each matched route...
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        // If a `beforeResolve` hook is defined, call it with
        // the same arguments as the `beforeEnter` hook.
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            // If the user chose to redirect...
            if (args.length) {
              // If redirecting to the same route we're coming from...
              // Complete the redirect.
              next(...args);
              reject(new Error("Redirected"));
            } else {
              resolve();
            }
          });
        } else {
          // Otherwise, continue resolving the route.
          resolve();
        }
      });
    }
    // If a `beforeResolve` hook chose to redirect, just return.
  } catch (error) {
    return;
  }

  // If we reach this point, continue resolving the route.
  next();
});

router.afterEach(() => {
  NProgress.done();
});

export default router;
