<template>
  <div class="dashboard-nav wf-section">
    <div class="text-block-145">
      <router-link to="/mall-all" class="breadcrumblink">Back </router-link>
    </div>
    <div class="main-dashboard-section wf-section">
      <div class="main-dashboard-body mall-copy">
        <div class="get-started-wrapper"></div>
        <div  class="mall-profile-details">
          <div  class="container-v2" style="display: flex; justify-content: flex-start">
            {{ currentOrganisation }}
            <div class="profile-picture w-inline-block">
              <b-img
                :src="organisation.organisationLogo"
                loading="lazy"
                rounded="circle"
                style="height: 157px; width: 157px"
                width=""
                alt=""
                class="image-61"
              ></b-img>
          </div>
            <div class="div-block-193">
              <div class="div-block-3">
                <h1 class="business-name">
                  {{ organisation.organisationName }}
                </h1>
                <div class="div-block-238">
<!--                  <a data-w-id="f1dfdc8f-7ddb-519c-9fbf-cb335cf6f291" href="#" class="btn edit-profile-btn w-button">Edit Mall</a>-->
<!--                  <a href="#" @click="showModal = !showModal" class="btn edit-profile-btn w-button"-->
<!--                    >Switch Company</a>-->
<!--                  <a href="#" class="btn share-btn w-button"></a>-->
                </div>
              </div>
              <div class="merchant-bio">
                {{ organisation.organisationDescription }}
              </div>
              <div class="div-block-239">
                <div class="merchant-email">
                  <img
                    :src="require('@/assets/images/sms.svg')"
                    loading="lazy"
                    alt=""
                    class="contact-icon"
                  />
                  <div class="text-block-139">{{ organisation.organisationEmail }} </div>
                </div>
                <div class="merchant-phone">
                  <img
                    :src="require('@/assets/images/call.svg')"
                    loading="lazy"
                    alt=""
                    class="contact-icon"
                  />
                  <div class="text-block-140">
                    {{ organisation.organisationPhone }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="products.length > 0" class="container-v2">
          <div
            data-current="Tab 4"
            data-easing="ease"
            data-duration-in="300"
            data-duration-out="100"
            class="tabs-2 w-tabs"
          >
            <div class="tabs-menu w-tab-menu">
              <a
                data-w-tab="Tab 4"
                class="tab products w-inline-block w-tab-link w--current"
                ><img
                  loading="lazy"
                  :src="require('@/assets/images/wallet.svg')"
                  alt=""
                  class="tab-icons"
                />
                <div class="tab-text">PRODUCTS</div>
              </a>
            </div>
            <div class="w-tab-content">
              <div data-w-tab="Tab 4" class="tab-content w-tab-pane w--tab-active">
                <div class="div-block-237">
                  <div class="product-number">All product(s) </div>
                </div>
                <div class="w-layout-grid grid-6">
                  <div
                    id="w-node-_919e8174-62fc-3f62-43aa-05e8085252c5-26baae02"
                    class="product-card card"
                    v-for="productObj in products" :key="productObj.productId">
<!--                    <div class="card-header bbb" style="height: 125px;" :style="{'backgroundImage':`url(${productObj.productImage.split(',')[0]})`}">-->
                     <img
                      id="w-node-_919e8174-62fc-3f62-43aa-05e8085252c6-26baae02"
                      loading="lazy"
                      class="img-fluid img"
                      :src="productObj.productImage.split(',')[0]"
                      alt=""
                    />
<!--                    </div>-->
                    <div class="product-details card-body">
                      <div class="product-name">
                        {{ productObj.productName }}
                      </div>
                      <div class="price">₦ {{productObj.productLocalCurrencyPrice | formatAmount}}</div>
                    <div class="">
                      <a href="#"
                        @click="seeProduct(productObj.productId)"
                        class="btn view-product-btn w-button"
                        style="vertical-align: bottom; alignment: bottom"
                      >View Product</a>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
              <div data-w-tab="Tab 2" class="tab-content w-tab-pane">
<!--                <div class="div-block-237">-->
<!--                  <div class="product-number">All orders</div>-->
<!--                </div>-->
<!--                <div class="order-container">-->
<!--                  <div class="order w-clearfix">-->
<!--                    <img-->
<!--                      width="134.5"-->
<!--                      loading="lazy"-->
<!--                      :src="require('@/assets/images/Rectangle-437.png')"-->
<!--                      alt=""-->
<!--                      class="product-image"-->
<!--                    />-->
<!--                    <div class="order-details">-->
<!--                      <div>-->
<!--                        <div class="product-name orders">-->
<!--                          Knight&#x27;s Fortress Men&#x27;s Cotton Long Sleeve-->
<!--                          Shirt In Orange Check + Casual shirt-->
<!--                        </div>-->
<!--                        <div class="order-number">Order 54675577</div>-->
<!--                      </div>-->
<!--                      <div class="order-status">Pending</div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="order w-clearfix">-->
<!--                  </div>-->
<!--                </div>-->
              </div>
          </div>
        </div>
      </div>
        <div v-else class="container-v2">
          <div
            data-current="Tab 4"
            data-easing="ease"
            data-duration-in="300"
            data-duration-out="100"
            class="tabs-2 w-tabs">
            <div class="tabs-menu w-tab-menu">
              <a
                data-w-tab="Tab 4"
                class="tab products w-inline-block w-tab-link w--current">
                <img
                loading="lazy"
                  :src="require('@/assets/images/wallet.svg')"
                alt=""
                class="tab-icons" />
                <div class="tab-text">PRODUCTS</div>
              </a>
<!--              <a data-w-tab="Tab 2" class="tab orders w-inline-block w-tab-link"-->
<!--                ><img-->
<!--                loading="lazy"-->
<!--                :src="require('@/assets/images/receipt.svg')"-->
<!--                alt=""-->
<!--                class="tab-icons"-->
<!--              />-->
<!--                <div class="tab-text">ORDERS</div>-->
<!--              </a>-->
            </div>
            <div class="w-tab-content">
              <div
                data-w-tab="Tab 4"
                class="tab-content w-tab-pane w--tab-active"
              >
                <div class="empty-state">
                  <div class="div-block-240">
                    <img
                      loading="lazy"
                      :src="require('@/assets/images/shopping.svg')"
                      alt=""
                      class="image-62"
                    />
                    <div class="text-block-141">
                      There are no products at the moment
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      <switch-company-modal :show="showModal" />
    </div>
  </div>
  </div>
</template>

<script>
import  SwitchCompanyModal from "@/components/modal/SwitchCompanyModal";
import { mapState } from "vuex";
import OrganisationRequest from "@/model/request/OrganisationRequest";
import ProductRequest from "@/model/request/ProductRequest";
import productRequest from "@/model/request/ProductRequest";
export default {
  name: "MallCustomerView",
  data() {
    return {
      showModal: false,
      model: OrganisationRequest.prototype.organisationReadSingleRequest(),
      allProducts: ProductRequest.prototype.productReadByOrgRequest(),
      singleProduct: productRequest.productReadByProductIdRequest,
      image:'https://i.insider.com/61fc2855b0ec040018d0baa4?width=1136&format=jpeg'

    };
  },
  components: { SwitchCompanyModal },
  computed:{
    ...mapState(["organisation", "auth", "product"]),
    ...mapState({
      organisation:state => state.organisation.organisation,
      organisations:state => state.organisation.organisations[0].organisationId,
      products:state => state.product.product.data
    }),

    currentOrganisation(){
      return this.$store.getters.getCurrentOrganization
    }
  },
  methods:{
    seeProduct(productObj) {
      // this.$store.commit("product/updateProductDetails", productObj);
      localStorage.productId = productObj
      this.singleProduct.productId = localStorage.productId
      this.$store.dispatch("updateSingleProduct", this.singleProduct)
      this.$router.push("/product-customer-view");
      console.log(productObj);
    },
  },
  mounted() {
    const plugin = document.createElement("script");
    plugin.setAttribute("src", "https://apvertise.com/js/webflow.js");
    plugin.async = true;
    document.head.appendChild(plugin);
      this.model.organisationId = localStorage.getItem('organisationId');
      this.$store.dispatch("organisation/updateOrganisation", this.model);
      this.allProducts.productOrganisationId = localStorage.getItem('organisationId');
    this.$store.dispatch("product/updateProduct", this.allProducts);
      console.log(this.allProducts)
    this.currentOrganisation();
    console.log(localStorage.getItem('organisationId'));
  },

  created() {
    const script = document.createElement("script");
    script.src = "https://apvertise.com/js/webflow.js";
  },
};
</script>

<style scoped>
.btn{
  color: #5DC9A0 !important;
  border: 1px solid #5DC9A0 !important;
  margin-bottom: 0 !important;
}
.card{
  border-radius: 7px;
  width: 207px;
  height: auto;
}
.price{
  margin: 0;
}
.card-body{
  margin: 0;
}

.img{
  border-top-left-radius: 7px;
  border-top-right-radius:7px ;
}
.view-product-btn{
  margin: 0;
}
.bbb{
  background-size: cover;
  background-repeat: no-repeat;
}
</style>
