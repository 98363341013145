<template>
  <div>
    <EditProductFormOne v-if="this.product.screen === 'one'" />
    <EditProductFormTwo v-else-if="this.product.screen === 'two'" />
    <ProductMerchantView v-else />
  </div>
</template>

<script>
import ProductRequest from "@/model/request/ProductRequest";
import { mapState } from "vuex";
import EditProductFormOne from "@/components/form/EditProductFormOne";
import EditProductFormTwo from "@/components/form/EditProductFormTwo";
import ProductMerchantView from "@/views/mall/ProductMerchantView";

export default {
  name: "EditProducts",
  components: { ProductMerchantView, EditProductFormOne, EditProductFormTwo},
  data() {
    return {
      model: ProductRequest.productUpdateRequest,
    };
  },
  computed: {
    ...mapState(["product"]),
  },
};
</script>

<style scoped></style>
