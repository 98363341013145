<template>
  <div>
    <div class="dashboard-header">
      <div class="dashboardtitle">
        <div class="div-block-91">
          <router-link to="/dashboard" class="link-block-12 w-inline-block">
            <div>Back to Dashboard</div>
          </router-link>
          <h1 class="heading-18">Wallet</h1>
        </div>
      </div>
    </div>
    <div class="wallet-body">
      <div class="div-block-94">
        <balance-card/>
        <div>
          <wallet-stat-card/>
        </div>
      </div>
      <transaction-card/>
    </div>
  </div>
</template>

<script>
import BalanceCard from "@/components/card/BalanceCard";
import WalletStatCard from "@/components/card/WalletStatCard";
import TransactionCard from "@/components/card/TransactionCard";
export default {
  name: "Wallet",
  components: { TransactionCard, WalletStatCard, BalanceCard },
  mounted() {
    let id = this.$route.query.transaction_id;
    console.log(this.$route.query.transaction_id)
    if (this.$route.query.transaction_id){
      this.$store.dispatch("transaction/callFlutterwaveVerify", {id: id})
    }
  },
  methods: {

  },
};
</script>

<style scoped>

</style>