class ProductResponse {
  productCreateResponse() {
    return {
      responseCode: null,
      responseMessage: null,
    };
  }
  productReadResponse() {
    return {
      responseCode: null,
      responseMessage: null,
      data: [
        {
          productId: null,
          productOrganisationId: null,
          productDescription: null,
          productLocalCurrencyPrice: null,
          productUsdPrice: null,
          productQuantity: null,
          productMinDeliveryTime: null,
          productMaxDeliveryTime: null,
          productImage: null,
          productStatus: null,
          productCreatedAt: null,
          productUpdatedAt: null,
        },
      ],
    };
  }
  productReadByOrgResponse() {
    return {
      responseCode: null,
      responseMessage: null,
      data: [
        {
          productId: null,
          productOrganisationId: null,
          productDescription: null,
          productLocalCurrencyPrice: null,
          productUsdPrice: null,
          productQuantity: null,
          productMinDeliveryTime: null,
          productMaxDeliveryTime: null,
          productImage: null,
          productStatus: null,
          productCreatedAt: null,
          productUpdatedAt: null,
        },
      ],
    };
  }
  static productReadByIdResponse = {
    responseMessage: null,
    responseCode: null,
    productId: null,
    productName: null,
    productDescription: null,
    productLocalCurrencyPrice: null,
    productUsdPrice: null,
    productQuantity: null,
    productMinDeliveryTime: null,
    productMaxDeliveryTime: null,
    productImage: null,
    productCreatedAt: null,
    productUpdatedAt: null,
    productStatus: null
    }
  static buyProductResponse= {
  responseMessage: null,
  responseCode: null,
  redirectURL: null
  }
  productUpdateResponse() {
    return {
      responseCode: null,
      responseMessage: null,
    };
  }
  ProductDeleteResponse() {
    return {
      responseCode: null,
      responseMessage: null,
    };
  }
}

export default ProductResponse;
