class S3Request {
  uploadBase64() {
    return {
      username: null,
      base64: null,
      region: "us-east-1",
      source: "qucoon",
      s3bucket: "apvertise-repo"
    };
  }
}

export default S3Request;
