<template>
  <div class="top-campaigns wf-section">
    <div class="container">
      <div
        data-w-id="16259ffb-9094-34ad-41b7-df788fcea549"
        class="campaign-header-block"
      >
        <div class="top-campaign-wrapper">
          <h1 class="top-campaign-text">Top Campaigns</h1>
        </div>
        <div class="view-button-wrapper">
          <router-link to="/campaign-all" class="view-all-button w-button"
            >View All</router-link
          >
        </div>
      </div>
      <div
        data-animation="slide"
        data-nav-spacing="0"
        data-duration="500"
        data-infinite="1"
        class="campagn-slider w-slider"
      >
        <div
          data-w-id="6482892c-4411-f9aa-0399-b3e3dcae2414"
          class="mask w-slider-mask"
        >
          <div
            v-for="campgn in modifiedCampaign"
            v-bind:key="campgn.campaignId"
            style="height: 345px"
            class="campaign one w-slide"
          >
            <div class="div-block-5">
              <div class="div-block-4">
                <h4 class="heading-7">{{ campgn.organisationType }}</h4>
              </div>
              <div v-if="campgn.campaignBudgetExhausted === 'NO'" class="budget-status"><img src="@/assets/images/Active.svg" loading="lazy" width="10" alt="" class="image-56">
                <div class="text-block-121">Active</div>
              </div>
              <div v-else class="budget-status"><img src="@/assets/images/Inactive.svg" loading="lazy" width="10" alt="" class="image-56">
                <div class="inactive">Inactive</div>
              </div>
            </div>
            <div class="div-block-10">
              <div class="div-block-11">
                <b-avatar
                  style="width: 50px; height: 50px"
                  :src="campgn.organisationLogo"
                  loading="lazy"
                  alt=""
                ></b-avatar>
              </div>
              <div>
                <h1 class="heading-8">
                  {{ campgn.organisationName | formatCompanyNameWithEllipsis }}
                </h1>
              </div>
            </div>
            <div>
              <div class="div-block-6">
                <h1 class="heading-8-copy">
                  {{ campgn.campaignName | formatCampaignTitleWithEllipsis }}
                </h1>
              </div>
              <div>
                <div class="text-block-2">
                  {{
                    campgn.campaignName | formatCampaignDescriptionWithEllipsis
                  }}
                </div>
              </div>
              <div
                v-if="auth.userInfo.responseCode === '00'"
                class="div-block-7"
              >
                <a
                  href="#"
                  @click="selectCampaign(campgn.campaignId)"
                  class="button-2 w-button"
                  >Share & Earn</a
                >
                <share-campaign-button
                  type="icon"
                  :customer-id="campgn.customerOrganisationCustomerId"
                  :campaign-id="campgn.campaignId"
                  :title="campgn.campaignName"
                  :description="campgn.campaignDescription"
                />
              </div>
              <div v-else class="div-block-7">
                <a href="#" @click="selectCampaign(campgn.campaignId)" class="button-2 w-button">Share & Earn</a>
                <router-link to="/logon" class="w-inline-block"
                  ><img
                    src="@/assets/images/Share-icon.svg"
                    loading="lazy"
                    alt=""
                /></router-link>
              </div>
            </div>
          </div>
          <div style="height: 345px" class="campaign one w-slide">
            <div class="div-block-8">
              <img src="@/assets/images/Group.svg" loading="lazy" alt="" />
              <h1 class="heading-9">Sign up to see <br />more campaigns</h1>
              <router-link to="/register" class="button-3 w-button"
                >Register</router-link
              >
            </div>
          </div>
        </div>
        <div
          data-w-id="6482892c-4411-f9aa-0399-b3e3dcae2440"
          class="left-arrow w-slider-arrow-left"
        >
          <img
            src="@/assets/images/left-arrow.svg"
            loading="lazy"
            alt=""
            class="image-20"
          />
        </div>
        <div
          data-w-id="6482892c-4411-f9aa-0399-b3e3dcae2442"
          class="right-arrow w-slider-arrow-right"
        >
          <img
            src="@/assets/images/right-arrow.svg"
            loading="lazy"
            alt=""
            class="image-21"
          />
        </div>
        <div class="slide-nav w-slider-nav w-round"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ShareCampaignButton from "@/components/button/ShareCampaignButton";

export default {
  name: "TopCampaign",
  components: { ShareCampaignButton },
  computed: {
    ...mapState(["auth", "campaign"]),
    campaigns: function () {
      if (this.campaign.campaigns.data.length > 0)
        return this.campaign.campaigns.data;
      else return [];
    },
    modifiedCampaign: function () {
      return this.campaigns.slice(0, 3);
    },
  },
  methods: {
    selectCampaign(_id) {
      localStorage.campaignId = _id;
      if (!this.$route.path.includes("campaign-details"))
        this.$router.push(`/campaign-details/${_id.hexEncode()}`);
      this.$store.dispatch("campaign/updateCampaign", {
        campaignId: localStorage.campaignId,
      });
    },
  },
  created() {
    this.$store
      .dispatch("campaign/updateCampaigns", this.campaign.model)
      .then(() => {
        const plugin = document.createElement("script");
        plugin.setAttribute("src", "https://apvertise.com/js/webflow.js");
        plugin.async = true;
        document.head.appendChild(plugin);
      });
  },
};
</script>

<style scoped>
.view-all-button{
  color: #63b8a0 !important;
}
.view-all-button:hover{
  color: #ffffff !important;
}
.button-2,
.button-3{
  color: #63b8a0 !important;
}

.button-2:hover,
.button-3:hover{
  color: #FFFFFF !important;
}
</style>
