<template>
  <div class="dashboardtitle">
    <div class="div-block-91">
      <h1 class="heading-18">Dashboard</h1>
      <div class="text-block-24"><span class="text-span-5">Hello, {{auth.userInfo.customerFirstName | titleCase}} {{auth.userInfo.customerLastName | titleCase}} </span>👋🏼</div>
    </div>
    <div class="div-block-92">
      <div class="view-wallet-flex">
        <router-link to="/view-wallet" class="view-wallet-button w-button">Wallet</router-link>
        <router-link to="/create-new-campaign" class="new-campaign-button w-button">New Campaign</router-link>
        <router-link to="/business-details" style="margin-left: 10px" class="new-campaign-button w-button">New Mall</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex"
export default {
  name: "DashboardSubHeader",
  computed:{
    ...mapState(["auth"])
  }
};
</script>

<style scoped>
  .view-wallet-flex{
    display: flex;
    flex-direction: row;
  }
  .new-campaign-button{
    color: white !important;
  }
</style>