<template>
  <b-modal
    style="background: white;"
    ref="add-payout-account-modal"
    centered
    hide-header
    hide-footer
  >
    <div class="div-block-156">
      <a data-w-id="0e756eac-b69c-ca0d-7ba7-f2b54c7eb6e2" @click="hide()" href="#" class="close-topup-modal w-inline-block"><img src="@/assets/images/close-modal.svg" loading="lazy" alt=""></a>
    </div>

    <div style="padding: 0px; background: white;">
      <div v-if="screen === 'one'" style="opacity:1; padding: 0px;" class="select-country-wrapper">
        <div class="select-country-content">
          <div class="topup-modal-1">
            <h1 class="heading-28">Select country</h1>
            <a @click="selectCountry('NG', 'Nigeria')" data-w-id="7e75fd48-207a-cfe8-2d2d-a5b79344ad91" href="#" class="link-block-22 w-inline-block"><img src="@/assets/images/Frame_1.svg" loading="lazy" width="32" alt="" class="image-60">
              <div class="text-block-130">Nigeria</div>
            </a>
            <a @click="selectCountry('GH', 'Ghana')" data-w-id="9f1b1b4a-5372-d7b4-402a-ba780ab1fd77" href="#" class="link-block-22 w-inline-block"><img src="@/assets/images/ghana-1.svg" loading="lazy" width="32" alt="" class="image-60">
              <div class="text-block-130">Ghana</div>
            </a>
            <a href="#" class="link-block-22 w-inline-block"><img src="@/assets/images/canada-1.svg" loading="lazy" width="32" alt="" class="image-60">
              <div class="text-block-130">Canada</div>
              <div class="text-block-130-copy">Coming soon</div>
            </a>
            <a data-w-id="9123000a-c823-ba47-6c53-fe337685fe54" href="#" class="link-block-22 w-inline-block"><img src="@/assets/images/Group_1.svg" loading="lazy" width="32" alt="" class="image-60">
              <div class="text-block-130">Rwanda</div>
              <div class="text-block-130-copy">Coming soon</div>
            </a>
          </div>
        </div>
        <a data-w-id="89a5f1a3-c781-59f7-8f51-c5972ca1a241" href="#" class="close-topup-modal w-inline-block"><img src="@/assets/images/close.svg" loading="lazy" alt=""></a>
      </div>
      <div v-if="screen === 'two'" style="opacity:1; display: block;" class="enter-details-modal-nigeria">
        <div >
          <div class="topup-modal-1">
            <h1 class="heading-28">Payout Account ({{ country.name }})</h1>
            <div class="w-form">
              <form @submit.prevent="createPayoutAccount" id="email-form-2" name="email-form-3" data-name="Email Form 3" method="get">
                <div class="div-block-223">
                  <div class="div-block-224"><label class="field-label-18">Account Name</label><input v-model="model.accountName" type="text" class="text-field-21 w-input" maxlength="256" name="name-2" data-name="Name 2" placeholder="" id="name-22"></div>
                  <div class="div-block-225"><label class="field-label-18">Account Number</label><input v-model="model.accountNumber" type="text" class="text-field-21 w-input" maxlength="256" name="account number" data-name="Name 2" placeholder="" id="name-23"></div>
                </div>
                <div class="div-block-223">
                  <div class="div-block-224"><label class="field-label-18">Bank <b-spinner
                    v-if="maintenance.maintenanceLoading"
                    small
                    style="color: #63b8a0"
                  /></label><select v-model="model.accountBankCode" required id="field-3" name="field-3" data-name="Field 3" class="select-field-6 w-select">
                    <option value="">Select Bank</option>
                    <option v-for="bank in maintenance.bankListFlw.data" v-bind:key="bank.code" :value="bank.code">
                      {{ bank.name }}
                    </option>
                  </select></div>
                  <div class="div-block-225"><label class="field-label-18">OTP</label>
                    <div class="div-block-236"> <input v-model="model.otp" required="" type="text" class="text-field-25 w-input" maxlength="256" name="name-3" data-name="Name 3" placeholder="" id="name-32">
                      <a  v-if="auth.authLoading" href="#" class="button-25 w-button"><b-spinner small style="color: #ffffff" /></a>
                      <a v-else-if="timerCount > 0" href="#" class="button-25 w-button">Request in {{timerCount}}</a>
                      <a v-else href="#" @click="sendOtp" class="button-25 w-button">Request OTP</a>
                    </div>
                  </div>
                </div>
                <input v-if="!accountPayout.accountPayoutLoading" type="submit" value="Save changes" data-wait="Please wait..." class="button-24 w-button">
                <button v-else class="button-24 w-button"><b-spinner small style="color: #ffffff" /> please wait...</button>
              </form>
<!--              <div class="w-form-done">-->
<!--                <div>Thank you! Your submission has been received!</div>-->
<!--              </div>-->
<!--              <div class="w-form-fail">-->
<!--                <div>Oops! Something went wrong while submitting the form.</div>-->
<!--              </div>-->
            </div>
          </div>
        </div>
        <a data-w-id="feaec21e-d603-fdfe-6b5e-9fafa27ed9b8" href="#" class="close-topup-modal w-inline-block"><img src="@/assets/images/close.svg" loading="lazy" alt=""></a>
      </div>
      <div v-if="screen === 'three'" style="opacity:1" class="cashout-successful">
        <div class="top-up-modal-wrapper">
          <div class="div-block-132">
            <div data-w-id="89a5f1a3-c781-59f7-8f51-c5972ca1a26b" data-animation-type="lottie" data-src="../documents/56421-smooth-success-check-animation.json" data-loop="1" data-direction="1" data-autoplay="1" data-is-ix2-target="0" data-renderer="svg" data-default-duration="2" data-duration="0" class="lottie-animation-2"></div>
          </div>
          <div class="div-block-133">
            <h1 class="heading-29">Successful</h1>
            <p>Your payout account has been added <br>successfully.</p>
          </div>
          <a data-w-id="89a5f1a3-c781-59f7-8f51-c5972ca1a273" href="#" class="button-18 w-button">Continue</a>
        </div>
        <a data-w-id="89a5f1a3-c781-59f7-8f51-c5972ca1a275" href="#" class="close-topup-modal w-inline-block"><img src="@/assets/images/close.svg" loading="lazy" alt=""></a>
      </div>
    </div>
    <!--      <a data-w-id="89b8a742-7170-306e-20da-6054dea3dd98"  href="#" @click="hide()" class="close-topup-modal w-inline-block">-->
    <!--        <b-icon-x style="width: 35px; height: 35px; color: #63b8a0;"/>-->
    <!--      </a>-->
  </b-modal>
</template>

<script>
import { mapState } from "vuex";
import AccountPayoutRequest from "@/model/request/AccountPayoutRequest";

export default {
  name: "AddPayoutAccountModal",
  props: ["show"],
  data() {
    return {
      showModal: true,
      screen: "one",
      timer: "2:00",
      timerCount: 0,
      model: new AccountPayoutRequest().create(),
      country: {
        code: "NG",
        name: "Nigeria",
      }
    }
  },
  methods:{
    fetchBank() {
      this.$store.dispatch("maintenance/bankListFlwApi", {
        country: this.model.accountCountry,
      });
    },
    hide() {
      this.$refs["add-payout-account-modal"].hide();
    },
    selectCountry(_code, _name) {
      this.country.code = _code;
      this.country.name = _name;
      this.model.accountCountry = _code;
      this.screen = "two"
      this.$store.dispatch("maintenance/bankListFlwApi", {
        country: this.model.accountCountry,
      })
    },
    startTimer(duration) {
      let timer = duration,
        minutes,
        seconds;
      setInterval(function () {
        minutes = parseInt((timer / 60).toString(), 10);
        seconds = parseInt((timer % 60).toString(), 10);
        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;
        this.timer = minutes + ":" + seconds;
        if (--timer < 0) {
          timer = duration;
        }
      }, 1000);
    },
    sendOtp() {
      this.$store
        .dispatch("auth/resendOtpInApp", {
          phoneNumber: this.auth.userInfo.customerEmail,
          countryCode: "234",
        })
        .then(() => {
          console.log("Timer...");
          this.timerCount = 120;
        });
    },
    createPayoutAccount() {
      this.model.accountCustomerId = this.auth.userInfo.customerId;
      this.model.username = this.auth.userInfo.customerEmail;
      this.model.accountBankName = this.maintenance.bankListFlw.data.filter(
        (it) => it.code === this.model.accountBankCode
      )[0].name;
      this.$store
        .dispatch("accountPayout/createAccountPayout", this.model)
        .then(() => {
          this.$store.dispatch("accountPayout/updateAccountPayout", {
            customerId: this.auth.userInfo.customerId,
          });
          this.model.otp = "";
          this.timerCount = 0;
          // .then(() => {
          //   this.model.accountCountry =
          //     this.accountPayout.accountPayout.accountCountry;
          //   this.model.accountNumber =
          //     this.accountPayout.accountPayout.accountNumber;
          //   this.model.accountName =
          //     this.accountPayout.accountPayout.accountName;
          //   this.model.accountBankCode =
          //     this.accountPayout.accountPayout.accountBankCode;
          //   this.model.accountBankName =
          //     this.accountPayout.accountPayout.accountBankName;
          //   this.model.accountCustomerId =
          //     this.accountPayout.accountPayout.accountCustomerId;
          // });
        });
    },
    isNumber(event) {
      if (!/\d/.test(event.key) && event.key !== ".")
        return event.preventDefault();
    },
  },
  created() {
    this.$store
      .dispatch("accountPayout/updateAccountPayout", {
        customerId: this.auth.userInfo.customerId,
      })
      .then(() => {
        this.model.accountCountry =
          this.accountPayout.accountPayout.accountCountry;
        this.model.accountNumber =
          this.accountPayout.accountPayout.accountNumber;
        this.model.accountName = this.accountPayout.accountPayout.accountName;
        this.model.accountBankCode =
          this.accountPayout.accountPayout.accountBankCode;
        this.model.accountBankName =
          this.accountPayout.accountPayout.accountBankName;
        this.model.accountCustomerId =
          this.accountPayout.accountPayout.accountCustomerId;
        this.fetchBank()
      });
  },
  mounted() {
    this.model.accountCountry =
      this.accountPayout.accountPayout.accountCountry;
    this.model.accountNumber =
      this.accountPayout.accountPayout.accountNumber;
    this.model.accountName = this.accountPayout.accountPayout.accountName;
    this.model.accountBankCode =
      this.accountPayout.accountPayout.accountBankCode;
    this.model.accountBankName =
      this.accountPayout.accountPayout.accountBankName;
    this.model.accountCustomerId =
      this.accountPayout.accountPayout.accountCustomerId;
    this.fetchBank()
  },

  computed: {
    ...mapState(["auth", "maintenance", "accountPayout"]),
  },
  watch: {
    // whenever question changes, this function will run
    show: function () {
      this.screen = "one";
      this.model = {
        payoutReference: "",
        payoutCustomerId: "",
        payoutAmount: "",
      };
      this.$refs["add-payout-account-modal"].show();
    },
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        }
      },
    },
  },
};
</script>

<style scoped>

</style>