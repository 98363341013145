<template>
  <div class="about-us-section wf-section">
    <div class="container">
      <div class="div-block-160">
        <div class="div-block-164">
          <h1 class="heading-33">About Us</h1>
          <p class="paragraph-16">We are a team of creative engineers and digital natives driving a change in influencer-marketing - fostering efficiency, transparency and trust. We empower the brands to drive up ROI while simplifying every step of the way.</p>
        </div>
        <div class="div-block-163"><img src="@/assets/images/about-us-image-min.png" loading="lazy" width="607" alt=""></div>
      </div>
      <div class="div-block-161">
        <div><img src="@/assets/images/growthimage-min.png" loading="lazy" width="505" alt=""></div>
        <div class="div-block-162">
          <h1 class="heading-34">Growth <br>Marketing Team</h1>
          <p class="paragraph-17">Founded in 2021, Apvertise empowers brands to connect directly with Influencers to manage campaigns, drive more sales, awareness and visibility. Our clients get access to over 3000 influencers who are ready to share brands&#x27; campaigns across their network of followers.</p>
        </div>
      </div>
      <div class="div-block-161-copy">
        <div><img src="@/assets/images/mission-min.png" loading="lazy" width="505" alt=""></div>
        <div class="div-block-162">
          <h1 class="heading-34">Mission</h1>
          <p class="paragraph-17">Our mission is to drive up influencer-marketing efficiency (impacts, engagements, measurability, ROI etc) while driving down cost. The digital world finds people at its centre as the new media, and our mission is to turn this reality into tangible results for advertisers.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutUsSection"
};
</script>

<style scoped>

</style>