import client from "./BaseService";

export default {
  callCreateOrderApi(payload) {
    // console.log("Service Payload ==>>", payload)
    return client.apiClient.post("order/create", payload);
  },
  callReadOrderApi(payload) {
    // console.log("Service Payload ==>>", payload)
    return client.apiClient.post("order/read", payload);
  },
  callReadOrderByOrganisationIdApi(payload) {
    // console.log("Service Payload ==>>", payload)
    return client.apiClient.post("order/read-by-organisation-id", payload);
  },
  callReadOrderIdApi(payload) {
    // console.log("Service Payload ==>>", payload)
    return client.apiClient.post("order/read-by-id", payload);
  },
  callUpdateOrderApi(payload) {
    // console.log("Service Payload ==>>", payload)
    return client.apiClient.post("order/update", payload);
  },
  callDeleteOrderApi(payload) {
    // console.log("Service Payload ==>>", payload)
    return client.apiClient.post("order/delete", payload);
  },
};
