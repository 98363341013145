<template>
  <div class="w-layout-grid dashboardcards">
    <div id="w-node-eee64f51-be3d-9bc3-3db8-22cdd694fbf3-49a4c6d0" class="campaigncard-copy">
      <div class="div-block-66">
        <div class="div-block-67"><img src="@/assets/images/Megaphone.svg" loading="lazy" alt=""></div>
        <div>
          <div class="campaign-text-header">No. of Campaigns</div>
          <div class="campaing-count">{{ campaign.myCampaigns.data.length }}</div>
        </div>
      </div>
    </div>
    <div class="wallet-card">
      <div class="div-block-66">
        <div class="div-block-67"><img src="@/assets/images/Cardholder.svg" loading="lazy" alt=""></div>
        <div>
          <div class="campaign-text-header">Wallet Balance</div>
          <div class="campaing-count">{{ currency }} {{ balance| formatAmount }}</div>
        </div>
      </div>
    </div>
    <div class="earnings-card">
      <div class="div-block-66">
        <div class="div-block-67"><img src="@/assets/images/Megaphone.svg" loading="lazy" alt=""></div>
        <div>
          <div class="campaign-text-header">Earnings</div>
          <div class="campaing-count">{{ currency }} {{ earnings | formatAmount }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex"
export default {
  name: "DashboardCard",
  computed:{
    ...mapState(["auth","campaign","transaction"]),
    currency: function () {
      if (this.auth.userInfo.accounts == null) return "---";
      if (this.transaction.transactions.accounts.length < 1) return "---";
      else return this.transaction.transactions.accounts[0].accountCurrency;
    },
    balance: function () {
      if (this.transaction.transactions.accounts.length < 1) return "0.00";
      else return this.transaction.transactions.accounts.filter(it=>it.accountClass === "TIER_1_SAVINGS")[0].accountBalance;
    },
    earnings: function () {
      if (this.transaction.transactions.accounts.length < 1) return "0.00";
      else return this.transaction.transactions.accounts.filter(it=>it.accountClass !== "TIER_1_SAVINGS")[0].accountBalance;
    },
  },
  mounted() {
    this.$store.dispatch("transaction/queryTransaction", {
      customerId: this.auth.userInfo.customerId,
    });
  }
};
</script>

<style scoped>

</style>