// import router from "@/router";
import ManualFundingService from "@/service/ManualFundingService";
// import ManualFundingRequest from "@/model/request/ManualFundingRequest";
import ManualFundingResponse from "@/model/response/ManualFundingResponse";
import swal from "sweetalert";
import store from "@/store/store";

export const state = {
  manualFunding: ManualFundingResponse.prototype.readSingle(),
  manualFundings: ManualFundingResponse.prototype.read(),
  manualFundingLoading: false,
};

export const getters = {};

export const mutations = {
  updateManualFunding: (state, payload) => {
    state.manualFunding = payload;
  },
  updateManualFundings: (state, payload) => {
    state.manualFundings = payload;
  },
  updateManualFundingLoading: (state, payload) => {
    state.manualFundingLoading = payload;
  },
};

export const actions = {
  createManualFunding: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateManualFundingLoading", true);
    return ManualFundingService.callCreateManualFundingApi(payload)
      .then((response) => {
        commit("updateManualFundingLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          store.commit("updateMessage", {
            type: "success",
            title: "Success",
            action: "reload",
            body: "Transaction processing, please check your mail for final confirmation.",
          });
        } else {
          store.commit("updateMessage", {
            type: "error",
            title: "Error",
            body: responseData.responseMessage,
          });
        }
      })
      .catch((err) => {
        commit("updateManualFundingLoading", false);
        console.log(err);
      });
  },

  readManualFunding: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateManualFundingLoading", true);
    return ManualFundingService.callReadManualFundingApi(payload)
      .then((response) => {
        commit("updateManualFundingLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          commit("updateManualFundings", responseData);
        }
      })
      .catch((err) => {
        commit("updateManualFundingLoading", false);
        console.log(err);
      });
  },

  updateManualFunding: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateManualFundingLoading", true);
    return ManualFundingService.callUpdateManualFundingApi(payload)
      .then((response) => {
        commit("updateManualFundingLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          swal("Success", responseData.responseMessage, "success");
        } else {
          swal("Error", responseData.responseMessage, "error");
        }
      })
      .catch((err) => {
        commit("updateManualFundingLoading", false);
        console.log(err);
      });
  },

  updateManualFundingStatus: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateManualFundingLoading", true);
    return ManualFundingService.callUpdateManualFundingStatusApi(payload)
      .then((response) => {
        commit("updateManualFundingLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          swal("Success", responseData.responseMessage, "success");
        } else {
          swal("Error", responseData.responseMessage, "error");
        }
      })
      .catch((err) => {
        commit("updateManualFundingLoading", false);
        console.log(err);
      });
  },
};
