import client from "./BaseService";

export default {
  callClicksPerCampaignApi(payload) {
    // console.log("Service Payload ==>>", payload)
    return client.apiClient.post("clicksPerCampaignAnalytics", payload);
  },
  callImpressionsPerCampaignApi(payload) {
    // console.log("Service Payload ==>>", payload)
    return client.apiClient.post("impressionsPerCampaignAnalytics", payload);
  },
  callSharesPerCampaignApi(payload) {
    // console.log("Service Payload ==>>", payload)
    return client.apiClient.post("sharesPerCampaignAnalytics", payload);
  },
};
