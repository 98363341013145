import Vue from "vue";
import VueCryptojs from "vue-cryptojs";
Vue.use(VueCryptojs);

class SecurityUtil {
  secrete() {
    return "ZXlKeVpYTndiMjV6WldOdlpHVWlPaUl3TUNJc0luSmxjM0J2Ym5ObGJXVnpjMkZuWlNJNklsVnpaWElnWkc4Z1pYaHBjM1FpZlE=";
  }
  encrypt(messageToEncrypt = "") {
    return new Buffer(
      Vue.CryptoJS.DES.encrypt(
        messageToEncrypt,
        Vue.CryptoJS.enc.Utf8.parse(this.secrete),
        {
          mode: Vue.CryptoJS.mode.ECB,
          padding: Vue.CryptoJS.pad.Pkcs7,
        }
      ).toString()
    ).toString("hex"); // encoded === 54686973206973206d7920737472696e6720746f20626520656e636f6465642f6465636f646564
  }

  decrypt(messageToDecrypt = "") {
    const decoded = new Buffer(messageToDecrypt, "hex").toString();
    return Vue.CryptoJS.DES.decrypt(
      decoded,
      Vue.CryptoJS.enc.Utf8.parse(this.secrete),
      {
        mode: Vue.CryptoJS.mode.ECB,
        padding: Vue.CryptoJS.pad.Pkcs7,
      }
    ).toString(Vue.CryptoJS.enc.Utf8);
  }
}

export default SecurityUtil;
