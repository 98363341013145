<template>
  <div>
    <share-link-modal
      :show="showModal"
      :customer-id="auth.userInfo.customerId"
      :title="auth.userInfo.campaignName"
      :description="campaign.campaignDescription"
      :link="inviteId"
    />
    <div class="div-block-122">
      <div>
        <h1 class="heading-27">Basic Information</h1>
      </div>
      <div class="div-block-123">
        <div class="div-block-124"><img src="@/assets/images/PROFILE.svg" loading="lazy" width="112" alt=""></div>
        <div class="div-block-421">
          <a href="#" class="button-17 w-button" style="color: white !important;">Upload image</a>
          <p class="paragraph-12">You can upload images up to 256x256. Your avatar shows up in your public profile</p>
        </div>
      </div>
      <div>
        <div>
          <h1 class="heading-27">Account Info</h1>
        </div>
        <div>
          <div class="w-form">
            <form id="email-form" name="email-form" data-name="Email Form">
              <div class="div-block-125">
                <div class="div-block-126">
                  <label for="name" class="field-label-10">First Name</label>
                  <input type="text" v-model="auth.userInfo.customerFirstName" readonly class="text-field-12 w-input" maxlength="256" name="name" data-name="Name" placeholder="First Name" id="name">
                </div>
                <div class="div-block-127">
                  <label for="email" class="field-label-11">Last Name</label>
                  <input type="text" v-model="auth.userInfo.customerLastName" readonly class="text-field-13 w-input" maxlength="256" name="email" data-name="Email" placeholder="Last Name" id="email" required="">
                </div>
              </div>
              <div class="div-block-125">
                <div class="div-block-126">
                  <label for="name-2" class="field-label-12">Country</label>
                  <input type="text" v-model="auth.userInfo.customerCountry" readonly class="text-field-15 w-input" maxlength="256" name="name-2" data-name="Country" placeholder="" id="name-2">
                </div>
                <div class="div-block-127">
                  <label for="email-2" class="field-label-13">Email</label>
                  <input type="email" v-model="auth.userInfo.customerEmail" readonly class="text-field-14 w-input" maxlength="256" name="email-2" data-name="State" placeholder="" id="email-2" required="">
                </div>
              </div>
              <div class="div-block-402">
                <input v-model="getLink" readonly class="text-field-14 w-input" maxlength="256" name="email-2" data-name="State" placeholder="" id="email-4" required="">
                <button class="button-103" @click="getText" v-if="!inviteId && !this.auth.userInfo.customerInviteId">Generate</button>
                <button class="button-103" @click="getText" v-if="inviteId || this.auth.userInfo.customerInviteId && notCopied">Share & Earn</button>
                <button class="button-103" v-if="!notCopied">Copied!</button>
              </div>
              <!--            <input type="submit" value="Save Changes" data-wait="Please wait..." class="submit-button-8 w-button">-->
            </form>
            <!--          <div class="w-form-done">-->
            <!--            <div>Thank you! Your submission has been received!</div>-->
            <!--          </div>-->
            <!--          <div class="w-form-fail">-->
            <!--            <div>Oops! Something went wrong while submitting the form.</div>-->
            <!--          </div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
// import swal from "sweetalert";
import store from "@/store/store";
import ShareLinkModal from "@/components/modal/ShareLinkModal.vue";


export default {
  name: "BasicInformationForm",
  components:{ShareLinkModal},
  data(){
    return {
      inviteId: "",
      prevRoute: null,
      notCopied: true,
      showModal: false,
    }
  },
  computed:{
      ...mapState(["campaign", "auth"]),
    getLink(){
      if (this.auth.userInfo.customerInviteId || this.inviteId !== "") {
        let url = window.__env.app.appUrl;
        if (this.auth.userInfo.customerInviteId) {
          return url + "/register/" + this.auth.userInfo.customerInviteId;
        }
        else {
          return url + "/register/" + this.inviteId;
        }
      }
      else {
        return "Click to generate";
      }
    }
  },
  methods: {
    hideShareCampaignModal() {
      this.$refs["share-link-modal"].hide();
    },
    showShareCampaignModal() {
      if (this.campaignBudgetExhausted === "NO") {
        if (this.auth.userInfo.responseCode !== "00") {
          this.$router.push("/logon")
        } else {
          if (this.auth.userInfo.customerId !== this.customerId)
            this.$store
              .dispatch("campaign/getShareLink", {
                shareCampaignId: this.campaignId,
                shareCustomerId: this.auth.userInfo.customerId,
              })
              .then(() => {
                if (this.campaign.shareLink.responseCode === "00")
                  this.showModal = !this.showModal;
              });
          else this.showModal = !this.showModal;
          //this.showModal = !this.showModal
          //this.$refs["share-campaign-modal"].show();
        }
      } else {
        store.commit("updateMessage", {
          type: "error",
          title: "Error",
          body: "Campaign budget is exhausted!",
        });
      }
    },
    async getText() {
      if (this.auth.userInfo.customerInviteId || this.inviteId !== ""){
        let copyText = document.getElementById("email-4");
        let url = window.__env.app.appUrl;

        // Select the text field
        copyText.select();
        copyText.setSelectionRange(0, 99999); // For mobile devices
        this.inviteId = url + "/register/" + this.auth.userInfo.customerInviteId;
        this.showModal = true
        // Copy the text inside the text field;
        await navigator.clipboard.writeText(copyText.value);
        // Alert the copied text
        // await swal("Success", "Copied the text:" + copyText.value).then()
        // setTimeout(()=>{
        //   this.notCopied = true
        // }, 5000)
      }
      else {
        let resp = await this.$store.dispatch("settings/updateGeneralInvite", {customerId: this.auth.userInfo.customerId});
        this.inviteId = resp.customerInviteId;
      }
    },
  },
  mounted() {
  }
};
</script>

<style scoped>
.paragraph-12{
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* or 114% */
  color: rgba(42, 52, 89, 0.6);
}

.div-block-123{
  display: flex;
  gap: 40px;
  align-items: center;
  margin-bottom: 50px;
}
.div-block-421{

}

.button-103{
  background: #63B8A0;
  border-radius: 0px 6px 6px 0px;
  width: 141px;
  height: 50px;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height, or 133% */

  color: #FFFFFF;
}

.div-block-402{
  display: flex;
  width: 100%;
  margin-top: 40px;
}

.div-block-402 input{
  width: 100%;
  border-radius: 6px 0 0 6px;
}
</style>