<template>
  <div class="main-campaign-section wf-section">
    <div class="main-campaign-body">
      <div class="dashboard-header">
        <div class="dashboardtitle">
          <a @click="goBack" class="link-block-14 w-inline-block">
            <h1 class="heading-18">Back to campaigns</h1>
          </a>
        </div>
        <div class="container-copy-copy">
          <div class="campaign-detail-block">
            <div style="height: 100%;" class="div-block-23">
              <div>
                <b-carousel
                  id="carousel-1"
                  v-model="slide"
                  :interval="4000"
                  controls
                  indicators
                  label-next=""
                  label-prev=""
                  background="#ffffff"
                  style="text-shadow: 1px 1px 2px #333;"
                  @sliding-start="onSlideStart"
                  @sliding-end="onSlideEnd"
                >
                  <b-carousel-slide style="border-radius: 20px 20px 20px 20px;" v-for="banner in banners" v-bind:key="banner">
                    <template #img>
                      <img
                        style="border-radius: 20px 20px 20px 20px; width: 100%;"
                        class="d-block img-fluid"
                        width="606"
                        height="387"
                        :src="banner"
                        alt="image slot"
                      >
                    </template>
                  </b-carousel-slide>
                </b-carousel>
              </div>
            </div>
            <div class="div-block-107">
              <div class="div-block-24">
                <div class="div-block-11">
                  <img src="../../assets/images/ruby.svg" loading="lazy" alt="" />
                </div>
                <div>
                  <h1 class="heading-19">{{ campaign.campaign.organisationName }}</h1>
                  <div class="text-block-49">{{ campaign.campaign.campaignCreatedAt | moment("calendar") }}</div>
                </div>
              </div>
              <div class="div-block-102">
                <p class="paragraph-7">
                  {{ campaign.campaign.campaignDescription }}
                </p>
              </div>
              <share-campaign-button type="button" :customer-id="campaign.campaign.customerOrganisationCustomerId" :campaign-id="campaignId" :title="campaign.campaign.campaignName" :description="campaign.campaign.campaignDescription" :campaign-budget-exhausted="campaign.campaign.campaignBudgetExhausted"/>
            </div>
          </div>
          <div class="performance-overview-wrapper">
            <h1 class="perfomance-overview-heading">Performance Overview</h1>
            <div class="general-details-section">
              <div class="general-details">
                <div class="left-pane">
                  <div class="status-wrapper">
                    <div class="text-block-114">Status</div>
                    <div class="text-block-115">{{ campaign.campaign.campaignStatus}}</div>
                  </div>
                  <div class="div-block-197"></div>
                  <div class="div-block-199">
                    <img
                      src="../../assets/images/divider.svg"
                      loading="lazy"
                      alt=""
                    />
                  </div>
                  <div class="div-block-197"></div>
                  <div class="potential-reach">
                    <div class="div-block-202">
                      <div class="text-block-114">Potential reach</div>
                      <div class="text-block-115">{{ parseInt(parseInt(campaign.campaign.campaignBudget) / campaign.campaign.campaignPrice) | formatNumeric }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="divide">
                  <div class="div-block-198">
                    <img
                      src="../../assets/images/divider.svg"
                      loading="lazy"
                      alt=""
                    />
                  </div>
                </div>
                <div class="right-pane">
                  <div class="campaign-goal">
                    <div class="text-block-114">Campaign goal</div>
                    <div class="text-block-115">{{  campaign.campaign.campaignType? campaign.campaign.campaignType.replace("_", ": ")  : "" }}
                    </div>
                  </div>
                  <div class="div-block-197"></div>
                  <div class="div-block-200">
                    <img
                      src="../../assets/images/divider.svg"
                      loading="lazy"
                      alt=""
                    />
                  </div>
                  <div class="div-block-197"></div>
                  <div class="duration">
                    <div class="text-block-114">Duration</div>
                    <div class="text-block-115">{{ getDuration(campaign.campaign.campaignStartDate, campaign.campaign.campaignEndDate) }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="div-block-179-copy">
                <div class="text-block-103">Impressions/Signup</div>
                <div class="div-block-189">
                  <div>
                    <div class="text-block-110">Day 1</div>
                  </div>
                  <div class="div-block-190"></div>
                  <div class="text">
                    <div class="text-block-109">53</div>
                  </div>
                </div>
                <div class="div-block-189">
                  <div>
                    <div class="text-block-110">Day 1</div>
                  </div>
                  <div class="div-block-190"></div>
                  <div class="text">
                    <div class="text-block-109">53</div>
                  </div>
                </div>
                <div class="div-block-189">
                  <div>
                    <div class="text-block-111">Day 1</div>
                  </div>
                  <div class="div-block-190-copy"></div>
                  <div class="text">
                    <div class="text-block-109">53</div>
                  </div>
                </div>
                <div class="div-block-189">
                  <div>
                    <div class="text-block-111">Day 1</div>
                  </div>
                  <div class="div-block-190-copy-copy"></div>
                  <div class="text">
                    <div class="text-block-109">53</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="impression-and-budget-2">
              <div class="impressions-2">
                <div class="div-block-268">
                  <div class="div-block-266">
                    <h1 class="heading-38-copy-2">Shares/Impression Ratio<img src="../../assets/images/Question.svg" loading="lazy" data-w-id="cf74f77d-ed91-b15e-687c-43643b4d1158" alt="" class="image-67">
                      <div class="div-block-269">
                        <div class="text-block-154">Shares/Impressions Ratio</div>
                        <div class="text-block-155">
                          This shows the number of Influencers sharing your campaign
                          against the no of
                          <span class="text-span-15">valid clicks(impressions)</span>
                          your campaign gathered
                        </div>
                      </div>
                    </h1>
                    <div data-hover="false" data-delay="0" class="dropdown-8 w-dropdown">
                      <b-form-group class="ml-0 mb-3 pd-l-0" label-class="mr-0">
                        <b-input-group>
                          <select
                            class="mt-1 p-1 dropdown-toggle-7"
                            @change="query(false)"
                            v-model="model.type"
                            id="sortBySelect"
                          >
                            <option value="select">Select Period</option>
                            <option value="today">Today</option>
                            <option value="thisweek">This Week</option>
                            <option value="thismonth">This Month</option>
                            <option value="last30" aria-selected="true">
                              Last 30days
                            </option>
                            <option value="last90">Last 90days</option>
                            <option value="thisyear">This Year</option>
                            <option value="alltime">All Time</option>
                            <option value="custom">Custom Period</option>
                          </select>
                          <input
                            v-if="model.type === 'custom'"
                            type="text"
                            class="mt-1 p-1 datepicker-trigger"
                            id="datepicker-trigger"
                            placeholder="Select dates"
                            :value="formatDates(model.startDate, model.endDate)"
                          />
                          <AirbnbStyleDatepicker
                            v-if="model.type === 'custom'"
                            :trigger-element-id="'datepicker-trigger'"
                            :mode="'range'"
                            :fullscreen-mobile="true"
                            :date-one="model.startDate"
                            :date-two="model.endDate"
                            :close-after-select="true"
                                @date-one-selected="
                        (val) => {
                          model.startDate = val;
                        }
                      "
                                @date-two-selected="
                        (val) => {
                          model.endDate = val;
                        }
                      "
                              />
                              <b-input-group-append v-if="model.type === 'custom'">
                                <b-btn
                                  size="xm"
                                  style="
                          font-size: 11px;
                          height: 90%;
                          width: 100%;
                          border-bottom-left-radius: 0;
                          border-top-left-radius: 0;
                        "
                             class="pd-t-0 pd-b-0 mt-1"
                              @click="query(true)"
                            >Query</b-btn
                            >
                          </b-input-group-append>
                        </b-input-group>
                        <!--                <b-input-group class="col-5">-->
                        <!--                <div v-if="model.type === 'custom'" class="">-->
                        <!--                </div>-->
                        <!--                <b-input-group-append >-->
                        <!--                  <b-btn-->
                        <!--                    size="xm"-->
                        <!--                    style="font-size: 11px; height: 87%; border-bottom-left-radius: 0; border-top-left-radius: 0;"-->
                        <!--                    class="pd-t-0 pd-b-0 mt-1"-->
                        <!--                    @click="query(true)"-->
                        <!--                    >Query</b-btn-->
                        <!--                  >-->
                        <!--                </b-input-group-append>-->
                        <!--              </b-input-group>-->
                      </b-form-group>
                    </div>
                  </div>
                </div>
                <div class="div-block-209">
                  <p
                    v-if="
            analytic.clicksPerCampaign.responseCode !== '00' ||
            analytic.impressionsPerCampaign.responseCode !== '00' ||
            analytic.sharesPerCampaign.responseCode !== '00'
          "
                    style="text-align: center"
                  >
                    <b-spinner style="position: center" variant="success" />
                  </p>
                  <horizontal-bar-chart :data="lineGraphData" :option="options" v-else/>
<!--                  <div style="width: 500px; height: 300px; display: inline-block; background-color: black" id="chart-container"></div>-->
                </div>
              </div>
              <div class="budget-wrapper-2">
                <h1 class="heading-38">Budget</h1>
                <div class="div-block-264">
                  <div class="graph2-2 w-embed w-script">
                    <doughnut-chart  :data="doughnutData" :option="doughnutOptions" :width="300" :height="300"/>

                    <!--                    <div class="svg-container">-->
<!--                      <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" data-value="75">-->
<!--                        <circle r="45" cx="50" cy="50" />-->
<!--                        &lt;!&ndash; 282.78302001953125 is auto-calculated by path.getTotalLength() &ndash;&gt;-->
<!--                        <path class="meter" d="M5,50a45,45 0 1,0 90,0a45,45 0 1,0 -90,0" stroke-linecap="round" stroke-linejoin="round" stroke-dashoffset="282.78302001953125" stroke-dasharray="282.78302001953125" />-->
<!--                        &lt;!&ndash; Value automatically updates based on data-value set above &ndash;&gt;-->
<!--                        <text x="50" y="45" text-anchor="middle" dominant-baseline="central" font-size="13" class="pie-chart-total-2">{{ parseFloat(campaign.campaign.campaignBudget) | formatAmount }}-->
<!--                          USD</text>-->
<!--                        <text x="32" y="60" text-anchor="start" dominant-baseline="central" font-size="5" class="text-block-153">Total Budget</text>-->
<!--                      </svg>-->
<!--                    </div>-->
                  </div>
                  <div class="div-block-99">
                    <h1 class="pie-chart-total-2" v-if="size">{{ parseFloat(campaign.campaign.campaignBudget) | formatAmount }}USD</h1>
                    <h1 class="pie-chart-total-2 smaller" v-else>{{ parseFloat(campaign.campaign.campaignBudget) | formatAmount }}USD</h1>
                    <div class="text-block-153">Total Budget</div>
                  </div>
                </div>
                <div class="div-block-261">
                  <div class="div-block-267">

                  </div>
                  <div>
                    <div class="div-block-265">
                      <div class="div-block-211">
                        <div class="div-block-263"></div>
                        <div class="text-block-119">Amount Spent</div>
                      </div>
                      <div>
                        <div class="text-block-118">{{ parseFloat(campaign.campaign.campaignRunningBudget) | formatAmount }}
                          USD</div>
                      </div>
                    </div>
                    <div class="div-block-210-copy">
                      <div class="div-block-211">
                        <div class="div-block-212-copy"></div>
                        <div class="text-block-119">Amount left</div>
                      </div>
                      <div>
                        <div class="text-block-118">{{
                            (parseFloat(campaign.campaign.campaignBudget) - parseFloat(campaign.campaign.campaignRunningBudget))
                              | formatAmount
                          }} USD</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import DoughnutChart from "@/components/chart/base/DoughnutChart.vue";
import HorizontalBarChart from "@/components/chart/base/HorizontalBarChart.vue";
import { mapState } from "vuex";
import format from "date-fns/format";
import ShareCampaignButton from "@/components/button/ShareCampaignButton.vue";
import DoughnutChart from "@/components/chart/base/DoughnutChart.vue";

export default {
  name: "CampaignMetrics",
  components: { DoughnutChart, ShareCampaignButton, HorizontalBarChart},
  data() {
    return {
      slide: 0,
      sliding: null,
      model: {
        customerId: "",
        campaignId: "",
        startDate: "",
        type: "last30",
        endDate: "",
      },
      dateFormat: "D MMM",
      config: {
        type: "bar",
        data: this.data,
        options: {
          indexAxis: "y",
          plugins: {
            tooltip: {
              callbacks: {
                afterBody: (tooltipItems) => {
                  let sum = 0;
                  tooltipItems.forEach(function(tooltipItem) {
                    sum += tooltipItem.parsed.y;
                  });
                  if (sum === 0) {
                    return "\n Total Number of Influencers that \n shared your campaign";
                  }
                  if (sum === 1) {
                    return "\n Total Number of people (potential \n customers) that clicked your link.";
                  }
                  if (sum === 2) {
                    return "\n No of customers/valid clicks on \n your campaign";
                  }
                },
              },
            },
            legend: {
              display: false,
              labels: {
                color: "rgb(255, 99, 132)",
              },
            },
          },
          scales: {
            x: {
              grid: { display: true, drawBorder: false },
              ticks: { color: "rgba(31, 36, 49, 0.4)" },
            },
            y: {
              grid: { display: false, drawBorder: false },
              min: 0,
              max: 45,
              ticks: {
                color: "rgba(31, 36, 49, 0.4)",
              },
            },
          },
        },
      },
      chartOption: {
        indexAxis: 'y',
        legend: {
          display: false,
          position: 'bottom',
          fullWidth: true,
          labels: {
            boxWidth: 10,
            padding: 50
          }
        },
        scales: {
          yAxes: [{
            stacked: true,
            barPercentage: 0.75,
            gridLines: {
              display: true,
              drawTicks: true,
              drawOnChartArea: false
            },
            ticks: {
              fontColor: '#555759',
              fontFamily: 'Lato',
              fontSize: 11
            }

          }],
          xAxes: [{
            barThickness: 20,
            gridLines: {
              display: true,
              drawTicks: false,
              tickMarkLength: 5,
              drawBorder: false
            },
            ticks: {
              padding: 5,
              beginAtZero: true,
              fontColor: '#555759',
              fontFamily: 'Lato',
              fontSize: 11,
              callback: function(label, index, labels) {
                console.log(index, labels)
                return label / 1000;
              }

            },
            scaleLabel: {
              display: true,
              padding: 10,
              fontFamily: 'Lato',
              fontColor: '#555759',
              fontSize: 16,
              fontStyle: 700,
              labelString: 'Scale Label'
            },

          }]
        },
        responsive: true,
        maintainAspectRatio: false,
      },
      doughnutOptions: {
        tooltips: {
          callbacks: {
            label: function () {
              return "Amount left"| "Amount Spent";
            },
          },
        },
        percentageInnerCutout: 85,
        cutoutPercentage: 85,
        responsive: true,
        plugins: {
          legend: {
            display: false,
            position: "down",
          },
          title: {
            display: false,
            text: "Chart.js Doughnut Chart",
          },
        },
      },
      options: {
        maintainAspectRatio: false,
        height: "200px",
        animation: {
          easing: "easeOutQuart"
        },
        legend: {
          position: "bottom",
          display: false
        },
        scales: {
          yAxes: [{
            categoryPercentage: 0.6,
            borderRadius: 1,
            barPercentage: 0.6,
            ticks: {
              fontColor: "rgba(31, 36, 49, 0.4)",
              fontStyle: "bold",
              beginAtZero: true,
              padding: 10,
              //display: false - remove this and commenting to display: false
            },
            gridLines: {
              display: true,
            }
          }],
          xAxes: [{
            categoryPercentage: 0.6,
            borderRadius: 1,
            barPercentage: 0.6,
            gridLines: {
              display: false,
              color: "rgba(31, 36, 49, 0.4)",
              zeroLineColor: "transparent"
            },
            ticks: {
              padding: 10,
              beginAtZero: true,
              fontColor: "rgba(31, 36, 49, 0.4)",
              fontStyle: "bold",
              maxTicksLimit: 20,
              //display: false - remove this and commenting to display: false
            }
          }]
        }
      },
      data: {
        labels: ['Clicks','Shares','Impression'],
        datasets: [{
          type: 'horizontalBar',
          label: 'Clicks',
          borderRadius: 1,
          backgroundColor: ["#FAD96C", "rgba(62, 154, 247, 1)", "rgb(255,87,87)"],
          hoverBackgroundColor: ["rgb(239,172,42)", "rgb(0,119,245)", "rgb(255,21,21)"],
          borderColor: "rgba(99, 184, 160, 1)",
          fillColor: ["#FAD96C", "rgba(62, 154, 247, 1)", "rgba(99, 184, 160, 1)"],
          borderWidth: 0.4,
          data: [100, 50, 60]
        }, ],
        backgroundColor: ["#FAD96C", "rgba(62, 154, 247, 1)", "rgba(99, 184, 160, 1)"],
        fill: true,
        borderRadius: 1,
      },

      // The labels for the chart. These labels are positioned on the
      // left-hand-side as normal and the data is also given as the
      // labelsAbove labels.
    }
  },
  methods:{
    onSlideStart() {
      this.sliding = true
    },
    onSlideEnd() {
      this.sliding = false
    },
    goBack(){
      this.$router.go(-1)
    },
    getMonday(d) {
      d = new Date(d);
      let day = d.getDay(),
        diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
      return new Date(d.setDate(diff));
    },
    formatDates(dateOne, dateTwo) {
      let formattedDates = "";
      if (dateOne) {
        formattedDates = format(dateOne, this.dateFormat);
      }
      if (dateTwo) {
        //this.query(true);
        formattedDates += " - " + format(dateTwo, this.dateFormat);
      }
      return formattedDates;
    },
    getDuration(_startDate, _endDate) {
      let years =
        new Date(new Date(_endDate) - new Date(_startDate)).getFullYear() -
        1970;
      //console.log(years);
      let months = new Date(
        new Date(_endDate) - new Date(_startDate)
      ).getMonth();
      //console.log(months);
      let days =
        (new Date(_endDate).getTime() - new Date(_startDate).getTime()) /
        (1000 * 3600 * 24);
      //console.log(days);
      let hours =
        (new Date(_endDate).getTime() - new Date(_startDate).getTime()) /
        (1000 * 3600);
      //console.log(hours);
      let minutes =
        (new Date(_endDate).getTime() - new Date(_startDate).getTime()) /
        (1000 * 60);
      //console.log(minutes);
      let seconds =
        (new Date(_endDate).getTime() - new Date(_startDate).getTime()) / 1000;
      // //console.log(seconds);
      //return years;
      if (years > 0) return `${years} Years`;
      else if (months > 0) return `${months} Months`;
      else if (days > 0) return `${days} Days`;
      else if (hours > 0) return `${hours} Hours`;
      else if (minutes > 0) return `${minutes} Minutes`;
      else `${seconds} Seconds`;
    },
    getDaysArray(start, end) {
      let arr = [];
      let dt = new Date(start);
      for (; dt <= end; dt.setDate(dt.getDate() + 1)) {
        arr.push(format(new Date(dt), "YYYY-MM-DD"));
      }
      return arr;
    },
    fetchAnalytics(_startDate, _endDate, refresh = false) {
      let data = {
        responseCode: "",
        responseMessage: "",
        data: [],
      };
      if (refresh) {
        this.$store.commit("analytic/updateClicksPerCampaign", data);
        this.$store.commit("analytic/updateImpressionsPerCampaign", data);
        this.$store.commit("analytic/updateSharesPerCampaign", data);
      }
      let model = this.model;
      model.startDate = _startDate;
      model.endDate = _endDate;
      model.customerId = localStorage.customerId;
      model.campaignId = this.campaignId;
      this.$store.dispatch("analytic/updateClicksPerCampaign", this.model);
      this.$store.dispatch("analytic/updateImpressionsPerCampaign", this.model);
      this.$store.dispatch("analytic/updateSharesPerCampaign", this.model);
    },
    getStartEndDate() {
      let today = new Date();
      let model = {
        customerId: "",
        campaignId: "",
        startDate: "",
        endDate: "",
      };
      if (this.model.type === "today") {
        model.startDate = today.toJSON().slice(0, 10);
        model.endDate = new Date(new Date().setDate(today.getDate() + 1))
          .toJSON()
          .slice(0, 10);
        //console.log("today::", model.startDate, model.endDate);
      } else if (this.model.type === "thisweek") {
        let priorDate = this.getMonday(new Date());
        model.startDate = priorDate.toJSON().slice(0, 10);
        model.endDate = new Date(new Date().setDate(today.getDate() + 1))
          .toJSON()
          .slice(0, 10);
        //console.log("thisweek::", model.startDate, model.endDate);
      } else if (this.model.type === "thismonth") {
        let priorDate = new Date(today.getFullYear(), today.getMonth(), 2);
        model.startDate = priorDate.toJSON().slice(0, 10);
        model.endDate = new Date(new Date().setDate(today.getDate() + 1))
          .toJSON()
          .slice(0, 10);
      } else if (this.model.type === "last30") {
        let priorDate = new Date(new Date().setDate(today.getDate() - 30));
        model.startDate = priorDate; //.toJSON().slice(0,10);
        model.endDate = new Date(new Date().setDate(today.getDate() + 1))
          .toJSON()
          .slice(0, 10);
      } else if (this.model.type === "last90") {
        let priorDate = new Date(new Date().setDate(today.getDate() - 90));
        model.startDate = priorDate.toJSON().slice(0, 10);
        model.endDate = new Date(new Date().setDate(today.getDate() + 1))
          .toJSON()
          .slice(0, 10);
      } else if (this.model.type === "thisyear") {
        let priorDate = new Date(new Date().getFullYear(), 0, 1);
        model.startDate = priorDate.toJSON().slice(0, 10);
        model.endDate = new Date(new Date().setDate(today.getDate() + 1))
          .toJSON()
          .slice(0, 10);
      } else if (this.model.type === "alltime") {
        model.type = "alltime";
        model.startDate = null;
        model.endDate = null;
      }
      return model;
    },
    query(_ignore) {
      //console.log(this.model.type);
      //console.log(_ignore);
      if (this.model.type === "custom" && !_ignore) return;
      this.$store.commit("analytic/reset");
      let startEndDate = this.getStartEndDate();
      //console.log("startEndDate>>>", startEndDate);
      if (this.model.type !== "custom") {
        this.model.endDate = startEndDate.endDate;
        this.model.startDate = startEndDate.startDate;
      }
      this.$store.dispatch("analytic/updateClicksPerCampaign", this.model);
      this.$store.dispatch("analytic/updateImpressionsPerCampaign", this.model);
      this.$store.dispatch("analytic/updateSharesPerCampaign", this.model);
    },
    progressBar(){
      const meters = document.querySelectorAll('svg[data-value] .meter');

      meters.forEach((path) => {
        // Get the length of the path
        let length = path.getTotalLength();

        // console.log(length);

        // Just need to set this once manually on the .meter element and then can be commented out
        // path.style.strokeDashoffset = length;
        // path.style.strokeDasharray = length;

        // Get the value of the meter
        // let value = parseInt(path.parentNode.getAttribute('data-value'));
        // Calculate the percentage of the total length
        let to = length * ((parseFloat(this.campaign.campaign.campaignBudget) - parseFloat(this.campaign.campaign.campaignRunningBudget)));
        // Trigger Layout in Safari hack https://jakearchibald.com/2013/animated-line-drawing-svg/
        path.getBoundingClientRect();
        // Set the Offset
        path.style.strokeDashoffset = Math.max(0, to);
        // path.nextElementSibling.innerHTML = `${value}%`;
      });
    },

  },
  computed:{
    ...mapState(["analytic", "auth"]),
    size(){
        return !!(parseFloat(this.campaign.campaign.campaignBudget) < 10000);
    },
    doughnutData() {
      return {
        datasets: [
          {
            label: "Budget",
            weight: 0.1,
            data: [
              parseFloat(this.campaign.campaign.campaignBudget) - parseFloat(this.campaign.campaign.campaignRunningBudget),
              parseFloat(this.campaign.campaign.campaignRunningBudget),
            ],
            backgroundColor: ["#63b8a0", "#cae3dc"],
            hoverOffset: 4,
          },
        ],
      };
    },

    budget(){
      return this.campaign.campaign.campaignBudget ? this.campaign.campaign.campaignBudget : ""
    },
    consumption(){
      return this.campaign.campaign.campaignRunningBudget ? this.campaign.campaign.campaignRunningBudget : ""
    },
    goal(){
      return this.campaign.campaign.campaignType ? this.campaign.campaign.campaignType : ""
    },
    startDate(){
      return this.campaign.campaign.campaignStartDate ? this.campaign.campaign.campaignStartDate : ""
    },
    endDate(){
      return this.campaign.campaign.campaignEndDate ? this.campaign.campaign.campaignEndDate : ""
    },
    price(){
      return this.campaign.campaign.campaignPrice ? this.campaign.campaign.campaignPrice : ""
    },
    lineGraphData() {
      return  {
        labels: ['Clicks','Shares','Impression'],
          datasets: [{
          type: 'horizontalBar',
          label: 'Clicks',
          borderRadius: 1,
          backgroundColor: ["#FAD96C", "rgba(62, 154, 247, 1)", "rgb(255,87,87)"],
          hoverBackgroundColor: ["rgb(239,172,42)", "rgb(0,119,245)", "rgb(255,21,21)"],
          borderColor: "rgba(99, 184, 160, 1)",
          fillColor: ["#FAD96C", "rgba(62, 154, 247, 1)", "rgba(99, 184, 160, 1)"],
          borderWidth: 0.4,
          data: [this.analytic.clicksPerCampaign.data.length, this.analytic.sharesPerCampaign.data.length, this.analytic.impressionsPerCampaign.data.length]
        }
        ],
          backgroundColor: ["#FAD96C", "rgba(62, 154, 247, 1)", "rgba(99, 184, 160, 1)"],
          fill: true,
          borderRadius: 1,
      }
    },
    banners: function(){
      if (this.campaign.campaign.campaignBanners == null) return []
      return this.campaign.campaign.campaignBanners.split(",")
    },
    campaignId: function () {
      //this.$store.dispatch("campaign/updateCampaign", {campaignId: localStorage.campaignId})
      if (this.$route.params.campaignId)
        return this.$route.params.campaignId.hexDecode();
      else return localStorage.campaignId;
    },
    ...mapState(["campaign","auth","analytic"]),
  },
  mounted() {
    this.progressBar();
    if (this.$route.params.campaignId != null) {
      this.$store.dispatch("campaign/updateCampaign", {campaignId: this.campaignId})
    }
    this.model.customerId = localStorage.customerId;
    this.model.campaignId = this.campaignId;
    let startEndDate = this.getStartEndDate();
    if (
      this.analytic.clicksPerCampaign.responseCode !== "00" ||
      this.analytic.impressionsPerCampaign.responseCode !== "00" ||
      this.analytic.sharesPerCampaign.responseCode !== "00"
    ){
      console.log("no wahala")
      this.fetchAnalytics(startEndDate.startDate, startEndDate.endDate);
    }
  },
  watch: {
    customerId: function () {
      let startEndDate = this.getStartEndDate();
      this.fetchAnalytics(startEndDate.startDate, startEndDate.endDate);
    },
  },
};
</script>

<style scoped>
h1{
  margin: 0;
}

.image-67{
  margin-left: 10px;
}

.heading-38-copy-2:hover .div-block-269{
  opacity: 1 !important;
  display: flex !important;
}
.div-block-23,
.div-block-107{
  width: 100%;
}

.div-block-269 {
  position: absolute;
  left: 43%;
  top: 6%;
  display: none;
  width: 242px;
  padding: 12px;
  flex-direction: column;
  grid-row-gap: 10px;
  border-radius: 4px;
  background-color: #373d3f;
  color: #fff;
  z-index: 9999;
}

.graph2-2{
  width: 100%;
}

.svg-container{
//margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.svg-container svg {
  display: inline-flex;
  vertical-align: bottom;
  width: 300px;
  height: 300px;
  margin-bottom: 10px;
}

.svg-container circle {
  stroke-width: 8px;
  stroke: #F3F6F8;

  stroke-dasharray: 0;
  fill: none;
}

.svg text{
  margin-bottom: 4px;
  color: #00325c;
  font-size: 20px;
  line-height: 100%;
  font-weight: 600;
}
.svg-container .meter {
  stroke-width: 8px;

  stroke: #5DC9A0;
  fill: none;
  transition: stroke-dashoffset 1s cubic-bezier(0.43, 0.41, 0.22, 0.91);
  transform-origin: center center;
  transform: rotate(-90deg) scaleX(-1);
}

.svg-container .small-text{
  font-family: 'Space Grotesk', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 18px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;
  text-align: center;

  /* Gray/500 */

  color: #667085;

}

.div-block-24{
  justify-content: space-between;
  width: 100%;
}

.w-button{
  color: #63b8a0 !important;
}

.doughnut .chartjs-render-monitor{
  width: 200px !important;
  height: 200px !important;
}

.impression-and-budget-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 40px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 20px;
}

.impressions-2 {
  position: relative;
  width: 66%;
  max-width: none;
  padding: 30px;
  border-radius: 10px;
  background-color: #fff;
  font-size: 18px;
  line-height: 18px;
}

.div-block-268 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
//margin-bottom: 30px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  border: none;

}

.div-block-269 {
  position: absolute;
  left: 103%;
  top: 4%;
  display: none;
  width: 242px;
  padding: 12px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-row-gap: 10px;
  border-radius: 4px;
  background-color: #373d3f;
  color: #fff;
  display: none;
}


.div-block-266{
  display: flex;
  grid-column-gap: 10px;
  width: 100%;
  justify-content: space-between;
  align-items: baseline;
}

.div-block-262 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 16px;
}

.heading-38-copy-2 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 18px;
  line-height: 18px;
  font-weight: 600;
}

.text-block-156{
  margin: 0;
  color: rgba(42, 52, 89, 0.7);
  font-size: 14px;
  font-weight: 500;
}

.dropdown-toggle-7 {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 15px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(42, 52, 89, 0.13);
  border-radius: 3px;
}
.w-dropdown-toggle {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: inline-block;
  cursor: pointer;
  padding-right: 40px;
  margin: 0;
//padding: 0;
}
.w-dropdown-btn, .w-dropdown-toggle, .w-dropdown-link {
  position: relative;
  vertical-align: top;
  text-decoration: none;
  color: #222222;
//padding: 20px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  white-space: nowrap;
}

.w-icon-dropdown-toggle {
  position: absolute;
  top: 28%;
  right: 20px;
  margin: 0;
  padding: 0;
}

.div-block-263 {
  width: 14px;
  height: 14px;
  background-color: #63b8a0;
  margin: 0 10px 0 0;
  padding: 0;
}
.div-block-265 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.budget-wrapper-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 35%;
  max-width: none;
  padding: 30px 27px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-radius: 10px;
  background-color: #fff;
}

.div-block-264 {
  position: relative;
  //margin-bottom: 40px;
//padding-right: 40px;
//padding-left: 40px;
}
.graph2-2 {
  position: relative;
  z-index: 70;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background-color: transparent;
}

.div-block-99 {
  position: absolute;
  left: -7%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.div-block-209 {
  margin: 30px 0 0;
}

.pie-chart-total-2 .smaller{
  font-size: 26px;
}

.image-67:hover .div-block-269{
  display: flex !important;
}

.heading-38-copy-2:hover .div-block-269{
  display: flex !important;
}

.heading-38-copy-2:hover{
  color: #0a0a0a;
}

@media screen and (max-width: 991px){
  .impression-and-budget-2{
    flex-direction: column;
  }
  .budget-wrapper-2{
    width: 100%;
  }
  .impressions-2{
    width: 100%;
  }
}
@media screen and (min-width: 1280px) {
  .impressions-2 {
    position: relative;
  }
  .div-block-266 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    grid-column-gap: 10px;
  }
  .div-block-269 {
    position: absolute;
    left: 38%;
    top: 8%;
    right: auto;
    bottom: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
  //display: flex;
    width: 242px;
    padding: 12px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    grid-row-gap: 10px;
    border-radius: 4px;
    background-color: #373d3f;
    color: #fff;
    background-clip: border-box;
    -webkit-text-fill-color: inherit;
  }
  .image-67:hover .div-block-269{
    display: flex !important;
  }

  .heading-38-copy-2:hover .div-block-269{
    display: flex !important;
  }
}

</style>
