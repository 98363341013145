<template>
  <div id="how-it-works" class="how-it-works wf-section">
    <div class="container how-it-works">
      <div class="wrapper">
        <div class="how-it-works-left">
          <h1 data-w-id="3fab4580-798b-a593-5d7e-e2b323e7cb0d" class="heading-10">Apvertise for brand <br>&amp; ideas promoters</h1>
          <p data-w-id="c51b9c5b-ca15-97e0-f234-687aa2ee20bb" class="paragraph-6">Digitize your campaign, make it available to a wide range of (or selected) brand promoters and social media influencers at a go. Drive your marketing goal effectively and monitor it.<br></p>
        </div>
        <div class="how-it-works-image"><img src="@/assets/images/img1-min.png" loading="lazy" width="450" data-w-id="a47e67d3-de50-9cf1-a6c3-bca7cf234039" alt="" class="image-23"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HowItWorksSection"
};
</script>

<style scoped>

</style>