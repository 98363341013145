<template>
  <b-modal
    style="background: white"
    ref="payout-modal"
    centered
    hide-header
    hide-footer
  >
    <div class="div-block-156">
      <a
        data-w-id="0e756eac-b69c-ca0d-7ba7-f2b54c7eb6e2"
        @click="hide()"
        href="#"
        class="close-topup-modal w-inline-block"
        ><img src="@/assets/images/close-modal.svg" loading="lazy" alt=""
      /></a>
    </div>
    <!--    <div class="top-up-modal-wrapper">-->
    <br />
    <div class="topup-modal-1">
      <h1 class="heading-28">Request Payout</h1>
    </div>
    <div class="w-form">
      <form
        id="email-form"
        @submit.prevent="makePayout"
        name="email-form"
        data-name="Email Form"
        class="form-4"
      >
        <p>
          <strong>Earnings:</strong> {{ currency }}
          {{ earnings | formatAmount }}
        </p>
        <br />
        <!--        <label for="name" class="field-label-15">Amount</label>-->
        <div style="display: flex; flex-direction: row">
          <button style="height: 50px; width: 20%" disabled>USD</button>
          <input
            v-if="
              model.payoutAmount > 0 &&
              model.payoutAmount <= parseFloat(earnings)
            "
            style="width: 80%"
            type="number"
            v-model="model.payoutAmount"
            step="0.01"
            class="text-field-17 w-input"
            maxlength="256"
            name="name"
            data-name="Name"
            placeholder="Enter amount"
            id="name"
          />
          <input
            v-else
            style="width: 80%; border-color: red"
            type="number"
            v-model="model.payoutAmount"
            step="0.01"
            class="text-field-17 w-input"
            maxlength="256"
            name="name"
            data-name="Name"
            placeholder="Enter amount"
            id="name"
          />
        </div>
        <br />
        <input
          v-if="!transaction.transactionLoading"
          type="submit"
          :disabled="
            model.payoutAmount <= 0 || model.payoutAmount > parseFloat(earnings)
          "
          value="Continue"
          style="z-index: 10; display: block"
          class="button-18 w-button"
        />
        <b-spinner v-else style="color: #63b8a0" />
      </form>
    </div>
    <!--    </div>-->
    <!--    <a data-w-id="89b8a742-7170-306e-20da-6054dea3dd98"-->
    <!--      href="#"-->
    <!--      @click="hide()"-->
    <!--      class="close-topup-modal w-inline-block">-->
    <!--      <b-icon-x style="width: 35px; height: 35px; color: #63b8a0" />-->
    <!--    </a>-->
  </b-modal>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "PayoutModal",
  props: ["show"],
  data() {
    return {
      model: {
        payoutReference: "",
        payoutCustomerId: "",
        payoutAmount: "",
      },
    };
  },
  created() {
    const script = document.createElement("script");
    script.src =
      window.__env.app.stage === "dev"
        ? "https://ravemodal-dev.herokuapp.com/v3.js"
        : "https://checkout.payout.com/v3.js";
    document.getElementsByTagName("head")[0].appendChild(script);
  },
  methods: {
    callback() {
      console.log("Payment made, verify payment");
    },
    hide() {
      this.$refs["payout-modal"].hide();
    },
    makePayout() {
      this.model.payoutReference = this.reference();
      this.model.payoutCustomerId = this.auth.userInfo.customerId;
      this.$store.dispatch("transaction/payoutCreate", this.model).then(() => {
        this.$store.dispatch("transaction/payoutReadByCustomerId", {
          customerId: this.auth.userInfo.customerId,
          readAll: "ALL",
        });
        this.hide();
      });
    },
    reference() {
      let text = "";
      let possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (let i = 0; i < 30; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      return text;
    },
  },
  computed: {
    ...mapState(["auth", "transaction"]),
    currency: function () {
      if (this.transaction.transactions.accounts.length < 1) return "---";
      else return this.transaction.transactions.accounts[0].accountCurrency;
    },
    balance: function () {
      if (this.transaction.transactions.accounts.length < 1) return "0.00";
      else
        return this.transaction.transactions.accounts.filter(
          (it) => it.accountClass === "TIER_1_SAVINGS"
        )[0].accountBalance;
    },
    earnings: function () {
      if (this.transaction.transactions.accounts.length < 1) return "0.00";
      else
        return this.transaction.transactions.accounts.filter(
          (it) => it.accountClass !== "TIER_1_SAVINGS"
        )[0].accountBalance;
    },
  },
  watch: {
    // whenever question changes, this function will run
    show: function () {
      this.model = {
        payoutReference: "",
        payoutCustomerId: "",
        payoutAmount: "",
      };
      this.$refs["payout-modal"].show();
    },
  },
};
</script>

<style>
/*.close-topup-modal {*/
/*  position: absolute;*/
/*  left: auto;*/
/*  top: 0%;*/
/*  right: 0%;*/
/*  bottom: auto;*/
/*  display: block;*/
/*}*/
</style>
