<template>
  <div>
    <div class="dashboard-header">
      <dashboard-sub-header />
      <dashboard-card />
      <div class="w-layout-grid grid-3">
        <campaign-card />
        <transaction-card />
      </div>
    </div>
  </div>

</template>

<script>
import DashboardSubHeader from "@/components/menu/DashboardSubHeader";
import DashboardCard from "@/components/card/DashboardCard";
import CampaignCard from "@/components/card/CampaignCard";
import TransactionCard from "@/components/card/TransactionCard";
export default {
  name: "Dashboard",
  components: {
    DashboardSubHeader,
    DashboardCard,
    CampaignCard,
    TransactionCard,
  },
};
</script>

<style scoped>

</style>