<template>
  <div class="footer wf-section">
    <div data-w-id="43c34cce-d416-5d79-c87b-99d895eaaecc" class="container footer">
      <div class="divider"></div>
      <div class="w-layout-grid footer-grid">
        <div class="grid-one"><img src="@/assets/images/White-logo_1.svg" loading="lazy" alt="" class="image-13">
          <div class="social-links">
            <a href="https://instagram.com/apvertise?utm_medium=copy_link" class="w-inline-block"><img src="@/assets/images/Group-4736.svg" loading="lazy" alt="" class="image-14"></a>
            <a href="#" class="w-inline-block"><img src="@/assets/images/Group-4737.svg" loading="lazy" alt="" class="image-14"></a>
          </div>
        </div>
        <div class="grid-two">
          <h1 class="heading-11">Links</h1>
          <div class="div-block-13">
            <a href="#" class="link-block w-inline-block">
              <div class="text-block-3">Home</div>
            </a>
            <a href="#" class="link-block w-inline-block">
              <div class="text-block-3">For Business</div>
            </a>
            <a href="#" class="link-block w-inline-block">
              <div class="text-block-3">For Micro Influencers</div>
            </a>
          </div>
        </div>
        <div class="grid-three">
          <h1 class="heading-11">Get Help</h1>
          <div class="div-block-13">
            <router-link to="/contact-us" class="link-block w-inline-block">
              <div class="text-block-3">Support (support@apvertise.com)</div>
            </router-link>
            <a href="#" class="link-block w-inline-block">
              <div class="text-block-3">FAQ’s</div>
            </a>
            <a href="#" class="link-block w-inline-block">
              <div class="text-block-3">Partnership</div>
            </a>
          </div>
        </div>
        <div class="grid-four">
          <h1 class="heading-11">Privacy</h1>
          <div class="div-block-13">
            <router-link to="/influencer-tc" class="link-block w-inline-block">
              <div class="text-block-3">Influencers T&amp;C&#x27;s</div>
            </router-link>
            <router-link to="/company-tc" class="link-block w-inline-block">
              <div class="text-block-3">Companies T&amp;C&#x27;s</div>
            </router-link>
            <router-link to="/privacy-policy" class="link-block w-inline-block">
              <div class="text-block-3">Privacy Policy</div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterSection"
};
</script>

<style scoped>

</style>