<template>
  <b-modal
    ref="promote-modal"
    centered
    hide-header
    hide-footer
  >
  <div  class="add-social-account-modal" >
    <div class="modal-login-archive">
      <div class="social-profile-modal promote-product">
        <div  style="margin: 50px auto">
          <div>
            <h1 class="heading-28 promote-product">Promote your product with Apvertise</h1>
          </div>
  <!--        <lottie class="lottie-animation-4"-->
  <!--                loop-->
  <!--                style="width: 50px"-->
  <!--                mode="normal"-->
  <!--             data-src="documents/lf30_editor_5cg4qa0w.json"-->
  <!--        ></lottie>-->
          <p class="paragraph-13"> Promote your product with an apvertise campaign.Promote your product with an apvertise campaign.Promote your product with an apvertise campaign.</p>
          <router-link to="/create-new-campaign" class="button-18 w-button">Create Campaign</router-link>
        </div>
      </div>
      <a @click="hide()" data-w-id="201b6b3a-d291-d387-cfd4-0622781084b4"  href="#" class="close-social-account-modal w-inline-block">
        <img :src=" require('@/assets/images/close.svg')" loading="lazy" alt=""></a>
    </div>
  </div>
  </b-modal>
</template>

<script>
export default {
  name: "PromoteProductModal",
  props: ["show"],
  methods: {
    hide() {
      this.$refs["promote-modal"].hide();
    },
  },
  watch: {
    // whenever question changes, this function will run
    show: function () {
      this.$refs["promote-modal"].show();
    },
  },
};
</script>

<style scoped>
.lottie-animation-4 {
  width: 45%;
  margin-right: auto;
  margin-bottom: 20px;
  margin-left: auto;
}
</style>