// import router from "@/router";
import CampaignService from "@/service/CampaignService";
// import CampaignRequest from "@/model/request/CampaignRequest";
import CampaignResponse from "@/model/response/CampaignResponse";
import swal from "sweetalert";
import router from "@/router";
import store from "@/store/store";
import CampaignRequest from "@/model/request/CampaignRequest";

export const state = {
  campaign: CampaignResponse.prototype.readSingleResponse(),
  filteredCampaigns: [],
  campaigns: CampaignResponse.prototype.readResponse(),
  myCampaigns: CampaignResponse.prototype.readResponse(),
  sharedCampaigns: CampaignResponse.prototype.readResponse(),
  campaignForm: CampaignResponse.prototype.form(),
  campaignEditForm: CampaignResponse.prototype.form(),
  model: CampaignRequest.prototype.campaignReadRequest(),
  sharedModel: CampaignRequest.prototype.campaignReadRequest(),
  myModel: CampaignRequest.prototype.campaignReadRequest(),
  exhibitionTypes: { data: [] },
  screen: "one",
  campaignLoading: false,
  shareLinkLoading: false,
  shareLink: {},
};
export const getters = {};
export const mutations = {
  updateCampaign: (state, payload) => {
    state.campaign = payload;
  },
  updateScreen: (state, payload) => {
    state.screen = payload;
  },
  updateShareLink: (state, payload) => {
    state.shareLink = payload;
  },
  updateModel: (state, payload) => {
    state.model = payload;
  },
  updateShareLinkLoading: (state, payload) => {
    state.shareLinkLoading = payload;
  },
  updateFilteredCampaigns: (state, payload) => {
    state.filteredCampaigns = payload;
  },
  updateSharedModel: (state, payload) => {
    state.sharedModel = payload;
  },
  updateMyModel: (state, payload) => {
    state.myModel = payload;
  },
  updateCampaignForm: (state, payload) => {
    // eslint-disable-next-line no-unused-vars
    Object.keys(payload).forEach(function (key, index) {
      state.campaignForm[key] = payload[key]
        ? payload[key]
        : state.campaignForm[key];
    });
    //console.log(state.campaignForm.campaignBudget);
  },
  updateCampaignEditForm: (state, payload) => {
    // eslint-disable-next-line no-unused-vars
    Object.keys(payload).forEach(function (key, index) {
      state.campaignEditForm[key] = payload[key]
        ? payload[key]
        : state.campaignEditForm[key];
    });
  },
  resetCampaignForm: (state, payload) => {
    state.campaignForm = payload
  },
  resetCampaignEditForm: (state, payload) => {
    state.campaignForm = payload
  },
  updateCampaigns: (state, payload) => {
    state.campaigns = payload;
  },
  addCampaigns: (state, payload) => {
    state.campaigns.data = state.campaigns.data.concat(payload.data);
  },
  updateSharedCampaigns: (state, payload) => {
    state.sharedCampaigns = payload;
  },
  addSharedCampaigns: (state, payload) => {
    state.sharedCampaigns.data = state.sharedCampaigns.data.concat(payload.data);
  },
  updateMyCampaigns: (state, payload) => {
    state.myCampaigns = payload;
  },
  addMyCampaigns: (state, payload) => {
    state.myCampaigns.data = state.myCampaigns.data.concat(payload.data);
  },
  updateCampaignLoading: (state, payload) => {
    state.campaignLoading = payload;
  },
  updateExhibitionTypes: (state, payload) => {
    state.exhibitionTypes = payload;
  },
};
export const actions = {
  updateCampaign: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateCampaignLoading", true);
    return CampaignService.callReadSingleCampaignApi(payload)
      .then((response) => {
        commit("updateCampaignLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          commit("updateCampaign", responseData);
        } else {
          swal("Error", responseData.responseMessage, "error");
        }
      })
      .catch((err) => {
        commit("updateCampaignLoading", false);
        console.log(err);
      });
  },

  updateExhibitionTypes: ({ commit }, payload) => {
    return CampaignService.callReadExhibitionTypesApi(payload)
      .then((response) => {
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          commit("updateExhibitionTypes", responseData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },

  updateCampaigns: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateCampaignLoading", true);
    payload.customerId = store.getters["auth/getUser"].customerId?store.getters["auth/getUser"].customerId:"0"
    return CampaignService.callReadCampaignApi(payload)
      .then((response) => {
        commit("updateCampaignLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          if (payload.page == null || payload.page === 1)
            commit("updateCampaigns", responseData);
          else
            commit("addCampaigns", responseData);
        }
      })
      .catch((err) => {
        commit("updateCampaignLoading", false);
        console.log(err);
      });
  },

  updateSharedCampaigns: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateCampaignLoading", true);
    return CampaignService.callReadSharedCampaignApi(payload)
      .then((response) => {
        commit("updateCampaignLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          if (payload.page == null || payload.page === 1)
            commit("updateSharedCampaigns", responseData);
          else commit("addSharedCampaigns", responseData);
        }
      })
      .catch((err) => {
        commit("updateCampaignLoading", false);
        console.log(err);
      });
  },

  updateMyCampaigns: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateCampaignLoading", true);
    return CampaignService.callReadCampaignByUserIdApi(payload)
      .then((response) => {
        commit("updateCampaignLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          if (payload.page == null || payload.page === 1)
            commit("updateMyCampaigns", responseData);
          else commit("addMyCampaigns", responseData);
        }
      })
      .catch((err) => {
        commit("updateCampaignLoading", false);
        console.log(err);
      });
  },

  createCampaign: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateCampaignLoading", true);
    return CampaignService.callCreateCampaignApi(payload)
      .then((response) => {
        commit("updateCampaignLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          //swal("Success", responseData.responseMessage, "success");
          commit("updateScreen", "success");
          commit("resetCampaignForm", {});
          store.commit("sbucket/updateUrls", []);
          //router.push("/create-new-campaign-success");
        } else {
          store.commit("updateMessage",{
            type: "error",
            title: "Error",
            body: responseData.responseMessage,
          })
        }
      })
      .catch((err) => {
        commit("updateCampaignLoading", false);
        console.log(err);
      });
  },

  editCampaign: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateCampaignLoading", true);
    return CampaignService.callUpdateCampaignApi(payload)
      .then((response) => {
        commit("updateCampaignLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          commit("resetCampaignEditForm", {});
          store.commit("sbucket/updateUrls", []);
          commit("updateScreen", "success");
        } else {
          store.commit("updateMessage", {
            type: "error",
            title: "Error",
            body: responseData.responseMessage
          });
        }
      })
      .catch((err) => {
        commit("updateCampaignLoading", false);
        console.log(err);
      });
  },

  getShareLink: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateShareLinkLoading", true);
    return CampaignService.callGetShareLinkApi(payload)
      .then((response) => {
        commit("updateShareLinkLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          commit("updateShareLink", responseData)
        } else {
          commit("updateShareLink", responseData)
          store.commit("updateMessage",{
            type: "error",
            title: "Error",
            body: responseData.responseMessage
          });
        }
      })
      .catch((err) => {
        commit("updateShareLink", "{}")
        commit("updateShareLinkLoading", false);
        console.log(err);
      });
  },

  editCampaignStatus: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateCampaignLoading", true);
    return CampaignService.callUpdateCampaignStatusApi(payload)
      .then((response) => {
        commit("updateCampaignLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          store.commit("updateMessage", {
            type: "success",
            title: "Success",
            body: responseData.responseMessage,
          });
        } else {
          store.commit("updateMessage",{
            type: "error",
            title: "Error",
            body: responseData.responseMessage,
          });
        }
      })
      .catch((err) => {
        commit("updateCampaignLoading", false);
        console.log(err);
      });
  },

  campaignSettlement: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateCampaignLoading", true);
    return CampaignService.callCampaignSettlementApi(payload)
      .then((response) => {
        commit("updateCampaignLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          if (responseData.campaignLink.includes("http"))
            window.location.href = responseData.campaignLink;
          else window.location.href = "https://" + responseData.campaignLink;
        } else {
          router.push("/");
        }
      })
      .catch((err) => {
        commit("updateCampaignLoading", false);
        console.log(err);
      });
  },
};
