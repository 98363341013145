<template>
  <div>
    <div v-if="url === '' || url == null" class="dropbox">
      <input multiple type="file"  class="input-file" id="files" name="files" :disabled="disableInput"
             @change="uploadFile" accept="image/*" ref="file">
      <p align="center" style="font-size: 50px;"><b-icon-image></b-icon-image></p>
      <p v-if="!showLoader" style="margin-top: -100px;">
        Drag your {{name}} here to begin<br> or click to browse
      </p>
      <p v-else style="margin-top: -100px;">
        Uploading file...
        <base-loader style="" :show="showLoader"></base-loader>
      </p>
    </div>
    <div v-else>
      <a :href="url" target="_blank" style="background-size: 50px 50px;">
        <div class="image-input mb-3" :style="{ 'background-image': `url(${this.url})` }" >
        </div>
      </a>
      <button class="singleBtn" @click="url = ''">re-upload</button>
    </div>
  </div>
</template>

<script>
import BaseLoader from "@/components/loader/BaseLoader";
import S3Request from "@/model/request/S3Request";
import { mapState } from "vuex";
import swal from "sweetalert";

export default {
  name: "UploadSingleImageField",
  props:["name"],
  components:{BaseLoader},
  data(){
    return{
      url:"",
      disableInput: false,
      showLoader: false,
      fileName: "",
      base64: "",
      model: S3Request.prototype.uploadBase64(),
    };
  },
  methods:{
    uploadFile(){
      let input = this.$refs.file;
      console.log(input);
      let files = input.files;
      this.fileName = files[0].name
      console.log(this.fileName);
      let size = files[0].size/1000000
      //console.log(size);
      if (size > 5) {
        swal("Error", "File size must not be more than 5mb, please try with a reduced file size", "error")
        return;
      }
      const reader = new FileReader;
      try {
        reader.onload = e => {
          this.base64 = e.target.result;
          console.log(e.target.result);
          this.uploadOfficerImage()
        };
        reader.readAsDataURL(files[0]);
        this.$emit('input', files[0]);
      } catch (e) {
        console.warn(e.message);
      }
    },
    async uploadOfficerImage() {
      this.showLoader = true;
      this.model.username = `${this.name}_${this.auth.userInfo.customerUsername}_${Date.now()}`;
      this.model.base64 = this.base64;
      await this.$store.dispatch("sbucket/uploadEncodedFile", this.model, {root: true})
      this.url = this.sbucket.s3bucketResponse.url;
      console.log(this.url);
      this.showLoader = false;
    },
  },
  computed: {
    ...mapState(["auth","sbucket"]),
  }
};
</script>

<style scoped>
@media only screen and (min-width: 900px) {
  .top-card {
    width: 103%;
  }
}
@media only screen and (min-width: 707px) and (max-width: 900px) {
  .top-card {
    width: 104%;
  }
}

@media only screen and (min-width: 517px)  and (max-width: 707px) {
  .top-card {
    width: 105%;
  }
}

@media only screen and (min-width: 451px)  and (max-width: 517px) {
  .top-card {
    width: 106%;
  }
}

@media only screen  and (max-width: 451px) {
  .top-card {
    width: 107%;
  }
}
.h-100 {
  height: 100vh !important;
}
@media only screen and  (max-width: 991px) {
  .extra{
    display: none;
  }
}
.image-input{
  display: block;
  width: 100%;
  height: 300px;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}

.placeholder{
  background: #F0F0F0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 18px;
  font-family: Helvetica,serif;
}

.placeholder:hover{
  background: #E0E0E0
}

.file-input{
  display: none;
}

.dropbox {
  z-index: 0;
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  padding: 10px 10px;
  min-height: 200px; /* minimum height */
  position: relative;
  cursor: pointer;
}

.input-file {
  z-index: 0;
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 100%;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: lightblue; /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}

.singleBtn{
  border-radius: 0.75rem;
  border: solid;
}

.singleBtn:hover{
  background-color: lightgrey;

}

</style>