<template>
  <b-modal
    ref="switch-company-modal"
    centered
    hide-header
    hide-footer
  >
    <div class="modal-body">
    <div class="div-block-156">
      <a data-w-id="0e756eac-b69c-ca0d-7ba7-f2b54c7eb6e2" @click="hide()" href="#" class="close-topup-modal w-inline-block"><img src="@/assets/images/close-modal.svg" loading="lazy" alt=""></a>
    </div>
      <div class="cashout-modal-content">
        <div class="cash-out-modal-wrapper" style="max-height:auto; overflow-y: scroll">
          <div class="topup-modal-1">
            <h1 class="heading-28-copy">Switch Company</h1>
            <div  class="div-block-248" v-for="i in organisations" :key="i.organisationId" style="cursor: pointer" @click="getID(obj = i.organisationId), hide()">
              <div class="div-block-249">
                <img :src="i.organisationLogo" loading="lazy" alt="" class="image-65">
                <div>{{ i.organisationName }}</div>
              </div>
            </div>
         </div>
        </div>
        <a data-w-id="f53034bd-90cc-7bd2-3945-2ff1f7e35c68" href="#" class="close-topup-modal w-inline-block"><img src="images/close.svg" loading="lazy" alt=""></a>
      </div>
    </div>
  </b-modal>
</template>

<script>
//import { mapState } from "vuex";
import { mapState } from "vuex";
import OrganisationRequest from "@/model/request/OrganisationRequest";
import ProductRequest from "@/model/request/ProductRequest";

export default {
  name: "SwitchCompanyModal",
  props: ["show"],
  data(){
    return {
      model: OrganisationRequest.prototype.organisationReadSingleRequest(),
      allProducts: ProductRequest.prototype.productReadByOrgRequest(),
    }
  },
  methods: {
    hide() {
      this.$refs["switch-company-modal"].hide();
    },
    getID(obj){
      this.model.organisationId=obj
      localStorage.organisationId = obj
      this.allProducts.productOrganisationId = obj
      this.$store.dispatch("organisation/updateOrganisation", this.model).then(()=>{
        this.$store.dispatch("product/updateProduct", this.allProducts)

      })

    }
  },
  computed:{
    // ...mapState(["organisation", "auth", "product"]),
    ...mapState({
      organisations:state => state.organisation.organisations,
      auth:state => state.auth,
      product: state => state.product
    })
  },
  watch: {
    // whenever question changes, this function will run
    show: function () {
      this.$refs["switch-company-modal"].show();
    },
  },
  mounted() {
    this.$store.dispatch("organisation/updateOrganisations", {
      customerId: this.auth.userInfo.customerId,
    });

    console.log(this.organisation)
}
};
</script>

<style scoped>

</style>