<template>
  <div class="create-campaign-form-container">
    <div class="back-button-container">
      <router-link to="/dashboard" class="link-block-11 w-inline-block">
        <div class="text-block-69">Back to dashboard </div>
      </router-link>
    </div>
    <div class="create-campaign-header">
      <div>
        <h1 class="heading-23">Edit Campaign</h1>
      </div>
      <div class="div-block-90">
        <div class="text-block-41">Connect with brands, advertisers, and publishers for sponsorship opportunities to create and share content.</div>
      </div>
      <div class="div-block-87">
        <div class="campaigndetail"><img src="@/assets/images/1.svg" loading="lazy" alt="" class="image-28">
          <div class="text-block-72">Campaign <br/>Details</div>
        </div>
        <div class="mediaupload"><img src="@/assets/images/2.svg" loading="lazy" alt="" class="image-29">
          <div class="text-block-73">Campaign <br/>Banner</div>
        </div>
        <div class="filterinfluencers"><img src="@/assets/images/3.svg" loading="lazy" alt="" class="image-29">
          <div class="text-block-74">Campaign <br/>Exhibits</div>
        </div>
      </div>
    </div>
    <div class="new-campaign-block2">
      <div class="w-form">
        <form id="email-form" name="email-form" @submit.prevent="navigate" data-name="Email Form">
          <div class="campaign-name">
            <label for="Campaign-Name" class="field-label-8">Campaign Name</label>
            <input v-if="!containsValidUrl(campaignName)" type="text" v-model="campaignName" class="text-field-10 w-input" minlength="2" maxlength="30" name="Campaign-Name" data-name="Campaign Name" placeholder="Enter Campaign name" id="Campaign-Name" required="">
            <input v-else type="text" style="border-color: red;" v-model="campaignName" class="text-field-10 w-input" minlength="2" maxlength="30" name="Campaign-Name" data-name="Campaign Name" placeholder="Enter Campaign name" id="Campaign-Namee" required="">
          </div>
<!--          <div class="industry">-->
<!--            <div class="div-block-145">-->
<!--              <label for="Industry" class="field-label-8">Company <b-spinner style="color: #63b8a0;" v-if="organisation.organisationLoading" small/></label>-->
<!--              <a data-w-id="69d342ba-eadf-ddbe-72b4-6fc45182b84c" href="#" class="link-block-16 w-inline-block">-->
<!--                <div @click="showModal = !showModal">+ Add new company</div>-->
<!--              </a>-->
<!--            </div>-->
<!--            <select v-model="campaignOrganisationId" id="Industry" name="Industry" required="" data-name="Industry" class="select-field w-select">-->
<!--              <option value="">Select Company </option>-->
<!--              <option v-for="company in organisation.organisations.data" :value="company.organisationId" v-bind:key="company.organisationId">{{ company.organisationName }}</option>-->
<!--            </select>-->
<!--          </div>-->
          <label for="startDate" class="field-label-8">Start Date</label>
          <input class="form-control"  id="startDate" type="date" v-model="campaignStartDate" />
          <br>
          <label for="endDate" class="field-label-8">End Date</label>
          <input class="form-control" :min="endDateMin" id="endDate" type="date" v-model="campaignEndDate" />
          <br>
          <div class="descriptuon">
            <label for="Campaign-Description" class="field-label-8">Description</label>
            <textarea v-if="!containsValidUrl(campaignDescription)" v-model="campaignDescription" placeholder="A brief description about the campaign" maxlength="300" id="Campaign-Description" name="Campaign-Description" required="" data-name="Campaign Description" class="textarea w-input"></textarea>
            <textarea v-else style="border-color: red;" v-model="campaignDescription" placeholder="A brief description about the campaign" maxlength="300" id="Campaign-Descriptionn" name="Campaign-Description" required="" data-name="Campaign Description" class="textarea w-input"></textarea>
          </div>
          <input type="submit" value="Proceed" data-wait="Please wait..." class="submit-button-7 w-button">
          <button type="submit" class="button-16-copy-copy w-button">Proceed</button>
        </form>
      </div>
    </div>
    <company-create-modal :show="showModal"/>
  </div>
</template>

<script>
import CampaignResponse from "@/model/response/CampaignResponse";
import CompanyCreateModal from "@/components/modal/CompanyCreateModal";
import { mapState } from "vuex";
//import store from "@/store/store";

export default {
  name: "CreateCampaignFormOne",
  components: { CompanyCreateModal },
  data() {
    return {
      showModal: false,
      model: CampaignResponse.prototype.form(),
    };
  },
  methods: {
    navigate() {
      //this.$store.commit("campaign/updateCampaignForm", this.model);
      console.log(this.campaign.campaignEditForm);
      if (
        this.containsValidUrl(this.model.campaignDescription) ||
        this.containsValidUrl(this.model.campaignName)
      ) {
        this.$store.commit("updateMessage", {
          type: "error",
          title: "Error",
          body: "Campaign name or description can not contain Link(s)",
        });
      } else {
        this.$store.commit("campaign/updateScreen", "two");
      }
    },
    isNumber(event) {
      if (!/\d/.test(event.key) && event.key !== ".")
        return event.preventDefault();
    },
    containsValidUrl(str) {
      let strArr = (str ? str : "").split(" ");
      for (let i = 0; i < strArr.length; i++) {
        if (
          /[(http(s)?)://(www\\.)?a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)/.test(
            strArr[i]
          ) ||
          strArr[i].includes("www.")
        ) {
          return true;
        }
      }
      return false;
    },
  },
  computed: {
    ...mapState(["campaign", "auth", "organisation"]),
    // ...mapState({
    //   organisation:(state) => state.organisation.organisations
    // }),
    campaignName: {
      set(val) {
        this.model.campaignName = val;
        this.$store.commit("campaign/updateCampaignEditForm", this.model);
      },
      get() {
        return this.campaign.campaignEditForm.campaignName;
      },
    },
    campaignOrganisationId: {
      set(val) {
        this.model.campaignOrganisationId = val;
        this.$store.commit("campaign/updateCampaignEditForm", this.model);
      },
      get() {
        return this.campaign.campaignEditForm.campaignOrganisationId;
      },
    },
    campaignStartDate: {
      set(val) {
        this.model.campaignStartDate = val;
        this.$store.commit("campaign/updateCampaignEditForm", this.model);
      },
      get() {
        return this.campaign.campaignEditForm.campaignStartDate;
      },
    },
    campaignEndDate: {
      set(val) {
        this.model.campaignEndDate = val;
        this.$store.commit("campaign/updateCampaignEditForm", this.model);
      },
      get() {
        return this.campaign.campaignEditForm.campaignEndDate;
      },
    },
    campaignDescription: {
      set(val) {
        this.model.campaignDescription = val;
        this.$store.commit("campaign/updateCampaignEditForm", this.model);
      },
      get() {
        return this.campaign.campaignEditForm.campaignDescription;
      },
    },
    campaignBudget: {
      set(val) {
        this.model.campaignBudget = val;
        this.$store.commit("campaign/updateCampaignEditForm", this.model);
      },
      get() {
        return this.campaign.campaignEditForm.campaignBudget;
      },
    },
    startDateMin() {
      let result = new Date();
      let date = result.getDate();
      if (date < 10) date = "0" + date;
      let month = result.getMonth() + 1;
      if (month < 10) month = "0" + month;
      return `${result.getFullYear()}-${month}-${date}`;
    },
    endDateMin() {
      let result = new Date(this.model.campaignStartDate);
      result.setDate(result.getDate() + 1);
      let date = result.getDate();
      if (date < 10) date = "0" + date;
      let month = result.getMonth() + 1;
      if (month < 10) month = "0" + month;
      return `${result.getFullYear()}-${month}-${date}`;
    },
  },
  mounted() {
    this.$store.dispatch("organisation/updateOrganisations", {
      customerId: this.auth.userInfo.customerId,
    });
    console.log("Mount Campaign Form one...");
    this.model = this.campaign.campaignEditForm;
  },
};
</script>

<style scoped></style>
