<template>
  <div class="create-campaign-form-container">
    <div class="campain-success">
<!--      <img src="@/assets/images/success.gif"  class="lottie-animation" style="width: 150px; width: 150px;">-->
      <lottie-player
        autoplay
        loop
        mode="normal"
        src="documents/64787-success.json"
        class="lottie-animation"
        style="width: 150px;"
      ></lottie-player>
<!--      <base-lottie :options="defaultOptions" v-on:animCreated="handleAnimation"/>-->
<!--      <div data-w-id="1aa27bed-5583-e548-a3fb-cb1d9957edd5" data-animation-type="lottie" data-src="./documents/64787-success.json" data-loop="1" data-direction="1" data-autoplay="1" data-is-ix2-target="0" data-renderer="canvas" data-default-duration="2" data-duration="3.5" class="lottie-animation"></div>-->
      <h1 class="heading-24">Your Campaign is live 🥳</h1>
      <div class="text-block-43">Your campaign has been created <br>successfully</div>
      <router-link to="/dashboard" style="display: block" class="button-11 w-button">Share campaign to influencers</router-link>
    </div>
  </div>
</template>

<script>
// import Lottie from './lottie.vue';
import * as animationData from '@/assets/documents/64787-success.json';
import BaseLottie from "@/components/lottie/BaseLottie";

export default {
  name: "CampaignCreateSuccess",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    BaseLottie,
    // 'lottie': Lottie
  },
  data() {
    return {
      defaultOptions: {animationData: animationData},
      animationSpeed: 1
    }
  },
  methods: {
    handleAnimation: function (anim) {
      this.anim = anim;
    },

    stop: function () {
      this.anim.stop();
    },

    play: function () {
      this.anim.play();
    },

    pause: function () {
      this.anim.pause();
    },

    onSpeedChange: function () {
      this.anim.setSpeed(this.animationSpeed);
    }
  }
};
</script>

<style scoped>

</style>