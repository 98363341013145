import client from "./BaseService";

export default {
    callSendMoneyRequestApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("apvSendMoneyRequest", payload);
    },
    callReadMoneyRequestListApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("apvReadMoneyRequestList", payload);
    },
    callMoneyRequestResponseApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("apvMoneyRequestResponse", payload);
    },
    callMoneyRequestReminderApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("apvMoneyRequestReminder", payload);
    },

};
