<template>
  <div class="business wf-section">
    <div class="container">
      <div class="wrapper merchants">
        <div data-w-id="7cce211f-331b-381b-aa67-4cae65dcbe05" class="business-wrapper">
          <div class="div-block-4 bus">
            <h4 class="heading-7 new">Business</h4>
          </div>
          <h1 class="heading-10">Apvertise for Business</h1>
          <p class="paragraph-6">Be ahead of your competitor by connecting directly to your customers on Apvertise with our gratifying reward programs that spur Influencers to promote your brand and stay loyal. By championing a brand, consumers can create more visibility for the brand.</p>
        </div>
        <div class="business-image"><img src="@/assets/images/Group-22512.png" loading="lazy" width="450" data-w-id="7cce211f-331b-381b-aa67-4cae65dcbe10" alt="" class="image-24"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BusinessSection"
};
</script>

<style scoped>

</style>