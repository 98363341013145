<template>
  <div class="login-form w-form">
    <form
      v-if="auth.forgetPasswordScreen === 'email'"
      id="email-form"
      @submit.prevent="initiateForgetPassword"
      name="email-form"
      data-name="Email Form"
      class="form-2"
    >
      <div class="div-block-64">
        <label for="name" class="field-label-3">Email</label>
        <input
          type="text"
          v-model="model.username"
          class="text-field-4 w-input"
          maxlength="256"
          name="name"
          data-name="Name"
          placeholder="Enter your email address"
          id="name"
        />
      </div>
      <base-button :loading="auth.authLoading" title="Continue" />
    </form>
    <form
      v-if="auth.forgetPasswordScreen === 'otp'"
      id="email-form-1"
      @submit.prevent="completeForgetPassword"
      name="email-form"
      data-name="Email Form"
      class="form-2"
    >
      <div class="div-block-64">
        <label for="name" class="field-label-3">OTP</label>
        <span style="display: flex; flex-direction: row">
          <input
            type="text"
            minlength="6"
            maxlength="6"
            @keypress="this.isNumber"
            v-model="model2.otp"
            class="text-field-4 w-input"
            name="otp"
            data-name="Name"
            placeholder="Enter the otp sent to you"
            id="otp"
          />
          <button
            v-if="timerCount < 1"
            @click="sendOtp"
            type="button"
            style="width: 30%; height: 50px; padding: 0; color: #63b8a0"
          >
            <b-spinner
              v-if="auth.authLoading"
              small
              style="color: #63b8a0"
            /><span v-else>request otp</span>
          </button>
          <button
            v-else
            type="button"
            style="width: 40%; height: 50px; padding: 0; color: #63b8a0"
          >
            Resend in {{ timerCount }}s
          </button>
        </span>
      </div>
      <div>
        <label for="Password" class="field-label-4">New Password</label>
        <input
          type="password"
          minlength="6"
          maxlength="6"
          @keypress="this.isNumber"
          v-model="model2.password"
          class="text-field-5 w-input"
          name="Password"
          data-name="Password"
          placeholder="Enter your Password"
          id="Password"
          required=""
        />
      </div>
      <div>
        <label for="Password" class="field-label-4">Retype Password</label>
        <input
          type="password"
          minlength="6"
          maxlength="6"
          @keypress="this.isNumber"
          v-model="model2.passwordConfirmation"
          class="text-field-5 w-input"
          name="Password"
          data-name="Password"
          placeholder="Retype your Password"
          id="PasswordConfirmation"
          required=""
        />
      </div>
      <base-button :loading="auth.authLoading" title="Continue" />
    </form>
    <!--    <div class="w-form-done">-->
    <!--      <div>Thank you! Your submission has been received!</div>-->
    <!--    </div>-->
    <!--    <div class="w-form-fail">-->
    <!--      <div>Oops! Something went wrong while submitting the form.</div>-->
    <!--    </div>-->
  </div>
</template>

<script>
import BaseButton from "@/components/button/BaseButton";
import AuthRequest from "@/model/request/AuthRequest";
import { mapState } from "vuex";
export default {
  name: "ForgetPasswordForm",
  components: {
    BaseButton,
  },
  data() {
    return {
      model: AuthRequest.prototype.initiateResetPassword(), //new AuthRequest().logon(),
      model2: AuthRequest.prototype.completeResetPassword(),
      timer: "2:00",
      timerCount: 0,
    };
  },
  methods: {
    initiateForgetPassword() {
      this.$store.dispatch("auth/initiateResetPassword", this.model);
      this.timerCount = 10;
    },
    completeForgetPassword() {
      this.model2.username = this.model.username;
      this.model2.countryCode = this.model.countryCode;
      this.$store.dispatch("auth/completeResetPassword", this.model2);
    },
    isNumber(event) {
      if (!/\d/.test(event.key) && event.key !== ".")
        return event.preventDefault();
    },
    startTimer(duration) {
      let timer = duration,
        minutes,
        seconds;
      setInterval(function () {
        minutes = parseInt((timer / 60).toString(), 10);
        seconds = parseInt((timer % 60).toString(), 10);
        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;
        this.timer = minutes + ":" + seconds;
        if (--timer < 0) {
          timer = duration;
        }
      }, 1000);
    },
    sendOtp() {
      this.$store
        .dispatch("auth/resendOtpInApp", {
          phoneNumber: this.model.username,
          countryCode: "234",
        })
        .then(() => {
          console.log("Timer...");
          this.timerCount = 10;
        });
    },
  },
  computed: {
    ...mapState(["auth"]),
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        }
      },
      //immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
  created() {
    this.$store.commit("auth/updateForgetPasswordScreen", "email");
  },
};
</script>

<style scoped></style>
