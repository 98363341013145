<template>
  <div>
    <input
      ref="input"
      type="file"
      name="image"
      accept="image/*"
      @change="setImage"
    />

    <div class="content">
      <!--      :aspect-ratio="16 / 9"-->
      <!--      :crop-box-resizable="false"-->
      <!--      max-crop-box-width="610"-->
      <!--      max-crop-box-height="387"-->
      <!--      min-crop-box-width="610"-->
      <!--      min-crop-box-height="387"-->
      <section  v-if="url.length < 3" class="cropper-area">
        <div @click.prevent="showFileChooser" v-if="this.imgSrc === '' || this.imgSrc == null">
          <b-form @submit.prevent="" enctype="multipart/form-data">
            <b-form-group>
              <div class="dropbox">
                <br>
                <p style="text-align: center; vertical-align: center;">
                  Click to upload an Image <br>
                  (you can upload upto three images)
                </p>
              </div>
            </b-form-group>
          </b-form>
        </div>
        <div v-else class="img-cropper">
          <vue-cropper
            ref="cropperx"
            :aspect-ratio="8 / 5"
            :src="imgSrc"
            preview=".preview"
          />
        </div>
        <div v-if="this.imgSrc !== '' && this.imgSrc != null" class="actions" style="margin-top: -2px">
          <p style="text-align: center;">
            <b-input-group>
              <button class="btnn" title="Upload Image" @click.prevent="showFileChooser"><b-icon-upload/></button>
              <button  class="btnn" size="sm" title="Zoom In" @click.prevent="zoom(0.2)"><b-icon-zoom-in/></button>
              <button class="btnn" size="sm" title="Zoom Out" @click.prevent="zoom(-0.2)"><b-icon-zoom-out/></button>
              <button class="btnn" size="sm" title="Move Left" @click.prevent="move(-10, 0)"><b-icon-arrow-left/></button>
              <button class="btnn" size="sm" title="Move Right" @click.prevent="move(10, 0)"><b-icon-arrow-right/></button>
              <button class="btnn" size="sm" title="Move Up" @click.prevent="move(0, -10)"><b-icon-arrow-up/></button>
              <button class="btnn" size="sm" title="Move Down" @click.prevent="move(0, 10)"><b-icon-arrow-down/></button>
              <button class="btnn" size="sm" title="Rotate Left" @click.prevent="rotate(90)"><b-icon-arrow-return-left/></button>
              <button class="btnn" size="sm" title="Rotate Right" @click.prevent="rotate(-90)"><b-icon-arrow-return-right/></button>
              <button class="btnn bg-warning" size="sm" variant="warning" title="Reset" @click.prevent="reset">reset</button>
              <button class="btnn bg-success" size="sm" variant="success" title="Crop and Save" @click.prevent="cropImage">crop and save</button>
            </b-input-group>
          </p>
          <!--          <a href="#" role="button" @click.prevent="showFileChooser">-->
          <!--            Upload Image-->
          <!--          </a>-->
        </div>
        <base-loader style="" :show="showLoader"></base-loader>
      </section>
<!--      <b-row>-->
<!--        <b-col col="12">-->
<!--          <div style="display: flex; width: 100%;">-->
<!--            <div size="120" v-for="(link, index) in url" v-bind:key="link" class="user">-->
<!--              <img :src="link" class="profile-img" />-->
<!--              <b-icon-x class="icon" variant="danger" @click="removeImage(index)">remove</b-icon-x>-->
<!--            </div>-->
<!--          </div>-->
<!--        </b-col>-->
<!--      </b-row>-->
<!--      <section class="preview-area">-->
<!--        <p>Preview</p>-->
<!--        <div class="preview" />-->
<!--        <p>Cropped Image</p>-->
<!--        <div class="cropped-image">-->
<!--          <img v-if="cropImg" :src="cropImg" alt="Cropped Image" />-->
<!--          <div v-else class="crop-placeholder" />-->
<!--        </div>-->
<!--      </section>-->
    </div>
    <div style="display: flex; width: 100%;">
      <div size="120" v-for="(link, index) in url" v-bind:key="link" class="user">
        <img :src="link" class="profile-img" />
        <b-icon-x class="icon" variant="danger" @click="removeImage(index)">remove</b-icon-x>
      </div>
    </div>
  </div>
</template>

<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import S3Request from "@/model/request/S3Request";
import { mapState } from "vuex";
import BaseLoader from "@/components/loader/BaseLoader";

export default {
  name: "UploadImageWithCropperV2",
  props: ["name","edit"],
  components: {
    VueCropper,
    BaseLoader
  },
  data() {
    return {
      imgSrc: "", //"/assets/images/growthimage-min.png",
      cropImg: "",
      data: null,
      url: [],
      showLoader: false,
      count: 1,
      base64Array: [],
      base64: "",
      model: S3Request.prototype.uploadBase64(),
    };
  },
  methods: {
    removeImage(_index){
      this.url.splice(_index, 1)
      this.$store.commit("sbucket/updateUrls", this.url);
    },
    async uploadOfficerImage(_base64) {
      console.log("this.base64Array.length;", this.base64Array.length);
      //for(let i = 0; i < this.base64Array.length; i++) {
      this.showLoader = true;
      this.model.username = `${this.name.replace(" ", "")}_${
        this.auth.userInfo.customerUsername
      }_${Date.now()}`;
      this.model.base64 = _base64;
      await this.$store.dispatch("sbucket/uploadEncodedFile", this.model, {
        root: true,
      });
      if (this.sbucket.s3bucketResponse.url != null)
        this.url.push(this.sbucket.s3bucketResponse.url);
      this.showLoader = false;
      //this.myCroppa.remove();
      //}
      this.$store.commit("sbucket/updateUrls", this.url);
    },
    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropperx.getCroppedCanvas().toDataURL();
      this.uploadOfficerImage(this.cropImg);
    },
    flipX() {
      const dom = this.$refs.flipX;
      let scale = dom.getAttribute("data-scale");
      scale = scale ? -scale : -1;
      this.$refs.cropperx.scaleX(scale);
      dom.setAttribute("data-scale", scale);
    },
    flipY() {
      const dom = this.$refs.flipY;
      let scale = dom.getAttribute("data-scale");
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleY(scale);
      dom.setAttribute("data-scale", scale);
    },
    getCropBoxData() {
      this.data = JSON.stringify(this.$refs.cropperx.getCropBoxData(), null, 4);
    },
    getData() {
      this.data = JSON.stringify(this.$refs.cropperx.getData(), null, 4);
    },
    move(offsetX, offsetY) {
      this.$refs.cropperx.move(offsetX, offsetY);
    },
    reset() {
      this.$refs.cropperx.reset();
    },
    rotate(deg) {
      this.$refs.cropperx.rotate(deg);
    },
    setCropBoxData() {
      if (!this.data) return;

      this.$refs.cropperx.setCropBoxData(JSON.parse(this.data));
    },
    setData() {
      if (!this.data) return;

      this.$refs.cropperx.setData(JSON.parse(this.data));
    },
    setImage(e) {
      const file = e.target.files[0];
      console.log(this.$refs.cropperx);

      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropperx.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    showFileChooser() {
      this.$refs.input.click();
    },
    zoom(percent) {
      this.$refs.cropperx.relativeZoom(percent);
    },
  },
  computed: {
    ...mapState(["auth","sbucket","campaign"]),
  },
  mounted() {
    if (this.edit){
      this.url = this.campaign.campaignEditForm.campaignBanners.split(",")
      this.$store.commit("sbucket/updateUrls", this.url);
    }else{
      this.url = this.sbucket.urls}
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  font-family: Arial, Helvetica, sans-serif;
  width: 1024px;
  margin: 0 auto;
}

input[type="file"] {
  display: none;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 5px 0;
}

.btnn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  background-color: #6c757d;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  padding: 0.375rem 0.75rem;
  font-size: .8rem;
  border-radius: 0.25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.btnn:hover{
  background-color: #79868d;
}
.header h2 {
  margin: 0;
}

.header a {
  text-decoration: none;
  color: black;
}

.content {
  display: flex;
  justify-content: space-between;
}

.cropper-area {
  width: 100%;
}

.actions {
  margin-top: 1rem;
}

.actions a {
  display: inline-block;
  padding: 5px 15px;
  background: #0062cc;
  color: white;
  text-decoration: none;
  border-radius: 3px;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

textarea {
  width: 100%;
  height: 100px;
}

.preview-area {
  width: 307px;
}

.preview-area p {
  font-size: 1.25rem;
  margin: 0;
  margin-bottom: 1rem;
}

.preview-area p:last-of-type {
  margin-top: 1rem;
}

.preview {
  width: 100%;
  height: calc(372px * (9 / 16));
  overflow: hidden;
}

.crop-placeholder {
  width: 100%;
  height: 200px;
  background: #ccc;
}

.cropped-image img {
  max-width: 100%;
}

.dropbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  padding: 10px 10px;
  min-height: 250px; /* minimum height */
  position: relative;
  cursor: pointer;
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 100%;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: lightblue; /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}

.user {
  width: 140px;
  height: 140px;
  border-radius: 100%;
  border: 3px solid #2e7d32;
  margin-top: 10px;
  margin-right: 5px;
  margin-bottom: 10px;
  position: relative;
}
.profile-img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}
.icon {
  position: absolute;
  top: 10px;
  right: 0;
  background: #e2e2e2;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  line-height: 30px;
  vertical-align: middle;
  text-align: center;
  color: #0000ff;
  font-size: 14px;
  cursor: pointer;
}
</style>
