<template>
  <div style="opacity:0" class="signin-login-modal">
    <div class="modal-content">
      <div class="logo-image"><img src="@/assets/images/white-logo.svg" loading="lazy" alt=""></div>
      <div class="div-block-81">
        <div class="text-block-36">Registering to this website, you accept our <span class="text-span-7">Terms of use</span> <br>and our <span class="text-span-6">Privacy policy</span></div>
      </div>
      <div class="div-block-82-copy-copy-copy">
        <div data-duration-in="300" data-duration-out="100" class="w-tabs">
          <div class="tabs-menu-3 w-tab-menu">
            <a data-w-tab="Tab 1" class="tab-link-tab-1-3 w-inline-block w-tab-link">
              <div class="text-block-37">Login</div>
            </a>
            <a data-w-tab="Tab 2" class="tab-link-tab-2-3 w-inline-block w-tab-link w--current">
              <div class="text-block-38">Sign Up</div>
            </a>
          </div>
          <div class="tabs-content-2 w-tab-content">
            <div data-w-tab="Tab 1" class="tab-pane-tab-1 w-tab-pane">
              <div>
                <div class="w-form">
                  <form id="email-form" name="email-form" data-name="Email Form">
                    <div class="div-block-83"><label for="Email-Address" class="field-label-6">Email Address</label><input type="email" class="text-field-8 w-input" maxlength="256" name="Email-Address" data-name="Email Address" placeholder="Enter your email address" id="Email-Address" required=""></div>
                    <div class="div-block-84"><label for="Password-2" class="field-label-7">Password</label><input type="password" class="text-field-9 w-input" maxlength="256" name="Password" data-name="Password" placeholder="Enter your password" id="Password-2" required=""></div>
                    <div class="div-block-85">
                      <a href="#" class="link-block-8 w-inline-block">
                        <div class="text-block-39">Forgot your Password?</div>
                      </a>
                    </div><input type="submit" value="Login" data-wait="Please wait..." class="submit-button-6 w-button">
                    <div class="div-block-86">
                      <a href="#" class="link-block-9 w-inline-block">
                        <div class="text-block-40"><span class="text-span-8">Not a member?</span><span class="text-span-9">Sign up</span></div>
                      </a>
                    </div>
                  </form>
                  <div class="w-form-done">
                    <div>Thank you! Your submission has been received!</div>
                  </div>
                  <div class="w-form-fail">
                    <div>Oops! Something went wrong while submitting the form.</div>
                  </div>
                </div>
              </div>
            </div>
            <div data-w-tab="Tab 2" class="tab-pane-tab-2 w-tab-pane w--tab-active">
              <div class="w-form">
                <form id="email-form-1" name="email-form" data-name="Email Form">
                  <div class="div-block-83"><label for="name-2" class="field-label-6">Email Address</label><input type="email" class="text-field-8 w-input" maxlength="256" name="name-2" data-name="Name 2" placeholder="Enter your email address" id="name-2" required=""></div>
                  <div class="div-block-83"><label for="Phone-Number" class="field-label-6">Phone Number</label><input type="tel" class="text-field-8 w-input" maxlength="256" name="Phone-Number" data-name="Phone Number" placeholder="Enter your phone number" id="Phone-Number" required=""></div>
                  <div class="div-block-83"><label for="Password" class="field-label-6">Password</label><input type="password" class="text-field-8 w-input" maxlength="256" name="Password" data-name="Password" placeholder="Enter your password" id="Password" required=""></div>
                  <div class="div-block-84"><label for="Confirm-Password" class="field-label-7">Confirm Password</label><input type="password" class="text-field-9 w-input" maxlength="256" name="Confirm-Password" data-name="Confirm Password" placeholder="Re-enter your password" id="Confirm-Password" required=""></div><input type="submit" value="Sign Up" data-wait="Please wait..." class="sign-up-button w-button">
                  <div class="div-block-86">
                    <a href="#" class="link-block-9 w-inline-block">
                      <div class="text-block-40"><span class="text-span-8">Already have an account?</span> <span class="text-span-9">Log in</span></div>
                    </a>
                  </div>
                </form>
                <div class="w-form-done">
                  <div>Thank you! Your submission has been received!</div>
                </div>
                <div class="w-form-fail">
                  <div>Oops! Something went wrong while submitting the form.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a data-w-id="e63104ec-21aa-345e-5b50-561c81e78fbc" href="#" class="link-block-10 w-inline-block">
        <b-icon-x style="width: 35px; height: 35px; color: #63b8a0;"/>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoginModal"
};
</script>

<style>
/*.close-topup-modal {*/
/*  position: absolute;*/
/*  left: auto;*/
/*  top: 0%;*/
/*  right: 0%;*/
/*  bottom: auto;*/
/*  display: block;*/
/*}*/
</style>