import Dashboard from "@/views/dashboard/Dashboard";
import Profile from "@/views/authentication/Profile";

export default [
  {
    path: "/dashboard",
    name: "Dashboard",
    meta: { layout: "dashboard", authRequired: true },
    component: Dashboard,
  },
  {
    path: "/profile-settings",
    name: "Profile",
    meta: { layout: "dashboard", authRequired: true },
    component: Profile,
  }
];
