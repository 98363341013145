class AccountPayoutResponse {
  read(){
    return {
      data: [],
      responseCode: null,
      responseMessage: null
    }
  }

  readSingle(){
    return {
      accountId: null,
      accountName: null,
      accountNumber: null,
      accountBankName: null,
      accountBankCode: null,
      accountCustomerId: null,
      accountStatus: null,
      accountCreatedAt: null,
      accountUpdatedAt: null,
      responseCode: null,
      responseMessage: null
    }
  }

}

export default AccountPayoutResponse;