import BaseResponse from "@/model/response/BaseResponse";

class BillResponse extends BaseResponse {
    readSingleResponse() {
        return {

        categoryId: null,
        categoryName: null,
        categoryType: null,
        id: null,
        responseCode: null,
        responseMessage: null,
        };
    }
    readResponse() {
        return {
            responseCode: null,
            responseMessage: null,
            data: [],
        };
    }
}

export default BillResponse;
