<template>
  <div class="dashboard-nav wf-section">
    <div class="main-dashboard-section wf-section">
      <div class="main-dashboard-body mall-copy">
        <div class="get-started-wrapper"></div>
        <div class="container-v2">
          <div class="create-campaign-form-container">
            <div class="back-button-container">
              <a @click="goBack" class="link-block-11 w-inline-block">
                <div class="text-block-69">Back</div>
              </a>
            </div>
            <div class="create-campaign-header">
              <div>
                <h1 class="heading-23">Create new product</h1>
              </div>
              <div class="div-block-90">
                <div class="text-block-41">
                  Please provide your basic product details.
                </div>
              </div>
              <div class="div-block-87">
                <div class="campaigndetail-copy">
                  <img
                    :src="require('@/assets/images/checkmark.svg')"
                    loading="lazy"
                    alt=""
                    class="image-28"
                  />
                  <div class="text-block-72-copy">Product Details</div>
                </div>
                <div class="mediaupload">
                  <img
                    :src="require('@/assets/images/2.svg')"
                    loading="lazy"
                    alt=""
                    class="image-29"
                  />
                  <div class="text-block-73">Media Uploads</div>
                </div>
              </div>
            </div>
            <form @submit.prevent="newProduct">
              <div>
                <upload-image-with-cropper-v2 name="images"/>
              </div>
              <br>
              <br>
              <base-button
                v-if="this.sbucket.urls.length > 0"
                :loading="product.productLoading"
                @submit="newProduct"
                title="Proceed"
              ></base-button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import UploadSingleImageField from "@/components/form/input/UploadSingleImageField";
import ProductRequest from "@/model/request/ProductRequest";
import { mapState } from "vuex";
import BaseButton from "@/components/button/BaseButton";
//import UploadMultipleImageField from "@/components/form/input/UploadMultipleImageField";
import swal from "sweetalert";
import UploadImageWithCropperV2 from "@/components/form/input/UploadImageWithCropperV2";

export default {
  name: "AddProductFormTwo",
  components: { UploadImageWithCropperV2, BaseButton },
  data() {
    return {
      model: ProductRequest.productCreateRequest,
      urlMax: 3,
    };
  },
  computed: {
    ...mapState(["sbucket", "product", "auth", "organisation"]),
  },
  methods: {
     newProduct() {
      if (this.sbucket.urls.length < this.urlMax){
        swal("Error", `Please select at least  ${this.urlMax} image(s)`);
        return;
      }
      // if (this.model) {
      // let productImage = { productImage: this.sbucket.urls.join()}
      this.model.productOrganisationId = localStorage.getItem('organisationId'),
      this.model.productImage = this.sbucket.urls.join()

      // this.$store.commit("product/updateProductFormObject", productImage);
       this.$store.dispatch("product/createProduct", this.model);
      console.log(this.model)
      console.log(this.product.productFormObject);
    },
    goBack() {
      this.$store.commit("product/updateScreen", "one");
    },
  },
};
</script>

<style scoped></style>
