<template>
  <div class="section wf-section">
    <div class="div-block-56">
      <div class="left-section">
        <div class="logo-container">
          <a href="https://www.apvertise.com" class="w-inline-block"><img src="@/assets/images/Logo.svg" loading="lazy" alt="" class="logo"></a>
        </div>
        <div class="image-container"><img src="@/assets/images/login-image-min.png" loading="lazy" alt="" class="image-22"></div>
        <div class="login-image-text">
          <p class="paragraph-9">Connect with brands and advertisers to share campaign content across your social media accounts in exchange for compensation.</p>
        </div>
        <div class="terms-of-service">
          <div class="text-block-21">Terms of Service | Privacy Notice</div>
          <p>©2021 APVERTISE, Inc. All Rights Reserved.</p>
        </div>
      </div>
      <router-view/>
    </div>
  </div>
</template>

<script>
export default {
  name: "AuthLayout",
  created() {
    const plugin = document.createElement("script");
    plugin.setAttribute("src", "https://apvertise.com/js/webflow.js");
    plugin.async = true;
    document.head.appendChild(plugin);
  },
  mounted() {
    console.log("this.$route.query>>>>>>>>:::<<<<<<<",this.$route.query);
    if (this.$route.query.shareId != null)
      localStorage.shareId = this.$route.query.shareId;
    if (this.$route.query.referralCode != null)
      localStorage.referralCode = this.$route.query.referralCode;
  }
};
</script>

<style scoped>

</style>