<template>
  <div class="right-section">
    <div class="login-form-container">
      <div class="logo-container-copy"><img src="@/assets/images/Logo.svg" loading="lazy" alt="" class="logo"></div>
      <div class="login-header">
        <h1 class="heading-20">Reset your password</h1>
      </div>
      <forget-password-form/>
      <div class="sign-up-block">
        <router-link to="/logon" class="link-block-6 w-inline-block">
          <div class="text-block-22">Already a Member? <span class="text-span-3">Login</span></div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import ForgetPasswordForm from "@/components/form/ForgetPasswordForm";
export default {
  name: "ForgetPassword",
  components:{
    ForgetPasswordForm,
  }
};
</script>

<style scoped>

</style>