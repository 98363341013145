
import client from "./BaseService";

export default {
    callCreateFixedDepositApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("apvCreateFixedDeposit", payload);
    },
    callCreateFlexSavingsApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("apvCreateFlexSavings", payload);
    },
    callCreateSavingsApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("apvCreateSavings", payload);
    },
    callReadInvestmentsApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("apvReadInvestments", payload);
    },
    callTopUpInvestmentsApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("apvTopUpInvestments", payload);
    },
    callLiquidateInvestmentsApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("apvLiquidateInvestments", payload);
    },
    callReadInvestmentsParamsApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("apvReadInvestmentsParams", payload);
    },
    callReadInterestsApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("apvReadInterests", payload);
    },
};
