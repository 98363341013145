<template>
  <div class="hero-section wf-section">
    <div class="container hero">
      <div class="hero">
        <div class="left-hero-block">
          <h1
            data-w-id="a9f608fc-5a02-4815-0887-84d7c5a29126"
            class="hero-header"
          >
            Brands, ideas &amp;<br />anything worth <br />sharing
          </h1>
          <div
            data-w-id="aeb8d676-4738-4920-06a0-93591a63f3db"
            class="text-block-20"
            style="color: #63b8a0"
          >
            Digitized. Monetized. Democratized.
          </div>
          <p
            data-w-id="144794bc-3612-71be-20a7-7733e1fbb264"
            class="hero-paragraph"
          >
            Digital marketing platform connecting brands, brand ambassadors and
            influencers.
          </p>
          <a
            data-w-id="96ae2f79-efb2-90a2-857a-5fbff3fb95cf"
            href="/register"
            class="button-9 w-button"
            >Get Started</a
          >
          <!--          <a data-w-id="96ae2f79-efb2-90a2-857a-5fbff3fb95cf" href="#" @click="$store.commit('updateShowMessage')" class="button-9 w-button">Get Started</a>-->
          <!--          <lottie-player-->
          <!--            autoplay-->
          <!--            controls-->
          <!--            loop-->
          <!--            mode="normal"-->
          <!--            src="https://assets3.lottiefiles.com/packages/lf20_UJNc2t.json"-->
          <!--            style="width: 320px"-->
          <!--          ></lottie-player>-->
        </div>
        <div class="right-hero-block">
          <img
            src="@/assets/images/Group-22532.png"
            loading="lazy"
            width="556"
            sizes="(max-width: 479px) 330px, (max-width: 767px) 432px, 556px"
            srcset="
              @/assets/images/Group-22532.png 500w,
              @/assets/images/Group-22532.png 800w,
              @/assets/images/Group-22532.png 931w
            "
            alt=""
            class="image-46"
          />
        </div>
      </div>
    </div>
    <div class="container lottie">
      <div class="lottie-container">
        <div class="lottie-wrapper">
          <p style="text-align: center; font-size: 5px">
            <lottie-player
              autoplay
              loop
              mode="normal"
              src="documents/22430-mouse-scroll-animation.json"
              style="width: 80px"
            ></lottie-player>
          </p>
          <!--          <div data-w-id="95052dc5-498c-888d-cf37-721d34b5281f" data-animation-type="lottie" data-src="documents/22430-mouse-scroll-animation.json" data-loop="1" data-direction="1" data-autoplay="1" data-is-ix2-target="0" data-renderer="canvas" data-default-duration="5.033333333333333" data-duration="0"></div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BannerSection",
};
</script>

<style scoped>
.button-9{
  color: #FFFFFF !important;
}
</style>
