<template>
  <div class="micro-influencers wf-section">
    <div class="container">
      <div class="wrapper influencers">
        <div data-w-id="84e16261-49b1-8e04-f671-8d252d2dcde7" class="influencer-block">
          <div class="div-block-4 influencers">
            <h4 class="heading-7 influencer">For micro- influencers</h4>
          </div>
          <h1 class="heading-10">Apvertise for Influencers</h1>
          <p class="paragraph-6">Promote ideas and brands worth spreading, monetize your hobby, your passion and your following.</p>
        </div>
        <div class="influencer-image"><img src="@/assets/images/img2-min-1.png" loading="lazy" width="450" data-w-id="84e16261-49b1-8e04-f671-8d252d2dcdef" alt="" class="image-16"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MicroInfluencerSection"
};
</script>

<style scoped>

</style>