// import router from "@/router";
import TransactionService from "@/service/TransactionService";
// import TransactionRequest from "@/model/request/TransactionRequest";
import TransactionResponse from "@/model/response/TransactionResponse";
// import swal from "sweetalert";
import TransactionRequest from "@/model/request/TransactionRequest";
import store from "@/store/store";

export const state = {
  transaction: TransactionResponse.prototype.readSingleResponse(),
  transactions: TransactionResponse.prototype.readResponse(),
  payouts: TransactionResponse.prototype.readPayoutResponse(),
  transactionLoading: false,
  flutterwaveProps: {
    isProduction: {
      type: Boolean,
      required: false,
      default: false,//set to true if you are going live
    },
    email: {
      type: String,
      required: true,
    },
    amount: {
      type: Number,
      required: true
    },
    flwKey: {
      type: String,
      required: true
    },
    callback: {
      type: Function,
      required: true,
      default: () => {
        console.log('Payment made, verify payment');
      }
    },
    close: {
      type: Function,
      required: true,
      default: () => {}
    },
    currency: {
      type: String,
      default: "USD"
    },
    country: {
      type: String,
      default: "NG"
    },
    custom_title: {
      type: String,
      default: "Wallet Funding"
    },
    custom_logo: {
      type: String,
      default: "https://veezahrepo.s3.amazonaws.com/images/Group-22425.png"
    },
    reference: {
      type: String,
      default: ""
    },
    payment_method: {
      type: String,
      default: "card,mobilemoney,ussd"
    }
  },
  flutterwaveDetails: null
};
export const getters = {};
export const mutations = {
  updateTransaction: (state, payload) => {
    state.transaction = payload;
  },
  updateFlutterwaveDetails: (state, payload) => {
    state.flutterwaveDetails = payload;
  },
  updatePayouts: (state, payload) => {
    state.payouts = payload;
  },
  updateTransactions: (state, payload) => {
    state.transactions = payload;
  },
  updateTransactionLoading: (state, payload) => {
    state.transactionLoading = payload;
  },
};
export const actions = {
  balanceEnquiry: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateTransactionLoading", true);
    return TransactionService.callBalanceEnquiryApi(payload)
      .then((response) => {
        commit("updateTransactionLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          commit("updateTransaction", responseData);
        } else {
          store.commit("updateMessage",{
            type: "error",
            title: "Error",
            body: responseData.responseMessage
          });
        }
      })
      .catch((err) => {
        commit("updateTransactionLoading", false);
        console.log(err);
      });
  },

  queryTransaction: ({ commit }, payload = TransactionRequest.prototype.queryTransaction()) => {
    //TODO USER LOADING
    commit("updateTransactionLoading", true);
    return TransactionService.callQueryTransactionApi(payload)
      .then((response) => {
        commit("updateTransactionLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          commit("updateTransactions", responseData);
        }
      })
      .catch((err) => {
        commit("updateTransactionLoading", false);
        console.log(err);
      });
  },

  callFlutterwaveTransaction: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateTransactionLoading", true);
    return TransactionService.callFlutterwaveApi(payload)
      .then((response) => {
        commit("updateTransactionLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          commit("updateFlutterwaveDetails", responseData);
          return responseData;
        }
      })
      .catch((err) => {
        commit("updateTransactionLoading", false);
        console.log(err);
      });
  },
  callFlutterwaveVerify: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateTransactionLoading", true);
    return TransactionService.callFlutterwaveVerifyApi(payload)
      .then((response) => {
        commit("updateTransactionLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          // commit("updateFlutterwaveDetails", responseData);
          return responseData;
        }
      })
      .catch((err) => {
        commit("updateTransactionLoading", false);
        console.log(err);
      });
  },

  payoutReadByCustomerId: ({ commit }, payload = TransactionRequest.prototype.queryTransaction()) => {
      //TODO USER LOADING
      commit("updateTransactionLoading", true);
      return TransactionService.callPayoutReadByCustomerIdApi(payload)
        .then((response) => {
          commit("updateTransactionLoading", false);
          let responseData = response.data;
          if (responseData.responseCode === "00") {
            commit("updatePayouts", responseData);
          }
        })
        .catch((err) => {
          commit("updateTransactionLoading", false);
          console.log(err);
        });
    },

  payoutCreate: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateTransactionLoading", true);
    return TransactionService.callPayoutCreateApi(payload)
      .then((response) => {
        commit("updateTransactionLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          store.commit("updateMessage",{
            type: "success",
            title: "Success",
            body: responseData.responseMessage
          });
        } else {
          store.commit("updateMessage",{
            type: "error",
            title: "Error",
            body: responseData.responseMessage
          });
        }
      })
      .catch((err) => {
        commit("updateTransactionLoading", false);
        console.log(err);
      });
  },

  reversal: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateTransactionLoading", true);
    return TransactionService.callReversalApi(payload)
      .then((response) => {
        commit("updateTransactionLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          store.commit("updateMessage",{
            type: "success",
            title: "Success",
            body: responseData.responseMessage
          });
        } else {
          store.commit("updateMessage",{
            type: "error",
            title: "Error",
            body: responseData.responseMessage
          });
        }
      })
      .catch((err) => {
        commit("updateTransactionLoading", false);
        console.log(err);
      });
  },

  fundTransferOutward: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateTransactionLoading", true);
    return TransactionService.callFundTransferOutwardApi(payload)
      .then((response) => {
        commit("updateTransactionLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          store.commit("updateMessage",{
            type: "success",
            title: "Success",
            body: responseData.responseMessage
          });
        } else {
          store.commit("updateMessage",{
            type: "error",
            title: "Error",
            body: responseData.responseMessage
          });
        }
      })
      .catch((err) => {
        commit("updateTransactionLoading", false);
        console.log(err);
      });
  },

  fundTransferInternal: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateTransactionLoading", true);
    return TransactionService.callFundTransferInternalApi(payload)
      .then((response) => {
        commit("updateTransactionLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          store.commit("updateMessage",{
            type: "success",
            title: "Success",
            body: responseData.responseMessage
          });
        } else {
          store.commit("updateMessage",{
            type: "error",
            title: "Error",
            body: responseData.responseMessage
          });
        }
      })
      .catch((err) => {
        commit("updateTransactionLoading", false);
        console.log(err);
      });
  },

  requestStatement: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateTransactionLoading", true);
    return TransactionService.callRequestStatementApi(payload)
      .then((response) => {
        commit("updateTransactionLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          store.commit("updateMessage",{
            type: "success",
            title: "Success",
            body: responseData.responseMessage
          });
        } else {
          store.commit("updateMessage",{
            type: "error",
            title: "Error",
            body: responseData.responseMessage
          });
        }
      })
      .catch((err) => {
        commit("updateTransactionLoading", false);
        console.log(err);
      });
  },
};
