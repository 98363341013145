// import router from "@/router";
import DocumentService from "@/service/DocumentService";
// import DocumentRequest from "@/model/request/DocumentRequest";
import DocumentResponse from "@/model/response/DocumentResponse";
import swal from 'sweetalert'

export const state = {
    document: DocumentResponse.prototype.readSingleResponse(),
    documents: DocumentResponse.prototype.readResponse(),
    documentLoading: false,
};

export const getters = {};

export const mutations = {
    updateDocument: (state, payload) => {
        state.document = payload;
    },
    updateDocuments: (state, payload) => {
        state.documents = payload;
    },
    updateDocumentLoading: (state, payload) => {
        state.documentLoading = payload;
    },
};

export const actions = {

    changeProfilePicture: ({ commit }, payload) => {
        //TODO USER LOADING
        commit("updateDocumentLoading", true);
        return DocumentService.callChangeProfilePictureApi(payload)
            .then((response) => {
                commit("updateDocumentLoading", false);
                let responseData = response.data;
                if (responseData.responseCode === "00") {
                    commit("updateDocument", responseData);
                }else{
                    swal("Error", responseData.responseMessage, "error");
                }
            })
            .catch((err) => {
                commit("updateDocumentLoading", false);
                console.log(err);
            });
    },


};


