<template>
  <div class="dashboard-nav wf-section">
  <div class="main-dashboard-section wf-section">
    <div class="main-dashboard-body mall">
      <div class="content">
          <div class="create-campaign-form-container">
            <div class="back-button-container">
              <router-link
                to="/dashboard"
                class="link-block-11 w-inline-block">
                <div class="text-block-69">Back to dashboard</div>
            </router-link>
          </div>
          <div class="create-campaign-header">
            <div>
              <h1 class="heading-23">Setup your Online Mall</h1>
            </div>
            <div class="div-block-90">
              <div class="text-block-41">Sell any kind of digital product or service to anyone, anywhere, with our all-in-one e-commerce store builder.</div>
            </div>
          </div>
          <div class="new-campaign-block2">
            <div class="w-form">
              <form id="email-form" name="email-form" data-name="Email Form" @submit.prevent="createCompany" class="form-4">
                <div class="industry">
                  <div class="div-block-145">
                    <label for="Industry" class="field-label-8">Company <b-spinner style="color: #63b8a0;" v-if="organisation.organisationLoading" small/></label>
                    <a data-w-id="69d342ba-eadf-ddbe-72b4-6fc45182b84c" href="#" class="link-block-16 w-inline-block">
                      <div @click="showModal = !showModal">+ Add new company</div>
                    </a>
                  </div>
                  <select v-model="model.organisationId" id="Industry" name="Industry" required="" data-name="Industry" class="select-field w-select">
                    <option value="">Select Company </option>
                    <option v-for="company in organisations" :value="company.organisationId" v-bind:key="company.organisationId">{{ company.organisationName }}</option>
                  </select>
                </div>
                <div class="campaign-name mall">
                  <div class="div-block-264">
                    <label  class="field-label-8">Mall Currency</label>
<!--                    <img src="images/Question.svg" loading="lazy" data-w-id="501aac60-12aa-a274-c8ce-e0fc20447ec3" alt="">-->
                  </div>
                  <select  v-model="model.organisationCurrency" id="Industry-2" name="Industry-2" required="" data-name="Industry 2" class="select-field w-select">
                    <option value="">Select Currency</option>
                  <option value="USD">USD</option>
                  <option value="NGN">NGN</option>
                </select>
                </div>

                <div class="campaign-name mall">
                  <div class="div-block-264">
                    <label  class="field-label-8">Redirection Platform</label>
<!--                    <img src="images/Question.svg" loading="lazy" data-w-id="501aac60-12aa-a274-c8ce-e0fc20447ec3" alt="">-->
                  </div>
                  <select  v-model="model.organisationRedirectPlatform" id="Industry-29" name="Industry-2" required="" data-name="Industry 2" class="select-field w-select">
                  <option value="Whatsapp">WhatsApp</option>
                  <option value="Instagram">Instagram</option>
                    <option value="Custom">Custom</option>
                </select>
                </div>
                <div v-if="model.organisationRedirectPlatform === 'Whatsapp'" class="phone-number-block" style="margin-bottom: 20px">
                  <label for="name-3" class="embolden phone-number-text"
                  >Whatsapp Phone Number</label>
                  <div class="div-block-143">
                    <input
                      type="number"
                      v-model="model.organisationRedirectParameter"
                      required
                      class="phone-number-textfield w-input"
                      maxlength="256"
                      name="name-3"
                      data-name="Name 3"
                      style="padding-left: 10px"
                      placeholder="Enter Whatsapp Phone number"
                      id="name-3"
                    />
                    <!--          <div>-->
                    <vue-country-code
                      class="dial-code"
                      style="padding-left: 0; width: 90px"
                      v-model="model.organisationCountryCode"
                      @onSelect="onSelect"
                      :preferredCountries="['ng', 'us', 'gb']"
                    />
                    <!--          </div>-->
                  </div>
                </div>

                <div v-else-if="model.organisationRedirectPlatform === 'Instagram'" class="div-block-146">
                    <label class="field-label-15">Company Instagram Username </label>
                    <input type="text" v-model="model.organisationRedirectParameter"   required class="text-field-17 w-input" maxlength="256" name="name" data-name="Name" placeholder="Instagram Username" id="namef6392">
                </div>
                <div v-else class="div-block-146">
                    <label class="field-label-15">Custom Redirection link </label>
                    <input type="text" v-model="model.organisationRedirectCustomUrl"   required class="text-field-17 w-input" maxlength="256" name="name" data-name="Name" placeholder="Redirection link" id="namef632">
                </div>
                <div class="div-block-146">
                    <label class="field-label-15">Mall Description </label>
                    <input type="text" v-model="model.organisationDescription"   required class="text-field-17 w-input" minlength="70" maxlength="150" name="name" data-name="Name" placeholder="Enter Mall Description" id="namef32">
                </div>

                <base-button title="Submit" :loading="organisation.organisationLoading"/>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import OrganisationRequest from "@/model/request/OrganisationRequest";
import OrganisationResponse from "@/model/response/OrganisationResponse";
import BaseButton from "@/components/button/BaseButton";

export default {
  name: "BusinessDetails",
  components: { BaseButton },
  data() {
    return {
      showModal: false,
      model: OrganisationRequest.prototype.organisationUpdateRequest(),
      orgModel: OrganisationRequest.prototype.organisationReadSingleRequest()

    };
  },
  computed: {
    ...mapState({
      sbucket: state => state.sbucket,
      organisations:state => state.organisation.organisations,
      auth: state => state.auth,
      organisation: state => state.organisation

    }),
    categories: function () {
      return new OrganisationResponse().readOrganisationCategoryResponse().data;
    },
  },
  methods:{
    async createCompany() {
      this.model.organisationLogo = this.sbucket.s3bucketResponse.url;
      this.model.organisationPhone = this.auth.userInfo.customerPhoneNumber;
      this.model.organisationEmail = this.auth.userInfo.customerEmail;
      this.model.organisationCustomerId = this.auth.userInfo.customerId;
      await this.$store.dispatch("organisation/editMall", this.model);
      await this.$store.dispatch("organisation/updateOrganisations", { customerId: this.auth.userInfo.customerId });
      localStorage.organisationId = this.model.organisationId
      this.orgModel.organisationId = localStorage.organisationId
      console.log(this.model);
      // await this.$router.push("/mall-registered")
      this.hide();
    },
    onSelect({ name, iso2, dialCode }) {
      this.model.organisationCountryCode = dialCode;
      console.log(name, iso2, dialCode);
    },
  }
};
</script>

<style scoped>
.btn{

}
</style>