import CampaignCreate from "@/views/campaign/CampaignCreate";
import CampaignCreateSuccess from "@/views/campaign/CampaignCreateSuccess";
import CampaignDetail from "@/views/campaign/CampaignDetail";
import CampaignAll from "@/views/campaign/CampaignAll";
import CampaignSettlement from "@/views/campaign/CampaignSettlement";
import CampaignEdit from "@/views/campaign/CampaignEdit";
import CampaignDownloadApp from "@/views/campaign/CampaignDownloadApp";
import Explainer from "@/views/campaign/Explainer";
import CampaignMetrics from "@/views/campaign/CampaignMetrics.vue";

export default [
  {
    path: "/create-new-campaign",
    name: "CampaignCreate",
    meta: { layout: "dashboard", authRequired: true },
    component: CampaignCreate,
  },
  {
    path: "/edit-campaign",
    name: "CampaignEdit",
    meta: { layout: "dashboard", authRequired: true },
    component: CampaignEdit,
  },
  {
    path: "/create-new-campaign-success",
    name: "CampaignCreateSuccess",
    meta: { layout: "dashboard", authRequired: true },
    component: CampaignCreateSuccess,
  },
  {
    path: "/campaign-details",
    name: "CampaignDetail",
    meta: { layout: "dashboard", authRequired: false },
    component: CampaignDetail,
  },
  {
    path: "/campaign-downloads/:campaignId",
    name: "CampaignDetail",
    meta: { layout: "dashboard", authRequired: false },
    component: CampaignDownloadApp,
  },
  {
    path: "/campaign-metrics/:campaignId",
    name: "CampaignMetrics",
    meta: { layout: "dashboard", authRequired: false },
    component: CampaignMetrics,
  },
  {
    path: "/campaign-details/:campaignId",
    name: "CampaignDetailWithCampaignId",
    meta: { layout: "dashboard", authRequired: false },
    component: CampaignDetail,
  },
  {
    path: "/campaign-all",
    name: "CampaignAll",
    meta: { layout: "dashboard", authRequired: false },
    component: CampaignAll,
  },
  {
    path: "/campaign-settlement/:reference",
    name: "CampaignSettlement",
    meta: { layout: "auth", authRequired: false },
    component: CampaignSettlement,
  },
  {
    path: "/explainer",
    name: "Explainer",
    meta: { authRequired: false },
    component: Explainer,
  },
];
