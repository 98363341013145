<template>
  <div data-collapse="medium" data-animation="default" data-duration="400" data-w-id="22a1af20-5236-39de-8049-59d42da3c105" role="banner" class="navbar-2 w-nav">
    <div class="container-copy">
      <a href="https://www.apvertise.com" aria-current="page" class="logo w-nav-brand w--current"><img src="@/assets/images/Logo.svg" loading="lazy" alt="" class="image-17"></a>
      <nav role="navigation" class="nav-menu-2 w-nav-menu">
        <div data-hover="" data-delay="0" class="dropdown w-dropdown">
          <div class="about-dropdown w-dropdown-toggle">
            <div class="w-icon-dropdown-toggle"></div>
            <div class="link-100">About</div>
          </div>
          <nav class="dropdown-list w-dropdown-list">
            <a href="/#how-it-works" class="dropdown-link w-dropdown-link link-100">Our Services</a>
<!--            <a href="#" class="dropdown-link-2 w-dropdown-link">Our Platform</a>-->
<!--            <a href="#how-it-works" class="dropdown-link-3 w-dropdown-link">Today@Apvertise</a>-->
            <router-link to="/about-us" class="dropdown-link-3 w-dropdown-link link-100">About Us</router-link>
<!--            <router-link to="/about-us" class="dropdown-link-3 w-dropdown-link">Contact Us</router-link>-->
          </nav>
        </div>
<!--        <router-link to="/about-us" aria-current="page" class="about-us w-nav-link">About Us</router-link>-->
        <router-link to="/api-documentation" class="login-button w-nav-link link-100">Documentation</router-link>
        <router-link to="/logon" class="login-button w-nav-link link-100">Login</router-link>
        <router-link to="/register" class="button-9 w-button">Get started</router-link>


        <!--        <router-link to="/logon" v-if="auth.userInfo.responseCode !== '00'" class="post-campaign-button w-nav-link">Post Campaign</router-link>-->
<!--        <router-link to="/create-new-campaign" v-else class="post-campaign-button w-nav-link">Post Campaign</router-link>-->
      </nav>
      <div class="menu-button-2 w-nav-button">
        <div data-w-id="f4325e8f-e2aa-9cf0-cf06-0317bb3879ff" class="burger">
          <div class="burger-bar-wrap">
            <div data-w-id="f4325e8f-e2aa-9cf0-cf06-0317bb387a01" class="b2-bar1"></div>
            <div class="b2-middle-wrap">
              <div data-w-id="f4325e8f-e2aa-9cf0-cf06-0317bb387a03" class="b2-middle-bar-left"></div>
              <div data-w-id="f4325e8f-e2aa-9cf0-cf06-0317bb387a04" class="b2-middle-bar-right"></div>
            </div>
            <div data-w-id="f4325e8f-e2aa-9cf0-cf06-0317bb387a05" class="b2-bar3"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "HomeHeader",
  computed:{
    ...mapState(["auth"])
  },
  methods:{
    logout() {
      window.location.href = "https://www.apvertise.com";
      window.location.replace("https://www.apvertise.com");
    },
  }
};
</script>

<style scoped>
.about-dropdown{
  font-family: 'DM Sans', sans-serif !important;
  margin-top: -3px;
  color: #2a3459;
  padding: 0 40px 0 0;
}

.dropdown-link{
  font-family: 'DM Sans', sans-serif !important;
}

.dropdown-link-3{
  font-family: 'DM Sans', sans-serif !important;
}

.button-9:hover{
  color: #FFFFFF !important;
}

.link-100:hover{
  color: #63b8a0 !important;
}
</style>