import Logon from "@/views/authentication/Logon";
import Home from "@/views/home/Home";
import PageNotFound from "@/views/authentication/PageNotFound";
import Register from "@/views/authentication/Register";
import SuccessfulRegister from "@/views/authentication/SuccessfulRegister";
import Preloader from "@/views/authentication/Preloader";
import ForgetPassword from "@/views/authentication/ForgetPassword";
import PrivacyPolicy from "@/views/home/PrivacyPolicy";
import AboutUs from "@/views/home/AboutUs";
import InfluencerTC from "@/views/home/InfluencerTC";
import CompanyTC from "@/views/home/CompanyTC";
import ContactUs from "@/views/home/ContactUs";
import TestCropper from "@/views/home/TestCropper";

export default [
  {
    path: "/",
    name: "Home",
    meta: { layout: "home", authRequired: false },
    component: Home,
  },
  {
    path: "/company-tc",
    name: "CompanyTC",
    meta: { layout: "home", authRequired: false },
    component: CompanyTC,
  },
  {
    path: "/influencer-tc",
    name: "InfluencerTC",
    meta: { layout: "home", authRequired: false },
    component: InfluencerTC,
  },
  {
    path: "/about-us",
    name: "AboutUs",
    meta: { layout: "home", authRequired: false },
    component: AboutUs,
  },
  {
    path: "/test-cropper",
    name: "TestCropper",
    meta: { layout: "home", authRequired: false },
    component: TestCropper,
  },
  {
    path: "/contact-us",
    name: "ContactUs",
    meta: { layout: "home", authRequired: false },
    component: ContactUs,
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    meta: { layout: "home", authRequired: false },
    component: PrivacyPolicy,
  },
  {
    path: "/logon",
    name: "Logon",
    meta: { layout: "auth", authRequired: false },
    component: Logon,
  },
  {
    path: "/logon/:inviteId",
    name: "LogonWithInvite",
    meta: { layout: "auth", authRequired: false },
    component: Logon,
  },
  {
    path: "/register",
    name: "Register",
    meta: { layout: "auth", authRequired: false },
    component: Register,
  },
  {
    path: "/register/:inviteId",
    name: "RegisterWithInvite",
    meta: { layout: "auth", authRequired: false },
    component: Register,
  },
  {
    path: "/preloader",
    name: "Preloader",
    meta: { layout: "preloader", authRequired: false },
    component: Preloader,
  },
  {
    path: "/successful-register",
    name: "SuccessfulRegister",
    meta: { layout: "auth", authRequired: false },
    component: SuccessfulRegister,
  },
  {
    path: "/forget-password",
    name: "ForgetPassword",
    meta: { layout: "auth", authRequired: false },
    component: ForgetPassword,
  },
  {
    path: "*",
    name: "PageNotFound",
    meta: { layout: "404", authRequired: false },
    component: PageNotFound,
  },
];
