<template>
  <div class="right-section-2-copy">
    <div class="social-media-signup-wrapper">
      <div class="div-block-59-copy"><img src="@/assets/images/Logo.svg" loading="lazy" alt=""></div>
      <div class="div-block-79">
        <lottie-player
          autoplay
          loop
          mode="normal"
          src="documents/64787-success.json"
          style="width: 250px"
        ></lottie-player>
<!--        <div data-w-id="897fdbfd-55bf-e3e6-17e3-7a9491ec2aa4" data-animation-type="lottie" data-src="documents/64787-success.json" data-loop="1" data-direction="1" data-autoplay="1" data-is-ix2-target="0" data-renderer="canvas" data-default-duration="2" data-duration="3.5"></div>-->
      </div>
      <div class="div-block-80">
        <h1 class="heading-22">Sign up successful 🥳</h1>
        <div>Your account has been created <br>successfully</div>
      </div>
      <router-link to="/logon" class="button-10 w-button">Proceed to login</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "SuccessfulRegister"
};
</script>

<style scoped>
.button-10:hover{
  color: #FFFFFF !important;
}
</style>



<!--"_PaymentType": "string",-->
<!--"_TicketType": "string",-->
<!--"_PaymentTexts": [-->
<!--{-->
<!--"Name": "string",-->
<!--"Text": "string"-->
<!--}-->
<!--],-->
<!--"_NumberOfBaggages": 0,-->
<!--"_NumberOfBaggages1": 0,-->
<!--"_HandLuggages": 0,-->
<!--"_HandLuggages1": 0,-->
<!--"_AmountPaid": 0,-->