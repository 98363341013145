import SettingsService from "@/service/SettingsService";

export const state = {
  notificationSettings: {  },
  settingsLoading: false
};
export const getters = {};
export const mutations = {
  updateNotificationSettings: (state, payload) => {
    state.notificationSettings = payload;
  },
  updateSettingsLoading: (state, payload) => {
    state.settingsLoading = payload;
  },
};
export const actions = {
  updateNotificationSettings: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateSettingsLoading", true);
    return SettingsService.callReadNotificationSettingsApi(payload)
      .then((response) => {
        commit("updateSettingsLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          commit("updateNotificationSettings", responseData);
        } else {
          //swal("Error", responseData.responseMessage, "error");
        }
      })
      .catch((err) => {
        commit("updateSettingsLoading", false);
        console.log(err);
      });
  },
  updateGeneralInvite: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateSettingsLoading", true);
    return SettingsService.callGenerateInvite(payload)
      .then((response) => {
        commit("updateSettingsLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          commit("updateNotificationSettings", responseData);
          return responseData;
        } else {
          //swal("Error", responseData.responseMessage, "error");
        }
      })
      .catch((err) => {
        commit("updateSettingsLoading", false);
        console.log(err);
      });
  },

  editNotificationSettings: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateSettingsLoading", true);
    return SettingsService.callUpdateNotificationSettingsApi(payload)
      .then((response) => {
        commit("updateSettingsLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          commit("updateNotificationSettings", responseData);
        }
      })
      .catch((err) => {
        commit("updateSettingsLoading", false);
        console.log(err);
      });
  },
};
