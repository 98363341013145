<template>
  <div>
    <share-campaign-modal
      :show="showModal"
      :campaign-id="campaignId"
      :customer-id="customerId"
      :title="title"
      :description="description"
    />
    <div v-if="!campaign.shareLinkLoading" @click="showShareCampaignModal">
      <a
        v-if="type === 'icon'"
        data-w-id="641b1996-6320-9202-5c1c-c44bd270e1d3"
        href="#"
        class="w-inline-block"
      >
        <img src="@/assets/images/Share-icon.svg" loading="lazy" alt="" />
      </a>
      <a
        v-else
        data-w-id="3b0786d9-0195-898d-4c9e-8fbfde6b3ed2"
        href="#"
        class="button-6 w-button"
      ><span v-if="this.auth.userInfo.customerId === customerId">Share Campaign to Influencers</span>
      <span v-else>Share Campaign</span></a
      >
    </div>
    <div v-else>
      <a
        data-w-id="641b1996-6320-9202-5c1c-c44bd270e1d3"
        href="#"
        style="color: #63b8a0"
        class="w-inline-block"
        ><b-spinner small
      /></a>
    </div>
  </div>
</template>

<script>
import ShareCampaignModal from "@/components/modal/ShareCampaignModal";
import { mapState } from "vuex";
import store from "@/store/store";
export default {
  components: { ShareCampaignModal },
  props: ["type", "campaignId", "customerId", "title", "description", "campaignBudgetExhausted"],
  name: "ShareCampaignButton",
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    ...mapState(["campaign", "auth"]),
  },
  methods: {
    hideShareCampaignModal() {
      this.$refs["share-campaign-modal"].hide();
    },
    showShareCampaignModal() {
      if (this.campaignBudgetExhausted === "NO") {
        if (this.auth.userInfo.responseCode !== "00") {
          this.$router.push("/logon")
        } else {
          if (this.auth.userInfo.customerId !== this.customerId)
            this.$store
              .dispatch("campaign/getShareLink", {
                shareCampaignId: this.campaignId,
                shareCustomerId: this.auth.userInfo.customerId,
              })
              .then(() => {
                if (this.campaign.shareLink.responseCode === "00")
                  this.showModal = !this.showModal;
              });
          else this.showModal = !this.showModal;
          //this.showModal = !this.showModal
          //this.$refs["share-campaign-modal"].show();
        }
      } else {
        store.commit("updateMessage", {
          type: "error",
          title: "Error",
          body: "Campaign budget is exhausted!",
        });
      }
    },
  },
};
</script>

<style scoped>
.w-button{
  color: #63b8a0 !important;
}
.w-button:hover{
  color: #63b8a0 !important;
}
</style>
