<template>
  <div class="wf-section">
    <div class="container">
      <div class="div-block-170">
        <div class="div-block-174">
          <div class="div-block-171">
            <div class="text-block-96">CONTACT US</div>
            <div class="text-block-97">How can we help you?</div>
            <div class="text-block-98">Drop an email 📩</div>
          </div>
          <div class="div-block-177">
            <div id="w-node-c899edd3-5d9f-f24f-755d-8b3aa25d6743-9b01b7d6" class="div-block-172"><img src="@/assets/images/mail.svg" loading="lazy" width="160" alt="" class="image-52">
              <div class="div-block-178">
                <div class="text-block-99">Send us a mail</div>
                <div class="text-block-100">
                  <div class="text-block-102"><strong class="bold-text-3">We’ll respond to you as soon as we possibly can</strong></div>
                </div>
                <a href="#" class="link-block-21 w-inline-block">
                  <div class="text-block-101">support@apvertise.com</div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactUsSection"
};
</script>

<style scoped>

</style>