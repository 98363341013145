import BaseResponse from "@/model/response/BaseResponse";

class TransactionResponse extends BaseResponse {
  readSingleResponse() {
    return {
      responseCode: null,
      responseMessage: null,
      amount: null,
      counterPartyService: null,
      transactionDate: null,
      eventDate: null,
      counterPartyBankCode: null,
      contractReference: null,
      paymentReference: null,
      counterPartyAccountName: null,
      drCr: null,
      counterPartyBank: null,
      counterPartyChannel: null,
      accountName: null,
      accountNumber: null,
      counterPartyAccountNumber: null,
      transactionType: null,
      narration: null,
      id: null
    };
  }
  readResponse() {
    return {
      responseCode: null,
      responseMessage: null,
      accounts: [],
      transactions: [],
    };
  }
  readPayoutResponse() {
    return {
      responseCode: null,
      responseMessage: null,
      data: [],
    };
  }
  readPayoutSingleResponse() {
    return {
      payoutId: null,
      payoutCustomerId: null,
      payoutReference: null,
      payoutAmount: null,
      payoutStatus: null,
      payoutCreatedAt: null,
      payoutUpdatedAt: null,
      responseCode: null,
      responseMessage: null,
    };
  }
}

export default TransactionResponse;
