
import client from "./BaseService";

export default {
    callProcessSmsApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("apvProcessSms", payload);
    },
    callProcessEmailApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("apvProcessEmail", payload);
    },
    callBankListApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("apvBankList", payload);
    },
    callReadBeneficiaryApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("apvReadBeneficiary", payload);
    },
    callRemoveBeneficiaryApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("apvRemoveBeneficiary", payload);
    },
    callBankListFromAccountApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("apvBankListFromAccount", payload);
    },
    callBankListFlwApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("bankListFlw", payload);
    },
};
