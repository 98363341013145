import { render, staticRenderFns } from "./CampaignEditSuccess.vue?vue&type=template&id=e55a024e&scoped=true&"
import script from "./CampaignEditSuccess.vue?vue&type=script&lang=js&"
export * from "./CampaignEditSuccess.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e55a024e",
  null
  
)

export default component.exports