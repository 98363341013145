import client from "./BaseService";

export default {
    callCreateOrganisationApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("organisationCreate", payload);
    },
    callReadOrganisationApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("organisationRead", payload);
    },
    callReadOrganisationByUserIdApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("organisationReadByUserId", payload);
    },
    callReadSingleOrganisationApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("organisationReadSingle", payload);
    },
    callReadMallByCustomerIdApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("organisation/readmallsbycustomerid", payload);
    },
    callReadAllMallsApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("organisation/readmalls", payload);
    },
    callReadOrganisationTypesApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("organisationTypeRead", payload);
    },
    callUpdateOrganisationApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("organisationUpdate", payload);
    },
    callUpdateOrganisationStatusApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("organisationUpdateStatus", payload);
    },
};
