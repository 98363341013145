<template>
  <div class="dashboard-nav wf-section">
    <div
      data-collapse="medium"
      data-animation="default"
      data-duration="400"
      role="banner"
      class="navbar-4 w-nav"
    >
      <div class="dashboard-container w-container">
        <div class="div-block-43">
          <div class="menu-button-3 w-nav-button">
            <div class="div-block-40"></div>
            <div class="icon-3 w-icon-nav-menu"></div>
          </div>
          <router-link to="/dashboard" class="brand w-nav-brand">
            <img
              src="@/assets/images/Logo.svg"
              loading="lazy"
              alt=""
              class="image-18"
          /></router-link>
          <nav role="navigation" class="nav-menu-3 w-nav-menu">
            <a
              href="#"
              @click="navigate('/dashboard', '.dashboardlink')"
              aria-current="page"
              class="dashboardlink w-nav-link"
              >Dashboard</a
            >
            <a
              href="#"
              @click="navigate('/campaign-all', '.campaignnav')"
              class="campaignnav w-nav-link"
              >Campaigns</a
            >
            <a
              href="#"
              @click="navigate('/mall-all', '.online-mall')"
              class="online-mall w-nav-link"
              >Mall</a
            >
          </nav>
          <div class="div-block-41">
            <div
              data-hover=""
              data-delay="0"
              class="dropdown-6 w-dropdown"
              @click="openNavbar"
            >
              <div class="dropdown-toggle-3 w-dropdown-toggle">
                <img
                  src="@/assets/images/PROFILE.svg"
                  loading="lazy"
                  width="33"
                  alt=""
                  class="dropdown-image"
                />
              </div>
              <nav
                class="dropdown-list-2 w-dropdown-list"
                :class="{ visible: open }"
              >
                <a
                  @click="goToProfileSettings"
                  class="dropdown-link-4 w-dropdown-link"
                  >Account</a
                >
                <a href="#" @click="logout" class="w-dropdown-link">Log Out</a>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "DashboardHeader",
  data(){
    return{
      open: false
    }
  },
  methods: {
    openNavbar() {
      if (
        document.querySelector(".w-dropdown-list").classList.contains("visible")
      ) {
        document.querySelector(".w-dropdown-list").classList.remove("visible")
      } else {
        document.querySelector(".w-dropdown-list").classList.add("visible")
      }
    },
    logout() {
      localStorage.clear();
      window.location.href = "https://www.apvertise.com";
      window.location.replace("https://www.apvertise.com");
    },
    goToProfileSettings() {
      localStorage.setItem("wallet", "no");
      this.$router.push("/profile-settings");
    },
    checkOrg() {
      if (this.organisation.length > 0) {
        this.$router.replace("/mall-registered");
      } else {
        this.$router.replace("/mall-get-started");
      }
      this.$router.push("/mall-all");
    },
    navigate(_to, _active) {
      // const menu = document.querySelector('.w-nav-overlay');
      const menu = document.querySelector(".w-nav-overlay");
      let active = document.querySelector(_active);
      let inactive = document.querySelectorAll(".w--current");
      [].forEach.call(inactive, function (el) {
        el.classList.remove("w--current");
      });
      this.$router.push(_to);
      menu.style.cssText = "display: none;";
      active.classList.add("w--current");
      console.log("clickeddddd");
    },
  },
  computed: {
    // ...mapState(["auth"]),
    ...mapState({
      auth: (state) => state.auth,
      organisation: (state) => state.organisation.organisations,
    }),
  },
  mounted() {
    this.$store.dispatch("organisation/updateOrganisations", {
      customerId: this.auth.userInfo.customerId,
    });
    window.onclick = function (event) {
      console.log("i am wrong")
      if (event.target !== document.querySelector(".w-dropdown") && event.target !== document.querySelector(".w-dropdown-toggle") && event.target !== document.querySelector(".dropdown-image")) {
        document.querySelector(".w-dropdown-list").classList.remove("visible");
        localStorage.setItem("isOpen", false);
      }
    };
  },

  created() {
    const plugin = document.createElement("script");
    plugin.setAttribute("src", "https://play-apvertise.com/js/webflow.js");
    plugin.async = true;
    document.head.appendChild(plugin);
  },
};
</script>

<style scoped>
.w-dropdown-link:hover {
  color: #5dc9a0 !important;
}

.w-dropdown-list {
  display: none !important;
  top: 58px;
}

.w-dropdown-toggle {
  display: flex !important;
  align-items: flex-end;
  justify-content: right;
}

.dashboard-container {
  display: flex;
  width: 100%;
  max-width: 100%;
  /* margin-right: auto; */
  /* margin-left: auto; */
}

.div-block-43 {
  width: 1240px;
}

.dropdown-list-2 {
  left: -144px;
  width: 180px;
  padding: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(31, 36, 49, 0.05);
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0 64px 0 rgb(31 36 49 / 5%);
}

.visible {
  display: block !important;
}
</style>
