import client from "./BaseService";

export default {
  callCreateManualFundingApi(payload) {
    // console.log("Service Payload ==>>", payload)
    return client.apiClient.post("ftManualInward", payload);
  },
  callReadManualFundingApi(payload) {
    // console.log("Service Payload ==>>", payload)
    return client.apiClient.post("readManualFunding", payload);
  },
  callUpdateManualFundingApi(payload) {
    // console.log("Service Payload ==>>", payload)
    return client.apiClient.post("apvUpdateManualFunding", payload);
  },
  callUpdateManualFundingStatusApi(payload) {
    // console.log("Service Payload ==>>", payload)
    return client.apiClient.post("apvUpdateManualFundingStatus", payload);
  },

};