<template>
  <div>
    <create-campaign-form-one v-if="campaign.screen === 'one'"/>
    <create-campaign-form-two v-else-if="campaign.screen === 'two'"/>
    <create-campaign-form-three v-else-if="campaign.screen === 'three'"/>
    <campaign-create-success v-else/>
  </div>
</template>

<script>
import CreateCampaignFormOne from "@/components/form/CreateCampaignFormOne";
import CreateCampaignFormTwo from "@/components/form/CreateCampaignFormTwo";
import CreateCampaignFormThree from "@/components/form/CreateCampaignFormThree";
import { mapState } from "vuex";
import CampaignCreateSuccess from "@/views/campaign/CampaignCreateSuccess";
export default {
  name: "CampaignCreate",
  components: { CampaignCreateSuccess, CreateCampaignFormThree, CreateCampaignFormTwo, CreateCampaignFormOne },
  computed:{
    ...mapState(["campaign"])
  },
  created() {
    this.$store.commit("campaign/updateScreen", "one")
    this.$store.dispatch("campaign/updateExhibitionTypes", {readAll:"YES"})
    this.$store.dispatch("organisation/updateOrganisationTypes", {readAll:"YES"})
  }
};
</script>

<style scoped>
/*https://play.google.com/store/apps/details?id=com.quccon.rubies2fa*/
</style>