<template>
  <div class="campaign-block">
    <div data-duration-in="300" data-duration-out="100" class="w-tabs">
      <div class="tabs-menu-2 w-tab-menu">
        <a
          @click="selected = 'created'"
          data-w-tab="Created Campaign"
          :class="{ 'w--current': selected === 'created' }"
          style="background-color: #f5f5f5"
          class="tab-link-tab-1-2 w-inline-block w-tab-link"
        >
          <div>Created Campaign(s)</div>
        </a>
        <a
          @click="selected = 'shared'"
          data-w-tab="Shared Campaign"
          style="background-color: #f5f5f5"
          :class="{ 'w--current': selected === 'shared' }"
          class="tab-link-tab-1-2 w-inline-block w-tab-link"
        >
          <div>Shared Campaign(s)</div>
        </a>
        <a
          @click="selected = 'online'"
          data-w-tab="Online Malls"
          :class="{ 'w--current': selected === 'online' }"
          style="background-color: #f5f5f5"
          class="tab-link-tab-1-2 w-inline-block w-tab-link"
        >
          <div>Online Mall(s)</div>
        </a>
      </div>
      <div
        style="height: 500px"
        class="tabs-content overflow-auto w-tab-content"
      >
        <div data-w-tab="Created Campaign" class="w-tab-pane" :class="{ 'w--tab-active': selected === 'created' }">
          <div v-if="campaign.myCampaigns.data.length > 0">
            <div
              style="cursor: pointer"
              class="div-block-69"
              v-for="campaign in campaign.myCampaigns.data"
              v-bind:key="campaign.campaignId"
              @click="selectCampaign(campaign.campaignId)"
            >
              <div class="div-block-70">
                <div class="div-block-71">
                  <b-avatar
                    style="
                      width: 50px;
                      height: 50px;
                      border-style: solid;
                      border-width: 1px;
                      border-color: #e7e7e7;
                    "
                    :src="campaign.organisationLogo"
                    loading="lazy"
                    alt=""
                  ></b-avatar>
                </div>
                <div class="div-block-74">
                  <div class="div-block-72">
                    <div class="text-block-27">
                      <img
                        v-if="campaign.campaignBudgetExhausted === 'NO'"
                        src="@/assets/images/Active.svg"
                        loading="lazy"
                        width="10"
                        alt=""
                        class="image-56"
                      />
                      <img
                        v-else
                        src="@/assets/images/Inactive.svg"
                        loading="lazy"
                        width="10"
                        alt=""
                        class="image-56"
                      />
                      {{ campaign.campaignName }}
                    </div>
                    <div
                      style="position: absolute; right: 5%"
                      class="text-block-28"
                    >
                      {{ campaign.campaignCreatedAt | moment("calendar") }}
                    </div>
                    <div
                      style="position: absolute; right: 5%; margin-top: 50px"
                      class="text-block-28"
                    >
                      <b-icon-share style="color: #63b8a0" />&nbsp;{{
                        campaign.campaignClickCount
                      }}
                    </div>
                  </div>
                  <div class="div-block-73">
                    <div class="text-block-29">
                      {{ campaign.organisationType }}
                    </div>
                  </div>
                  <div>
                    <div class="text-block-30">
                      {{
                        campaign.campaignDescription
                          | formatCampaignDescriptionWithEllipsis
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-else
            data-w-tab="Created Campaign"
            class="tab-pane-created-campaign w-tab-pane w--tab-active"
          >
            <div class="div-block-105">
              <img
                src="@/assets/images/Group-22481.svg"
                loading="lazy"
                alt=""
                class="image-41"
              />
              <div class="text-block-56">No campaign record</div>
              <p class="paragraph-11">
                You have no created campaigns <br />currently
              </p>
              <router-link to="/create-new-campaign" class="button-14 w-button"
              >Create new campaign</router-link
              >
            </div>
          </div>
        </div>
        <div data-w-tab="Shared Campaign" class="w-tab-pane" :class="{ 'w--tab-active': selected === 'shared' }">
          <div v-if="campaign.sharedCampaigns.data.length > 0">
            <div
              style="cursor: pointer"
              class="div-block-69"
              v-for="campaign in campaign.sharedCampaigns.data"
              v-bind:key="campaign.campaignId"
              @click="selectCampaign(campaign.campaignId)"
            >
              <div class="div-block-70">
                <div class="div-block-71">
                  <b-avatar
                    style="
                      width: 45px;
                      height: 45px;
                      border-style: solid;
                      border-width: 1px;
                      border-color: #e7e7e7;
                    "
                    :src="campaign.organisationLogo"
                    loading="lazy"
                    alt=""
                  ></b-avatar>
                </div>
                <div class="div-block-74">
                  <div class="div-block-72">
                    <div class="text-block-27">
                      <img
                        v-if="campaign.campaignBudgetExhausted === 'NO'"
                        src="@/assets/images/Active.svg"
                        loading="lazy"
                        width="10"
                        alt=""
                        class="image-56"
                      />
                      <img
                        v-else
                        src="@/assets/images/Inactive.svg"
                        loading="lazy"
                        width="10"
                        alt=""
                        class="image-56"
                      />
                      {{ campaign.campaignName }}
                    </div>
                    <div
                      style="position: absolute; right: 5%"
                      class="text-block-28"
                    >
                      {{ campaign.shareCreatedAt | moment("calendar") }}
                    </div>
                    <div
                      style="position: absolute; right: 5%; margin-top: 50px"
                      class="text-block-28"
                    >
                      <b-icon-share style="color: #63b8a0" />&nbsp;{{
                        campaign.shareCount
                      }}
                    </div>
                  </div>
                  <div class="div-block-73">
                    <div class="text-block-29">
                      {{ campaign.organisationType }}
                    </div>
                  </div>
                  <div>
                    <div class="text-block-30">
                      {{
                        campaign.campaignDescription
                          | formatCampaignDescriptionWithEllipsis
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-else
            data-w-tab="Created Campaign"
            class="tab-pane-created-campaign w-tab-pane w--tab-active"
          >
            <div class="div-block-105">
              <img
                src="@/assets/images/Group-22481.svg"
                loading="lazy"
                alt=""
                class="image-41"
              />
              <div class="text-block-56">No campaign shared</div>
              <p class="paragraph-11">
                You have no shared campaigns <br />currently
              </p>
              <router-link to="/campaign-all" class="button-14 w-button"
              >Share now</router-link
              >
            </div>
          </div>
        </div >
        <div data-w-tab="Online Malls" class="w-tab-pane" :class="{ 'w--tab-active': selected === 'online' }">
          <div class="div-block-261">
            <div v-if="malls.length > 0" class="mall-active-state">
              <div
                style="cursor: pointer"
                v-for="mall in malls"
                :key="mall.organisationId"
                class="div-block-69 mall"
                @click="selectMall(mall.organisationId)"
              >
                <div class="div-block-70">
                  <div class="div-block-71">
                    <!--                    <img loading="lazy" width="50" :src=" mall.organisationLogo" alt="" class="image-31">-->
                    <b-avatar
                      style="
                        width: 45px;
                        height: 45px;
                        border-style: solid;
                        border-width: 1px;
                        border-color: #e7e7e7;
                      "
                      :src="mall.organisationLogo"
                      loading="lazy"
                      alt=""
                    ></b-avatar>
                  </div>
                  <div class="div-block-74">
                    <div class="div-block-72">
                      <div class="text-block-27">
                        {{ mall.organisationName }}
                      </div>
                      <div
                        style="position: absolute; right: 5%"
                        class="text-block-28"
                      >
                        {{ mall.organisationUpdatedAt | moment("calendar") }}
                      </div>
                    </div>
                    <div class="div-block-73">
                      <div style="font-size: 11px" class="text-block-29">
                        {{ mall.organisationType }}
                      </div>
                    </div>
                    <div>
                      <div class="text-block-30">
                        {{ mall.organisationDescription }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="mall-empty-state">
              <img
                loading="lazy"
                :src="require('@/assets/images/Group-22481_1.svg')"
                alt=""
                class="image-68"
              />
              <div class="text-block-156">No Online Malls</div>
              <div class="text-block-157">
                You have not created any online mall for your companies
              </div>
              <router-link
                to="/business-details"
                class="view-wallet-button mall w-button"
              >New Mall</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import OrganisationRequest from "@/model/request/OrganisationRequest";
export default {
  name: "CampaignCard",
  data() {
    return {
      model: OrganisationRequest.mallReadByCustomerIdRequest,
      orgModel: OrganisationRequest.prototype.organisationReadSingleRequest(),
      selected: "created",
    };
  },
  computed: {
    ...mapState({
      campaign: (state) => state.campaign,
      auth: (state) => state.auth,
      organisation: (state) => state.organisation,
      malls: (state) => state.organisation.malls.data,
    }),
  },
  methods: {
    selectCampaign(_id) {
      localStorage.campaignId = _id;
      this.$router.push(`/campaign-details/${_id.hexEncode()}`);
    },
    selectMall(mall) {
      localStorage.organisationId = mall;
      this.orgModel.organisationId = localStorage.organisationId;
      // this.$store.dispatch("organisation/updateOrganisation", this.model)
      this.$router.push("/mall-registered");
    },
  },
  mounted() {
    this.model.customerId = localStorage.customerId;
    console.log(this.model);
    this.$store.dispatch("organisation/updateMalls", this.model);
    const plugin = document.createElement("script");
    plugin.setAttribute("src", "https://play-apvertise.com/js/webflow.js");
    plugin.async = true;
    document.head.appendChild(plugin);
    this.$store.dispatch("campaign/updateMyCampaigns", {
      customerId: this.auth.userInfo.customerId,
    });
    console.log(this.auth.userInfo.customerId);
    this.$store.dispatch("campaign/updateSharedCampaigns", {
      customerId: this.auth.userInfo.customerId,
    });
    console.log(this.auth.userInfo);
  },
  created() {
    const script = document.createElement("script");
    script.src = "https://play-apvertise.com/js/webflow.js";
  },
};
</script>

<style scoped></style>
