class TransactionRequest {

    balanceEnquiry() {
        return {

            accountNumber: null,
        };
    }

    queryTransaction() {
        return {
            customerId: null,
            startDate: null,
            endDate: null,
        };
    }

    reversal() {
        return {
            cbaReference: null,
            module: null,
        };
    }

    fundTransferOutward() {
        return {
            countryCode: null,
            creditAccountBank: null,
            creditAccountName: null,
            creditAccountNumber: null,
            creditAccountPhone: null,
            creditAccountSwitchCode: null,
            debitAccountName: null,
            debitAccountNumber: null,
            ftProductCode: null,
            password: null,
            reference: null,
            source: null,
            trnAmount: null,
            trnNarration: null,
            trnSwitch: null,
            trnSwitchService: null,
            username: null,

        };
    }

    fundTransferInternal() {
        return {
            creditAccountNumber: null,
            debitAccountNumber: null,
            creditAccountName: null,
            ftProductCode: null,
            reference: null,
            source: null,
            username: null,
            password: null,
            countryCode: null,
            trnAmount: null,
            trnNarration: null,
        };
    }

    requestStatement() {
        return {
            startDate: null,
            endDate: null,
            accountNumber: null,
        };
    }

}

export default TransactionRequest;