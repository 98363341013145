import BaseResponse from "@/model/response/BaseResponse";

class AuthResponse extends BaseResponse {
  logon() {
    return {
      custId: null,
      customerCreatedAt: null,
      customerId: null,
      customerPassword: null,
      customerPushId: null,
      customerUpdatedAt: null,
      customerUsername: null,
      customerLoginTrial: null,
      customerStatus: null,
      responseCode: null,
      responseMessage: null,
      loginId: null,
      loginUsername: null,
      loginStatus: null,
      loginIpAddress: null,
      loginLongitude: null,
      loginLatitude: null,
      loginDeviceId: null,
      loginDevicePlatform: null,
      loginDeviceName: null,
      loginAppVersion: null,
      loginProfileStatus: null,
      loginDate: null,
      loginSource: null,
      customerName: null,
      customerBvn: null,
      upgradeCount: null,
      customerAge: null,
      customerPhoneNumber: null,
      customerFirstName: null,
      customerMiddleName: null,
      customerLastName: null,
      customerAddress: null,
      customerEmail: null,
      customerState: null,
      customerCountry: null,
      customerBankCode: null,
      bankCode: null,
      bankName: null,
      bankAddress: null,
      bankCity: null,
      bankState: null,
      bankCountry: null,
      bankNIBSSCode: null,
      bankSource: null,
      bankStatus: null,
      bankCreatedBy: null,
      bankLogo: null,
      bankSortCode: null,
      bankSupportEmail: null,
      bankColor: null,
      bankSupportPhone: null,
      bankType: null,
      customerBranchCode: null,
      customerCreatedDate: null,
      customerUpdatedDate: null,
      customerLastLoginDate: null,
      beneficiary: {
        billBeneficiaries: [],
        frequentBillBeneficiaries: [],
        frequentFtBeneficiaries: [],
        ftBeneficiaries: [],
        responseCode: null,
        responseMessage: null,
      },
      customerPicture: null,
      customerProfilePicture: null,
      customerMandate: null,
      customerUtilityBill: null,
      customerDetailsUpdated: null,
      customerIdCard: null,
      accounts: [
        {
          accountCustomerId: null,
          accountNumber: null,
          accountName: null,
          accountClass: null,
          accountType: null,
          accountCurrency: null,
          accountCurrencySign: null,
          accountBalance: null,
          accountStatus: null,
          accountBankCode: null,
          accountBranchCode: null,
          accountCreatedDate: null,
          accountLedgerBalance: null,
        },
      ],
      transactions: [],
      token: null,
      stage: null,
      flwQgSk: null,
      flwQgPk: null,
      flwQgEn: null,
      customerGender: null,
    };
  }

  logout() {
    return {};
  }

  initiateEnrollment() {
    return {
      emailAccountBalance: null,
      emailAccountName: null,
      emailAccountNumber: null,
      emailBankCode: null,
      emailBody: null,
      emailCbaReference: null,
      emailContent: null,
      emailCounterAccount: null,
      emailCounterParty: null,
      emailCounterPartyBank: null,
      emailDrCr: null,
      emailMaker: null,
      emailAddress: null,
      emailReference: null,
      emailSubject: null,
      emailToken: null,
      emailTrnAmount: null,
      templateCode: null,
      responseCode: null,
      responseMessage: null,
    };
  }

  completeEnrollment() {
    return {
      emailAccountBalance: null,
      emailAccountName: null,
      emailAccountNumber: null,
      emailBankCode: null,
      emailBody: null,
      emailCbaReference: null,
      emailContent: null,
      emailCounterAccount: null,
      emailCounterParty: null,
      emailCounterPartyBank: null,
      emailDrCr: null,
      emailMaker: null,
      emailAddress: null,
      emailReference: null,
      emailSubject: null,
      emailToken: null,
      emailTrnAmount: null,
      templateCode: null,
      responseCode: null,
      responseMessage: null,
    };
  }

  initiateResetPassword() {
    return {
      customerEmail: null,
      responseCode: null,
      responseMessage: null,
    };
  }

  completeResetPassword() {
    return {
      customerEmail: null,
      responseCode: null,
      responseMessage: null,
    };
  }

  changePassword() {
    return {};
  }

  checkPhoneExistence() {
    return {};
  }

  resendOtp() {
    return {};
  }
  readResponse() {
    return {
      responseCode: null,
      responseMessage: null,
      data: [],
    };
  }
}

export default AuthResponse;
