<template>
  <div class="home">
    <dashboard-header v-if="auth.userInfo.responseCode === '00'" />
    <home-header v-else />
    <login-modal />
    <banner-section />
    <top-campaign-section />
    <how-it-works-section />
    <micro-influencer-section />
    <business-section />
    <footer-section />
  </div>
</template>

<script>
import HomeHeader from "@/components/menu/HomeHeader";
import LoginModal from "@/components/modal/LoginModal";
import BannerSection from "@/components/section/BannerSection";
import TopCampaignSection from "@/components/section/TopCampaignSection";
import HowItWorksSection from "@/components/section/HowItWorksSection";
import MicroInfluencerSection from "@/components/section/MicroInfluencerSection";
import BusinessSection from "@/components/section/BusinessSection";
import FooterSection from "@/components/section/FooterSection";
import { mapState } from "vuex";
import DashboardHeader from "@/components/menu/DashboardHeader";

export default {
  name: "Home",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    DashboardHeader,
    // eslint-disable-next-line vue/no-unused-components
    HomeHeader,
    LoginModal,
    BannerSection,
    TopCampaignSection,
    HowItWorksSection,
    MicroInfluencerSection,
    BusinessSection,
    FooterSection,
  },
  computed: {
    ...mapState(["auth"])
  }
};
</script>
