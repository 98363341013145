<template>
  <b-modal
    style="background: white"
    ref="share-link-modal"
    id="link-modal"
    centered
    hide-header
    hide-footer
  >
    <div class="div-block-156">
      <a data-w-id="0e756eac-b69c-ca0d-7ba7-f2b54c7eb6e2" @click="hide()" href="#" class="close-topup-modal w-inline-block"><img src="@/assets/images/close-modal.svg" loading="lazy" alt=""></a>
    </div>
    <!--      <div class="share-campaign-modal-content">-->
    <!--        <div class="div-block-82">-->
    <div style="padding: 8px 20px 16px 20px">
      <h1 class="heading-25" style="margin: 0">Share Link</h1>
    </div>
    <div class="div-block-103" style="padding: 8px 20px">
      <div class="w-layout-grid grid-4">
        <a
          :href="`https://twitter.com/intent/tweet?text=${encodeURIComponent(title)}%0A${encodeURIComponent(description)}%0A%0A` + link"
          class="link-block-13 w-inline-block"
          ><img
            src="@/assets/images/twitter.svg"
            loading="lazy"
            alt=""
            class="image-35"
          />
          <div class="text-block-48">Twitter</div>
        </a>
        <a :href="`fb-messenger://share/?link= ${encodeURIComponent(title)}%0A${encodeURIComponent(description)}%0A%0A${link}&app_id=123456789`" class="link-block-13 w-inline-block"
          ><img
            src="@/assets/images/Facebook.svg"
            loading="lazy"
            alt=""
            class="image-36"
          />
          <div class="text-block-49">Facebook</div>
        </a>
        <a href="#" class="link-block-13 w-inline-block"
          ><img
            src="@/assets/images/Discord.svg"
            loading="lazy"
            alt=""
            class="image-37"
          />
          <div class="text-block-50">Discord</div>
        </a>
        <a
          :href="`whatsapp://send?text=*${title}*%0A${encodeURIComponent(description)}%0A%0A` + link"
          class="link-block-13 w-inline-block"
          ><img
            src="@/assets/images/Whatsapp.svg"
            loading="lazy"
            alt=""
            class="image-38"
          />
          <div class="text-block-51">Whatsapp</div>
        </a>
        <a :href="`https://telegram.me/share/url?url=${link}&text=${encodeURIComponent(title)}%0A${encodeURIComponent(description)}%0A%0A`" class="link-block-13 w-inline-block"
          ><img
            src="@/assets/images/Telegram.svg"
            loading="lazy"
            alt=""
            class="image-39"
          />
          <div class="text-block-52">Telegram</div>
        </a>
        <a :href="`fb-messenger://share/?link=${encodeURIComponent(title)}\n\n${encodeURIComponent(description)}\n\n ${link}&app_id=123456789`" class="link-block-13 w-inline-block"
          ><img
            src="@/assets/images/Messenger.svg"
            loading="lazy"
            alt=""
            class="image-40"
          />
          <div class="text-block-53">Messenger</div>
        </a>
      </div>
    </div>
    <div>
      <div class="text-block-54">Share Link</div>
      <div class="div-block-104">
        <div class="text-block-55 overflow-auto text-nowrap">{{ link }}</div>
        <button
          id="tooltip-button-show-event"
          type="button"
          v-clipboard:copy="link"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
        >
          <img
            style="width: 30px; height: 30px;"
            src="@/assets/images/Copy.svg"
            loading="lazy"
            alt=""
          />
        </button>
      </div>
      <b-tooltip
        ref="tooltip"
        :variant="variant"
        target="tooltip-button-show-event"
      >
        {{ text }}
      </b-tooltip>
    </div>
    <!--        </div>-->
<!--    <a data-w-id="89b8a742-7170-306e-20da-6054dea3dd98"  href="#" @click="hide()" class="close-topup-modal w-inline-block">-->
<!--      <b-icon-x style="width: 35px; height: 35px; color: #63b8a0;"/>-->
<!--    </a>-->
  </b-modal>
</template>

<script>
//style="z-index: 10; background: #ffffff;"
import CampaignRequest from "@/model/request/CampaignRequest";

import { mapState } from "vuex";
import SecurityUtil from "@/utilities/SecurityUtil";
export default {
  name: "ShareCampaignModal",
  props: ["show","customerId", "row", "title", "description", "link"],
  components: {},
  data() {
    return {
      disabled: true,
      model: CampaignRequest.prototype.campaignShareRequest(),
      body: "The text inside this text input will be copied to the clipboard",
      text: "Click to copy",
      variant: "dark",
    };
  },
  methods: {
    hide() {
      this.$refs["share-link-modal"].hide();
    },
    disableByRef() {
      if (this.disabled) {
        this.$refs.tooltip.$emit("enable");
      } else {
        this.$refs.tooltip.$emit("disable");
      }
    },
    async createCompany() {
      this.model.organisationLogo = this.sbucket.s3bucketResponse.url;
      this.model.organisationPhone = this.auth.userInfo.customerPhoneNumber;
      this.model.organisationEmail = this.auth.userInfo.customerEmail;
      this.model.organisationCustomerId = this.auth.userInfo.customerId;
      await this.$store.dispatch("organisation/createOrganisation", this.model);
      await this.$store.dispatch("organisation/updateOrganisations", {
        customerId: this.auth.userInfo.customerId,
      });
      this.hide();
    },
    doCopy: function () {
      this.$copyText(this.link).then(
        function (e) {
          alert("Copied");
          console.log(e);
        },
        function (e) {
          alert("Can not copy");
          console.log(e);
        }
      );
    },
    // eslint-disable-next-line no-unused-vars
    onCopy: function (e) {
      //this.$refs.tooltip.$emit('enable')
      this.variant = "success"
      this.text = "Copied!"
      this.$refs.tooltip.$emit("show");
      let self = this
      window.setTimeout(function(){
        self.variant = "dark"
        self.text = "Click to copy"
        self.$refs.tooltip.$emit("show");
      }, 3000)
      // this.$bvToast.toast("You just copied the link", {
      //   title: `Copy - Success`,
      //   variant: "success",
      //   autoHideDelay: 100000,
      //   solid: true
      // })
    },
    // eslint-disable-next-line no-unused-vars
    onError: function (e) {
      this.$bvToast.toast("Failed to copy the text to the clipboard", {
        title: `Copy - Error`,
        variant: "error",
        autoHideDelay: 100000,
        solid: true,
      });
    },
  },
  mounted() {
  },
  computed: {
    ...mapState(["sbucket", "organisation", "auth", "campaign"]),
    baseUrl: function () {
      return window.__env.api.baseUrl;
    },
    shareUrl: function () {
      return window.__env.api.shareUrl;
    },
    appUrl: function () {
      return window.__env.app.appUrl;
    },
    token: function () {
      return SecurityUtil.prototype.encrypt(JSON.stringify(this.model));
    },
  },
  watch: {
    // whenever question changes, this function will run
    show: function () {
      console.log(this.token);
      this.model.customerId = this.auth.userInfo.customerId;
      this.$refs["share-link-modal"].show();
      this.$bvModal.show("link-modal");
    },
  },
};
</script>

<style scoped>
/*.close-topup-modal {*/
/*  position: absolute;*/
/*  left: auto;*/
/*  top: 0%;*/
/*  right: -7%;*/
/*  bottom: auto;*/
/*  display: block;*/
/*}*/

</style>
