<script>
    import {Doughnut} from 'vue-chartjs'
    export default {
        props:["data","option", "width","height"],
        name: "DoughnutChart",
        extends: Doughnut,
        mounted() {
            this.renderChart(this.data, this.option)
        }
    }
</script>

<style scoped>

</style>