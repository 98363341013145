import client from "./BaseService";

export default {
      callLogonApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("login", payload);
      },

      callLogoutApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("logout", payload);
      },

      callInitiateEnrollmentApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("initiateEnrollment", payload);
      },

      callCompleteEnrollmentApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("completeEnrollment", payload);
      },

      callSocialiteEnrollmentApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("socialiteEnrollment", payload);
      },

      callInitiateResetPasswordApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("initiateResetPass", payload);
      },

      callCompleteResetPasswordApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("completeResetPassword", payload);
      },

      callResendOtpApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("resendOtp", payload);
      },

      callCheckPhoneExistenceApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("checkPhoneExistence", payload);

      },

      callVerifyTokenApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("verifyToken", payload);
      },

      callChangePasswordApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("changePassword", payload);
      },

      callValidateUserTokenApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("validateUserToken", payload);
      },

};
