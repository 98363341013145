import client from "./BaseService";

export default {
  callCreateProductApi(payload) {
    // console.log("Service Payload ==>>", payload)
    return client.apiClient.post("product/create", payload);
  },
  callReadProductApi(payload) {
    // console.log("Service Payload ==>>", payload)
    return client.apiClient.post("product/read", payload);
  },
  callReadProductByOrganisationIdApi(payload) {
    // console.log("Service Payload ==>>", payload)
    return client.apiClient.post("product/read-by-organisation-id", payload);
  },
  callReadProductByIdApi(payload) {
    // console.log("Service Payload ==>>", payload)
    return client.apiClient.post("product/read-by-id", payload);
  },
  callUpdateProductApi(payload) {
    // console.log("Service Payload ==>>", payload)
    return client.apiClient.post("product/update", payload);
  },
  callBuyProductApi(payload) {
    // console.log("Service Payload ==>>", payload)
    return client.apiClient.post("product/buy", payload);
  },
  callDeleteProductApi(payload) {
    // console.log("Service Payload ==>>", payload)
    return client.apiClient.post("product/delete", payload);
  },
};