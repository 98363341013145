// import router from "@/router";
import CardService from "@/service/CardService";
// import CardRequest from "@/model/request/CardRequest";
import CardResponse from "@/model/response/CardResponse";
import swal from "sweetalert";

export const state = {
  card: CardResponse.prototype.readSingleResponse(),
  cards: CardResponse.prototype.readResponse(),
  response: {},
  cardLoading: false,
};

export const getters = {};

export const mutations = {
  updateCard: (state, payload) => {
    state.card = payload;
  },
  updateCards: (state, payload) => {
    state.cards = payload;
  },
  updateCardLoading: (state, payload) => {
    state.cardLoading = payload;
  },
  updateResponse: (state, payload) => {
    state.response = payload;
  },
};

export const actions = {
  rubiesCardFunding: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateCardLoading", true);
    return CardService.callRubiesCardFundingApi(payload)
      .then((response) => {
        commit("updateCardLoading", false);
        let responseData = response.data;
        commit("updateResponse", responseData);
      })
      .catch((err) => {
        commit("updateCardLoading", false);
        console.log(err);
      });
  },
  rubiesCardFundingOtp: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateCardLoading", true);
    return CardService.callRubiesCardFundingOtpApi(payload)
      .then((response) => {
        commit("updateCardLoading", false);
        let responseData = response.data;
        commit("updateResponse", responseData);
      })
      .catch((err) => {
        commit("updateCardLoading", false);
        console.log(err);
      });
  },
  cardDetails: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateCardLoading", true);
    return CardService.callCardDetailsApi(payload)
      .then((response) => {
        commit("updateCardLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          commit("updateCard", responseData);
        } else {
          swal("Error", responseData.responseMessage, "error");
        }
      })
      .catch((err) => {
        commit("updateCardLoading", false);
        console.log(err);
      });
  },

  cardSendPin: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateCardLoading", true);
    return CardService.callCardSendPinApi(payload)
      .then((response) => {
        commit("updateCardLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          commit("updateCards", responseData);
        }
      })
      .catch((err) => {
        commit("updateCardLoading", false);
        console.log(err);
      });
  },

  cardSendOtp: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateCardLoading", true);
    return CardService.callCardSendOtpApi(payload)
      .then((response) => {
        commit("updateCardLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          swal("Success", responseData.responseMessage, "success");
        } else {
          swal("Error", responseData.responseMessage, "error");
        }
      })
      .catch((err) => {
        commit("updateCardLoading", false);
        console.log(err);
      });
  },

  readSavedCard: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateCardLoading", true);
    return CardService.callReadSavedCardApi(payload)
      .then((response) => {
        commit("updateCardLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          swal("Success", responseData.responseMessage, "success");
        } else {
          swal("Error", responseData.responseMessage, "error");
        }
      })
      .catch((err) => {
        commit("updateCardLoading", false);
        console.log(err);
      });
  },

  validateChargeFromSavedCard: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateCardLoading", true);
    return CardService.callValidateChargeFromSavedCardApi(payload)
      .then((response) => {
        commit("updateCardLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          swal("Success", responseData.responseMessage, "success");
        } else {
          swal("Error", responseData.responseMessage, "error");
        }
      })
      .catch((err) => {
        commit("updateCardLoading", false);
        console.log(err);
      });
  },

  updateSavedCardStatus: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateCardLoading", true);
    return CardService.callUpdateSavedCardStatusApi(payload)
      .then((response) => {
        commit("updateCardLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          swal("Success", responseData.responseMessage, "success");
        } else {
          swal("Error", responseData.responseMessage, "error");
        }
      })
      .catch((err) => {
        commit("updateCardLoading", false);
        console.log(err);
      });
  },

  requestDebitCard: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateCardLoading", true);
    return CardService.callRequestDebitCardApi(payload)
      .then((response) => {
        commit("updateCardLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          swal("Success", responseData.responseMessage, "success");
        } else {
          swal("Error", responseData.responseMessage, "error");
        }
      })
      .catch((err) => {
        commit("updateCardLoading", false);
        console.log(err);
      });
  },

  readCustomerCard: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateCardLoading", true);
    return CardService.callReadCustomerCardApi(payload)
      .then((response) => {
        commit("updateCardLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          swal("Success", responseData.responseMessage, "success");
        } else {
          swal("Error", responseData.responseMessage, "error");
        }
      })
      .catch((err) => {
        commit("updateCardLoading", false);
        console.log(err);
      });
  },
};
