<template>
  <div class="right-section">
    <div class="login-form-container">
      <div class="logo-container-copy"><img src="@/assets/images/Logo.svg" loading="lazy" alt="" class="logo"></div>
      <div class="login-header">
        <h1 class="heading-20">Login to your account</h1>
        <socialite-auth-button/>
        <div class="divide">
          <div class="div-block-216"></div>
          <div class="div-block-216-copy">
            <div class="text-block-122">OR</div>
          </div>
          <div class="div-block-216"></div>
        </div>
      </div>
      <login-form/>
      <div class="sign-up-block">
        <router-link to="/register" class="link-block-6 w-inline-block">
          <div class="text-block-22">Not a member? <span class="text-span-3">Sign Up</span></div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import LoginForm from "@/components/form/LoginForm";
import SocialiteAuthButton from "@/components/form/input/SocialiteAuthButton";
export default {
  name: "Logon",
  components:{
    LoginForm,
    SocialiteAuthButton
  }
};
</script>

<style scoped>

</style>