// import router from "@/router";
import InvestmentService from "@/service/InvestmentService";
// import InvestmentRequest from "@/model/request/InvestmentRequest";
import InvestmentResponse from "@/model/response/InvestmentResponse";
import swal from 'sweetalert'

export const state = {
    investment: InvestmentResponse.prototype.readSingleResponse(),
    investments: InvestmentResponse.prototype.readResponse(),
    investmentLoading: false,
};
export const getters = {};
export const mutations = {
    updateInvestment: (state, payload) => {
        state.investment = payload;
    },
    updateInvestments: (state, payload) => {
        state.investments = payload;
    },
    updateInvestmentLoading: (state, payload) => {
        state.investmentLoading = payload;
    },
};
export const actions = {
    createFixedDeposit: ({ commit }, payload) => {
        //TODO USER LOADING
        commit("updateInvestmentLoading", true);
        return InvestmentService.callCreateFixedDepositApi(payload)
            .then((response) => {
                commit("updateInvestmentLoading", false);
                let responseData = response.data;
                if (responseData.responseCode === "00") {
                    commit("updateInvestment", responseData);
                }else{
                    swal("Error", responseData.responseMessage, "error");
                }
            })
            .catch((err) => {
                commit("updateInvestmentLoading", false);
                console.log(err);
            });
    },

    createFlexSavings: ({ commit }, payload) => {
        //TODO USER LOADING
        commit("updateInvestmentLoading", true);
        return InvestmentService.callCreateFlexSavingsApi(payload)
            .then((response) => {
                commit("updateInvestmentLoading", false);
                let responseData = response.data;
                if (responseData.responseCode === "00") {
                    commit("updateInvestments", responseData);
                }
            })
            .catch((err) => {
                commit("updateInvestmentLoading", false);
                console.log(err);
            });
    },

    createSavings: ({ commit }, payload) => {
        //TODO USER LOADING
        commit("updateInvestmentLoading", true);
        return InvestmentService.callCreateSavingsApi(payload)
            .then((response) => {
                commit("updateInvestmentLoading", false);
                let responseData = response.data;
                if (responseData.responseCode === "00") {
                    swal("Success", responseData.responseMessage, "success");
                }else{
                    swal("Error", responseData.responseMessage, "error");
                }
            })
            .catch((err) => {
                commit("updateInvestmentLoading", false);
                console.log(err);
            });
    },

    readInvestments: ({ commit }, payload) => {
        //TODO USER LOADING
        commit("updateInvestmentLoading", true);
        return InvestmentService.callReadInvestmentsApi(payload)
            .then((response) => {
                commit("updateInvestmentLoading", false);
                let responseData = response.data;
                if (responseData.responseCode === "00") {
                    swal("Success", responseData.responseMessage, "success");
                }else{
                    swal("Error", responseData.responseMessage, "error");
                }
            })
            .catch((err) => {
                commit("updateInvestmentLoading", false);
                console.log(err);
            });
    },

    topUpInvestments: ({ commit }, payload) => {
        //TODO USER LOADING
        commit("updateInvestmentLoading", true);
        return InvestmentService.callTopUpInvestmentsApi(payload)
            .then((response) => {
                commit("updateInvestmentLoading", false);
                let responseData = response.data;
                if (responseData.responseCode === "00") {
                    swal("Success", responseData.responseMessage, "success");
                }else{
                    swal("Error", responseData.responseMessage, "error");
                }
            })
            .catch((err) => {
                commit("updateInvestmentLoading", false);
                console.log(err);
            });
    },

    liquidateInvestments: ({ commit }, payload) => {
        //TODO USER LOADING
        commit("updateInvestmentLoading", true);
        return InvestmentService.callLiquidateInvestmentsApi(payload)
            .then((response) => {
                commit("updateInvestmentLoading", false);
                let responseData = response.data;
                if (responseData.responseCode === "00") {
                    swal("Success", responseData.responseMessage, "success");
                }else{
                    swal("Error", responseData.responseMessage, "error");
                }
            })
            .catch((err) => {
                commit("updateInvestmentLoading", false);
                console.log(err);
            });
    },

    readInvestmentsParams: ({ commit }, payload) => {
        //TODO USER LOADING
        commit("updateInvestmentLoading", true);
        return InvestmentService.callReadInvestmentsParamsApi(payload)
            .then((response) => {
                commit("updateInvestmentLoading", false);
                let responseData = response.data;
                if (responseData.responseCode === "00") {
                    swal("Success", responseData.responseMessage, "success");
                }else{
                    swal("Error", responseData.responseMessage, "error");
                }
            })
            .catch((err) => {
                commit("updateInvestmentLoading", false);
                console.log(err);
            });
    },

    readInterests: ({ commit }, payload) => {
        //TODO USER LOADING
        commit("updateInvestmentLoading", true);
        return InvestmentService.callReadInterestsApi(payload)
            .then((response) => {
                commit("updateInvestmentLoading", false);
                let responseData = response.data;
                if (responseData.responseCode === "00") {
                    swal("Success", responseData.responseMessage, "success");
                }else{
                    swal("Error", responseData.responseMessage, "error");
                }
            })
            .catch((err) => {
                commit("updateInvestmentLoading", false);
                console.log(err);
            });
    },
};


