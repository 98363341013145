<template>
    <b-modal
      style="background: white;"
      ref="create-company-modal-new"
      centered
      hide-header
      hide-footer
    >
      <div class="div-block-156">
        <a data-w-id="0e756eac-b69c-ca0d-7ba7-f2b54c7eb6e2" @click="hide()" href="#" class="close-topup-modal w-inline-block"><img src="@/assets/images/close-modal.svg" loading="lazy" alt=""></a>
      </div>
      <div style="padding: 30px; background: white;">
        <h1 class="heading-28">Add new company</h1><br>
        <form id="email-form" name="email-form" data-name="Email Form" @submit.prevent="createCompany" class="form-4">
          <div class="div-block-146">
            <label for="name" class="field-label-15">Company Name </label>
            <input type="text" v-model="model.organisationName" required class="text-field-17 w-input" maxlength="256" name="name" data-name="Name" placeholder="Enter company name" id="name">
          </div>
          <div>
            <label for="field" class="field-label-15">Company type </label>
            <select v-model="model.organisationType" id="field" required name="field" class="select-field-3 w-select">
              <option value="">Select company type</option>
              <option v-for="category in organisation.organisationTypes.data" v-bind:key="category.value" :value="category.value">
                {{ category.label }}</option>
            </select>
          </div>
          <div>
            <upload-single-image-field name="logo"/>
          </div><br/>
          <base-button title="Submit" :loading="organisation.organisationLoading"/>
        </form>
      </div>
<!--      <a data-w-id="89b8a742-7170-306e-20da-6054dea3dd98"  href="#" @click="hide()" class="close-topup-modal w-inline-block">-->
<!--        <b-icon-x style="width: 35px; height: 35px; color: #63b8a0;"/>-->
<!--      </a>-->
    </b-modal>
</template>

<script>
//style="z-index: 10; background: #ffffff;"
import UploadSingleImageField from "@/components/form/input/UploadSingleImageField";
import OrganisationRequest from "@/model/request/OrganisationRequest";
import OrganisationResponse from "@/model/response/OrganisationResponse";

import { mapState } from "vuex";
import BaseButton from "@/components/button/BaseButton";
export default {
  name: "CompanyCreateModal",
  props: ["show"],
  components: { BaseButton, UploadSingleImageField },
  data(){
    return {
      model: OrganisationRequest.organisationCreateRequest,
    }
  },
  methods: {
    hide() {
      this.$refs["create-company-modal-new"].hide();
    },
    async createCompany() {
      this.model.organisationLogo = this.sbucket.s3bucketResponse.url;
      this.model.organisationPhone = this.auth.userInfo.customerPhoneNumber;
      this.model.organisationEmail = this.auth.userInfo.customerEmail;
      this.model.organisationCustomerId = this.auth.userInfo.customerId;
      await this.$store.dispatch("organisation/createOrganisation", this.model);
      await this.$store.dispatch("organisation/updateOrganisations", { customerId: this.auth.userInfo.customerId});
      this.hide()
    }
  },
  computed:{
    ...mapState(["sbucket", "organisation", "auth"]),
    categories: function(){
      return new OrganisationResponse().readOrganisationCategoryResponse().data
    }
  },
  watch: {
    // whenever question changes, this function will run
    show: function () {
      this.$refs["create-company-modal-new"].show();
    },
  },
};
</script>

<style>
/*.close-topup-modal {*/
/*  position: absolute;*/
/*  left: auto;*/
/*  top: 0%;*/
/*  right: 0%;*/
/*  bottom: auto;*/
/*  display: block;*/
/*}*/
</style>