import client from "@/service/BaseService";

export const state = {
  overlayLoading: false,
  showMessage: false,
  message: {
    title: "",
    body: "",
  },
};

export const getters = {};

export const mutations = {
  updateOverlayLoading: (state, payload) => {
    state.overlayLoading = payload;
  },
  updateMessage: (state, payload) => {
    state.message = payload;
    state.showMessage = !state.showMessage
  },
  updateShowMessage: (state) => {
    state.showMessage = !client.apiClient;
  },
};

export const actions = {};
