class OrderResponse {
  orderCreateResponse() {
    return {
      responseMessage: null,
      responseCode: null,
      orderId: null,
    };
  }
  orderReadResponse() {
    return {
      responseCode: null,
      responseMessage: null,
      data: [
        {
          orderId: null,
          orderOrganisationId: null,
          orderCustomerEmail: null,
          orderCustomerPhoneNumber: null,
          orderProductId: null,
          orderUnitLocalPrice: null,
          orderUnitUsdPrice: null,
          orderQuantity: null,
          orderConfirmationCode: null,
          orderCurrency: null,
          orderDeliveryAddress: null,
          orderStatus: null,
          orderCreatedAt: null,
          orderUpdatedAt: null,
          productId: null,
          productOrganisationId: null,
          productName: null,
          productDescription: null,
          productLocalCurrencyPrice: null,
          productUsdPrice: null,
          productQuantity: null,
          productMinDeliveryTime: null,
          productMaxDeliveryTime: null,
          productImage: null,
          productStatus: null,
          productCreatedAt: null,
          productUpdatedAt: null
        },
      ],
    };
  }
  orderReadByOrgResponse() {
    return {
      responseCode: null,
      responseMessage: null,
      data: [],
    };
  }
 static orderReadByIdResponse = {
   responseMessage: null,
   responseCode: null,
   orderId: null,
   orderOrganisationId: null,
   orderCustomerEmail: null,
   orderCustomerPhoneNumber: null,
   orderProductId: null,
   orderUnitLocalPrice: null,
   orderUnitUsdPrice: null,
    orderQuantity: null,
    orderDeliveryAddress: null,
    orderStatus: null,
    orderCreatedAt: null,
   orderUpdatedAt: null,
   productId: null,
   productOrganisationId: null,
   productName: null,
   productDescription: null,
   productLocalCurrencyPrice: null,
   productUsdPrice: null,
   productQuantity: null,
   productMinDeliveryTime: null,
   productMaxDeliveryTime: null,
   productImage: null,
   productStatus: null,
   productCreatedAt: null,
   productUpdatedAt: null
  }
  orderUpdateResponse() {
    return {
      responseCode: null,
      responseMessage: null,
    };
  }
  orderDeleteResponse() {
    return {
      responseCode: null,
      responseMessage: null,
    };
  }
}

export default OrderResponse;