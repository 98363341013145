import Wallet from "@/views/wallet/Wallet";

export default [
  {
    path: "/view-wallet",
    name: "Wallet",
    meta: { layout: "dashboard", authRequired: true },
    component: Wallet,
  },
];
