<template>
  <div class="section-3 wf-section">
    <div class="t-c-container">
      <div class="header-t-c">
        <h1 class="heading-36">Terms &amp; Conditions for Companies</h1>
        <div class="text-block-94">Last updated: October 28, 2021</div>
      </div>
      <div class="div-block-169">
        <div class="text-block-95">These Agency / Brand Terms and Conditions constitute a legally binding agreement between the Agency or Brand and APVERTISE concerning the access and use of our platform and associated services. The Agency / Brand Agreement is concluded by a representative of the Agency or Brand accepting these Agency / Brand terms and Conditions through the APVERTISE Platform. <br>‍<br>Hereafter you will agree to the following:</div>
      </div>
      <div class="table-of-contents">
        <h1 class="heading-37">Table Of Contents</h1>
        <ol role="list" class="list-2">
          <li class="list-item">Table of contents</li>
          <li class="list-item">Definitions</li>
          <li class="list-item">What Apvertise is</li>
          <li class="list-item">The Services of Apvertise</li>
          <li class="list-item">Campaigns and Assignments</li>
          <li class="list-item">Intellectual Property Rights</li>
          <li class="list-item">Financial Agreements</li>
          <li class="list-item">Privacy and Processing Personal Data</li>
          <li class="list-item">Liability, Warranties and Indemnifications</li>
          <li class="list-item">Duration and Termination</li>
          <li class="list-item">Miscellaneous</li>
          <li class="list-item">Governing Law and Jurisdiction</li>
        </ol>
        <div class="text-block-95">In these Agency / Brand Terms and Conditions, words are used which start with a capital letter. Those words have the meaning as set out hereunder in this article.</div>
      </div>
      <div class="definitions">
        <h1 class="heading-37">Definitions</h1>
        <ol role="list" class="list-2">
          <li class="list-item">Agreement: the agreement as mentioned in the preface of these Influencer Terms and Conditions. The Agreement is confirmed through a confirmation e-mail.</li>
          <li class="list-item">Assignment: an Agency or Brand assignment for an influencer to contribute to a Campaign.</li>
          <li class="list-item">Agency: means an agency who acts on behalf of a Brand, other party in the agency / brand agreement. Usually the agency runs an overall campaign for a Brand, in which campaign there are smaller influencer marketing campaigns.</li>
          <li class="list-item">Campaign: an influencer marketing campaign as made available for Influencer by Agency or Brand.</li>
          <li class="list-item">Collaboration Agreement: the agreement between Influencer and the Agency or Brand for an Assignment. Creation of campaign constitutes an offer, sharing of the campaign constitutes acceptance and a Collaboration contract is therefore executed automatically.</li>
          <li class="list-item">Influencer: someone who is able to endorse a service or product of a Brand through his or her social media account(s) or weblog(s).</li>
          <li class="list-item">APVERTISE Platform: the website, the marketplace and -if any- the mobile app of APVERTISE.</li>
          <li class="list-item">APVERTISE: the database of APVERTISE approved Influencers.</li>
          <li class="list-item">APVERTISE:: refers to, the United Kingdom company “APVERTISE Limited”.</li>
        </ol>
      </div>
      <div class="what-apvertise-is">
        <h1 class="heading-37">What Apvertise Is</h1>
        <ol role="list" class="list-2">
          <li class="list-item">Agency / Brand acknowledges and agrees that APVERTISE is a platform bringing Brands or Agencies and Influencers together, and that APVERTISE is therefore never a party in the actual Collaboration Agreement between an Agency or Brand and Influencer. APVERTISE cannot be held responsible nor liable for shortcomings of Influencer regarding the (non) performance of the Collaboration Agreement.</li>
          <li class="list-item">We do help the Brands or Agencies with the payments based on the number of times the influencer is able to share the brand’s campaign through the influencer’s social media network. The billing and payment is automatically completed by the Apvertise platform.</li>
        </ol>
      </div>
      <div class="the-services-of-apvertise">
        <h1 class="heading-37">The Services Of Apvertise</h1>
        <ol role="list" class="list-2">
          <li class="list-item">APVERTISE grants Agency or Brand access to the functionality of the APVERTISE Platform and has access to its Influencer Network. For the duration of the Agreement, Agency or Brand may use the functionality of the APVERTISE Platform and has access to its Influencer Network, provided that the Subscription Fee or Single Campaign Fee is paid. In case the Agreement is entered into for a Single Campaign, the access is limited to one Campaign only.</li>
          <li class="list-item">APVERTISE supports Agency or Brand with the listing of a Campaign and/or Assignment and the functionality of the APVERTISE Platform. Support is rendered without any warranty regarding response times. Support is therefore rendered by APVERTISE “best effort”. Support is rendered without additional charge. Upon first request of Agency or Brand, APVERTISE is willing to make an offer to enter into a service level agreement.</li>
        </ol>
      </div>
      <div class="campaigns-assignments">
        <h1 class="heading-37">Campaigns &amp; Assignments</h1>
        <ol role="list" class="list-2">
          <li class="list-item">Brand or Agency is responsible to create a Campaign and Assignments itself. Campaigns and Assignments should be: (i) clearly formulated; (ii) in accordance with the APVERTISE Code of Conduct; (iii) in accordance with Brand or Agency local laws or regulations regarding influencer marketing. APVERTISE is allowed to disapprove a Campaign or Assignment in case it deems a Campaign or Assignment not in accordance with the aforementioned requirements. However, in that case APVERTISE supports Agency or Brand as stated in paragraph 4.2 of these Agency / Brand Terms and Conditions.</li>
          <li class="list-item">In case Agency or Brand approves the application of an Influencer for an Assignment as mentioned in the previous paragraph, where applicable, Agency or Brand has entered into a Collaboration Agreement by simply submitting a campaign on APVERTISE. That agreement is between Influencer and the Agency or Brand. APVERTISE is not a party in that agreement..</li>
          <li class="list-item">4.5 In case an Assignment Agency or Brand has created through the APVERTISE Platform is approved by APVERTISE, APVERTISE immediately charges Agency or Brand with the by Agency or Brand set reward(s). The amount due is set off against the available APVERTISE Credits.</li>
        </ol>
      </div>
      <div class="what-it-takes-to-become-an-influencer">
        <h1 class="heading-37">Intellectual Property Rights</h1>
        <ol role="list" class="list-2">
          <li class="list-item">The intellectual property rights regarding the APVERTISE Platform, the tradenames and trademarks of APVERTISE, remain vested in APVERTISE.</li>
          <li class="list-item">The intellectual property rights regarding the material Agency or Brands put at the disposal APVERTISE or Influencer through the APVERTISE Platform, remain vested in the Agency, Brand or any third party from whom Agency or Brand acquired a license.</li>
        </ol>
      </div>
      <div class="financial-agreements">
        <h1 class="heading-37">Financial Agreements</h1>
        <ol role="list" class="list-2">
          <li class="list-item">APVERTISE is entitled to increase the Fees. However, it will notify Agency or Brand in due time. Running subscriptions will not be affected by the increase, therefore, only renewals will be affected by any increase.</li>
          <li class="list-item">For Campaigns and Assignments, Agency or Brand must acquire APVERTISE Credits. APVERTISE charges a transaction fee of 5% on the campaign budget.</li>
          <li class="list-item">APVERTISE will not provide Agency or Brand with any interest regarding the APVERTISE Credits. The APVERTISE Credits lapse after one year of acquiring, without Agency or Brand being entitled to a refund.</li>
        </ol>
      </div>
      <div class="privacy-processing-personal-data">
        <h1 class="heading-37">Privacy &amp; Processing Personal Data</h1>
        <ol role="list" class="list-2">
          <li class="list-item">To fulfill the obligations under the Agreement, APVERTISE has to process the personal data of the users of Agency or Brand. Under the General Data Protection Regulation (“GDPR”), APVERTISE is controller. Agency or Brand is also a controller under the GDPR. Therefore, parties do not enter into a data processing agreement with each other.</li>
          <li class="list-item">APVERTISE processes personal data in accordance with the separate privacy policy of APVERTISE. APVERTISE may change the privacy policy from time to time. Please, take notice of the privacy policy on a regular basis.</li>
        </ol>
      </div>
      <div class="liability-warranties-indemnifications">
        <h1 class="heading-37">Liability, Warranties &amp; Indemnifications</h1>
        <ol role="list" class="list-2">
          <li class="list-item">INFLUENCER FOR YOU IS NOT LIABLE FOR ANY ATTRIBUTABLE SHORTCOMINGS, NOR FOR ANY OTHER SHORTCOMINGS (BASED ON TORT FOR EXAMPLE), UNLESS THE SHORTCOMING IS DUE TO WILLFUL MISCONDUCT OR CONSCIOUS RECKLESSNESS OF THE TOP LEVEL MANAGEMENT OF INFLUENCER FOR YOU. IN CASE THIS LIMITATION OF LIABILITY IS LAWFULLY UNENFORCEABLE, THE LIABILITY FOR THE AFOREMENTIONED SHORTCOMINGS ARE LIMITED TO WHAT IS CHARGED BY INFLUENCER FOR YOU UNDER THE AGREEMENT DURING A PERIOD OF TWELVE (12) MONTHS PRIOR TO THE DAMAGE CAUSING EVENT, V.A.T. AND INFLUENCER FOR YOU CREDITS EXCLUDED, UNLESS THE SHORTCOMING IS DUE TO WILLFUL MISCONDUCT OR CONSCIOUS RECKLESSNESS OF THE TOP LEVEL MANAGEMENT OF INFLUENCER FOR YOU.</li>
          <li class="list-item">Agency or Brand hereby accepts the functionality of the APVERTISE Platform “as is”. Agency or Brand also hereby accepts that the functionality of the APVERTISE Platform may be subject of unilateral change, since APVERTISE tries to make it better along the way.</li>
          <li class="list-item">APVERTISE strives for a high availability of the APVERTISE Platform, however, APVERTISE cannot warrant that the APVERTISE Platform is always available for Agency or Brand without interruptions.</li>
          <li class="list-item">Agency or Brand hereby indemnifies APVERTISE for all costs and damages in case of Agency or Brand shortcomings regarding the execution of the Collaboration Agreement.</li>
        </ol>
      </div>
      <div class="duration-termination">
        <h1 class="heading-37">Duration &amp; Termination</h1>
        <ol role="list" class="list-2">
          <li class="list-item">The Influencer Agreement is entered into for the duration of each campaign.</li>
          <li class="list-item">You may always terminate the Influencer Agreement, just by terminating Your account.</li>
          <li class="list-item">In case We owe You money, we will make sure that We settle the outstanding amounts as We agreed upon in paragraph 7. Be aware of the fact that You always must finish any running Assignments. Terminating the Influencer Agreement does not mean you are exempted from any running Assignments.</li>
          <li class="list-item">We may terminate the Influencer Agreement at any time for any reason.</li>
        </ol>
      </div>
      <div class="miscellaneous">
        <h1 class="heading-37">Miscellaneous</h1>
        <ol role="list" class="list-2">
          <li class="list-item">The account credentials You use to have access to the APVERTISE Platform are strictly personal. You cannot share those with others.</li>
          <li class="list-item">We are entitled to transfer Our rights and obligations out of the Influencer Agreement to a third party. This is especially allowed in case of a transfer due to a change of legal entity, transfer of Our business or merger of Our business. You hereby grant Your permission to us to do so. A possible transfer is without You losing any rights under the Influencer Agreement.</li>
          <li class="list-item">Without prior permission of Us, You are not allowed to transfer the rights and obligations under the Influencer Agreement to a third party. The permission can by subject to additional stipulations, for example the stipulation that the transfer must be made free of any charge.</li>
          <li class="list-item">We may change these influencer Terms and Conditions unilaterally. We make sure we communicate any changes in due time. In case you don’t feel comfortable with the changes, you are entitled to terminate the Influencer Agreement as stated in paragraph 10.2.</li>
          <li class="list-item">To the extent that any provision of these Influencer Terms and Conditions are void and/or annulled, all other conditions between parties remain in force. In such a case, We will agree to a new set of provisions, which will correspond as much as possible to the void or annulled conditions.</li>
          <li class="list-item">Headings and captions used in these Influencer Terms and Conditions are for reference purposes only.</li>
        </ol>
      </div>
      <div class="governing-law-and-jurisdiction">
        <h1 class="heading-37">Governing Law And Jurisdiction</h1>
        <ol role="list" class="list-2">
          <li class="list-item">The Influencer Agreement and Influencer Terms and Conditions are governed by the laws of the United Kingdom.</li>
          <li class="list-item">All disputes will solely be settled exclusively by the competent court in England.</li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompanyTCSection"
};
</script>

<style scoped>

</style>