class ReviewRequest {
  static ReviewCreateRequest = {
    reviewOrderId: null,
    reviewRating: null,
    reviewTopic: null,
    reviewBody: null,

  }
  ReviewReadRequest() {
    return {
      readAll: "YES",
    };
  }
  ReviewReadSingleRequest() {
    return {
      reviewId: null,
    };
  }
 static ReviewReadByProductIdRequest() {
    return {
      productId: null,
    };
  }
}

export default ReviewRequest;
