<template>
  <b-modal ref="order-modal"
           centered
           hide-header
           hide-footer>
    <div class="modal-body">
  <div class="modal orderdetails">
    <div class="modalpopupsmall walletpaymentmodal w-clearfix">
      <a data-w-id="dc870d06-4419-e0eb-786a-29a89f37fdfe" @click="hide()" href="#" class="link-13"></a>
      <div>
        <div class="walletmodalheader order-details">Order Details</div>
        <div class="div-block-278">
          <img :src=" currentOrder.productImage.split(',')[0]" loading="lazy" width="91" alt="" class="image-69">
          <div> {{currentOrder.productName}}</div>
        </div>
        <div class="div-block-281">
          <div class="detail">
            <div class="div-block-279">
              <div class="detail-header">Order Number</div>
              <div class="detail-body">#{{ currentOrder.orderId }}</div>
            </div>
            <div class="div-block-280">
              <div class="detail-header">Order Status</div>
              <div class="detail-body">{{ currentOrder.orderId }}</div>
            </div>
          </div>
          <div class="detail">
            <div class="div-block-279">
              <div class="detail-header">Amount Paid (Local Currency)</div>
              <div class="detail-body">NGN {{ currentOrder.orderUnitLocalPrice }}</div>
            </div>
            <div class="div-block-280">
              <div class="detail-header">Amount Paid (USD)</div>
              <div class="detail-body">USD {{ currentOrder.orderUnitUsdPrice }}</div>
            </div>
          </div>
          <div class="detail">
            <div class="div-block-279">
              <div class="detail-header">Currency Paid In</div>
              <div class="detail-body">NGN</div>
            </div>
            <div class="div-block-280">
              <div class="detail-header">Quantity</div>
              <div class="detail-body">{{ currentOrder.orderQuantity }}</div>
            </div>
          </div>
          <div class="w-form">
            <form id="email-form" name="email-form" data-name="Email Form" method="get">
              <label for="name" class="order-detail-label">Order PIN</label>
              <input type="text" class="order-detail-field w-input" maxlength="256" name="name" data-name="Name" placeholder="Input Order PIN" id="name">
              <input type="submit" value="Validate Order" data-wait="Please wait..." class="apvertise-button w-button">
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
    </div>
  </b-modal>
</template>

<script>
import OrderRequest from "@/model/request/OrderRequest";
import { mapState } from "vuex";

export default {
  name: "OrderModal",
  data(){
    return{
      model: OrderRequest.orderReadByIdRequest,
    }
  },
  props: ["show"],
  computed: {
    ...mapState({
      order: state => state.order,
      currentOrder: state => state.order.singleOrder
    })
  },
  methods: {
    hide() {
      this.$refs["order-modal"].hide();
    },
    doSomething() {
      this.model.orderId = localStorage.orderId;
      this.$store.dispatch("order/updateSingleOrder", this.model);
        console.log('hello');
      }
  },
  watch: {
    // whenever question changes, this function will run
    show:  function () {
      this.doSomething()
      this.$refs["order-modal"].show();
    },
  },
  mounted() {

    const plugin = document.createElement("script");
    plugin.setAttribute("src", "https://play-apvertise.com/js/webflow.js");
    plugin.async = true;
    document.head.appendChild(plugin);
  },
  created() {
    const script = document.createElement("script");
    script.src = "https://play-apvertise.com/js/webflow.js";
  }
};
</script>

<style scoped>

</style>