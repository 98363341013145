import { render, staticRenderFns } from "./MallNewUser.vue?vue&type=template&id=90577bd0&scoped=true&"
import script from "./MallNewUser.vue?vue&type=script&lang=js&"
export * from "./MallNewUser.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "90577bd0",
  null
  
)

export default component.exports