<template>
  <div class="loader">
    <!--  <div>-->
    <!--    <p id="demo"></p>-->
    <!--    <button @click="getLocation()">jhjjhjjkk</button>-->
    <div class="loader-inner">
      <img src="@/assets/images/apvertise_loader.gif" style="width: 1000px" />
    </div>
  </div>
</template>

<script>
// import store from "../../store/store";
import { mapState } from "vuex";

export default {
  name: "Preloader",
  data() {
    return {
      finger: "",
      location: null,
      gettingLocation: false,
      errorStr: null,
    };
  },
  beforeCreate() {
    console.log(this.$fingerprint);
    this.$fingerprint.get((components) => {
      this.finger = this.$fingerprint.x64hash128(
        components
          .map((pair) => {
            return pair.value;
          })
          .join(),
        31
      );
      console.log(this.finger);
      this.$store.commit("auth/updateDeviceId", this.finger);
    });
  },
  mounted() {
    this.getLocation();
    //https://p.apvertise.com/share/NTY5MTI4Njk2Mzc1
    console.log(this.$route.params.reference);
    // let reference = this.$route.params.reference;
    // this.$store.dispatch("campaign/campaignSettlement", {
    //   reference: reference,
    // });
  },
  methods: {
    getLocation() {
      let x = document.getElementById("demo");
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.showPosition);
      } else {
        x.innerHTML = "Geolocation is not supported by this browser.";
      }
    },
    showPosition(position) {
      document.cookie = `latitude=${position.coords.latitude}`;
      document.cookie = `longitude=${position.coords.longitude}`;
      let latLng = btoa(
        `${position.coords.latitude},${position.coords.longitude}`
      );
      let reference = this.$route.params.reference;
      window.location = `${window.__env.api.baseUrl}final-share/${reference}?q=${latLng}`;
    },
  },
  computed: {
    ...mapState(["auth"]),
  },
};

</script>

<style scoped>
.loader {
  background-color: white;
  opacity: 0.9;
  /*background: radial-gradient(#fff, #63b8a0);*/
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99999;
}

.loader-inner {
  bottom: 0;
  height: 400px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 400px;
}

.loader-line-wrap {
  animation: spin 2000ms cubic-bezier(0.175, 0.885, 0.32, 1.275) infinite;
  box-sizing: border-box;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  opacity: 0.5;
  top: 0;
  transform-origin: 50% 100%;
  width: 100%;
}
.loader-line {
  border: 4px solid transparent;
  border-radius: 100%;
  box-sizing: border-box;
  height: 100px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 100px;
}
.loader-line-wrap:nth-child(1) {
  animation-delay: -50ms;
}
.loader-line-wrap:nth-child(2) {
  animation-delay: -100ms;
}
.loader-line-wrap:nth-child(3) {
  animation-delay: -150ms;
}
.loader-line-wrap:nth-child(4) {
  animation-delay: -200ms;
}
.loader-line-wrap:nth-child(5) {
  animation-delay: -250ms;
}

.loader-line-wrap:nth-child(1) .loader-line {
  border-color: hsl(0, 80%, 60%);
  height: 90px;
  width: 90px;
  top: 7px;
}
.loader-line-wrap:nth-child(2) .loader-line {
  border-color: hsl(60, 80%, 60%);
  height: 76px;
  width: 76px;
  top: 14px;
}
.loader-line-wrap:nth-child(3) .loader-line {
  border-color: hsl(120, 80%, 60%);
  height: 62px;
  width: 62px;
  top: 21px;
}
.loader-line-wrap:nth-child(4) .loader-line {
  border-color: hsl(180, 80%, 60%);
  height: 48px;
  width: 48px;
  top: 28px;
}
.loader-line-wrap:nth-child(5) .loader-line {
  border-color: hsl(240, 80%, 60%);
  height: 34px;
  width: 34px;
  top: 35px;
}

@keyframes spin {
  0%,
  15% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
