class OrderRequest {
  orderCreateRequest() {
    return {
      orderOrganisationId: null,
      orderCustomerEmail: null,
      orderCustomerPhoneNumber: null,
      orderProductId: null,
      orderQuantity: null,
      orderDeliveryAddress: null,
      orderCurrency: null,
    };
  }
  orderReadRequest() {
    return {
      readAll: "YES",
    };
  }
  static orderReadByOrgRequest = {
    orderOrganisationId: null,
  };
  static orderReadByIdRequest = {
    orderId: null,
  };
   static orderUpdateRequest = {
    orderId: null,
    orderOrganisationId: null,
    orderDeliveryAddress: null,
    orderCustomerEmail: null,
    orderProductId: null,
    orderCustomerPhoneNumber: null,
    orderQuantity: null,
    orderCurrency: null,
  }
  orderDeleteRequest() {
    return {
      orderId: null,
    };
  }
}

export default OrderRequest;
