<template>
  <div class="div-block-122">
    <div>
      <h1 class="heading-27-copy">Change Password</h1>
    </div>
    <div>
      <div class="w-form">
        <form
          @submit.prevent="changePassword"
          id="email-form-2"
          name="email-form-2"
          data-name="Email Form 2"
        >
          <div class="old-password-block">
            <label for="Old-Password" class="old-password-textfield"
              >Enter old password</label
            >
            <input
              v-model="model.oldPassword"
              type="password"
              @keypress="isNumber"
              class="text-field-18 w-input"
              minlength="6"
              maxlength="6"
              name="Old-Password"
              data-name="Old Password"
              placeholder=""
              id="Old-Password"
              required=""
            />
          </div>
          <div class="new-password">
            <label for="new-Password" class="new-password-textfield"
              >Enter new password</label
            >
            <input
              v-model="model.password"
              type="password"
              @keypress="isNumber"
              class="text-field-18-copy w-input"
              minlength="6"
              maxlength="6"
              name="new-Password"
              data-name="new Password"
              placeholder=""
              id="new-Password"
              required=""
            />
          </div>
          <div class="confirm-new-password">
            <label for="confirm-password" class="confirm-new-password-textfield"
              >Confirm new password</label
            >
            <input
              v-model="model.passwordConfirmation"
              type="password"
              @keypress="isNumber"
              class="confirm-password w-input"
              minlength="6"
              maxlength="6"
              name="confirm-password"
              data-name="confirm password"
              placeholder=""
              id="confirm-password"
              required=""
            />
          </div>
          <base-button
            class="submit-button-11 w-button"
            title="Change Password"
            :loading="auth.authLoading"
          />
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import AuthRequest from "@/model/request/AuthRequest";
import { mapState } from "vuex";
import BaseButton from "@/components/button/BaseButton";
export default {
  name: "ChangePasswordForm",
  components: { BaseButton },
  data() {
    return {
      model: new AuthRequest().changePassword(),
    };
  },
  computed: {
    ...mapState(["auth"]),
  },
  methods: {
    changePassword() {
      this.model.username = this.auth.userInfo.customerEmail;
      this.$store.dispatch("auth/changePassword", this.model).then(()=>{
        this.model.password = "";
        this.model.oldPassword = "";
        this.model.passwordConfirmation = "";
      });
    },
    isNumber(event) {
      if (!/\d/.test(event.key) && event.key !== ".")
        return event.preventDefault();
    },
  },
};
</script>

<style scoped></style>
