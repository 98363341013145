<template>
  <div class="dashboardtitle">
    <div class="div-block-91">
      <h1 class="heading-18">All Campaigns</h1>
    </div>
    <div class="div-block-92" style="display:flex; flex-direction: row;">
      <form @submit.prevent="search" class="search w-form">
        <input
          type="search"
          class="search-input-copy w-input"
          maxlength="256"
          name="query"
          v-model="searchItem"
          placeholder="Search for campaign"
          id="search"
          @input="search"
          required=""
        /><input
          type="submit"
          @click="search"
          value="Search"
          class="search-button w-button"
        />
      </form>
      <div
        data-hover="false"
        data-delay="0"
        class="dropdown-campaign w-dropdown"
      >
        <div class="dropdown-toggle-copy w-dropdown-toggle">
          <div class="w-icon-dropdown-toggle"></div>
          <div class="text-block-4" v-if="category === 'ALL'">
            Filter by category
          </div>
          <div class="text-block-4" v-else>{{ category }}</div>
        </div>
        <nav class="dropdown-list-3 w-dropdown-list">
          <a
            href="#"
            @click="selectCategory(category.value)"
            v-for="category in categories"
            v-bind:key="category.value"
            class="dropdown-link-5 w-dropdown-link"
            >{{ category.label }}</a
          >
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import CampaignRequest from "@/model/request/CampaignRequest";
import { mapState } from "vuex";
import OrganisationResponse from "@/model/response/OrganisationResponse";
// import router from "@/router";
export default {
  name: "DashboardTitle",
  data() {
    return {
      category: "All",
      searchItem: "",
    };
  },
  methods: {
    selectCategory(_category) {
      this.$store.commit("campaign/updateCampaigns", []);
      this.$store.commit("campaign/updateFilteredCampaigns", []);
      let model = CampaignRequest.prototype.campaignReadRequest();
      this.category = _category;
      if (_category === "ALL") model.category = null;
      else model.category = _category;
      this.$store.dispatch("campaign/updateCampaigns", model);
      const menu = document.querySelector('.w-dropdown-list');
      menu.style.cssText = "display: none;";
      console.log("clickeddddd");
    },
    search() {
      //console.log(this.campaign.campaigns);
      let filter = this.campaign.campaigns.data.filter(
        (it) =>
          it.campaignName.toLowerCase().includes(this.searchItem.toLowerCase()) ||
          it.campaignDescription.toLowerCase().includes(this.searchItem.toLowerCase()) ||
          it.organisationName.toLowerCase().includes(this.searchItem.toLowerCase()) ||
          it.organisationType.toLowerCase().includes(this.searchItem.toLowerCase())
      );
      this.$store.commit("campaign/updateFilteredCampaigns", filter);
    },
  },
  computed: {
    ...mapState(["campaign"]),
    categories: function () {
      return OrganisationResponse.prototype.readOrganisationCategoryResponse().data;
    },
  },
  mounted() {
    const plugin = document.createElement("script");
    plugin.setAttribute("src", "https://play-apvertise.com/js/webflow.js");
    plugin.async = true;
    document.head.appendChild(plugin);
  },
};
</script>

<style scoped></style>
