// import router from "@/router";
import swal from "sweetalert";
import store from "@/store/store";
import OrderResponse from "@/model/response/OrderResponse";
import OrderRequest from "@/model/request/OrderRequest";
import OrderService from "@/service/OrderService";

export const state = {
  order: OrderResponse.prototype.orderReadByOrgResponse(),
  orders: OrderResponse.prototype.orderReadResponse(),
  singleOrder: OrderResponse.orderReadByIdResponse,
  screen: "one",
  model: OrderRequest.prototype.orderReadRequest(),
  orderLoading: false,
};
export const getters = {};
export const mutations = {
  updateOrder: (state, payload) => {
    state.order = payload;
  },
  updateOrders: (state, payload) => {
    state.orders = payload;
  },
  updateModel: (state, payload) => {
    state.model = payload;
  },
  updateSingleOrder: (state, payload) => {
    state.singleOrder = payload;
  },
  updateOrderLoading: (state, payload) => {
    state.orderLoading = payload;
  },
};
export const actions = {
  updateOrder: ({ commit }, payload) => {
    //TODO ORDER LOADING
    commit("updateOrderLoading", true);
    return OrderService.callReadOrderByOrganisationIdApi(payload)
      .then((response) => {
        commit("updateOrderLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          commit("updateOrder", responseData);
        } else {
          store.commit("updateMessage", {
            type: "error",
            title: "Error",
            body: responseData.responseMessage,
          });
        }
      })
      .catch((err) => {
        commit("updateOrderLoading", false);
        console.log(err);
      });
  },
  updateSingleOrder: ({ commit }, payload) => {
    //TODO ORDER LOADING
    commit("updateOrderLoading", true);
    return OrderService.callReadOrderIdApi(payload)
      .then((response) => {
        commit("updateOrderLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          commit("updateSingleOrder", responseData);
        } else {
          store.commit("updateMessage", {
            type: "error",
            title: "Error",
            body: responseData.responseMessage,
          });
        }
      })
      .catch((err) => {
        commit("updateOrderLoading", false);
        console.log(err);
      });
  },
  updateOrders: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateOrderLoading", true);
    return OrderService.callReadOrderApi(payload)
      .then((response) => {
        commit("updateOrderLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          commit("updateOrders", responseData);
        }
      })
      .catch((err) => {
        commit("updateOrderLoading", false);
        console.log(err);
      });
  },

  createOrder: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateOrderLoading", true);
    return OrderService.callCreateOrderApi(payload)
      .then((response) => {
        commit("updateOrderLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          store.commit("updateMessage", {
            type: "success",
            title: "Success",
            body: responseData.responseMessage,
          });
        } else {
          store.commit("updateMessage", {
            type: "error",
            title: "Error",
            body: responseData.responseMessage,
          });
        }
      })
      .catch((err) => {
        commit("updateOrderLoading", false);
        console.log(err);
      });
  },

  editOrder: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateOrderLoading", true);
    return OrderService.callUpdateOrderApi(payload)
      .then((response) => {
        commit("updateOrderLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          swal("Success", responseData.responseMessage, "success");
        } else {
          swal("Error", responseData.responseMessage, "error");
        }
      })
      .catch((err) => {
        commit("updateOrderLoading", false);
        console.log(err);
      });
  },

  deleteOrder: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateOrderLoading", true);
    return OrderService.callDeleteOrderApi(payload)
      .then((response) => {
        commit("updateOrderLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          swal("Success", responseData.responseMessage, "success");
        } else {
          swal("Error", responseData.responseMessage, "error");
        }
      })
      .catch((err) => {
        commit("updateOrderLoading", false);
        console.log(err);
      });
  },
};
