<template>
  <div class="home">
    <home-header/>
    <influence-t-c-section/>
    <footer-section/>
  </div>
</template>

<script>
import HomeHeader from "@/components/menu/HomeHeader";
import FooterSection from "@/components/section/FooterSection";
import InfluenceTCSection from "@/components/section/InfluenceTCSection";
export default {
  name: "InfluencerTC",
  components: { InfluenceTCSection, FooterSection, HomeHeader }
};
</script>

<style scoped>

</style>