<template>
  <b-modal
    style="background: white"
    ref="select-card-currency-modal"
    centered
    hide-header
    hide-footer
  >
    <div class="div-block-156">
      <a
        data-w-id="0e756eac-b69c-ca0d-7ba7-f2b54c7eb6e2"
        @click="hide()"
        href="#"
        class="close-topup-modal w-inline-block"
        ><img src="@/assets/images/close-modal.svg" loading="lazy" alt=""
      /></a>
    </div>
    <!--    <div class="top-up-modal-wrapper">-->
    <br />
    <div class="topup-modal-1">
      <h1 class="heading-28" style="text-align: center">Top up Wallet</h1>
      <p style="text-align: center" v-if="currency === 'NGN'">
        NGN {{ (auth.userInfo.ngnPayInRate * model.amount) | formatAmount }}
        gives you USD{{ model.amount | formatAmount }}
      </p>
      <p style="text-align: center" v-if="currency === 'GHS'">
        GHS {{ (auth.userInfo.ghPayInRate * model.amount) | formatAmount }}
        gives you USD{{ model.amount | formatAmount }}
      </p>
    </div>
    <b-alert
      v-if="
        card.response.responseCode !== '00' &&
        card.response.responseCode != null
      "
      show
      dismissible
      variant="danger"
    >
      {{ card.response.responseMessage }}
    </b-alert>
    <div class="w-form">
      <form
        v-if="screen === 'one'"
        id="email-form"
        @submit.prevent="makePayment"
        name="email-form"
        data-name="Email Form"
        class="form-4"
      >
        <label for="name" class="field-label-15">What currency are you paying with?</label>
        <!--        <input v-cardformat:formatCardExpiry>-->
        <select
          id="name"
          v-model="currency"
          name="field"
          class="select-field-4 w-select"
        >
          <!--          <option value="">Select card currency</option>-->
          <option
            v-for="currency in currencies.data"
            v-bind:key="currency.cc"
            :value="currency.cc"
          >
            {{ currency.cc }}
          </option>
        </select>
        <br />
        <input
          type="submit"
          value="Continue"
          style="z-index: 10; display: block"
          class="button-18 w-button"
        />
      </form>
      <div v-else-if="screen === 'two'" class="accordion" role="tablist">
        <a class="payment-hub" :href="href" v-if="currency === 'NGN' || currency === 'GHS'"><b-icon-credit-card style="margin-right: 5px"/>Pay with card</a>
        <b-card no-body class="mb-1" v-else>
          <b-card-header
            style="cursor: pointer"
            header-tag="header"
            v-b-toggle.accordion-1
            class="p-1"
            role="tab"
          >
            <p style="padding: 5px 5px 5px 20px; text-align: left">
              <b-icon-credit-card/>&nbsp;Pay with card
            </p>
          </b-card-header>
          <b-collapse
            id="accordion-1"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <form
                id="email-formm"
                @submit.prevent="chargeCard"
                name="email-form"
                data-name="Email Form"
                class="form-4"
              >
                <div class="card-details">
                  <label for="name" class="field-label-17">Card details</label>
                  <input
                    type="text"
                    class="text-field-20 w-input"
                    maxlength="256"
                    name="name-3"
                    data-name="Name 3"
                    placeholder=""
                    v-model="chargeCardModel.cardNumber"
                    v-cardformat:formatCardNumber
                    id="name-3"
                  />
                </div>
                <div class="card-details-copy">
                  <div class="div-block-157">
                    <label for="name-4" class="field-label-17"
                      >Expiry date</label
                    >
                    <input
                      type="text"
                      class="text-field-20 w-input"
                      maxlength="5"
                      name="name-3"
                      data-name="Name 3"
                      placeholder="MM/YY"
                      v-model="chargeCardModel.expirydate"
                      v-cardformat:formatCardExpiry
                      id="name-4"
                    />
                  </div>
                  <div class="div-block-157">
                    <label for="name-5" class="field-label-17">CVV</label>
                    <input
                      type="text"
                      class="text-field-20 w-input"
                      maxlength="3"
                      name="name-3"
                      data-name="Name 3"
                      placeholder="123"
                      v-model="chargeCardModel.cvv"
                      v-cardformat:formatCardCVC
                      id="name-5"
                    />
                  </div>
                </div>
                <div class="div-block-157">
                  <label for="name-6" class="field-label-17">Pin</label>
                  <input
                    type="password"
                    class="text-field-20 w-input"
                    maxlength="4"
                    minlength="4"
                    name="name-3"
                    data-name="Name 3"
                    placeholder="Pin"
                    v-model="chargeCardModel.pin"
                    id="name-6"
                  />
                </div>
                <base-button title="Proceed" :loading="card.cardLoading" />
                <!--        <input-->
                <!--          type="submit"-->
                <!--          value="Proceed"-->
                <!--          style="z-index: 10; display: block"-->
                <!--          class="button-18 w-button"-->
                <!--        />-->
              </form>
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card no-body class="mb-1" v-if="currency === 'NGN'">
          <b-card-header
            style="cursor: pointer"
            v-b-toggle.accordion-2
            header-tag="header"
            class="p-1"
            role="tab"
          >
            <p style="padding: 5px 5px 5px 20px; text-align: left">
              <b-icon-cash-stack/>&nbsp;Pay by transfer
            </p>
          </b-card-header>
          <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text style="text-align: left"
                >Account Name:&nbsp;&nbsp;<code>{{
                  auth.userInfo.fundingAccountName
                }}</code></b-card-text
              >
              <b-card-text style="text-align: left"
                >Bank Name:&nbsp;&nbsp;<code>{{
                  auth.userInfo.fundingBank
                }}</code></b-card-text
              >
              <b-card-text style="text-align: left"
                >Account Number:&nbsp;&nbsp;<code>{{
                  auth.userInfo.fundingAccount
                }}</code>
                <button
                  id="tooltip-button-show-event-acc"
                  type="button"
                  style="background-color: white"
                  v-clipboard:copy="auth.userInfo.fundingAccount"
                  v-clipboard:success="onCopyAcc"
                  v-clipboard:error="onError"
                >
                  <img
                    style="width: 15px; height: 15px"
                    src="@/assets/images/Copy.svg"
                    loading="lazy"
                    alt=""
                  />
                </button>
              </b-card-text>
              <b-card-text style="text-align: left;"
                >Token:&nbsp;&nbsp;<code>{{ manualFundingModel.token }}</code>
                <button
                  id="tooltip-button-show-event-token"
                  type="button"
                  style="background-color: white"
                  v-clipboard:copy="manualFundingModel.token"
                  v-clipboard:success="onCopyToken"
                  v-clipboard:error="onError"
                >
                  <img
                    style="width: 15px; height: 15px"
                    src="@/assets/images/Copy.svg"
                    loading="lazy"
                    alt=""
                  />
                </button>
                <b-tooltip
                  ref="tooltipAcc"
                  :variant="variant1"
                  target="tooltip-button-show-event-acc"
                >
                  {{ text1 }}
                </b-tooltip>
                <b-tooltip
                  ref="tooltipToken"
                  :variant="variant"
                  target="tooltip-button-show-event-token"
                >
                  {{ text }}
                </b-tooltip>
              </b-card-text><br/>
              <p style="text-align: left">
                <b>Note: </b> <br/> <span style="font-size:  12px;">Please use the token above as the narration for the transfer.
                <br/>When the transaction is completed click on <code>I have paid</code> button
              below to confirm payment</span>
              </p>
              <form @submit.prevent="confirmPayment">
                <base-button title="I have paid" :loading="manualFunding.manualFundingLoading"/>
              </form>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
      <form
        v-else-if="screen === 'three'"
        id="email-formmn"
        @submit.prevent="chargeCardOtp"
        name="email-form"
        data-name="Email Form"
        class="form-4"
      >
        <div class="card-details">
          <label for="name" class="field-label-17">{{ message }}</label>
          <input
            type="password"
            class="text-field-20 w-input"
            maxlength="256"
            name="name-3"
            data-name="Name 3"
            placeholder="OTP"
            v-model="chargeCardModelOtp.otp"
            id="field-label-17"
          />
        </div>
        <base-button title="Proceed" :loading="card.cardLoading" />
        <!--        <input-->
        <!--          type="submit"-->
        <!--          value="Proceed"-->
        <!--          style="z-index: 10; display: block"-->
        <!--          class="button-18 w-button"-->
        <!--        />-->
      </form>
    </div>
    <!--    </div>-->
    <!--    <a-->
    <!--      data-w-id="89b8a742-7170-306e-20da-6054dea3dd98"-->
    <!--      href="#"-->
    <!--      @click="hide()"-->
    <!--      class="close-topup-modal w-inline-block"-->
    <!--    >-->
    <!--      <b-icon-x style="width: 35px; height: 35px; color: #63b8a0" />-->
    <!--    </a>-->
  </b-modal>
</template>

<script>
import { mapState } from "vuex";
import CountryResponse from "@/model/response/CountryResponse";
import BaseButton from "@/components/button/BaseButton";
import store from "@/store/store";

export default {
  name: "SelectCardCurrencyModal",
  components: { BaseButton },
  props: ["show", "model"],
  data() {
    return {
      message: "",
      currency: "NGN",
      screen: "one",
      text: "Click to copy",
      variant: "dark",
      text1: "Click to copy",
      variant1: "dark",
      chargeCardModel: {
        amount: "",
        cvv: "",
        cardNumber: "",
        expirydate: "",
        expirymonth: "",
        expiryyear: "",
        pan: "",
        pin: "",
        reference: "",
      },
      chargeCardModelOtp: {
        action: "",
        amount: "",
        flwref: "",
        otp: "",
        pan: "",
        raveref: "",
        reference: "",
      },
      manualFundingModel: {
        creditAccountNumber: "",
        debitAccountNumber: "",
        creditAccountName: "",
        ftProductCode: "",
        reference: "",
        source: "",
        username: "",
        password: "",
        countryCode: "",
        trnAmount: "",
        token: "",
        trnNarration: "",
      },
      href: ""
    };
  },
  created() {
    const script = document.createElement("script");
    script.src =
      window.__env.app.stage === "dev"
        ? "https://ravemodal-dev.herokuapp.com/v3.js"
        : "https://checkout.flutterwave.com/v3.js";
    document.getElementsByTagName("head")[0].appendChild(script);
  },
  methods: {
    async callFlutterwave(){
      let currency = this.currency;
      let amount;
      if (currency === "NGN"){
        amount = this.model.amount * this.auth.userInfo.ngnPayInRate;
      }
      else {
        amount = this.model.amount * this.auth.userInfo.ghPayInRate;
      }
      let res = await this.$store.dispatch("transaction/callFlutterwaveTransaction", {amount: amount, currency: currency, customerId: this.auth.userInfo.customerId}).then();
      this.href = res.location;
    },
    callback() {
      console.log("Payment made, verify payment");
    },
    hide() {
      this.$refs["select-card-currency-modal"].hide();
    },
    // eslint-disable-next-line no-unused-vars
    onError: function (e) {
      this.$bvToast.toast("Failed to copy the text to the clipboard", {
        title: `Copy - Error`,
        variant: "error",
        autoHideDelay: 100000,
        solid: true,
      });
    },
    // eslint-disable-next-line no-unused-vars
    onCopyAcc: function (e) {
      this.variant1 = "success";
      this.text1 = "Copied!";
      this.$refs.tooltipAcc.$emit("show");
      let self = this;
      window.setTimeout(function () {
        self.variant1 = "dark";
        self.text1 = "Click to copy";
        self.$refs.tooltipAcc.$emit("show");
      }, 3000);
    },
    // eslint-disable-next-line no-unused-vars
    onCopyToken: function (e) {
      this.variant = "success";
      this.text = "Copied!";
      this.$refs.tooltipToken.$emit("show");
      let self = this;
      window.setTimeout(function () {
        self.variant = "dark";
        self.text = "Click to copy";
        self.$refs.tooltipToken.$emit("show");
      }, 3000);
    },
    async chargeCard() {
      this.chargeCardModel.expirymonth = this.chargeCardModel.expirydate
        .split("/")[0]
        .trim();
      this.chargeCardModel.expiryyear = this.chargeCardModel.expirydate
        .split("/")[1]
        .trim();
      this.chargeCardModel.reference = this.reference();
      this.chargeCardModel.pan = this.chargeCardModel.cardNumber.replace(
        /\s+/g,
        ""
      );
      this.chargeCardModel.amount = this.model.amount;
      await this.$store.dispatch(
        "card/rubiesCardFunding",
        this.chargeCardModel
      );
      if (this.card.response.responseCode === "00") {
        this.screen = "three";
        this.message = this.card.response.responseMessage;
      }
      this.chargeCardModelOtp = this.card.response;
    },
    async chargeCardOtp() {
      this.chargeCardModelOtp.amount = this.model.amount;
      this.chargeCardModelOtp.reference = this.chargeCardModel.reference;
      this.chargeCardModelOtp.action = "SENDOTP";
      await this.$store.dispatch(
        "card/rubiesCardFundingOtp",
        this.chargeCardModelOtp
      );
      if (this.card.response.responseCode === "00") {
        store.commit("updateMessage", {
          action: "reload",
          type: "success",
          title: "Success",
          body: this.card.response.responseMessage,
        });
        this.hide();
        this.$refs["flutterwave-modal"].hide();
        this.$store.dispatch("transaction/queryTransaction", {
          customerId: this.auth.userInfo.customerId,
        });
      }
      this.chargeCardModelOtp.responseCode = this.card.response.responseCode;
      this.chargeCardModelOtp.responseMessage =
        this.card.response.responseMessage;
    },
    reference() {
      let text = "";
      let possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (let i = 0; i < 30; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      return text;
    },
    token() {
      let text = "";
      let possible = "ABCDEFGHIJKLMNPQRSTUVWXYZ123456789";
      for (let i = 0; i < 10; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      return text;
    },
    confirmPayment() {
      this.manualFundingModel.creditAccountNumber = this.accountNumber()
      this.manualFundingModel.craccount = this.accountNumber()
      this.manualFundingModel.originatoraccountnumber = this.accountNumber()
      this.manualFundingModel.debitAccountNumber = this.accountNumber()
      this.manualFundingModel.creditAccountName = this.auth.userInfo.customerName
      this.manualFundingModel.craccountname = this.auth.userInfo.customerName
      this.manualFundingModel.ftProductCode = "FT_PRD"
      this.manualFundingModel.reference = this.reference();
      this.manualFundingModel.paymentreference = this.manualFundingModel.reference;
      this.manualFundingModel.sessionid = this.manualFundingModel.reference;
      this.manualFundingModel.source = "WEB";
      this.manualFundingModel.username = this.auth.userInfo.customerUsername;
      this.manualFundingModel.password = "000000";
      this.manualFundingModel.countryCode = "234";
      this.manualFundingModel.trnAmount = this.model.amount;
      this.manualFundingModel.amount = this.model.amount;
      this.manualFundingModel.trnNarration = "PAYMENT BY TRF";
      this.manualFundingModel.narration = "PAYMENT BY TRF";
      this.$store.dispatch(
        "manualFunding/createManualFunding",
        this.manualFundingModel
      );
    },
    accountNumber: function () {
      if (this.transaction.transactions.accounts.length < 1) return "";
      else
        return this.transaction.transactions.accounts.filter(
          (it) => it.accountClass === "TIER_1_SAVINGS"
        )[0].accountNumber;
    },
    async makePayment() {
      if (this.currency === "NGN" || this.currency === "GHS") {
        console.log(this.currency);
        this.screen = "two";
        await this.callFlutterwave();
      } else {
        console.log(window.__env.app.flwKey);
        console.log(this.auth.userInfo.customerEmail.trim());
        window.FlutterwaveCheckout({
          public_key: window.__env.app.flwKey,
          tx_ref: `${this.reference()}-${this.auth.userInfo.customerId}-${
            this.auth.userInfo.accounts[0].accountNumber
          }-${this.auth.userInfo.customerBankCode}`,
          amount: this.model.amount,
          currency: "USD",
          payment_options: "card,mobilemoney,ussd",
          customer: {
            name: this.auth.userInfo.customerName, //this.name,
            email: this.auth.userInfo.customerEmail.trim(),
          },
          callback: (response) => this.callback(response),
          customizations: {
            title: "Wallet Funding",
            description: "Payment for items in cart",
            logo: window.__env.app.flwLogo,
          },
        });
      }
    },
  },
  computed: {
    ...mapState(["auth", "card", "manualFunding", "transaction"]),
    currencies() {
      return CountryResponse.prototype.currencies();
    },
  },
  watch: {
    // whenever question changes, this function will run
    show: function () {
      this.screen = "one";
      this.manualFundingModel.token = this.token()
      this.chargeCardModel = {
        amount: "",
        cvv: "",
        cardNumber: "",
        expirydate: "",
        expirymonth: "",
        expiryyear: "",
        pan: "",
        pin: "",
        reference: "",
      };
      this.chargeCardModelOtp = {
        action: "",
        amount: "",
        flwref: "",
        otp: "",
        pan: "",
        raveref: "",
        reference: "",
      };
      this.$store.commit("card/updateResponse", {});
      this.$refs["select-card-currency-modal"].show();
    },
  },
};
</script>

<style>
.payment-hub{
  background-color: rgba(0, 0, 0, 0.03);
  padding: 0.25rem;
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  margin-bottom: 0.25rem !important;
  text-decoration: none;
  color: #0a0a0a;
  padding-left: 25px;
}
/*.close-topup-modal {*/
/*  position: absolute;*/
/*  left: auto;*/
/*  top: 0%;*/
/*  right: 0%;*/
/*  bottom: auto;*/
/*  display: block;*/
/*}*/
</style>

<!--5199 1107 9099 8063-->
<!--5366 1317 0664 2394-->

<!--ILFecCdAimijAihxNSHJdIG2zqacuQ-->
