
import client from "./BaseService";

export default {
    callChangeProfilePictureApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("apvChangeProfilePicture", payload);
    },

};
