<template>
  <div class="main-campaign-body" v-if="campaign.campaign.responseCode === '00' && !campaign.campaignLoading && auth.userInfo.customerId !== campaign.campaign.customerOrganisationCustomerId">
    <div class="dashboard-header">
      <div class="dashboardtitle">
        <router-link v-if="auth.userInfo.responseCode === '00'" to="/dashboard" class="link-block-14 w-inline-block">
          <h1 class="heading-18">Back to campaigns</h1>
        </router-link>
      </div>
      <div class="container-copy-copy">
        <div class="campaign-detail-block">
          <div style="height: 100%;" class="div-block-23">
            <div>
              <b-carousel
                id="carousel-1"
                v-model="slide"
                :interval="4000"
                controls
                indicators
                label-next=""
                label-prev=""
                background="#ffffff"
                style="text-shadow: 1px 1px 2px #333;"
                @sliding-start="onSlideStart"
                @sliding-end="onSlideEnd"
              >
                <b-carousel-slide style="border-radius: 20px 20px 20px 20px;" v-for="banner in banners" v-bind:key="banner">
                  <template #img>
                    <img
                      style="border-radius: 20px 20px 20px 20px; width: 100%;"
                      class="d-block img-fluid"
                      width="606"
                      height="387"
                      :src="banner"
                      alt="image slot"
                    >
                  </template>
                </b-carousel-slide>
              </b-carousel>
            </div>
          </div>
          <div class="div-block-107">
            <div class="div-block-24">
              <div class="div-block-11">
                <b-avatar style="width: 50px; height: 50px;" :src="campaign.campaign.organisationLogo" loading="lazy" alt=""></b-avatar></div>
                <div>
                  <h1 class="heading-19">{{ campaign.campaign.organisationName }}</h1>
                  <div class="mt-2 text-block-49">{{ campaign.campaign.campaignCreatedAt | moment("calendar") }}</div>
              </div>
              </div>
            <div class="div-block-102">
              <p class="paragraph-7">{{ campaign.campaign.campaignDescription }}<br></p>
            </div>
            <share-campaign-button type="button" :customer-id="campaign.campaign.customerOrganisationCustomerId" :campaign-id="campaignId" :title="campaign.campaign.campaignName" :description="campaign.campaign.campaignDescription" :campaign-budget-exhausted="campaign.campaign.campaignBudgetExhausted"/>
            <a v-if="auth.userInfo.customerId === campaign.campaign.customerOrganisationCustomerId" data-w-id="3b0786d9-0195-898d-4c9e-8fbfde6b3ed2" @click="navigateToEdit(campaign.campaign)" style="margin-top: 10px;" href="#" class="button-6 w-button">Edit Campaign</a>
          </div>
        </div>
        <div v-if="auth.userInfo.customerId !== campaign.campaign.customerOrganisationCustomerId" class="div-block-27">
          <h1 class="heading-16">Related Campaigns</h1>
          <b-row>
            <b-col md="4" v-for="campaignObj in campaign.campaign.relatedCampaigns" v-bind:key="campaignObj.campaignId">
              <campaign-all-card
                :title="campaignObj.campaignName"
                :category="campaignObj.organisationType"
                :logo="campaignObj.organisationLogo"
                :company-name="campaignObj.organisationName"
                :campaign-id="campaignObj.campaignId"
                :customer-id="campaignObj.customerOrganisationCustomerId"
                :description="campaignObj.campaignDescription"
                :campaign-budget-exhausted="campaignObj.campaignBudgetExhausted"
              /><br/>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <p style="text-align: center; margin-top: 25%;"><b-spinner v-if="campaign.campaignLoading" style="color: #34a853;"/></p>
  </div>
  <campaign-metrics v-else/>

</template>

<script>
import { mapState } from "vuex";
import ShareCampaignButton from "@/components/button/ShareCampaignButton";
import CampaignAllCard from "@/components/card/CampaignAllCard";
import CampaignMetrics from "@/views/campaign/CampaignMetrics.vue";
export default {
  name: "CampaignDetail",
  components: { CampaignMetrics, ShareCampaignButton, CampaignAllCard },
  data() {
    return {
      slide: 0,

      sliding: null
    }
  },
  methods: {
    onSlideStart() {
      this.sliding = true
    },
    onSlideEnd() {
      this.sliding = false
    },
    navigateToEdit(_campaign) {
      this.$store.commit("campaign/updateCampaignEditForm", _campaign);
      this.$router.push("/edit-campaign");
    },
    selectCampaign(_id) {
      localStorage.campaignId = _id;
      console.log(this.$route.path);
      if (!this.$route.path.includes("campaign-details"))
        this.$router.push(`/campaign-details/${_id.hexEncode()}`);
      this.$store.dispatch("campaign/updateCampaign", {
        campaignId: localStorage.campaignId,
      });
    },
  },
  computed:{
    ...mapState(["campaign","auth"]),
    banners: function(){
      if (this.campaign.campaign.campaignBanners == null) return []
      return this.campaign.campaign.campaignBanners.split(",")
    },
    campaignId: function () {
      //this.$store.dispatch("campaign/updateCampaign", {campaignId: localStorage.campaignId})
      if (this.$route.params.campaignId)
        return this.$route.params.campaignId.hexDecode();
      else return localStorage.campaignId;
    }
  },
  mounted() {
    if (this.$route.params.campaignId != null) {
      this.$store.dispatch("campaign/updateCampaign", {campaignId: this.campaignId})
    }
    //this.$store.dispatch("campaign/updateCampaign", {campaignId: localStorage.campaignId})
  },
  watch:{
    campaignId: function (){
      //this.$store.dispatch("campaign/updateCampaign", {campaignId: localStorage.campaignId})
    }
  }
};
</script>

<style scoped>

</style>