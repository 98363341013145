import BaseResponse from "@/model/response/BaseResponse";

class CardResponse extends BaseResponse {
    readSingleResponse() {
        return {

            cardAccountNumber: null,
            cardAlias: null,
            cardBankCode: null,
            cardCustomerId: null,
            cardCustomerName: null,
            cardDefaultPin: null,
            cardDeliveryLga: null,
            cardDeliveryState: null,
            cardDeliveryAddress: null,
            cardEmail: null,
            cardId: null,
            cardLatitude: null,
            cardLongitude: null,
            cardNewPhoneNumber: null,
            cardPan: null,
            cardPhoneNumber: null,
            cardRequestedDate: null,
            cardStatus: null,
            cardUpdatedDate: null,
            cardCreatedAt: null,
            cardUpdatedAt: null,
            responseCode: null,
            responseMessage: null,
        };
    }
    readResponse() {
        return {
            responseCode: null,
            responseMessage: null,
            data: [],
        };
    }
}

export default CardResponse;