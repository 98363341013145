<template>
  <div class="dashboard-nav wf-section">
  <div class="main-dashboard-section wf-section">
    <div class="main-dashboard-body mall">
      <div class="get-started-wrapper">
        <div class="content">
          <div class="div-block-119"><img :src= "require('@/assets/images/Group-34115.svg')" loading="lazy" alt=""></div>
          <h3 class="heading">Welcome to Online Mall</h3>
          <div class="sub">Apvertise mall is your all-in-one e-commerce store builder to sell any kind of digital product or service to anyone, anywhere in the world.</div>
          <router-link to="/business-details" class="apvertise-button w-button">Start Selling</router-link>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  name: "MallGetStarted",
};
</script>

<style scoped>

</style>