import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import "./assets/css/morah.css";
import router from "./router";
import store from "./store/store";
import "./filters";
import VueSweetalert2 from "vue-sweetalert2";
const options = {
  confirmButtonColor: "#042893",
  cancelButtonColor: "#ff7674",
};

Vue.use(VueSweetalert2, options);

// import component and stylesheet
import AirbnbStyleDatepicker from "vue-airbnb-style-datepicker";
import "vue-airbnb-style-datepicker/dist/vue-airbnb-style-datepicker.min.css";

// see docs for available options
const datepickerOptions = {};

// make sure we can use it in our components
Vue.use(AirbnbStyleDatepicker, datepickerOptions);

import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
Vue.component(VueCropper);

import LottieAnimation from "lottie-web";
Vue.use(LottieAnimation); // add lottie-animation to your global scope

import * as LottiePlayer from "@lottiefiles/lottie-player";
Vue.use(LottiePlayer);

import Datetime from "vue-datetime";
// You need a specific loader for CSS files
import "vue-datetime/dist/vue-datetime.css";
Vue.use(Datetime);

import VueCardFormat from "vue-credit-card-validation";
Vue.use(VueCardFormat);

import * as Fingerprint2 from "fingerprintjs2";
Vue.prototype.$fingerprint = Fingerprint2;

import Multiselect from "vue-multiselect";

// register globally
Vue.component("multiselect", Multiselect);

// import VueToast from 'vue-toast-notification';
// // Import one of the available themes
// //import 'vue-toast-notification/dist/theme-default.css';
// import 'vue-toast-notification/dist/theme-sugar.css';
//
// Vue.use(VueToast);
import "vue-croppa/dist/vue-croppa.css";
import Croppa from "vue-croppa";
Vue.use(Croppa);

import VueClipboard from "vue-clipboard2";
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);

const moment = require("moment");
require("moment/locale/en-au");
Vue.use(require("vue-moment"), {
  moment,
});
console.log(Vue.moment().locale());

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

// Import Bootstrap an BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "@/assets/css/normalize.css";
import "@/assets/css/webflow.css";
import "@/assets/css/apvertise.webflow.css";

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

import VueCountryCode from "vue-country-code";
Vue.use(VueCountryCode);

import vueCountryRegionSelect from "vue-country-region-select";
Vue.use(vueCountryRegionSelect);

import GAuth from "vue-google-oauth2";

const gauthOption = {
  clientId:
    "486273947762-hqfmq4qdl64slv1ctf8idjp67peinukh.apps.googleusercontent.com",
  scope: "profile email",
  prompt: "select_account",
};
Vue.use(GAuth, gauthOption);

Vue.config.productionTip = false;

import IdleVue from "idle-vue";

const eventsHub = new Vue();

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  idleTime: 36000000000,
});

new Vue({
  router,
  store,
  logout() {
    this.$store.dispatch("auth/logout", {}, { root: true });
  },
  onIdle() {
    if (this.$route.meta.authRequired) {
      console.log(router.currentRoute.path);
      let timerInterval;
      store.commit("updateMessage", {
        type: "timeout",
        title: "Session Time Out!",
        body: "Application will log off in less than $timerCount seconds.",
      });
      clearInterval(timerInterval);
    }
  },
  onActive() {
    // router.push('/')
  },
  render: (h) => h(App),
}).$mount("#app");


// Google Analytics API
// Google Cloud APIs
// Google Cloud Storage JSON API
// Google+ API
// Service Management API