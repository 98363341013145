import client from "./BaseService";

export default {
    callCategoryListApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("apvCategoryList", payload);
    },

    callBillerCategoryListApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("apvBillerCategoryList", payload);
    },

    callBillerProductListApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("apvBillerProductList", payload);
    },

    callBillerValidationApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("apvBillerValidation", payload);
    },

    callBillerPaymentApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("apvBillerPayment", payload);
    },

    callMobileDataProductListApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("apvMobileDataProductList", payload);
    },

    callMobileDataPurchaseApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("apvMobileDataPurchase", payload);
    },

    callAirtimePurchaseApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return client.apiClient.post("apvAirtimePurchase", payload);
    },
};
