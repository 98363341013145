<template>
  <div class="div-block-122">
    <div>
      <h1 class="heading-27">Notification Settings</h1>
    </div>
    <br>
    <b-row>
      <b-col>Login Notification:</b-col>
      <b-col><b-spinner small variant="success" v-if="settings.settingsLoading"/><switches v-else v-model="model.customerLoginNotification" @toggle="updateNotificationSettings" theme="bootstrap" color="success"></switches>
      </b-col>
    </b-row>
    <br>
    <b-row>
      <b-col>New Campaign Notification:</b-col>
      <b-col><b-spinner small variant="success" v-if="settings.settingsLoading"/><switches v-else v-model="model.customerCampaignNotification" @change="updateNotificationSettings" theme="bootstrap" color="success"></switches></b-col>
    </b-row>
    <br>
    <b-row>
      <b-col>Transaction Notification:</b-col>
      <b-col><b-spinner small variant="success" v-if="settings.settingsLoading"/><switches v-else v-model="model.customerTransactionNotification" @change="updateNotificationSettings" theme="bootstrap" color="success"></switches></b-col>
    </b-row>
    <br>
    <b-row>
      <b-col>Newsletter:</b-col>
      <b-col><b-spinner small variant="success" v-if="settings.settingsLoading"/><switches v-else v-model="model.customerNewsletter" @change="updateNotificationSettings" theme="bootstrap" color="success"></switches></b-col>
    </b-row>
    <div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Switches from 'vue-switches';

export default {
  name: "BasicInformationForm",
  components: { Switches },
  data() {
    return {
      enabled: false,
      value: {
        type: Boolean,
        required: true,
      },
      model: {
        customerId: false,
        customerLoginNotification: false,
        customerCampaignNotification: false,
        customerTransactionNotification: false,
        customerNewsletter: false,
      },
      model2: {
        customerId: "",
        customerLoginNotification: "",
        customerCampaignNotification: "",
        customerTransactionNotification: "",
        customerNewsletter: "",
      },
    };
  },
  computed:{
    ...mapState(["auth","settings"]),
    notificationSettings: {
      set(val){
        console.log(val);
      },
      get(){
        return this.settings.notificationSettings;
      }
    },
    customerId(){
      return this.auth.userInfo.customerId;
    }
  },
  methods: {
    updateNotificationSettings() {
      let self = this;
      // eslint-disable-next-line no-unused-vars
      Object.keys(this.model).forEach(function (key, index) {
        self.model2[key] = self.model[key] ? "YES" : "NO";
      });
      this.model.customerId = this.customerId;
      this.model2.customerId = this.customerId;
      this.$store.dispatch("settings/editNotificationSettings", this.model2);
    },
  },
  watch: {
    notificationSettings() {
      let self = this;
      // eslint-disable-next-line no-unused-vars
      Object.keys(this.notificationSettings).forEach(function(key,index) {
        self.model[key] = self.notificationSettings[key] === "YES";
      });
    },
    customerId() {
      this.$store.dispatch("settings/updateNotificationSettings", {
        customerId: this.auth.userInfo.customerId,
      });
    },
    'model.customerLoginNotification'(){
      this.updateNotificationSettings();
    },
    'model.customerCampaignNotification'(){
      this.updateNotificationSettings();
    },
    'model.customerTransactionNotification'(){
      this.updateNotificationSettings();
    },
    'model.customerNewsletter'(){
      this.updateNotificationSettings();
    }
  },
  mounted(){
    this.$store.dispatch("settings/updateNotificationSettings", {
      customerId: this.auth.userInfo.customerId,
    }).then(res => {
      console.log(res);
      let self = this
      // eslint-disable-next-line no-unused-vars
      Object.keys(this.notificationSettings).forEach(function(key,index) {
        self.model[key] = self.notificationSettings[key] === "YES";
      });
    })

  }
};
</script>

<style scoped>

</style>