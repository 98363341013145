 import router from "@/router";
import swal from "sweetalert";
import store from "@/store/store";
import ProductResponse from "@/model/response/ProductResponse";
import ProductRequest from "@/model/request/ProductRequest";
import ProductService from "@/service/ProductService";

export const state = {
  product: ProductResponse.prototype.productReadByOrgResponse(),
  products: ProductResponse.prototype.productReadResponse(),
  singleProduct: ProductResponse.productReadByIdResponse,
  screen: "one",
  model: ProductRequest.prototype.productReadRequest(),
  purchaseProduct: ProductResponse.buyProductResponse,
  productLoading: false,
  productFormObject: {},
  productDetails: {},
};

export const mutations = {
  updateProduct: (state, payload) => {
    state.product = payload;
  },
  updateProducts: (state, payload) => {
    state.products = payload;
  },
  updatePurchaseProduct: (state, payload) => {
    state.purchaseProduct = payload;
  },
  updateSingleProduct: (state, payload) => {
    state.singleProduct = payload;
  },
  updateProductFormObject: (state, payload) => {
    state.productFormObject = { ...state.productFormObject, ...payload };
  },
  updateModel: (state, payload) => {
    state.model = payload;
  },
  updateProductDetails: (state, payload) => {
    state.productDetails = { ...state.productDetails, ...payload };
  },
  updateScreen: (state, payload) => {
    state.screen = payload;
  },
  updateProductLoading: (state, payload) => {
    state.productLoading = payload;
  },
};
export const actions = {
  //TODO READ BY ORGANISATION ID
  updateProduct: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateProductLoading", true);
    return ProductService.callReadProductByOrganisationIdApi(payload)
      .then((response) => {
        commit("updateProductLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          commit("updateProduct", responseData);
          // if (!localStorage.organisationId){
          //   localStorage.productOrganisationId = responseData.data[0].productOrganisationId
          // }
          // else {
          //   if (responseData.data.filter(it => it.productOrganisationId === localStorage.productOrganisationId).length < 1)
          //     localStorage.productOrganisationId = responseData.data[0].productOrganisationId
          // }
        } else {
          store.commit("updateMessage", {
            type: "error",
            title: "Error",
            body: responseData.responseMessage,
          });
        }
      })
      .catch((err) => {
        commit("updateProductLoading", false);
        console.log(err);
      });
  },

  //TODO READ SINGLE PRODUCT
  updateSingleProduct: ({ commit }, payload) => {
    commit("updateProductLoading", true);
    return ProductService.callReadProductByIdApi(payload)
      .then((response) => {
        commit("updateProductLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          commit("updateSingleProduct", responseData);
        } else {
          store.commit("updateMessage", {
            type: "error",
            title: "Error",
            body: responseData.responseMessage,
          });
        }
      })
      .catch((err) => {
        commit("updateProductLoading", false);
        console.log(err);
      });
  },
  updatePurchaseProduct: ({ commit }, payload) => {
    //TODO BUY PRODUCT
    commit("updateProductLoading", true);
    return ProductService.callBuyProductApi(payload)
      .then((response) => {
        commit("updateProductLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          commit("updatePurchaseProduct", responseData);
        } else {
          store.commit("updateMessage", {
            type: "error",
            title: "Error",
            body: responseData.responseMessage,
          });
        }
      })
      .catch((err) => {
        commit("updateProductLoading", false);
        console.log(err);
      });
  },

  //TODO READ PRODUCT
  updateProducts: ({ commit }, payload) => {
    commit("updateProductLoading", true);
    return ProductService.callReadProductApi(payload)
      .then((response) => {
        commit("updateProductLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          commit("updateProducts", responseData);
        }
      })
      .catch((err) => {
        commit("updateProductLoading", false);
        console.log(err);
      });
  },
  //TODO CREATE PRODUCT
  createProduct: ({ commit }, payload) => {
    commit("updateProductLoading", true);
    return ProductService.callCreateProductApi(payload)
      .then((response) => {
        commit("updateProductLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          store.commit("updateMessage", {
            type: "success",
            title: "",
            body: responseData.responseMessage,
          });
          router.push({ name: "AddProductsSuccess" });
        }
        else {
          store.commit("updateMessage", {
            type: "error",
            title: "Error",
            body: responseData.responseMessage,
          });
        }
      })
      .catch((err) => {
        commit("updateProductLoading", false);
        console.log(err);
      });
  },
  //TODO EDIT PRODUCT
  editProduct: ({ commit }, payload) => {
    commit("updateProductLoading", true);
    return ProductService.callUpdateProductApi(payload)
      .then((response) => {
        commit("updateProductLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00")
        {
          store.commit("updateMessage", {
            type: "success",
            title: "",
            body: responseData.responseMessage,
          });
          router.push({ name: "ProductMerchantView" });
        }else {
          store.commit("updateMessage", {
            type: "error",
            title: "Error",
            body: responseData.responseMessage,
          });
        }
      })
      .catch((err) => {
        commit("updateProductLoading", false);
        console.log(err);
      });
  },

  //TODO DELETE PRODUCT
  deleteProduct: ({ commit }, payload) => {
    commit("updateProductLoading", true);
    return ProductService.callDeleteProductApi(payload)
      .then((response) => {
        commit("updateProductLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          swal("Success", responseData.responseMessage, "success");
        } else {
          swal("Error", responseData.responseMessage, "error");
        }
      })
      .catch((err) => {
        commit("updateProductLoading", false);
        console.log(err);
      });
  },
};


