// import router from "@/router";
import AnalyticService from "@/service/AnalyticService";
import S3Response from "@/model/response/S3Response";

export const state = {
  analyticLoading: false,
  analyticResponse: S3Response.prototype.uploadBase64Response(),
  clicksPerCampaign: {
    responseCode: "",
    responseMessage: "",
    data: [],
  },
  impressionsPerCampaign: {
    responseCode: "",
    responseMessage: "",
    data: [],
  },
  sharesPerCampaign: {
    responseCode: "",
    responseMessage: "",
    data: [],
  },
};
export const getters = {};
export const mutations = {
  updateAnalyticLoading: (state, payload) => {
    state.analyticLoading = payload;
  },
  updateClicksPerCampaign: (state, payload) => {
    state.clicksPerCampaign = payload;
  },
  updateImpressionsPerCampaign: (state, payload) => {
    state.impressionsPerCampaign = payload;
  },
  updateSharesPerCampaign: (state, payload) => {
    state.sharesPerCampaign = payload;
  },
  updateUrls: (state, payload) => {
    state.urls = payload;
  },
  reset: (state) => {
    state.clicksPerCampaign = {
      responseCode: "",
      responseMessage: "",
      data: [],
    };
    state.impressionsPerCampaign = {
      responseCode: "",
      responseMessage: "",
      data: [],
    };
    state.sharesPerCampaign = {
      responseCode: "",
      responseMessage: "",
      data: [],
    };
  },
};
export const actions = {
  updateClicksPerCampaign: ({ commit }, payload) => {
    commit("updateAnalyticLoading", true);
    return AnalyticService.callClicksPerCampaignApi(payload)
      .then((response) => {
        commit("updateAnalyticLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          commit("updateClicksPerCampaign", responseData);
        }
      })
      .catch((err) => {
        commit("updateAnalyticLoading", false);
        console.log(err);
      });
  },
  updateImpressionsPerCampaign: ({ commit }, payload) => {
    commit("updateAnalyticLoading", true);
    return AnalyticService.callImpressionsPerCampaignApi(payload)
      .then((response) => {
        commit("updateAnalyticLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          commit("updateImpressionsPerCampaign", responseData);
        }
      })
      .catch((err) => {
        commit("updateAnalyticLoading", false);
        console.log(err);
      });
  },
  updateSharesPerCampaign: ({ commit }, payload) => {
    commit("updateAnalyticLoading", true);
    return AnalyticService.callSharesPerCampaignApi(payload)
      .then((response) => {
        commit("updateAnalyticLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          commit("updateSharesPerCampaign", responseData);
        }
      })
      .catch((err) => {
        commit("updateAnalyticLoading", false);
        console.log(err);
      });
  },
};
