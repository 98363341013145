import BaseResponse from "@/model/response/BaseResponse";

class MoneyResponse extends BaseResponse {
    readSingleResponse() {
        return {
            id:null,
            amount:null,
            reason:null,
            requesterAccount:null,
            requesterCustomerId:null,
            requesterName:null,
            requestId:null,
            senderAccountNumber:null,
            senderCountryCode:null,
            senderCustomerId:null,
            senderName:null,
            senderPhoneNumber:null,
            status:null,
            username:null,
            CreatedAt: null,
            UpdatedAt: null,
            responseCode: null,
            responseMessage: null,
        };
    }
    readResponse() {
        return {
            responseCode: null,
            responseMessage: null,
            data: [],
        };
    }
}

export default MoneyResponse;
