<template>
  <span>
<!--    <button @click="login()">Login</button>-->
<!--    is login ? {{ isLogin }}-->
<!--    <button @click="logOut()">Logout</button>-->
    <b-form
      v-if="auth.regScreen === 'reg'"
      id="reg-form"
      name="email-form"
      data-name="Email Form"
      class="signup-form"
      @submit.prevent="initiateEnrollment"
    >
      <div class="email-block" style="margin-bottom: 20px">
        <label for="customerFirstName" class="embolden email-text"
          >First Name</label>
        <input
          v-model="model.customerFirstName"
          type="text"
          required
          class="email-text-field w-input"
          maxlength="256"
          name="name"
          data-name="Name"
          placeholder="Enter your first name"
          id="customerFirstName"
        />
      </div>
      <div class="email-block" style="margin-bottom: 20px">
        <label for="customerLastName" class="embolden email-text"
          >Last Name</label
        >
        <input
          v-model="model.customerLastName"
          type="text"
          required
          class="email-text-field w-input"
          maxlength="256"
          name="name"
          data-name="Name"
          placeholder="Enter your last name"
          id="customerLastName"
        />
      </div>
      <div class="email-block" style="margin-bottom: 20px">
        <label for="customerEmail" class="embolden email-text">Email</label>
        <input
          v-model="model.customerEmail"
          type="email"
          required
          class="email-text-field w-input"
          maxlength="256"
          name="name"
          data-name="Name"
          placeholder="Enter your email address"
          id="customerEmail"
        />
      </div>
      <!--      <div class="phone-number-block">-->
      <!--        <label for="customerPhoneNumber" class="phone-number-text"-->
      <!--          >Phone Number</label-->
      <!--        >-->
      <!--        <vue-country-code-->
      <!--          class="dial-code"-->
      <!--          v-model="model.customerCountryCode"-->
      <!--          @onSelect="onSelect"-->
      <!--          :preferredCountries="['ng', 'us', 'gb']"-->
      <!--        />-->
      <!--        <input-->
      <!--          type="tel"-->
      <!--          v-model="model.customerPhoneNumber"-->
      <!--          id="customerPhoneNumber"-->
      <!--          class="phone-number-textfield w-input"-->
      <!--        />-->
      <!--      </div>-->
      <div class="phone-number-block" style="margin-bottom: 20px">
        <label for="name-3" class="embolden phone-number-text"
          >Phone Number</label>
        <div class="div-block-143">
          <input
            type="number"
            v-model="model.customerPhoneNumber"
            required
            class="phone-number-textfield w-input"
            maxlength="256"
            name="name-3"
            data-name="Name 3"
            style="padding-left: 10px"
            placeholder="Enter your phone number"
            id="name-3"
          />
          <!--          <div>-->
          <vue-country-code
            class="dial-code"
            style="padding-left: 0; width: 90px"
            v-model="model.customerCountryCode"
            @onSelect="onSelect"
            :preferredCountries="['ng', 'us', 'gb']"
          />
          <!--          </div>-->
        </div>
      </div>
      <div class="country-block" style="margin-bottom: 20px">
        <label for="name-41" class="country embolden">Country</label
        ><select
          v-model="model.customerCountry"
          required
          id="COUNTRY"
          name="COUNTRY"
          data-name="COUNTRY"
          class="select-country w-select"
        >
          <option
            v-for="country in countries"
            v-bind:key="country.name"
            :value="country.name"
          >
            <img
              :src="'@/assets/countries/flags/16x16/' + country.alpha2 + '.png'"
            />
            {{ country.name }}
          </option>
        </select>
      </div>
      <base-button :loading="auth.authLoading" title="Continue" /><br /><br />
    </b-form>
    <b-form
      v-else-if="auth.regScreen === 'password'"
      id="email-form"
      name="email-form"
      data-name="Email Form"
      class="signup-form"
      @submit.prevent="goToOtp()"
    >
      <div class="password-block">
        <label for="name-21" class="embolden password-text"
          >Set your 6-digits passcode
          <span style="font-weight: lighter">(Numbers Only)</span></label>
        <input
          @keypress="this.isNumber"
          v-model="modelComplete.password"
          type="password"
          required
          class="password-textfield w-input"
          maxlength="6"
          minlength="6"
          name="name-2"
          data-name="Name 2"
          placeholder="Enter your 6-digits passcode"
          id="name-21"
        />
      </div>
      <div class="confirm-password-block">
        <label for="name-41" class="embolden field-label-5"
          >Re-enter Passcode</label
        >
        <input
          @keypress="this.isNumber"
          :style="
            !(
              modelComplete.password === modelComplete.passwordConfirmation ||
              modelComplete.passwordConfirmation === '' ||
              modelComplete.passwordConfirmation == null
            )
              ? 'border-color: red'
              : ''
          "
          v-model="modelComplete.passwordConfirmation"
          type="password"
          minlength="6"
          maxlength="6"
          required
          class="text-field-6 w-input"
          name="name-4"
          data-name="Name 4"
          placeholder="Confirm Passcode"
          id="name-41"
        />
        <p
          v-if="
            !(
              modelComplete.password === modelComplete.passwordConfirmation ||
              modelComplete.passwordConfirmation === '' ||
              modelComplete.passwordConfirmation == null
            )
          "
          style="color: red; text-align: left; margin-top: -10px"
        >
          <i>password mismatch.</i>
        </p>
      </div>
      <base-button
        :disabled="
          modelComplete.password !== modelComplete.passwordConfirmation
        "
        :loading="auth.authLoading"
        title="Continue"
      /><br /><br />
    </b-form>
    <b-form
      v-else
      id="email-form"
      name="email-form"
      data-name="Email Form"
      class="signup-form"
      @submit.prevent="completeEnrollment"
    >
      <p
        style="color: #63b8a0; cursor: pointer; text-align: left"
        @click="$store.commit('auth/updateRegScreen', 'password')"
      >
        <b-icon-arrow-left /> Back
      </p>
      <div class="password-block" style="width: 100%">
        <label for="otp" class="embolden password-text">OTP</label>
        <span style="display: flex; flex-direction: row">
          <input
            @keypress="this.isNumber"
            v-model="modelComplete.otp"
            type="text"
            required
            class="password-textfield w-input"
            minlength="6"
            maxlength="6"
            name="otp"
            data-name="otp"
            style="width: 70%"
            placeholder="Enter the OTP sent to you"
            id="otp"
          />
          <button
            v-if="timerCount < 1"
            @click="sendOtp"
            type="button"
            style="width: 30%; height: 50px; padding: 0; color: #63b8a0"
          >
            <b-spinner
              v-if="auth.authLoading"
              small
              style="color: #63b8a0"
            /><span v-else>request otp</span>
          </button>
          <button
            v-else
            type="button"
            style="width: 40%; height: 50px; padding: 0; color: #63b8a0"
          >
            Resend in {{ timerCount }}s
          </button>
        </span>
      </div>
      <b-alert
        style="margin-top: -40px; margin-bottom: 40px; font-size: 13px"
        show
        variant="info"
        >Otp has been sent to your email, please supply it to proceed
        <br />
        💡<code>Tip:</code> Can’t find the OTP? Check your Spam,Promotion or
        Junk Folder</b-alert
      >
      <base-button :loading="auth.authLoading" title="Continue" /><br /><br />
    </b-form>
  </span>
</template>

<script>
import BaseButton from "@/components/button/BaseButton";
import { mapState } from "vuex";
import AuthRequest from "@/model/request/AuthRequest";
import countriesJson from "@/assets/countries/data/en/countries.json";
// const BaseUtil = require('@/components/utility/BaseUtil');
export default {
  name: "RegisterForm",
  components: {
    BaseButton,
  },
  data() {
    return {
      model: new AuthRequest().initiateEnrollment(),
      modelComplete: new AuthRequest().completeEnrollment(),
      timer: "2:00",
      timerCount: 0,
      isLogin: false,
    };
  },
  methods: {
    onSelect({ name, iso2, dialCode }) {
      this.model.customerCountryCode = dialCode;
      console.log(name, iso2, dialCode);
    },
    goToOtp() {
      this.$store.commit("auth/updateRegScreen", "otp");
    },
    isNumber(event) {
      if (!/\d/.test(event.key) && event.key !== ".")
        return event.preventDefault();
    },
    initiateEnrollment() {
      console.log(this.model);
      this.$store.dispatch("auth/initiateEnrollment", this.model);
    },
    completeEnrollment() {
      this.modelComplete.customerPhoneNumber = this.model.customerPhoneNumber;
      this.modelComplete.countryCode = this.model.customerCountryCode;
      if (localStorage.inviteId != null)
        this.modelComplete.hostId = localStorage.inviteId;
      this.modelComplete.referralCode = localStorage.referralCode;
      this.modelComplete.shareId = localStorage.shareId;
      console.log(this.modelComplete);
      this.$store.dispatch("auth/completeEnrollment", this.modelComplete);
    },
    startTimer(duration) {
      let timer = duration,
        minutes,
        seconds;
      setInterval(function () {
        minutes = parseInt((timer / 60).toString(), 10);
        seconds = parseInt((timer % 60).toString(), 10);
        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;
        this.timer = minutes + ":" + seconds;
        if (--timer < 0) {
          timer = duration;
        }
      }, 1000);
    },
    sendOtp() {
      this.$store
        .dispatch("auth/resendOtpInApp", {
          phoneNumber: this.model.customerEmail,
          countryCode: "234",
        })
        .then(() => {
          console.log("Timer...");
          this.timerCount = 120;
        });
    },
    createPayoutAccount() {
      this.model.accountCustomerId = this.auth.userInfo.customerId;
      this.model.username = this.auth.userInfo.customerEmail;
      this.model.accountBankName = this.maintenance.bankListFlw.data.filter(
        (it) => it.code === this.model.accountBankCode
      )[0].name;
      this.$store
        .dispatch("accountPayout/createAccountPayout", this.model)
        .then(() => {
          this.$store.dispatch("accountPayout/updateAccountPayout", {
            customerId: this.auth.userInfo.customerId,
          });
          this.model.otp = "";
          this.timerCount = 0;
        });
    },
  },
  computed: {
    ...mapState(["auth"]),
    countries() {
      return countriesJson;
    },
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        }
      },
      //immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
  mounted() {
    this.timerCount = 120;
    this.$store.commit("auth/updateRegScreen", "reg");
  },
};
</script>

<style scoped>
@import "../../assets/css/morah.css";
.embolden {
  font-weight: bold;
}
.vue-country-select {
  text-align: left;
  padding-left: 0;
  width: 90px;
}
/*.dial-code {*/
/*  padding: 0;*/
/*  margin-right: 5px;*/
/*  border: transparent;*/
/*}*/
/*.phone-number-textfield {*/
/*  padding-left: 60px;*/
/*}*/
</style>
