<template>
  <div class="home">
    <home-header/>
    <company-t-c-section/>
    <footer-section/>
  </div>
</template>

<script>
import HomeHeader from "@/components/menu/HomeHeader";
import FooterSection from "@/components/section/FooterSection";
import CompanyTCSection from "@/components/section/CompanyTCSection";
export default {
  name: "CompanyTC",
  components: { CompanyTCSection, FooterSection, HomeHeader }
};
</script>

<style scoped>

</style>