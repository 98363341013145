import BaseResponse from "@/model/response/BaseResponse";

class ContractResponse extends BaseResponse {
    readSingleResponse() {
        return {
            contractId: null,
            contractAmount: null,
            contractAmountType: null,
            contractBalance: null,
            contractCountryId: null,
            contractCreditAccountName: null,
            contractCreditAccountNumber: null,
            contractCreditBankCode: null,
            contractCreditCustomerId: null,
            contractDate: null,
            contractDay: null,
            contractDebitAccountName: null,
            contractDebitAccountNumber: null,
            contractDebitBankCode: null,
            contractDebitCustomerId: null,
            contractEndDate: null,
            contractFrequency: null,
            contractFtProduct: null,
            contractHour: null,
            contractMaker: null,
            contractMonth: null,
            contractNarration: null,
            contractSource: null,
            contractStartDate: null,
            contractTransactionType: null,
            contractTrigger: null,
            contractType: null,
            contractWeek: null,
            countryId: null,
            username: null,
            password: null,
            countryCode: null,
            requestType: null,
            contractStatus: null,
            contractCreatedAt: null,
            contractUpdatedAt: null,
            responseCode: null,
            responseMessage: null,
        };
    }
    readResponse() {
        return {
            responseCode: null,
            responseMessage: null,
            data: [],
        };
    }
}

export default ContractResponse;
