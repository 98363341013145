class ProductRequest {
  static productCreateRequest = {
    productName: null,
      productOrganisationId: null,
      productDescription: null,
    productLocalCurrencyPrice: null,
    productUsdPrice: null,
    productMinDeliveryTime: "2",
    productMaxDeliveryTime: "3",
    productQuantity: "2",
    productImage: null,

  }
  productReadRequest() {
    return {
      readAll: "YES",
    };
  }
  productReadByOrgRequest() {
    return {
      productOrganisationId: null,
    };
  }
 static productUpdateRequest = {
      productId: null,
   productName: null,
   productOrganisationId: null,
   productDescription: null,
   productLocalCurrencyPrice: null,
   productUsdPrice: null,
   productMinDeliveryTime: "2",
   productMaxDeliveryTime: "5",
   productQuantity: "4",
   productImage: null,

  }
  static productReadByProductIdRequest= {
      productId: null,
  }
  static buyProductRequest= {
      productId: null,
  }
  ProductDeleteRequest() {
    return {
      productId: null,
    };
  }
}

export default ProductRequest;
