<template>
  <div class="main-dashboard-section wf-section">
    <div class="main-dashboard-body mall">
      <div class="dashboardtitle">
        <div class="div-block-91">
          <h1 class="heading-18">All Malls</h1>
        </div>
        <div class="div-block-92">
          <form @submit.prevent="search" action="#" class="search w-form">
            <input
              @input="search"
              v-model="searchItem"
              type="search"
              class="search-input-copy w-input"
              maxlength="256"
              name="query"
              placeholder="Search for Malls"
              id="search"
              required=""
            />
            <input
              @click="search"
              v-model="searchItem"
              type="submit"
              value="Search"
              class="search-button w-button"
            />
          </form>
          <div
            data-hover="false"
            data-delay="0"
            class="dropdown-campaign w-dropdown"
          >
            <div class="dropdown-toggle-copy w-dropdown-toggle">
              <div class="w-icon-dropdown-toggle"></div>
              <div class="text-block-4" v-if="category === 'ALL'">
                Filter by category
              </div>
              <div class="text-block-4" v-else>{{ category }}</div>
            </div>
            <nav class="dropdown-list-3 w-dropdown-list">
              <a
                href="#"
                class="dropdown-link-5 w-dropdown-link"
                @click="selectCategory(category.value)"
                v-for="category in categories"
                v-bind:key="category.value"
                >{{ category.label }}</a
              >
            </nav>
          </div>
        </div>
      </div>
      <div
        data-w-id="0131b9f6-9df9-8551-084f-e5fcf6f3f6c8"
        style="opacity: 2"
        class="w-layout-grid grid"
      >
        <div
          v-for="mall in computedMall"
          :key="mall.organisationId"
          class="campaign-cards-dashboard mall-cards"
        >
          <div class="div-block-262" v-if="!mall.organisationBanner">
            <img
              :src="require('@/assets/images/new-mall-image.png')"
              loading="lazy"
              alt=""
            />
          </div>
          <div class="div-block-262" v-else>
            <div>
              <img
                :src="mall.organisationBanner.split(',')[0]"
                loading="lazy"
                alt=""
                class="product-image-mall"
              />
            </div>
            <div>
              <img
                :src="mall.organisationBanner.split(',')[1]"
                loading="lazy"
                alt=""
                class="product-image-mall"
              />
            </div>
            <div>
              <img
                :src="mall.organisationBanner.split(',')[2]"
                loading="lazy"
                alt=""
                class="product-image-mall"
              />
            </div>
          </div>
          <div class="div-block-263">
            <div class="div-block-17">
              <div class="div-block-18">
                <!--                <img :src="mall.organisationLogo" loading="lazy" width="50" style="border-radius: 5px" class="image-31" alt="">-->
                <b-avatar
                  style="
                    width: 45px;
                    height: 45px;
                    border-style: solid;
                    border-width: 1px;
                    border-color: #e7e7e7;
                  "
                  :src="mall.organisationLogo"
                  loading="lazy"
                  alt=""
                ></b-avatar>
              </div>
              <div>
                <h1 class="heading-13 mall">{{ mall.organisationName }}</h1>
              </div>
            </div>
            <div class="div-block-19 mall">
              <div>
                <div class="text-block-7">
                  {{ mall.organisationDescription }}
                </div>
              </div>
            </div>
            <div class="div-block-20">
              <a
                @click="viewMall(mall.organisationId)"
                class="button-4-copy-copy mall w-button"
                >View Mall</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import StarRating from "vue-star-rating";
import { mapState } from "vuex";
import OrganisationRequest from "@/model/request/OrganisationRequest";
import OrganisationResponse from "@/model/response/OrganisationResponse";
import ProductRequest from "@/model/request/ProductRequest";

export default {
  name: "MallAll",
  data() {
    return {
      model: OrganisationRequest.mallsReadRequest,
      orgModel: OrganisationRequest.prototype.organisationReadSingleRequest(),
      allProducts: ProductRequest.prototype.productReadByOrgRequest(),
      category: "All",
      searchItem: "",
      product: true,
    };
  },
  components: {
    //StarRating
  },
  methods: {
    viewMall(mall) {
      //localStorage.productID = prop
      //   this.$store.dispatch("property/updateProduct", prop)
      //   this.proId = localStorage.propertyId
      //   console.log(this.proId)
      //   if(this.auth.customerId = organisation.customerOrganisationCustomerId ){
      //     this.$router.push("/product-merchantView")
      //
      //   } else{
      //     this.$router.push("/product-customer-view")
      console.log(this.malls.customerOrganisationCustomerId);
      /////////////
      localStorage.organisationId = mall;
      this.orgModel.organisationId = localStorage.organisationId;
      if (
        this.malls.customerOrganisationCustomerId === localStorage.customerId
      ) {
        this.$router.push("/mall-registered");
      } else {
        this.$router.push("/mall-customer-view");
      }
      ///////////
      // this.$store.dispatch("organisation/updateOrganisation", this.model)
      // this.$router.push("/mall-registered");
    },
    selectCategory(_category) {
      this.$store.commit("organisation/updateAllMalls", []);
      this.$store.commit("organisation/updateFilteredMalls", []);
      let model = OrganisationRequest.mallsReadRequest;
      this.category = _category;
      if (_category === "ALL") model.category = null;
      else model.category = _category;
      this.$store.dispatch("organisation/allMalls", model);
      const menu = document.querySelector(".w-dropdown-list");
      menu.style.cssText = "display: none;";
      console.log("clickeddddd");

      console.log(_category);
    },

    search() {
      //console.log(this.campaign.campaigns);
      let filter = this.organisation.allMalls.data.filter(
        (it) =>
          it.organisationName
            .toLowerCase()
            .includes(this.searchItem.toLowerCase()) ||
          it.organisationType
            .toLowerCase()
            .includes(this.searchItem.toLowerCase())
      );
      this.$store.commit("organisation/updateFilteredMalls", filter);
    },
  },
  computed: {
    ...mapState({
      campaign: (state) => state.campaign,
      products: (state) => state.product.product.data,
      auth: (state) => state.auth,
      organisation: (state) => state.organisation,
      allMall: (state) => state.organisation.allMalls.data,
      filteredMalls: (state) => state.organisation.filteredMalls,
      malls: (state) => state.organisation.malls,
    }),

    computedMall() {
      if (this.filteredMalls.length < 1) return this.allMall;
      else return this.filteredMalls;
    },

    categories: function () {
      return OrganisationResponse.prototype.readOrganisationCategoryResponse()
        .data;
    },
  },
  mounted() {
    console.log(this.organisation.customerOrganisationCustomerId);
    console.log(this.auth.userInfo.customerId);
    this.$store.dispatch("organisation/allMalls", this.model);
    const plugin = document.createElement("script");
    plugin.setAttribute("src", "https://apvertise.com/js/webflow.js");
    plugin.async = true;
    document.head.appendChild(plugin);
  },
  created() {
    const script = document.createElement("script");
    script.src = "https://apvertise.com/js/webflow.js";
    this.$store.dispatch("organisation/allMalls", this.model);
  },
};
</script>

<style scoped>
.button-4-copy-copy {
  color: #63b8a0 !important;
}

.campaign-cards-dashboard {
  width: 353px;
  box-shadow: 0px 0px 64px rgba(31, 36, 49, 0.05);
  height: 384px;
}

.div-block-263 {
  margin-top: 10px;
  padding-right: 25px;
  padding-left: 25px;
  overflow: hidden;
  height: 224px;
  display: flex;
  flex-direction: column;
  /* JUSTIFY-CONTENT: space-between; */
  /* HEIGHT: 100%; */
  padding-bottom: 30px;
}

.div-block-19.mall {
  overflow: hidden;
//text-transform: capitalize;
  height: 100%;
  margin: 0 0 10px;
}

.button-4-copy-copy.mall:hover{
  color: #FFFFFF !important;
}

.div-block-17 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  margin: 0;
}

.div-block-20 {
  margin: 0;
  justify-content: right;
  height: 100%;
}

.product-image-mall {
  height: 160px;
}

.div-block-92{
  align-self: end;
  gap: 20px;
  align-items: baseline;
}

.w-form{
  margin: 0;
  gap: 20px;
  height: 100%;
}

.dropdown-toggle-copy{
  height: 40px;
}

.search-input-copy{
  margin: 0;
  height: 40px;
}

@media screen and (max-width: 398px){
  .campaign-cards-dashboard{
    width: 287px;
  }
}
</style>
