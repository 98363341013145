<template>
    <div v-if="auth.regScreen !== 'success'" class="right-section-2">
      <div class="social-media-signup-wrapper">
        <div class="div-block-59-copy" style="margin-top: 30px; margin-bottom: -5px;">
          <img src="@/assets/images/Logo.svg" loading="lazy" alt="">
        </div>
        <div class="create-account-block">
          <h1 class="heading-21">Create your account</h1>
          <socialite-auth-button type="register"/>
          <div class="divide">
            <div class="div-block-216"></div>
            <div class="div-block-216-copy">
              <div class="text-block-122">OR</div>
            </div>
            <div class="div-block-216"></div>
          </div>
        </div>
        <register-form/>
        <div class="sign-up-block">
          <router-link to="/logon" class="link-block-6 w-inline-block">
            <div class="text-block-23">Already have an account? <span class="text-span-4">Log in</span></div>
          </router-link>
        </div>
      </div>
    </div>
    <successful-register v-else/>
</template>

<script>
import RegisterForm from "@/components/form/RegisterForm";
import SuccessfulRegister from "@/views/authentication/SuccessfulRegister";
import { mapState } from "vuex";
import SocialiteAuthButton from "@/components/form/input/SocialiteAuthButton";

export default {
  name: "Register",
  computed:{
    ...mapState
    (["auth"])
  },
  components:{
    SocialiteAuthButton,
    SuccessfulRegister,
    RegisterForm
  },
  methods: {
    async logOut() {
      const result = await this.$gAuth.signOut();
      this.isLogin = false;
      console.log(`result`, result);
    },
    async login() {
      const googleUser = await this.$gAuth.signIn();
      console.log("googleUser", googleUser);
      console.log("getId", googleUser.getId());
      console.log("getBaseProfile", googleUser.getBasicProfile());
      console.log("getAuthResponse", googleUser.getAuthResponse());
      console.log(
        "getAuthResponse$G",
        this.$gAuth.GoogleAuth.currentUser.get().getAuthResponse()
      );
      this.isLogin = this.$gAuth.isAuthorized;
    },
  },
  mounted() {
    if (this.$route.params.inviteId != null)
      localStorage.inviteId = this.$route.params.inviteId;
    this.$store.commit("auth/updateRegScreen", "reg")
  }
};
</script>

<style scoped>

</style>