<template>
  <b-modal
    style="background: white"
    ref="flutterwave-modal"
    centered
    hide-header
    hide-footer
  >
    <div class="div-block-156" style="background-color: white">
      <a data-w-id="0e756eac-b69c-ca0d-7ba7-f2b54c7eb6e2" @click="hide()" href="#" class="close-topup-modal w-inline-block"><img src="@/assets/images/close-modal.svg" loading="lazy" alt=""></a>
    </div>
    <br />
    <div class="topup-modal-1">
      <h1 class="heading-28" style="text-align: center;">Fund your USD wallet, from any currency</h1>
    </div>
    <div class="w-form">
      <form
        id="email-form"
        @submit.prevent="navigate"
        name="email-form"
        data-name="Email Form"
        class="form-4"
      >
        <!--          <label for="name" class="field-label-15">Amount</label>-->
        <div style="display: flex; flex-direction: row">
          <button style="height: 50px; width: 25%" disabled>USD Amount</button
          ><input v-if="amount < 10"
            style="width: 75%; border-color: red;"
            type="number"
            v-model="amount"
            required="true"
            step="0.01"
            min="1"
            class="text-field-17 w-input"
            maxlength="256"
            name="name"
            data-name="Name"
            placeholder="Enter Amount"
            id="namee"
          /><input v-else
            style="width: 80%"
            type="number"
            v-model="amount"
            required="true"
            step="0.01"
            min="1"
            class="text-field-17 w-input"
            maxlength="256"
            name="name"
            data-name="Name"
            placeholder="Enter Amount"
            id="name"
          />
        </div>
        <i style="text-decoration-style: wavy; color: #63b8a0"
          >Minimum amount is USD 10.00</i
        >
        <br />
        <br />
        <input
          :disabled="amount < 10"
          type="submit"
          value="Continue"
          style="z-index: 10; display: block"
          class="button-18 w-button"
        />
      </form>
    </div>
    <!--    </div>-->
    <select-card-currency-modal :model="{amount: amount}" :show="showModal"/>
  </b-modal>
</template>

<script>
import { mapState } from "vuex";
import SelectCardCurrencyModal from "@/components/modal/SelectCardCurrencyModal";

export default {
  name: "FlutterwaveModal",
  components: { SelectCardCurrencyModal },
  props: ["show"],
  data() {
    return {
      showModal: false,
      amount: "",
    };
  },
  created() {
    const script = document.createElement("script");
    script.src =
      window.__env.app.stage === "dev"
        ? "https://ravemodal-dev.herokuapp.com/v3.js"
        : "https://checkout.flutterwave.com/v3.js";
    document.getElementsByTagName("head")[0].appendChild(script);
  },
  methods: {
    callback() {
      console.log("Payment made, verify payment");
    },
    hide() {
      this.$refs["flutterwave-modal"].hide();
    },
    navigate() {
      //this.hide()
      this.showModal = !this.showModal
    },
    makePayment() {
      console.log(window.__env.app.flwKey);
      console.log(this.auth.userInfo.customerEmail.trim());
      window.FlutterwaveCheckout({
        public_key: window.__env.app.flwKey,
        tx_ref: `${this.reference}-${this.auth.userInfo.customerId}-${this.auth.userInfo.accounts[0].accountNumber}-${this.auth.userInfo.customerBankCode}`,
        amount: this.amount,
        currency: "USD",
        payment_options: "card,mobilemoney,ussd",
        customer: {
          name: this.auth.userInfo.customerName, //this.name,
          email: this.auth.userInfo.customerEmail.trim(),
        },
        callback: (response) => this.callback(response),
        customizations: {
          title: "Wallet Funding",
          description: "Payment for items in cart",
          logo: window.__env.app.logo,
        },
      });
    },
  },
  computed: {
    ...mapState(["auth"]),
    reference() {
      let text = "";
      let possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (let i = 0; i < 10; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      return text;
    },
  },
  watch: {
    // whenever question changes, this function will run
    show: function () {
      this.amount = "";
      this.$refs["flutterwave-modal"].show();
    },
  },
};
</script>

<style>
.modal-content{
  background-color: white;
}
/*.close-topup-modal {*/
/*  position: absolute;*/
/*  left: auto;*/
/*  top: 0%;*/
/*  right: 0%;*/
/*  bottom: auto;*/
/*  display: block;*/
/*}*/
</style>
