<template>
  <div class="main-campaign-body">
    <div class="dashboard-header">
      <!--TODO DASHBOARD TITLE      -->
      <dashboard-title v-if="!count" />
      <!--TODO CAMPAIGN CARDS      -->
      <b-row v-if="count">
        <b-col
          md="4"
          v-for="campaignObj in modifiedCampaign"
          v-bind:key="campaignObj.campaignId"
        >
          <campaign-all-card
            :title="campaignObj.campaignName"
            :category="campaignObj.organisationType"
            :logo="campaignObj.organisationLogo"
            :company-name="campaignObj.organisationName"
            :customer-id="campaignObj.customerOrganisationCustomerId"
            :campaign-id="campaignObj.campaignId"
            :description="campaignObj.campaignDescription"
            :campaign-budget-exhausted="campaignObj.campaignBudgetExhausted"
          /><br />
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col
          md="4"
          v-for="campaignObj in campaigns"
          v-bind:key="campaignObj.campaignId"
        >
          <campaign-all-card
            :title="campaignObj.campaignName"
            :category="campaignObj.organisationType"
            :logo="campaignObj.organisationLogo"
            :company-name="campaignObj.organisationName"
            :customer-id="campaignObj.customerOrganisationCustomerId"
            :campaign-id="campaignObj.campaignId"
            :description="campaignObj.campaignDescription"
            :campaign-budget-exhausted="campaignObj.campaignBudgetExhausted"
          /><br />
        </b-col>
      </b-row>
      <span v-if="!showMoreIsRemoved">
        <p style="text-align: center" v-if="!campaign.campaignLoading"><a
          href="#"
          @click="loadMore"
          class="post-campaign-button w-nav-link"
          >Load More</a
        ></p>
        <p style="text-align: center" v-else><a
          href="#"
          style="text-align: center"
            class="post-campaign-button w-nav-link"
          ><b-spinner small /> &nbsp;Load More</a
        ></p>
      </span>
    </div>
  </div>
</template>

<script>
import DashboardTitle from "@/components/menu/DashboardTitle";
import CampaignAllCard from "@/components/card/CampaignAllCard";
import { mapState } from "vuex";
export default {
  name: "CampaignAll",
  props:["count","showMoreIsRemoved"],
  components: { CampaignAllCard, DashboardTitle },
  data() {
    return {};
  },
  methods: {
    loadMore() {
      let model = this.campaign.model;
      model.page += 1;
      this.$store.commit("campaign/updateModel", model);
      this.$store.dispatch("campaign/updateCampaigns", this.campaign.model);
    },
  },
  computed: {
    ...mapState(["campaign"]),
    campaigns: function(){
      if (this.campaign.filteredCampaigns.length < 1)
        return this.campaign.campaigns.data
      else
        return this.campaign.filteredCampaigns
    },
    modifiedCampaign: function() {
      return this.campaigns.slice(0, this.count)
    }
  },
  created() {
    this.$store.dispatch("campaign/updateCampaigns", this.campaign.model);
  },
};
</script>

<style scoped></style>
