// import router from "@/router";
// import swal from "sweetalert";
import store from "@/store/store";
import ReviewResponse from "@/model/response/ReviewResponse";
import ReviewRequest from "@/model/request/ReviewRequest";
import ReviewService from "@/service/ReviewService";

export const state = {
  review: ReviewResponse.reviewReadByIdResponse,
  reviews: ReviewResponse.prototype.reviewReadResponse(),
  screen: "one",
  model: ReviewRequest.prototype.ReviewReadRequest(),
  reviewLoading: false,
};
export const getters = {};
export const mutations = {
  updateReview: (state, payload) => {
    state.review = payload;
  },
  updateReviews: (state, payload) => {
    state.reviews = payload;
  },
  updateModel: (state, payload) => {
    state.model = payload;
  },
  updateReviewLoading: (state, payload) => {
    state.reviewLoading = payload;
  },
};
export const actions = {
  updateReview: ({ commit }, payload) => {
    //TODO ORDER LOADING
    commit("updateReviewLoading", true);
    return ReviewService.callReadSingleReviewApi(payload)
      .then((response) => {
        commit("updateReviewLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          commit("updateReview", responseData);
        } else {
          store.commit("updateMessage", {
            type: "error",
            title: "Error",
            body: responseData.responseMessage,
          });
        }
      })
      .catch((err) => {
        commit("updateReviewLoading", false);
        console.log(err);
      });
  },
  updateReviews: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateReviewLoading", true);
    return ReviewService.callReadReviewApi(payload)
      .then((response) => {
        commit("updateReviewLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          commit("updateReviews", responseData);
        }
      })
      .catch((err) => {
        commit("updateReviewLoading", false);
        console.log(err);
      });
  },

  createReview: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateReviewLoading", true);
    return ReviewService.callCreateReviewApi(payload)
      .then((response) => {
        commit("updateReviewLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          store.commit("updateMessage", {
            type: "success",
            title: "Success",
            body: responseData.responseMessage,
          });
        } else {
          store.commit("updateMessage", {
            type: "error",
            title: "Error",
            body: responseData.responseMessage,
          });
        }
      })
      .catch((err) => {
        commit("updateReviewLoading", false);
        console.log(err);
      });
  },

  // editReview: ({ commit }, payload) => {
  //   //TODO USER LOADING
  //   commit("updateReviewLoading", true);
  //   return ReviewService.callUpdateReviewApi(payload)
  //     .then((response) => {
  //       commit("updateReviewLoading", false);
  //       let responseData = response.data;
  //       if (responseData.responseCode === "00") {
  //         swal("Success", responseData.responseMessage, "success");
  //       } else {
  //         swal("Error", responseData.responseMessage, "error");
  //       }
  //     })
  //     .catch((err) => {
  //       commit("updateReviewLoading", false);
  //       console.log(err);
  //     });
  // },
  //
  // deleteReview: ({ commit }, payload) => {
  //   //TODO USER LOADING
  //   commit("updateReviewLoading", true);
  //   return ReviewService.callDeleteReviewApi(payload)
  //     .then((response) => {
  //       commit("updateReviewLoading", false);
  //       let responseData = response.data;
  //       if (responseData.responseCode === "00") {
  //         swal("Success", responseData.responseMessage, "success");
  //       } else {
  //         swal("Error", responseData.responseMessage, "error");
  //       }
  //     })
  //     .catch((err) => {
  //       commit("updateReviewLoading", false);
  //       console.log(err);
  //     });
  // },
};
