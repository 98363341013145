<template>
  <div :class="transactionClass">
    <div class="div-block-75">
      <div class="text-block-31">Payout Transactions</div>
      <a href="#" @click="showModal = !showModal" class="link-block-7 w-inline-block">
        <div class="text-block-35">Request For Payout</div>
      </a>
    </div>
    <div
      style="height: 500px"
      class="overflow-auto"
      v-if="transaction.payouts.data.length > 0"
    >
      <div
        v-for="trxn in transaction.payouts.data"
        v-bind:key="trxn.id"
        class="transaction-box"
      >
        <div class="div-block-70">
          <div class="div-block-71">
            <img src="@/assets/images/Debit.svg" loading="lazy" alt="" />
          </div>
          <div class="div-block-76">
            <div class="div-block-78">
              <div v-if="trxn.payoutStatus === 'SUCCESSFUL'" style="color: green" class="text-block-32">{{ trxn.payoutStatus }}</div>
              <div v-else-if="trxn.payoutStatus === 'FAILED'" style="color: red" class="text-block-32">{{ trxn.payoutStatus }}</div>
              <div v-else class="text-block-32">{{ trxn.payoutStatus }}</div>
              <div style="color: black" class="text-block-33-copy">
                - {{ trxn.payoutAmount | formatAmount }} USD
              </div>
            </div>
            <div class="div-block-77">
              <div class="text-block-34">
                {{ trxn.payoutCreatedAt | moment("calendar") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <span v-else>
      <div class="div-block-105-copy">
        <img
          src="@/assets/images/transaction.svg"
          loading="lazy"
          width="73"
          alt=""
          class="image-41-copy"
        />
        <div class="text-block-56">No payout transactions</div>
        <p class="paragraph-11">You have no payout transactions</p>
      </div>
    </span>
    <payout-modal :show="showModal"/>
  </div>
</template>

<script>
import { mapState } from "vuex";
import PayoutModal from "@/components/modal/PayoutModal";

export default {
  name: "PayoutCard",
  components: { PayoutModal },
  data(){
    return {
      showModal: false,
    }
  },
  computed: {
    ...mapState(["auth", "transaction"]),
    currency: function () {
      if (this.transaction.transactions.accounts.length < 1) return "---";
      else return this.transaction.transactions.accounts[0].accountCurrency;
    },
    balance: function () {
      if (this.transaction.transactions.accounts.length < 1) return "0.00";
      else return this.transaction.transactions.accounts[0].accountBalance;
    },
    transactionClass: function () {
      if (this.$route.path === "/view-wallet") return "div-block-95";
      else return "transaction-block";
    },
    debits: function () {
      if (this.transaction.transactions.transactions.length < 1) return "0.00";
      else
        return this.transaction.transactions.transactions
          .filter((it) => it.drCr === "DR")
          .reduce(
            (init, curr) =>
              (init += parseFloat(
                curr.amount == null
                  ? "0.00"
                  : curr.amount.toString().replace(/,/g, "")
              )),
            0
          )
          .toFixed(2);
    },
    credits: function () {
      if (this.transaction.transactions.transactions.length < 1) return "0.00";
      else
        return this.transaction.transactions.transactions
          .filter((it) => it.drCr === "CR")
          .reduce(
            (init, curr) =>
              (init += parseFloat(
                curr.amount == null
                  ? "0.00"
                  : curr.amount.toString().replace(/,/g, "")
              )),
            0
          )
          .toFixed(2);
    },
  },
  mounted() {
    this.$store.dispatch("transaction/payoutReadByCustomerId", {
      customerId: this.auth.userInfo.customerId,
      readAll: "ALL",
    });
  },
};
</script>

<style scoped></style>
