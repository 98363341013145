import BaseResponse from "@/model/response/BaseResponse";

class MaintenanceResponse extends BaseResponse {
    readSingleResponse() {
        return {

            smsAccountBalance: null,
            smsAccountName: null,
            smsAccountNumber: null,
            smsApiResponse: null,
            smsBankCode: null,
            smsContent: null,
            smsToken: null,
            smsBankName: null,
            smsBody: null,
            smsCbaReference: null,
            smsCounterAccount: null,
            smsCounterParty: null,
            smsCounterPartyBank: null,
            smsCreatedAt: null,
            smsDrCr: null,
            smsId: null,
            smsPhone: null,
            smsReference: null,
            smsStatus: null,
            smsTitle: null,
            smsTrials: null,
            journalNarration: null,
            smsTrnAmount: null,
            smsUpdatedAt: null,
            sysTrnNarration: null,
            CreatedAt: null,
            UpdatedAt: null,
            responseCode: null,
            responseMessage: null,
        };
    }
    readResponse() {
        return {
            responseCode: null,
            responseMessage: null,
            data: [],
        };
    }
}

export default MaintenanceResponse;