<template>
  <div class="section-3 wf-section">
    <div class="t-c-container">
      <div class="header-t-c">
        <h1 class="heading-36">Terms &amp; Conditions for Influencers</h1>
        <div class="text-block-94">Last updated: October 28, 2021</div>
      </div>
      <div class="div-block-169">
        <div class="text-block-95">These Influencer Terms and Conditions constitute a legally binding agreement between you, the influencer and APVERTISE concerning the access and use of our platform and associated services.<br>‍<br>The Influencer Agreement is concluded by you accepting these Influencer Terms and Conditions through the AP VER TISE Platform. APVERTISE is a mere platform and is therefore not a party in the Collaboration Agreement.<br>‍<br>Hereafter you will agree to the following:</div>
      </div>
      <div class="table-of-contents">
        <h1 class="heading-37">Table Of Contents</h1>
        <ol role="list" class="list-2">
          <li class="list-item">Table of content</li>
          <li class="list-item">Definitions</li>
          <li class="list-item">What Apvertise is</li>
          <li class="list-item">What it takes to become an Influencer</li>
          <li class="list-item">Assignments</li>
          <li class="list-item">Intellectual Property Rights</li>
          <li class="list-item">Financial Agreements</li>
          <li class="list-item">Privacy and Processing Personal Data</li>
          <li class="list-item">Liability, Warranties and Indemnifications</li>
          <li class="list-item">Duration and Termination</li>
          <li class="list-item">Miscellaneous</li>
          <li class="list-item">Governing Law and Jurisdiction</li>
        </ol>
        <div class="text-block-95">In these Influencer Terms and Conditions, words are used which start with a capital letter. Those words have the meaning as set out hereunder in this article.</div>
      </div>
      <div class="definitions">
        <h1 class="heading-37">Definitions</h1>
        <ol role="list" class="list-2">
          <li class="list-item">
            Assignment: A Brand or Agency assignment to contribute to a Campaign
            as an Influencer.
          </li>
          <li class="list-item">Agency: an agency who acts on behalf of a Brand. Usually, the agency runs an overall campaign for a Brand, in which campaign there are smaller influencer marketing campaigns.</li>
          <li class="list-item">Campaign: an influencer marketing campaign as made available for You by a Brand or Agency.</li>
          <li class="list-item">Collaboration Agreement: the agreement between You and the Brand or Agency for an Assignment.</li>
          <li class="list-item">Influencer: someone that is able to endorse a service or product of a Brand through his or her social media account(s) or weblog(s).</li>
          <li class="list-item">APVERTISE Platform: the website, the marketplace and -if any- the mobile app of Us.</li>
          <li class="list-item">Influencer Network: the database of APVERTISE approved Influencers.</li>
          <li class="list-item">You: refers to you as an Influencer (including Your).</li>
          <li class="list-item">We: refers to us (including Us and Our), the United Kingdom Limited Liability company “APVERTISE”.</li>
        </ol>
      </div>
      <div class="what-apvertise-is">
        <h1 class="heading-37">What Apvertise Is</h1>
        <ol role="list" class="list-2">
          <li class="list-item">You acknowledge that APVERTISE is a platform bringing Brands or Agencies and Influencers together, and that We are therefore never a party in the actual Collaboration Agreement between a Brand or Agency and You. We cannot be held responsible nor liable for shortcomings of Brand or Agency regarding the (non) performance of the Collaboration Agreement. By creating and submitting a campaign on Apvertise platform, you have agreed to engage any influencer that is interested in sharing the campaign.</li>
          <li class="list-item">However, as part of Our service, We do help You and the Brands or Agencies with the payments based on the number of times the influencer is able to share the brand’s campaign through the influencer’s social media network. The billing and payment is automatically completed by the Apvertise platform.</li>
        </ol>
      </div>
      <div class="what-it-takes-to-become-an-influencer">
        <h1 class="heading-37">What it takes to become an Influencer</h1>
        <ol role="list" class="list-2">
          <li class="list-item">If You want to become part of Our Influencer Network, You must apply through our APVERTISE Platform. We will revise and investigate your application thoroughly. We may turn you down in case we don’t see added value for the Brands and Agencies, to Our own discretion. We hope you don’t experience any hard feelings!</li>
          <li class="list-item">Once chosen as an Influencer, You will have access to our APVERTISE Platform and a profile which can be partially customized by You. The information We gather from the APVERTISE Platform in accordance with paragraph 8.4 cannot be changed nor modified by You.</li>
          <li class="list-item">In the case a Brand or Agency chooses You to represent them in a particular Campaign, You will agreeto carefully follow Our APVERTISE Code of Conduct. Keep it professional, clean and neat; Your posts should not include nudity, be of a sexually explicit nature, nor violate the terms and conditions of Instagram and other social media platforms. You indemnify Us from claims of third parties regarding not adhering to the APVERTISE Code of Conduct of the relevant social media platform conditions.</li>
        </ol>
      </div>
      <div class="what-it-takes-to-become-an-influencer">
        <h1 class="heading-37">Assignments</h1>
        <ol role="list" class="list-2">
          <li class="list-item">In case You are interested in an Assignment, You must apply through the APVERTISE Platform. A Campaign may have several Assignments. The Brand or Agency will approve or disapprove You for an assignment. Also be aware of the fact that the Brand or Agency may engage multiple influencers for a Campaign.</li>
          <li class="list-item">Agreement. Creation of a campaign on Apvertise is an open offer to the influencer network, acceptance is deemed completed for the Collaboration Agreement when any influencer shares the campaign.</li>
          <li class="list-item">Once offer is accepted, You agree to be fully engaged in the Assignment and be available to perform the duties necessary to successfully fulfill the completion of the Assignment. These duties include but are not limited to: (i) generate post, stories and/or videos (live (at event) and otherwise) in the timeframe of the campaign, (iii) utilize the previously agreed upon hashtags to properly identify sponsored content (for example: #SPONSOR, #AD, etc.), (iv) be clear when utilizing hashtags, these should be directly related to the Campaign, (v) make sure that your post are 100% dedicated to the Campaign the Brand or Agency requested You for; therefore, avoid mixing brands and interest. In case You are an expert in a certain niche area such as fashion, the mixing of Brands could be accepted.</li>
        </ol>
      </div>
      <div class="what-it-takes-to-become-an-influencer">
        <h1 class="heading-37">Intellectual Property Rights</h1>
        <ol role="list" class="list-2">
          <li class="list-item">The intellectual property rights regarding the APVERTISE Platform, the tradenames and trademarks of Us, remain vested in Us.</li>
          <li class="list-item">The intellectual property rights regarding the material Brands or Agencies put at Our and Your disposal through the APVERTISE Platform, remain vested in the Brand, Agency or any third party from whom Brand or Agency acquired a license.</li>
        </ol>
      </div>
      <div class="financial-agreements">
        <h1 class="heading-37">Financial Agreements</h1>
        <ol role="list" class="list-2">
          <li class="list-item">In case You are situated within the European Union, You need to have a valid V.A.T. number. Without it, You cannot become part of the Influencer Network. We check the validity of a V.A.T. number.</li>
          <li class="list-item">We make payment into your wallet within 30 (thirty) days, provided your posts, share and creations have been verified as authentic. We reserve the right to determine authenticity.</li>
          <li class="list-item">Apvertise charges a fee of 10% of Your earnings.</li>
        </ol>
      </div>
      <div class="privacy-processing-personal-data">
        <h1 class="heading-37">Privacy &amp; Processing Personal Data</h1>
        <ol role="list" class="list-2">
          <li class="list-item">You understand that We, to fulfill Our obligations, We have to process Your personal data. Under the General Data Protection Regulation (“GDPR”), We are controller. Also be aware of the fact that the Agency or Brand is also a controller under the GDPR. For that reason, we do not enter into a data processing agreement with You, nor with the Agency or Brand.</li>
          <li class="list-item">To verify Your account, We may ask You to send us a copy of Your passport or valid picture ID. You must make sure that You black out at least: Your photo, Your place of birth and Your social security number (in case visible). After validation We delete the copies of the passport of picture ID.</li>
          <li class="list-item">We process your personal data in accordance with our separate privacy policy. We may change the privacy policy from time to time. Take notice of the privacy policy on a regular basis.</li>
          <li class="list-item">During signing up to become an Influencer, We ask You to give Us access to Your social media accounts. In case You gave permission, We use the access to create a profile of You, including the amount of followers, the amount of posts, the post frequency, etc. The profile is available for Brands and Agencies. The access You gave Us, is done through the respective social media websites. This means that the access is governed by the (privacy) terms and conditions of the respective social media websites. We recommend You to take notice of those (privacy) terms and conditions. You must make sure We have access for the duration of the Influencer Agreement. In case -for whatever reason- We do not have access anymore, We notify You immediately. In case no-access was not remedied by You, We have to right to terminate the Influencer Agreement with immediate effect.</li>
          <li class="list-item">We process your personal data in accordance with our separate privacy policy. We may change the privacy policy from time to tiTo connect your YouTube social profile, APVERTISE uses the YouTube API Services. By agreeing to these Influencer Terms and Conditions, You agree with and are bound to YouTube&#x27;s Terms of Service (https://www.youtube.com/t/terms). In addition, the Google Privacy Policy (http://www.google.com/policies/privacy) should be read and agreed upon by You as an addition to APVERTISE Privacy Policy (https://www.Apvertise.com/privacy/).me. Take notice of the privacy policy on a regular basis.</li>
          <li class="list-item">We may process Your personal data for statistical or demographic purposes. We make sure that Your personal data is anonymous and aggregated.</li>
        </ol>
      </div>
      <div class="liability-warranties-indemnifications">
        <h1 class="heading-37">Liability, Warranties &amp; Indemnifications</h1>
        <ol role="list" class="list-2">
          <li class="list-item">WE ARE NOT LIABLE FOR ANY ATTRIBUTABLE SHORTCOMINGS, NOR FOR ANY OTHER SHORTCOMINGS (BASED ON TORT FOR EXAMPLE), UNLESS THE SHORTCOMING IS DUE TO WILLFUL MISCONDUCT OR CONSCIOUS RECKLESSNESS OF OUR TOP LEVEL MANAGEMENT. IN CASE THIS LIMITATION OF LIABILITY IS LAWFULLY UNENFORCEABLE, THE LIABILITY FOR THE AFOREMENTIONED SHORTCOMINGS ARE LIMITED TO AN AMOUNT OF € 250 (TWO HUNDRED AND FIFTY EUROS), UNLESS THE SHORTCOMING IS DUE TO WILLFUL MISCONDUCT OR CONSCIOUS RECKLESSNESS OF OUR TOP LEVEL MANAGEMENT.</li>
          <li class="list-item">You hereby accept the functionality of the APVERTISE Platform “as is”. You also accept that the functionality of the APVERTISE Platform can be subject of unilateral change, since We try to make it better along the way.</li>
          <li class="list-item">We process your personal data in accorWe strive for a high availability of the APVERTISE Platform, however, We cannot warrant that the APVERTISE Platform is always available to You without interruptions.dance with our separate privacy policy. We may change the privacy policy from time to time. Take notice of the privacy policy on a regular basis.</li>
          <li class="list-item">You hereby warrant that You are the actual Influencer. This means that You cannot have -for example- your management agency enters into the Influencer Agreement on behalf of You. You indemnify Us for all costs and damages in case it turns out, that the party entered into the Influencer Agreement is not actually You.</li>
          <li class="list-item">You indemnify Us for all costs and damages in case of Your shortcomings regarding the execution of the Collaboration Agreement.</li>
        </ol>
      </div>
      <div class="duration-termination">
        <h1 class="heading-37">Duration &amp; Termination</h1>
        <ol role="list" class="list-2">
          <li class="list-item">The Influencer Agreement is entered into for an indefinite period of time.</li>
          <li class="list-item">You may always terminate the Influencer Agreement, just by terminating Your account.</li>
          <li class="list-item">In case We owe You money, we will make sure that We settle the outstanding amounts as We agreed upon in paragraph 7. Be aware of the fact that You always must finish any running Assignments. Terminating the Influencer Agreement does not mean you are exempted from any running Assignments.</li>
          <li class="list-item">We may terminate the Influencer Agreement at any time for any reason.</li>
        </ol>
      </div>
      <div class="miscellaneous">
        <h1 class="heading-37">Miscellaneous</h1>
        <ol role="list" class="list-2">
          <li class="list-item">The account credentials You use to have access to the APVERTISE Platform are strictly personal. You cannot share those with others.</li>
          <li class="list-item">We are entitled to transfer Our rights and obligations out of the Influencer Agreement to a third party. This is especially allowed in case of a transfer due to a change of legal entity, transfer of Our business or merger of Our business. You hereby grant Your permission to us to do so. A possible transfer is without You losing any rights under the Influencer Agreement.</li>
          <li class="list-item">Without prior permission of Us, You are not allowed to transfer the rights and obligations under the Influencer Agreement to a third party. The permission can by subject to additional stipulations, for example the stipulation that the transfer must be made free of any charge.</li>
          <li class="list-item">We may change these influencer Terms and Conditions unilaterally. We make sure we communicate any changes in due time. In case you don’t feel comfortable with the changes, you are entitled to terminate the Influencer Agreement as stated in paragraph 10.2.</li>
          <li class="list-item">To the extent that any provision of these Influencer Terms and Conditions are void and/or annulled, all other conditions between parties remain in force. In such a case, We will agree to a new set of provisions, which will correspond as much as possible to the void or annulled conditions.</li>
          <li class="list-item">Headings and captions used in these Influencer Terms and Conditions are for reference purposes only.</li>
        </ol>
      </div>
      <div class="governing-law-and-jurisdiction">
        <h1 class="heading-37">Governing Law And Jurisdiction</h1>
        <ol role="list" class="list-2">
          <li class="list-item">The Influencer Agreement and Influencer Terms and Conditions are governed by the laws of the United Kingdom.</li>
          <li class="list-item">All disputes will solely be settled exclusively by the competent court in England.</li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InfluenceTCSection"
};
</script>

<style scoped>

</style>